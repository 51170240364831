import { Flex, Alert, Text, Button, AlertIcon } from '@chakra-ui/react';
import routes from 'routes';
import React from 'react';
import { Link } from 'react-router-dom';
import { useCurrentUser } from 'auth/user/CurrentUserController';

const LockedEvent: React.FC = () => {
  const currentUser = useCurrentUser();
  return (
    <Flex justifyContent="center" alignItems="center" position="relative">
      <Flex
        style={{ filter: 'blur(3px)' }}
        position="absolute"
        justifyContent="space-between"
        width="100%"
        pointerEvents="none"
        opacity=".35"
        overflow="hidden"
        flexWrap="nowrap"
      >
        <Text isTruncated>
          Your plan does not currently allow you to see this information.
        </Text>
        <Text isTruncated>
          Your plan does not currently allow you to see this information.
        </Text>
      </Flex>
      <Flex>
        <Alert status="warning" borderRadius="lg">
          <AlertIcon />
          {currentUser.access.canModifyPlan ? (
            <Flex>
              <Flex mx="auto">
                <Text>Upgrade your plan to see this activity</Text>
                <Button
                  as={Link}
                  size="xs"
                  variant="outline"
                  colorScheme="warning"
                  to={routes.settings.plans}
                  display="inline-flex"
                  mx={2}
                >
                  Upgrade Now
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Text>
              Contact your administrator about upgrading your plan to view this
              activity
            </Text>
          )}
        </Alert>
      </Flex>
    </Flex>
  );
};

export default LockedEvent;
