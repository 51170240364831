import React, { useMemo } from 'react';
import {
  Grid,
  Stack,
  StackProps,
  Icon,
  IconProps,
  Button,
  ButtonProps,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverBodyProps,
  PopoverArrow,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import routes from 'routes';
import { GetStatsParams } from 'backend/api-types/dashboard';
import { ACTIVITY_FEED_EVENTS } from 'activity/constants';
import ThreatIcon from 'activity/components/ThreatIcon';
import useStats from 'monitoring/hooks/useStats';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { timezoneOffset } from 'backend/services';
import {
  DashboardStat,
  DashboardStatNumber,
  DashboardStatNumberProps,
  DashboardStatLabel,
  Divider,
  BoxHeading,
  WrapperBox,
} from './styled';

interface DashboardStatsProps extends StackProps {
  /** Filter the stats for a user. */
  user?: number;
  /** Filter the stats from a start date. */
  fromDate?: Date;
  /** Filter the stats from to an date. */
  toDate?: Date;
  goodTabIndex: number;
  warningsTabIndex: number;
  threatsTabIndex: number;
  onStatClick: (index: number) => void;
}

const DashboardStats: React.FC<DashboardStatsProps> = ({
  user,
  fromDate,
  toDate,
  goodTabIndex,
  warningsTabIndex,
  threatsTabIndex,
  onStatClick,
  ...props
}) => {
  const statsFilters = useMemo((): GetStatsParams => {
    return {
      user,
      from: fromDate?.getTime(),
      to: toDate?.getTime(),
      action: ACTIVITY_FEED_EVENTS.map((e) => `^${e}$`).join('|'),
      tz: timezoneOffset,
    };
  }, [user, fromDate, toDate]);
  const currentBusiness = useCurrentBusiness();
  const { data, loading } = useStats(statsFilters, currentBusiness.data?.id);
  const isTeamView = !user;
  const communicationsRoute = isTeamView
    ? routes.keyPacket.list.team
    : routes.keyPacket.list.user;

  const handleStatClick = (tabIndex: number) => () => onStatClick(tabIndex);

  const popoverTriggerButtonProps: ButtonProps = {
    variant: 'unstyled',
    height: 'auto',
    minWidth: 'auto',
    _focus: { outline: 'none' },
    p: 0,
    borderRadius: 0,
    ml: 1,
    mr: -6,
    top: '-2px',
    position: 'relative',
  };

  const popoverTriggerIconProps: Omit<IconProps, 'css'> = {
    color: 'gray.300',
    boxSize: 5,
  };

  const popoverBodyProps: PopoverBodyProps = {
    fontWeight: 'normal',
  };

  const dashboardStatNumberProps: DashboardStatNumberProps = {
    opacity: data || !loading ? 1 : 0,
  };

  const countUnavailable = 'n/a';

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={6}
      userSelect="none"
      {...props}
    >
      <WrapperBox
        flexBasis={{ base: '100%', md: '40%' }}
        data-tour-id="activity-communications"
      >
        <BoxHeading>
          {isTeamView ? 'Team' : 'My'} Communications
          <Popover trigger="hover">
            <PopoverTrigger>
              <Button {...popoverTriggerButtonProps}>
                <Icon as={HiQuestionMarkCircle} {...popoverTriggerIconProps} />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody {...popoverBodyProps}>
                A communication is counted as any file, email, or message that
                has been encrypted.
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </BoxHeading>

        <Grid templateColumns="1fr 1px 1fr" gap={6} width="100%">
          <DashboardStat as={Link} to={communicationsRoute}>
            <DashboardStatNumber {...dashboardStatNumberProps}>
              {data?.communications.toLocaleString() ?? countUnavailable}
            </DashboardStatNumber>
            <DashboardStatLabel>Total</DashboardStatLabel>
          </DashboardStat>

          <Divider />

          <DashboardStat
            as={Link}
            to={{
              pathname: communicationsRoute,
              state: { filterExpired: true },
            }}
          >
            <DashboardStatNumber {...dashboardStatNumberProps}>
              {data?.expired.toLocaleString() ?? countUnavailable}
            </DashboardStatNumber>
            <DashboardStatLabel>Expired</DashboardStatLabel>
          </DashboardStat>
        </Grid>
      </WrapperBox>

      <WrapperBox
        flexBasis={{ base: '100%', md: '60%' }}
        data-tour-id="activity-activities-stats"
      >
        <BoxHeading>
          {isTeamView ? 'Team' : 'My'} Activities
          <Popover trigger="hover">
            <PopoverTrigger>
              <Button {...popoverTriggerButtonProps}>
                <Icon as={HiQuestionMarkCircle} {...popoverTriggerIconProps} />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody {...popoverBodyProps}>
                An activity is counted as any action performed on a
                communication. Examples of activities include reading a message,
                encrypting an email and revoking access to a file.
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </BoxHeading>

        <Grid
          templateColumns={{ base: '1fr', sm: '1fr 1px 1fr 1px 1fr' }}
          gap={{ base: 3, md: 6 }}
          width="100%"
        >
          <DashboardStat onClick={handleStatClick(goodTabIndex)}>
            <ThreatIcon threatGroup="good" boxSize={6} />
            <DashboardStatNumber {...dashboardStatNumberProps}>
              {data?.good.toLocaleString() ?? countUnavailable}
            </DashboardStatNumber>
            <DashboardStatLabel>Good</DashboardStatLabel>
          </DashboardStat>

          <Divider />

          <DashboardStat onClick={handleStatClick(warningsTabIndex)}>
            <ThreatIcon threatGroup="warn" boxSize={6} />
            <DashboardStatNumber {...dashboardStatNumberProps}>
              {data?.warnings.toLocaleString() ?? countUnavailable}
            </DashboardStatNumber>
            <DashboardStatLabel>Warnings</DashboardStatLabel>
          </DashboardStat>

          <Divider />

          <DashboardStat onClick={handleStatClick(threatsTabIndex)}>
            <ThreatIcon threatGroup="threat" boxSize={6} />
            <DashboardStatNumber {...dashboardStatNumberProps}>
              {data?.alert.toLocaleString() ?? countUnavailable}
            </DashboardStatNumber>
            <DashboardStatLabel>
              <Text display={{ base: 'block', xl: 'none' }}>Attention</Text>
              <Text display={{ base: 'none', xl: 'block' }}>
                Needs Attention
              </Text>
            </DashboardStatLabel>
          </DashboardStat>
        </Grid>
      </WrapperBox>
    </Stack>
  );
};

export default DashboardStats;
