import React, { useState } from 'react';
import {
  Text,
  Flex,
  Box,
  Alert,
  Button,
  FormControl,
  FormLabel,
  Select,
  Collapse,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import Crypto from 'crypto-js';
import { useForm } from 'react-hook-form';
import { HiChevronDown } from 'react-icons/hi';
import { StackedTextarea } from 'shared/components/form';
import { useDashboardContentContext } from 'dashboard/layout/DashboardContentContext';
import CodeView, { Code } from 'shared/components/CodeView';
import { TutorialName } from '../constants';
import {
  ChapterContainer,
  ChapterContent,
  ChapterFooter,
  ChapterHeader,
  ChapterIntroBox,
  ChapterIntroContent,
  ChapterNavLink,
  ChapterResponseAlert,
} from '../components';
import { useEncryptTutorial } from '../encrypt/EncryptTutorialContext';
import { getCipherAlgorithm, Ciphers } from '../encrypt/helpers';
import { useDecryptTutorial } from './DecryptTutorialContext';
import chapters from './chapters';

const CURRENT_CHAPTER = 2;

interface EncryptionKeyProps {
  encryptionKey: string;
}

const EncryptionKey: React.FC<EncryptionKeyProps> = ({ encryptionKey }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box mb={6}>
      <Button
        variant="unstyled"
        fontWeight="bold"
        fontSize="lg"
        textTransform="none"
        height="auto"
        borderRadius={0}
        display="inline-flex"
        onClick={onToggle}
      >
        Encryption Key
        <Icon
          as={HiChevronDown}
          boxSize={6}
          display="block"
          transform={isOpen ? 'rotate(-180deg)' : undefined}
        />
      </Button>
      <Collapse in={isOpen}>
        <Box
          as="pre"
          fontFamily="mono"
          fontSize="sm"
          color="white"
          borderRadius="lg"
          bg="blue.900"
          whiteSpace="pre-wrap"
          p={6}
        >
          {encryptionKey}
        </Box>
      </Collapse>
    </Box>
  );
};

type FormValues = {
  cipher: Ciphers;
  ciphertext: string;
};

const Chapter2: React.FC = () => {
  const tutorial = useDecryptTutorial();
  const encryptTutorial = useEncryptTutorial();
  const { scrollToBottom } = useDashboardContentContext();
  const [decryptedMessage, setDecryptedMessage] = useState<
    string | undefined
  >();
  const [decryptError, setDecryptError] = useState<string | undefined>();
  const { handleSubmit, register, setValue } = useForm<FormValues>({
    defaultValues: {
      cipher: Ciphers.AES,
      ciphertext: '',
    },
  });
  const hasEncryptExample = encryptTutorial.cipherText;

  const handleSetFromEncryptionTutorial = () => {
    setValue('ciphertext', encryptTutorial.cipherText);
  };

  const onSubmit = ({ cipher, ciphertext }: FormValues) => {
    const cipherAlgorithm = getCipherAlgorithm(cipher);

    try {
      const decrypted = cipherAlgorithm
        .decrypt(ciphertext, tutorial.encryptionKey)
        .toString(Crypto.enc.Utf8);

      if (decrypted) {
        setDecryptedMessage(decrypted);
        setDecryptError(undefined);
      } else {
        throw new Error(
          'Could not decrypt the ciphertext with your key. Did you use the same Cipher Algorithm and key?'
        );
      }
    } catch (err) {
      setDecryptedMessage(undefined);
      setDecryptError(err.toString());
    }

    scrollToBottom();
  };

  return (
    <ChapterContainer>
      <ChapterIntroBox>
        <ChapterHeader
          tutorial={TutorialName.encrypt}
          chapters={chapters}
          chapterNum={CURRENT_CHAPTER}
        />
        <ChapterIntroContent>
          <Text>
            Now that we have the key we can use this to decrypt your message.
            How you decrypt your message will depend on the method you used to
            encrypt the data.
          </Text>
          <CodeView>
            <Code language="bash" title="Command Line">
              openssl enc -d -k [key] -in [encrypted file] -out [decrypted file]
            </Code>
          </CodeView>
        </ChapterIntroContent>
      </ChapterIntroBox>
      <ChapterContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <EncryptionKey encryptionKey={tutorial.encryptionKey} />

          <Flex justify="space-between" alignItems="flex-end" mb={4}>
            <FormControl>
              <FormLabel>Cipher Algorithm</FormLabel>
              <Select
                name="cipher"
                ref={register}
                display="inline-block"
                width="auto"
              >
                {Object.entries(Ciphers).map(([cipher]) => (
                  <option key={cipher} value={cipher}>
                    {cipher}
                  </option>
                ))}
              </Select>
            </FormControl>
            {hasEncryptExample && (
              <Button
                flex="none"
                type="button"
                variant="outline"
                onClick={handleSetFromEncryptionTutorial}
              >
                Paste Values
              </Button>
            )}
          </Flex>

          <StackedTextarea
            variant="code"
            label="Ciphertext"
            name="ciphertext"
            rows={10}
            ref={register}
            mb={4}
          />
          <Flex justify="flex-end">
            <Button type="submit">Decrypt Message</Button>
          </Flex>

          {decryptError && (
            <Alert variant="subtle" status="error" mt={6}>
              {decryptError}
            </Alert>
          )}

          {decryptedMessage && (
            <>
              <Box
                as="pre"
                border="1px"
                borderColor="gray.100"
                borderRadius="lg"
                mt={6}
                p={4}
                fontFamily="inherit"
              >
                {decryptedMessage}
              </Box>

              <ChapterResponseAlert title="And we are done!">
                <Text>
                  Your message has been successfully decrypted using your key
                  stored on XQ. You have now completed this tutorial.
                </Text>
              </ChapterResponseAlert>
            </>
          )}
        </form>
      </ChapterContent>

      <ChapterFooter>
        <ChapterNavLink direction="back" chapters={chapters} chapterNum={1} />
      </ChapterFooter>
    </ChapterContainer>
  );
};

export default Chapter2;
