import { AxiosInstance } from 'axios';
import { action, computed, observable } from 'mobx';
import bind from 'bind-decorator';
import omit from 'lodash/omit';
import authController from 'auth/AuthenticationController';
import {
  BusinessSummary,
  UpdateBusinessRequest,
  UpdateSubscriptionRequest,
  UpdateSubscriptionResponse,
} from 'backend/api-types/dashboard';
import { PlanType } from 'settings/account/plan/constants';
import { dashboardService } from '../backend/services';
import { SimpleAsyncData } from '../cache/AsyncData';
import useAsyncData from '../cache/useAsyncData';

export default class CurrentBusinessController extends SimpleAsyncData<
  BusinessSummary,
  void
> {
  protected _axios: AxiosInstance = dashboardService;

  @observable
  private _logo?: string;

  constructor() {
    super(`current-business`, '/business');
  }

  @bind
  protected async _execute(): Promise<BusinessSummary> {
    const result = await super._execute();

    // Fetch the business' logo. Do this here instead of using the 'src' in components
    // because we need to pass authentication headers when fetching the images.
    //
    // Do not wait for this to complete.
    this._axios
      .get(`/image/business/${result.id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        this._logo = URL.createObjectURL(response.data);
      })
      .catch((err) => {
        // Failed to fetch the business' logo. They most likely haven't uploaded one yet, so lets just leave
        // the field empty.
        console.info('Failed to fetch the business logo: ', err.message);
      });

    return result;
  }

  @computed
  public get logo(): string | undefined {
    if (!this._logo) {
      // Explicitly returning undefined to avoid a react-scripts console warning
      return undefined;
    }

    return this._logo;
  }

  @bind
  public async updateSubscription(
    plan: PlanType,
    seats?: number
  ): Promise<void> {
    // Declaring this in a separate line so that Typescript enforces that we are sending the proper request body
    const requestBody: UpdateSubscriptionRequest = {
      plan,
      seats: plan === PlanType.Pro ? seats : undefined,
    };

    await dashboardService.put<UpdateSubscriptionResponse>(
      '/subscription',
      requestBody
    );

    // Refetch the current business data
    await this.execute();
  }

  @bind
  @action
  public async update(body: UpdateBusinessRequest): Promise<void> {
    await dashboardService.patch<UpdateBusinessRequest>('/business', body);

    if (this._data) {
      this._data = {
        ...this._data,
        ...omit(body, ['telephone']),
        phone: body.telephone,
      };
    }
  }
}

export function useCurrentBusiness(): CurrentBusinessController {
  return useAsyncData<CurrentBusinessController>(
    new CurrentBusinessController(),
    !authController.data?.sub
  );
}
