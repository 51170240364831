import React from 'react';
import JoyRide, { Step } from 'react-joyride';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { firstTourStepProps, defaultJoyrideProps } from './constants';
import useTour from './useTour';

const CommunicationsTour = () => {
  const tour = useTour('communications');
  const user = useCurrentUser();

  if (tour.isFinished) {
    return null;
  }

  const steps: Step[] = [
    {
      target: '[data-tour-id="communications-date-filter"]',
      title: 'Filter by date',
      content:
        'Use a date or range or dates to filter communications by when they are sent.',
      styles: {
        spotlight: {
          borderRadius: 100,
        },
      },
      ...firstTourStepProps,
    },
  ];

  if (user.access.canViewTeamCommunications) {
    steps.push({
      target: '[data-tour-id="communications-tab-nav"]',
      title: 'View your team communications',
      content:
        'Switch between viewing your own communications and any sent by other members of your team.',
      styles: {
        spotlight: {
          borderRadius: 100,
        },
      },
    });
  }

  steps.push(
    ...[
      {
        target: '[data-tour-id="communications-filter-type"]',
        title: 'Filter by type',
        content:
          'Communications can be one of three types: File, Email and Other.',
      },
      {
        target: '[data-tour-id="communications-filter-status"]',
        title: 'Filter by status',
        content:
          'Communications can have one of three statuses: Available, Revoked and Expired.',
      },
      {
        target: '[data-tour-id="communications-filter-recipient"]',
        title: 'Filter by recipients',
        content:
          'Filter communications by the email address of the recipients.',
      },
      {
        target: '[data-tour-id="communications-filter-keyword"]',
        title: 'Search by keyword',
        content:
          'Use keywords to search resource filenames and email subjects.',
      },
      {
        target: '[data-tour-id="first-communication-table-row"]',
        title: 'View communication details',
        content: 'Click on a row to view more details about a communication.',
        spotlightPadding: 0,
        styles: {
          spotlight: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      },
    ]
  );

  return (
    <JoyRide
      steps={steps}
      run={tour.run}
      callback={tour.handleCallback}
      {...defaultJoyrideProps}
    />
  );
};

export default React.memo(CommunicationsTour);
