import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { CommunicationMeta, CommunicationType } from 'key-packet/types';

const DescriptionText: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <Text fontWeight="semibold" color="black" {...props}>
      {children}
    </Text>
  );
};

type KeyPacketDescriptionProps = {
  type: CommunicationType;
  meta?: CommunicationMeta;
};

const MissingInfo: React.FC = () => {
  return (
    <DescriptionText isTruncated>
      Information not available at this time
    </DescriptionText>
  );
};

const KeyPacketDescription: React.FC<KeyPacketDescriptionProps> = ({
  type,
  meta = {},
}) => {
  if (type === CommunicationType.File) {
    if (!meta.title) {
      return <MissingInfo />;
    }

    return (
      <>
        <DescriptionText noOfLines={1}>{meta.title}</DescriptionText>
        {meta.type && <Text fontSize="sm">{meta.type}</Text>}
      </>
    );
  }

  if (type === CommunicationType.Email) {
    if (!meta.subject) {
      return <MissingInfo />;
    }

    return <DescriptionText noOfLines={2}>{meta.subject}</DescriptionText>;
  }

  return <DescriptionText>Message</DescriptionText>;
};

export default KeyPacketDescription;
