import React, { createContext, useCallback, useEffect } from 'react';
import Crypto from 'crypto-js';
import { useHistory, useLocation } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import authController from 'auth/AuthenticationController';

const LOCAL_STORAGE_KEY = 'compose-message-state';
const MESSAGE_PREFIX = '[MESSAGE]';

export type ReplyToMessageLocationState = {
  subject: string;
  replyTo: string;
};

export type EncryptWizardLocationState = {
  /** Current chapter that the user is on */
  chapter: number;
};

export type EncryptMessageLocationState = Partial<ReplyToMessageLocationState> &
  EncryptWizardLocationState;

export type EncryptWizardNavigation = {
  goPrev: () => void;
  goNext: (state: EncryptMessageValues) => void;
  reset: () => void;
};

export type EncryptWizardValues = EncryptWizardLocationState &
  EncryptWizardNavigation;

export type EncryptMessageValues = {
  /** The raw un-encrypted message */
  message?: string;

  /** The recipients of the message */
  recipients?: string[];

  /** Number of hours till the message expires */
  expireInHours?: number;

  /** The subject of the message */
  subject?: string;
};

export type EncryptMessageContextValues = EncryptWizardValues &
  EncryptMessageValues;

export const defaultEncryptWizardValues: EncryptWizardValues = {
  chapter: 1,
  goPrev: () => {
    // Do nothing
  },
  goNext: () => {
    // Do nothing
  },
  reset: () => {
    // Do nothing
  },
};

const EncryptMessageContext = createContext<EncryptMessageContextValues>(
  defaultEncryptWizardValues
);

type EncryptMessageContextProviderProps = {
  finalRoute: string;
  numChapters: number;
};

export const EncryptMessageContextProvider: React.FC<EncryptMessageContextProviderProps> = ({
  children,
  finalRoute,
  numChapters,
}) => {
  const sessionKey = authController.accessToken ?? '';
  const history = useHistory();
  const [state, setState] = useLocalStorageState<EncryptMessageValues>(
    LOCAL_STORAGE_KEY
  );
  const { state: locationState, pathname } = useLocation<
    EncryptMessageLocationState | undefined
  >();

  const goNext = useCallback(
    (newState: EncryptMessageValues) => {
      if (locationState?.chapter && locationState.chapter >= numChapters) {
        // There are no other chapters to go to. Use the exit route instead.
        setState.reset();
        history.push(finalRoute);
        return;
      }

      const merged = {
        ...state,
        ...newState,
      };
      if (newState.message) {
        merged.message = Crypto.AES.encrypt(
          MESSAGE_PREFIX + newState.message,
          sessionKey
        ).toString();
      }
      setState(merged);

      history.push(pathname, {
        chapter: (locationState?.chapter ?? 1) + 1,
      });
    },
    [
      finalRoute,
      history,
      locationState?.chapter,
      numChapters,
      pathname,
      sessionKey,
      setState,
      state,
    ]
  );

  let decrypted = '';
  try {
    decrypted = state?.message
      ? Crypto.AES.decrypt(state.message, sessionKey).toString(Crypto.enc.Utf8)
      : '';
  } catch (err) {
    // Sometimes the decryption succeeds, but encoding back the Utf8 throws an error.
    // That just means that the sessionKey has changed, so we can ignore the error
    // and leave "decrypted" as an empty string.
  }

  const message =
    decrypted && decrypted.startsWith(MESSAGE_PREFIX)
      ? decrypted.substring(MESSAGE_PREFIX.length)
      : '';

  // Reset the store if there is a stagnant message. Stagnant is defined as the access token having changed. That means
  // It is either old, or was saved by a different user.
  useEffect(() => {
    if (state?.message && !message) {
      setState.reset();
    }
  }, [state?.message, message, setState]);

  // Redirect to the first step if the user has somehow made it to a later step
  // without inputting a message. This handles the user hitting "back" in the browser
  // after the state has been cleared.
  useEffect(() => {
    if (!message && locationState?.chapter && locationState?.chapter !== 1) {
      history.replace(pathname, {
        chapter: 1,
      });
    }
  }, [message, locationState?.chapter, history, pathname]);

  // If we have a replyTo parameters passed in the location state, we need to pre-populate
  // the wizard with the right values. Reset anything that was already there.
  useEffect(() => {
    if (locationState?.replyTo || locationState?.subject) {
      setState({
        recipients: locationState.replyTo ? [locationState.replyTo] : [],
        subject: locationState.subject ?? '',
      });
    }
    // Only do this when the location state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState?.replyTo, locationState?.subject]);

  return (
    <EncryptMessageContext.Provider
      value={{
        ...state,
        chapter: locationState?.chapter ?? 1,
        goPrev: history.goBack,
        goNext,
        reset: setState.reset,
        message,
      }}
    >
      {children}
    </EncryptMessageContext.Provider>
  );
};

export default EncryptMessageContext;
