import React, { useEffect } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import MultiEmail from './MultiEmail';

interface MultiEmailFieldProps extends BoxProps {
  name: string;
}

const MultiEmailField: React.FC<MultiEmailFieldProps> = ({
  name,
  ...props
}) => {
  const { register, setValue, watch } = useFormContext();

  useEffect(() => {
    register({
      name,
    });
  }, [register, name]);

  return (
    <Box className="multi-email-wrapper" mb={4} {...props}>
      <MultiEmail
        emails={watch(name)}
        onChange={(emails) => {
          setValue(name, emails);
        }}
      />
    </Box>
  );
};

export default MultiEmailField;
