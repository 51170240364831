import React, { useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import GrantAccessForm from './GrantAccessForm';
import CommunicationController from '../CommunicationController';

type GrantAccessModalModalProps = {
  communication: CommunicationController;
  isOpen?: boolean;
  onClose: () => void;
};

const GrantAccessModal: React.FC<GrantAccessModalModalProps> = ({
  communication,
  isOpen,
  onClose,
}) => {
  const initialFocusRef = useRef<HTMLInputElement>(null);
  return (
    <Modal
      isOpen={isOpen ?? true}
      size="3xl"
      autoFocus={false}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Grant Access</ModalHeader>
        <ModalCloseButton size="md" color="white" right={6} top={6} />
        <ModalBody>
          <GrantAccessForm communication={communication} onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default observer(GrantAccessModal);
