import React from 'react';
import { Stack, StackProps } from '@chakra-ui/react';

const ChapterFooter: React.FC<StackProps> = ({ children, ...props }) => {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      mt="auto"
      borderTop="1px"
      borderTopColor="gray.50"
      pt={4}
      pb={8}
      px={8}
      justify={{ base: 'center', md: 'space-between' }}
      alignItems="center"
      {...props}
    >
      {children}
    </Stack>
  );
};

export default ChapterFooter;
