import React from 'react';
import {
  Tabs,
  TabList,
  Tab,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import routes from 'routes';

const EncryptionTabs: React.FC = () => {
  const isMobileNav = useBreakpointValue({ base: true, sm: false });
  const location = useLocation();
  const isDecryptMessageRoute = useRouteMatch(routes.encryption.decryptMessage);

  const tabs = [
    {
      to: routes.encryption.encryptMessage,
      label: 'Encrypt Message',
    },
  ];

  if (isDecryptMessageRoute) {
    tabs.push({
      to: routes.encryption.decryptMessage,
      label: 'Decrypt Message',
    });
  }

  tabs.push(
    ...[
      {
        to: routes.encryption.encryptFile,
        label: 'Encrypt File',
      },
      {
        to: routes.encryption.decryptFile,
        label: 'Decrypt File',
      },
    ]
  );

  const selectedTab = tabs.find((tab) => tab.to === location.pathname);

  if (isMobileNav) {
    return (
      <Menu autoSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius={0}
          variant="outline"
          colorScheme="gray"
          color="gray.800"
          fontSize="md"
          fontWeight="normal"
          textAlign="left"
          textTransform="none"
          width="100%"
          mb={10}
        >
          {selectedTab?.label ?? 'Trigger'}
        </MenuButton>
        <MenuList>
          {tabs.map((tab) => (
            <MenuItem key={tab.to} as={NavLink} to={tab.to}>
              {tab.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }

  return (
    <Tabs index={-1} mb={10} size="lg">
      <TabList>
        {tabs.map((tab) => (
          <Tab key={tab.to} as={NavLink} to={tab.to}>
            {tab.label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export default EncryptionTabs;
