import { Box, Collapse, Icon } from '@chakra-ui/react';
import React, { useState } from 'react';
import { HiChevronRight } from 'react-icons/hi';

interface CollapsableGroupProps {
  heading: string;
}

const CollapsableGroup: React.FC<CollapsableGroupProps> = ({
  heading,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!isOpen);
  };
  return (
    <>
      <Box
        onClick={handleToggle}
        role="button"
        fontWeight="bold"
        fontSize="sm"
        pt={4}
        pl={10}
        display="flex"
        alignItems="center"
      >
        {heading}
        <Icon
          as={HiChevronRight}
          transform={isOpen ? 'rotate(90deg)' : undefined}
          transition="transform 0.2s linear"
          transformOrigin="50% 50%"
          boxSize={5}
        />
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
    </>
  );
};

export default CollapsableGroup;
