import { useRef, useEffect, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { GetChartResponse } from 'backend/api-types/dashboard';
import { dashboardService, timezoneOffset } from 'backend/services';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { EventLogFilters } from 'event-log/list/useEventFilters';

export type ChartPoint = {
  date: string;
  levelBenign: number;
  levelInfo: number;
  levelWarn: number;
  levelAlert: number;
};

type FetchDataArguments = [filters: EventLogFilters];

export default function useThreatLevelChart(filters: EventLogFilters = {}) {
  const filterRef = useRef<EventLogFilters | null>(null);
  const [fetch, loading, { data, error, success }] = useAsyncAction<
    ChartPoint[],
    FetchDataArguments
  >(
    useCallback(async (f: EventLogFilters) => {
      const res = await dashboardService.get<GetChartResponse>('/chart', {
        params: {
          from: f.dateRange?.fromDate?.getTime(),
          to: f.dateRange?.toDate?.getTime(),
          threat: f.threatLevels?.join(',') || undefined,
          appid: f.apps?.join(',') || undefined,
          tz: timezoneOffset,

          // Regular expression support instead of ",". So this converts each item
          // to "^{action}$" and separates with a pipe.
          action: f.eventTypes?.map((e) => `^${e}$`).join('|') || undefined,
        },
      });

      return (
        res.data.items.map((item: Array<string | number>) => ({
          date: item[0] as string,
          levelBenign: item[1] as number,
          levelInfo: item[2] as number,
          levelWarn: item[3] as number,
          levelAlert: item[4] as number,
        })) ?? []
      );
    }, [])
  );

  useEffect(() => {
    if (!isEqual(filterRef.current, filters)) {
      // Start fetching the data as soon as the component mounts or if the filters change
      fetch(filters);

      // Update the filter ref for the future. This is required to do a deep equal on the filters
      // and prevent a render cycle when non-memoized filters are provided.
      filterRef.current = filters;
    }
  }, [fetch, filters]);

  return {
    data: data as ChartPoint[],
    loading,
    error,
    success,
  };
}
