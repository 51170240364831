import { useMemo } from 'react';
import { ConfirmAlertDialogProps } from 'shared/components/alert-dialogs';
import { AsyncAction } from 'shared/hooks/useAsyncAction';

export type UseConfirmDialogActionReturn<V, R> = Pick<
  ConfirmAlertDialogProps<V, R>,
  'isSubmitting' | 'onConfirm'
>;

/**
 * An AsyncAction that is adapted to match confirm dialog props.
 * @template T Type of the AsyncAction function returned.
 * @template V Type of the ID passed to AsyncAction function.
 * @template R Type of the record selected for confirmation.
 * @param asyncAction AsyncAction returned by useAsyncAction.
 */
export default function useConfirmDialogAction<T, V, R>(
  asyncAction: AsyncAction<T, [V]>
): UseConfirmDialogActionReturn<V, R> {
  const [onConfirm, isSubmitting] = asyncAction;

  return useMemo(() => {
    return {
      isSubmitting,
      onConfirm,
    };
  }, [isSubmitting, onConfirm]);
}
