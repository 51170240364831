import React from 'react';
import { BoxProps, Button } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import useNavigate from 'shared/hooks/useNavigate';
import routes from '../routes';
import { usePayMethods } from '../payment/PayMethodsController';
import { Section, SectionHeading, DT, DD } from '../styled';

interface MaskedCreditCardProps {
  brand?: string;
  last4Digits?: string;
}

const MaskedCreditCard: React.FC<MaskedCreditCardProps> = ({
  brand,
  last4Digits,
}) => {
  return (
    <>
      {brand?.toUpperCase()} **** **** ****
      {last4Digits}
    </>
  );
};

const PaymentInformationSection: React.FC<BoxProps> = (props) => {
  const user = useCurrentUser();
  const payMethods = usePayMethods();
  const showPayMethod = !!(payMethods.data && payMethods.default);

  // Using a click handler instead of a link because the disabled state doesn't prevent
  // clicking on links
  const goToPayment = useNavigate(routes.payment);

  return (
    <Section {...props}>
      <SectionHeading>Payment Information</SectionHeading>

      {showPayMethod ? (
        <dl>
          <DT>Billing method</DT>
          <DD>
            <MaskedCreditCard
              brand={payMethods.default?.brand}
              last4Digits={payMethods.default?.last4Digits}
            />
            {user.access.canModifyPayMethod && (
              <Button
                as={Link}
                to={routes.payment}
                size="xs"
                colorScheme="blue"
                ml={4}
              >
                Update
              </Button>
            )}
          </DD>
        </dl>
      ) : (
        <Button
          onClick={goToPayment}
          size="xs"
          colorScheme="blue"
          disabled={!user.access.canModifyPayMethod}
        >
          Add a payment method
        </Button>
      )}
    </Section>
  );
};

export default observer(PaymentInformationSection);
