import * as React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { StackedInputField } from 'shared/components/form';
import StackedSelectField from 'shared/components/form/StackedSelect/StackedSelectField';
import { RolesCodes, NotificationsCodes } from 'shared/constants/contacts';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { UserRole } from 'auth/user/types';

const UpdateContactFormContent: React.FC = () => {
  const currentUser = useCurrentUser();
  return (
    <>
      <SimpleGrid spacing={8}>
        <StackedInputField
          id="email"
          name="email"
          type="text"
          label="Email"
          disabled
        />
        <StackedSelectField name="role" size="lg" label="Role">
          {RolesCodes.map(([label, value]) => (
            <option
              value={value}
              label={label}
              key={value}
              disabled={
                value === UserRole.SuperUser &&
                !currentUser.access.canMakeSuperUser
              }
            />
          ))}
        </StackedSelectField>
        <StackedInputField
          id="first_name"
          name="firstName"
          type="text"
          label="First Name"
        />
        <StackedInputField
          id="last_name"
          name="lastName"
          type="text"
          label="Last Name"
        />
        <StackedInputField id="title" name="title" type="text" label="Title" />
        <StackedInputField id="phone" name="phone" type="text" label="Phone" />

        <StackedSelectField
          name="notificationsStatus"
          size="lg"
          label="Notifications"
        >
          {NotificationsCodes.map(([label, value]) => (
            <option value={value} label={label} key={value} />
          ))}
        </StackedSelectField>
      </SimpleGrid>
    </>
  );
};

export default observer(UpdateContactFormContent);
