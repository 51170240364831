import { BUSINESS_PLAN_USERCOUNT, PLAN_USER_LIMITS } from './constants';

/* 
  returns userCounts by specified increment
*/
export function adjustUserCount(
  userCount: number,
  increment: number,
  increase?: boolean
): number {
  let newIncrement = 0;

  // determine if we are incrementing by 1 (free plan)
  if (increase) {
    newIncrement = userCount < BUSINESS_PLAN_USERCOUNT ? 1 : increment;
  } else {
    newIncrement = userCount <= BUSINESS_PLAN_USERCOUNT ? 1 : increment;
  }

  const newUserCount = increase
    ? userCount + newIncrement
    : userCount - newIncrement;

  // do not return invalid userCounts
  if (newUserCount <= PLAN_USER_LIMITS.min) {
    return PLAN_USER_LIMITS.min;
  }

  // do not return counts that exceed plan maximum
  if (newUserCount >= PLAN_USER_LIMITS.max) {
    return PLAN_USER_LIMITS.max;
  }

  return newUserCount;
}
