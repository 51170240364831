import React, { useEffect } from 'react';
import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import TutorialSection from './TutorialSection';

export interface SaveAPIKeyWarningModalProps {
  apiKey: string;
  isOpen: boolean;
  onClose: () => void;
}

const SaveAPIKeyWarningModal: React.FC<SaveAPIKeyWarningModalProps> = ({
  isOpen,
  apiKey,
  onClose,
}) => {
  const { hasCopied, onCopy } = useClipboard(apiKey);
  const toast = useToast();

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'API key copied to your clipboard',
        description: apiKey,
        status: 'info',
        position: 'bottom-right',
      });
    }
  }, [hasCopied, toast, apiKey]);

  return (
    <Modal
      variant="warning"
      isOpen={isOpen}
      size="3xl"
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      autoFocus={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save This API Key!</ModalHeader>

        <ModalBody py={6} px={6}>
          <Text fontSize="lg" fontWeight="bold" mb={6}>
            You will not have access to this key again. Save it somewhere safe!
          </Text>
          <Flex
            alignItems="center"
            px={4}
            py={3}
            fontSize="sm"
            fontWeight="bold"
            bg="green.50"
            color="green.700"
          >
            {apiKey}
          </Flex>
          <Button
            type="button"
            size="xs"
            variant="link"
            colorScheme="primary"
            mt={6}
            mx="auto"
            onClick={onCopy}
          >
            Copy to clipboard
          </Button>
        </ModalBody>

        <ModalFooter borderTop="1px" borderColor="gray.50" pt={4} pb={4} px={6}>
          <Button type="button" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
        <Divider opacity={1} borderColor="gray.50" />
        <TutorialSection apiKey={apiKey} />
      </ModalContent>
    </Modal>
  );
};

export default SaveAPIKeyWarningModal;
