import { Tabs, TabList, Tab } from '@chakra-ui/react';
import * as React from 'react';
import { observer } from 'mobx-react';
import { NavLink, Switch } from 'react-router-dom';
import routes from 'routes';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import RestrictedRoute from 'auth/RestrictedRoute';
import { PageBox } from 'shared/components/containers';
import Billing from './Billing';
import Profile from './Profile';

const Settings: React.FC = () => {
  const user = useCurrentUser();

  return (
    <PageBox p={0}>
      <Tabs index={-1}>
        <TabList>
          {user.access.canViewOrganizationSettings && (
            <Tab as={NavLink} to={routes.settings.profile}>
              Organization
            </Tab>
          )}
          {user.access.canViewBilling && (
            <Tab as={NavLink} to={routes.settings.billing}>
              Billing
            </Tab>
          )}
        </TabList>
        <Switch>
          <RestrictedRoute
            path={routes.settings.profile}
            exact
            canAccess={user.access.canViewOrganizationSettings}
          >
            <Profile />
          </RestrictedRoute>
          <RestrictedRoute
            path={routes.settings.billing}
            exact
            canAccess={user.access.canViewBilling}
          >
            <Billing />
          </RestrictedRoute>
        </Switch>
      </Tabs>
    </PageBox>
  );
};

export default observer(Settings);
