import Crypto from 'crypto-js';

function characterHexToBin(hex: string) {
  return parseInt(hex, 16).toString(2).padStart(4, '0');
}

export function toEntropyBits(data: string) {
  try {
    return atob(data).split('').map(characterHexToBin).join('');
  } catch (err) {
    console.error(err);
  }
  return '';
}

export enum Ciphers {
  AES = 'AES',
  DES = 'DES',
  Rabbit = 'Rabbit',
  RC4 = 'RC4',
}

export function getCipherAlgorithm(cipher: Ciphers) {
  let cipherAlgorithm;
  switch (cipher) {
    case Ciphers.DES:
      cipherAlgorithm = Crypto.DES;
      break;
    case Ciphers.Rabbit:
      cipherAlgorithm = Crypto.Rabbit;
      break;
    case Ciphers.RC4:
      cipherAlgorithm = Crypto.RC4;
      break;
    case Ciphers.AES:
    default:
      cipherAlgorithm = Crypto.AES;
      break;
  }
  return cipherAlgorithm;
}
