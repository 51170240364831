/* eslint-disable func-names */
import JSZip from 'jszip';

export async function unzipContent(
  contentF: string | ArrayBuffer,
  extensions: string[]
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new Promise<any>((resolve) => {
    const jsZip = new JSZip();

    // you now have every files contained in the loaded zip
    // zip.file("hello.txt").async("string"); // a promise of "Hello World\n"
    // this is like Object.values(zip.files) which is not yet implemented everywhere

    jsZip.loadAsync(contentF).then(function (zip) {
      const entries = Object.keys(zip.files)
        .map(function (name) {
          return zip.files[name];
        })
        .filter(function (item) {
          return (
            !item.name.startsWith('__MACOSX/') &&
            extensions.filter(function (ext) {
              return item.name.endsWith(ext);
            }).length > 0
          );
        });

      const promiseOfList = Promise.all(
        entries.map(function (entry) {
          if (entry.name.endsWith('.xml')) {
            return entry.async('text').then(function (content) {
              const parser = new DOMParser();

              const dom = parser.parseFromString(content, 'application/xml');
              let headers = '';

              const msgBody = dom.querySelector('OPFMessageCopyHTMLBody');
              if (msgBody === null) return [];

              let filename = entry.name;

              // Find the date received
              const msgDate = dom.querySelector('OPFMessageCopyReceivedTime');
              if (msgDate != null) {
                headers += 'Date: ' + msgDate.innerHTML + '\n';
              }

              // Find the subject
              const msgSubject = dom.querySelector('OPFMessageCopySubject');
              if (msgSubject != null) {
                headers += 'Subject: ' + msgSubject.textContent + '\n';
                if (msgSubject.textContent)
                  filename = msgSubject.textContent
                    .replace(/\s+/g, '_')
                    .replace(/\./g, '_');
                if (filename.length > 100)
                  filename = filename.substring(0, 100) + '.eml';
                else filename += '.eml';
              }

              // Find the sender
              const msgFrom = dom.querySelector(
                'OPFMessageCopyFromAddresses emailAddress'
              );
              if (msgFrom != null) {
                headers +=
                  'From: ' +
                  msgFrom.getAttribute('OPFContactEmailAddressAddress') +
                  '\n';
              }

              // Find the recipient
              const msgTo = dom.querySelector(
                'OPFMessageCopyToAddresses emailAddress'
              );
              if (msgTo != null) {
                headers +=
                  'To: ' +
                  msgTo.getAttribute('OPFContactEmailAddressAddress') +
                  '\n';
              }

              // Find the content
              let body = '';

              if (msgBody != null) {
                body = msgBody.innerHTML;
              }

              return ['text', filename, body, headers];
            });
          }
          if (entry.name.endsWith('.xqf')) {
            return entry.async('arraybuffer').then(function (content) {
              return ['file', entry.name, content];
            });
          }
          if (entry.name.endsWith('.eml')) {
            return entry.async('text').then(function (content) {
              const lines = content.match(/[^\r\n]+/g);
              let headers = '';
              if (lines)
                for (const line of lines) {
                  if (line.startsWith('Date:')) headers += line + '\n';
                  if (line.startsWith('Subject:')) headers += line + '\n';
                  if (line.startsWith('From:')) headers += line + '\n';
                  if (line.startsWith('To:')) headers += line + '\n';
                }

              return ['text', entry.name, content, headers];
            });
          }

          return entry.async('text').then(function (content) {
            return ['text', entry.name, content, ''];
          });
        })
      );

      promiseOfList.then(function (mappedData) {
        const mappedDataOut = mappedData.filter(function (item) {
          return item != null && item.length > 0;
        });
        resolve(mappedDataOut);
      });
    });
  });
}
