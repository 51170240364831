import React, { useEffect, useMemo, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import {
  Box,
  FormLabel,
  Input,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { PropsOf, useTheme } from '@chakra-ui/system';
import routes from 'routes';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { useFormHandler } from 'shared/hooks/useFormHandler';
import { StackedInputField } from 'shared/components/form';
import {
  ApplicationSummary,
  useApplications,
} from 'application/ApplicationsController';

type DeleteApplicationFormValues = {
  name: string;
};

const defaultValues: DeleteApplicationFormValues = {
  name: '',
};

type DeleteApplicationFormProps = {
  app: ApplicationSummary;
  isExpanded?: boolean;
};

const DeleteApplicationForm: React.FC<DeleteApplicationFormProps> = ({
  app,
  isExpanded = false,
}) => {
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const applications = useApplications();
  const toast = useToast();
  const theme = useTheme();
  const formSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .equals([app.name], 'Application name does not match')
          .required('Required'),
      }),
    [app]
  );

  useEffect(() => {
    if (nameFieldRef.current && isExpanded) {
      nameFieldRef.current.focus();
    }
  }, [isExpanded, nameFieldRef]);

  const { onSubmit, form, error } = useFormHandler<DeleteApplicationFormValues>(
    async () => {
      await applications.deleteApplication(app.id);

      toast({
        title: 'Application successfully deleted',
        description: app.name,
        status: 'success',
        position: 'bottom-right',
      });

      // Send the user back to the listing applications page
      history.push(routes.application.root);
    },
    defaultValues,
    formSchema
  );
  const { isSubmitting } = form.formState;
  const watchName = form.watch('name');
  const isNameMatch = watchName === app.name;
  const nameMatchProps: PropsOf<typeof Input> = {};

  if (isNameMatch) {
    nameMatchProps.bg = 'danger.50';
    nameMatchProps._focus = {
      borderColor: 'gray.900',
      boxShadow: `0 0 0 2px ${theme.colors.gray[100]}`,
    };
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <FormErrorAlert error={error} />
        <FormLabel
          htmlFor="name-field"
          fontSize="sm"
          fontWeight="normal"
          mb={1}
        >
          Type the name of this application and then confirm delete.
        </FormLabel>
        <StackedInputField
          initialFocusRef={nameFieldRef}
          id="name-field"
          name="name"
          type="text"
          placeholder={app.name}
          fontWeight="bold"
          _placeholder={{
            fontWeight: 'normal',
            color: 'danger.300',
          }}
          rightElement={
            isNameMatch && (
              <InputRightElement>
                <CheckIcon color="green.700" />
              </InputRightElement>
            )
          }
          {...nameMatchProps}
        />
        <Box textAlign="right" mt={8}>
          <SpinnerButton
            type="submit"
            colorScheme="danger"
            isLoading={isSubmitting}
          >
            Confirm Delete
          </SpinnerButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default DeleteApplicationForm;
