import React from 'react';
import { Box, Flex, Text, Link } from '@chakra-ui/react';
import routes from 'routes';

interface DescriptionPanelProps {
  name?: string;
}

const DescriptionPanel: React.FC<DescriptionPanelProps> = ({ name }) => {
  return (
    <Flex
      direction="column"
      justify="center"
      bg="primary.900"
      borderRadius="md"
      minHeight={{ base: 'auto', lg: '600px' }}
      py={{ base: 6, lg: 10 }}
      px={{ base: 6, lg: 10 }}
    >
      <Box
        color="white"
        fontWeight="400"
        lineHeight={1.5}
        mt={{ base: 0, lg: -16 }}
      >
        {name && (
          <Text fontSize="2xl" mt={0} mb={2} color="primary.500">
            Welcome, {name}
          </Text>
        )}
        <Text fontSize="2xl" mt={0} mb={6}>
          XQ is the privacy platform for all your apps. Start by creating an app
          and walk through our simple tutorial to get started.
        </Text>
        <Text fontSize="lg" mt={0} mb={2}>
          Build the right APIs for your business.
        </Text>
        <Link
          href={routes.docs.apiReference}
          target="_blank"
          rel="noopener noreferrer"
          fontSize="sm"
          fontWeight="bold"
        >
          Browse our docs
        </Link>
      </Box>
    </Flex>
  );
};

export default DescriptionPanel;
