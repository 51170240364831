import { AxiosInstance } from 'axios';
import {
  CreateApplicationRequest,
  CreateApplicationResponse,
  UpdateApplicationAppIdResponse,
  GetApplicationsResponse,
} from 'backend/api-types/dashboard';
import { action, computed, ObservableMap } from 'mobx';
import authController from 'auth/AuthenticationController';
import { dashboardService } from '../backend/services';
import AsyncData from '../cache/AsyncData';
import useAsyncData from '../cache/useAsyncData';

export type ApplicationSummary = {
  id: number;
  name: string;
  description?: string;
};

export default class ApplicationsController extends AsyncData<
  GetApplicationsResponse,
  void,
  Array<ApplicationSummary>
> {
  protected _axios: AxiosInstance = dashboardService;

  constructor() {
    super('applications', '/devapps');
  }

  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: GetApplicationsResponse): Array<ApplicationSummary> {
    return (
      data.apps?.map((d) => ({
        id: d.id ?? 0,
        name: d.name ?? 'Untitled',
        description: d.desc,
      })) ?? []
    );
  }

  @action
  public async addApplication(
    body: CreateApplicationRequest
  ): Promise<ApplicationSummary> {
    const res = await dashboardService.post<CreateApplicationResponse>(
      '/devapp',
      body
    );

    const summary = {
      id: res.data.id ?? 0,
      name: body.name,
      description: body.desc,
    };

    // Update the list of applications
    if (this._data) {
      this._data = [...this._data, summary];
    }
    return summary;
  }

  @action
  public async updateApplication(
    id: number,
    name: string,
    description?: string
  ): Promise<ApplicationSummary> {
    await dashboardService.patch<UpdateApplicationAppIdResponse>(
      `/devapp/${id}`,
      {
        name,
        desc: description,
      }
    );

    const summary = {
      id,
      name,
      description,
    };

    // Update the list of applications
    if (this._data) {
      const index = this._data.findIndex(
        (item: ApplicationSummary) => item.id === summary.id
      );
      if (index >= 0) {
        this._data[index] = summary;
      } else {
        // Should never happen, fallback if application is not in cache.
        this._data = [...this._data, summary];
      }
    }
    return summary;
  }

  @action
  public async deleteApplication(id: number): Promise<void> {
    await dashboardService.delete(`/devapp/${id}`);

    // Update the list of applications
    if (this._data) {
      this._data = this._data.filter((app) => app.id !== id);
    }
  }

  // This is a lookup map on the raw for performance reasons. It is cached by mobx and re-built any time the _data changes
  @computed
  protected get _lookup(): ObservableMap<number, ApplicationSummary> {
    if (!this._data) {
      return new ObservableMap<number, ApplicationSummary>();
    }

    const result = new Map();
    this._data.forEach((app) => {
      result.set(app.id, app);
    });

    return new ObservableMap(result);
  }

  public getById(id: number): ApplicationSummary | undefined {
    return this._lookup.get(id);
  }
}

type UseApplicationsOptions = {
  /** Skip the initial fetching of data. */
  skip?: boolean;
};

export function useApplications(
  options: UseApplicationsOptions = {}
): ApplicationsController {
  const { skip = false } = options;
  return useAsyncData<ApplicationsController>(
    new ApplicationsController(),
    skip || !authController.isLoggedIn
  );
}
