import { Step, Props as JoyrideProps } from 'react-joyride';
import JoyrideTooltip from './components/JoyrideTooltip';
import JoyrideBeacon from './components/JoyrideBeacon';

// Set default props for the first tour step to ensure a consistent experience
// across multiple Joyride instances.
export const firstTourStepProps: Partial<Step> = {
  // Disabling the beacon forces the first tooltip to show automatically.
  disableBeacon: true,
  floaterProps: {
    // Disabling animation forces the first tooltip to render in its destination
    // instead of seeing it animate from its initial origin in the top left of
    // the viewport.
    disableAnimation: true,
  },
};

// Set default Joyride props that all instances should use for a consistent experience.
export const defaultJoyrideProps: Partial<JoyrideProps> = {
  tooltipComponent: JoyrideTooltip,
  beaconComponent: JoyrideBeacon,
  disableScrolling: true,
  disableOverlayClose: true,
  disableCloseOnEsc: true,
  continuous: true,
  styles: {
    options: {
      zIndex: 10000,
    },
  },
};
