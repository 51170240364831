import React from 'react';
import {
  Flex,
  Box,
  BoxProps,
  Button,
  Alert,
  AlertTitle,
  AlertDescription,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import routes, { accountErrorHashId } from '../routes';
import { PlanType, readablePlanTypeMap } from '../account/plan/constants';
import { usePlanUsage } from '../account/plan/PlanUsageController';
import { Section, SectionHeading, DT, DD } from '../styled';

interface OverflowSeatsAlertProps {
  graceExpires?: number;
  canModifyPlan?: boolean;
}

const OverflowSeatsAlert: React.FC<OverflowSeatsAlertProps> = ({
  graceExpires,
  canModifyPlan = false,
}) => {
  return (
    <Alert status="warning" mt={4}>
      <Flex flex="1" direction="column">
        <AlertTitle>
          You have exceeded the maximum number of seats your billing plan allows
        </AlertTitle>
        <AlertDescription mt={2}>
          New contacts have been added to your account using temporary overflow
          seats.
          <br />
          Add more seats to your account before{' '}
          <Text as="span" fontWeight="bold" textDecoration="underline">
            {format(graceExpires ?? 0, 'LLLL d, YYY h:mm:ss a')}
          </Text>{' '}
          to prevent disruption in service.
        </AlertDescription>
        {canModifyPlan && (
          <Box mt={3}>
            <Button as={Link} to={routes.plans} colorScheme="warning">
              Update Plan
            </Button>
          </Box>
        )}
      </Flex>
    </Alert>
  );
};

const PlanInformationSection: React.FC<BoxProps> = (props) => {
  const user = useCurrentUser();
  const business = useCurrentBusiness();
  const usage = usePlanUsage();

  if (!business.data) {
    return null;
  }

  const { subscription } = business.data;

  const showRenewalData =
    subscription.plan !== PlanType.Free && !!subscription.renewalDate;
  const accountIssue = subscription.reason;
  const showOverflowSeatsAlert = !!(
    usage?.data?.overflow && usage?.data?.overflow > 0
  );

  return (
    <Section {...props}>
      <SectionHeading>Plan Information</SectionHeading>

      <dl>
        <DT>Current plan</DT>
        <DD>
          {readablePlanTypeMap[subscription.plan] ?? 'Unknown'}{' '}
          {user.access.canModifyPlan && (
            <Button
              as={Link}
              to={routes.plans}
              size="xs"
              colorScheme="blue"
              mx={4}
            >
              {subscription.plan === PlanType.Free ? 'Upgrade' : 'Update'}
            </Button>
          )}
        </DD>
        <br />

        <DT>Active seats</DT>
        <DD>
          {usage.data?.used ?? '-'} / {subscription.seats ?? '-'}
        </DD>
        <br />

        {showOverflowSeatsAlert && (
          <>
            <DT>Overflow seats</DT>
            <DD>{usage.data?.overflow ?? 0}</DD>
            <br />
          </>
        )}

        {showRenewalData && (
          <>
            <DT>Plan renewal date</DT>
            <DD>{format(subscription.renewalDate ?? 0, 'LLLL d, YYY')}</DD>
          </>
        )}
      </dl>

      {showOverflowSeatsAlert && (
        <OverflowSeatsAlert
          graceExpires={subscription.graceExpires}
          canModifyPlan={user.access.canModifyPlan}
        />
      )}

      {accountIssue && (
        <FormErrorAlert
          id={accountErrorHashId}
          mt={2}
          error={{
            message: accountIssue,
          }}
        />
      )}
    </Section>
  );
};

export default observer(PlanInformationSection);
