import { Box } from '@chakra-ui/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import SpinnerButton from 'shared/components/SpinnerButton';
import PaymentFormContent from './PaymentFormContent';
import useCreatePayMethodForm from './useCreatePayMethodForm';

type PaymentFormProps = {
  isMakingPurchase?: boolean;
  onSuccess: () => void;
};

const PaymentForm: React.FC<PaymentFormProps> = ({
  isMakingPurchase = false,
  onSuccess,
}) => {
  const { form, onSubmit, error } = useCreatePayMethodForm(
    isMakingPurchase,
    onSuccess
  );

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={onSubmit}>
        <PaymentFormContent />
        <FormErrorAlert error={error} />
        <Box textAlign="right">
          <SpinnerButton type="submit" isLoading={form.formState.isSubmitting}>
            {isMakingPurchase ? 'Pay Now' : 'Save'}
          </SpinnerButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default PaymentForm;
