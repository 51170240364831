import React, { useCallback, useState, useRef } from 'react';
import { Box, Heading, Text, Button, Stack, useToast } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { PageContainer } from 'shared/components/containers';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import useDownloadFile from 'shared/hooks/useDownloadFIle';
import { useDecryptFile } from './decryptFile';
import DropzoneBox, { decryptFileImage } from '../components/DropzoneBox';
import EncryptionTabs from '../components/EncryptionTabs';

const DecryptFileScene: React.FC = () => {
  const toast = useToast();

  const [decryptedBlob, setDecryptedBlob] = useState<Blob>();
  const [decryptedFileName, setDecryptedFileName] = useState<string>();
  const [decryptFile, loading, { error }] = useDecryptFile();
  const downloadFile = useDownloadFile();
  const downloadBoxRef = useRef<HTMLDivElement>(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const res = await decryptFile(acceptedFiles[0]);
      if (res) {
        const [blob, fileName] = res;
        setDecryptedBlob(blob);
        setDecryptedFileName(fileName);
        downloadBoxRef.current?.scrollIntoView();
      }
    },
    [decryptFile, setDecryptedBlob, setDecryptedFileName, downloadBoxRef]
  );

  const { getRootProps, getInputProps, ...dropzoneState } = useDropzone({
    onDrop,
  });

  const handleDownloadClick = () => {
    // This condition will always be true when download button is visible.
    if (decryptedBlob && decryptedFileName) {
      downloadFile(decryptedBlob, decryptedFileName);

      toast({
        title: 'Your file has been decrypted',
        status: 'success',
        position: 'bottom-right',
        description: 'You can find the decrypted file in your downloads folder',
      });
    }
  };

  const canShowDownload = decryptedBlob && decryptedFileName;

  return (
    <PageContainer display="flex" flexDirection="column" flex={1}>
      <EncryptionTabs />

      <Box>
        <Heading size="md" mb={2}>
          Securely decrypt files using your local device
        </Heading>
        <Text maxWidth="xl">
          Decrypt files with an .xqf extension by dragging them onto the
          container below. Your file is decrypted locally and never leaves your
          device.
        </Text>
      </Box>

      <Stack
        spacing={8}
        direction={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'flex-start', md: 'center' }}
        my={8}
      >
        <Box flex="none" width="100%" maxWidth={{ base: '100%', md: 'xs' }}>
          <DropzoneBox
            root={getRootProps()}
            input={getInputProps()}
            iconImage={decryptFileImage}
            isLoading={loading}
            loadingText="Decrypting file"
            dropMessage="Drop your file to decrypt it"
            {...dropzoneState}
          />
        </Box>
        <Box
          ref={downloadBoxRef}
          textAlign="center"
          width="100%"
          maxWidth={{ base: '100%', md: 'md' }}
        >
          {canShowDownload && (
            <>
              <Text mb={2} isTruncated>
                {decryptedFileName}
              </Text>
              <Button type="button" onClick={handleDownloadClick}>
                Download Decrypted File
              </Button>
            </>
          )}
        </Box>
      </Stack>

      <FormErrorAlert error={error} />
    </PageContainer>
  );
};

export default DecryptFileScene;
