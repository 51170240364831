import { UserRole, UserSummary } from './user/types';

export class AccessControls {
  private _user: UserSummary | null = null;

  // Default to a "User" user role
  private _role: UserRole = UserRole.User;

  constructor(user?: UserSummary | null) {
    if (user) {
      this._user = user;
      this._role = user.role;
    }
  }

  private isOneOf(options: UserRole[]) {
    return options.includes(this._role);
  }

  private only(role: UserRole) {
    return this._role === role;
  }

  private isSelf(id: number) {
    return this._user?.id === id;
  }

  private get atLeastAdmin() {
    return this.isOneOf([UserRole.SuperUser, UserRole.Admin]);
  }

  private get isSuperUser() {
    return this.only(UserRole.SuperUser);
  }

  get canViewBilling() {
    return this.atLeastAdmin;
  }

  get canViewInvoices() {
    return this.atLeastAdmin;
  }

  get canModifyPlan() {
    return this.isSuperUser;
  }

  get canModifyPayMethod() {
    return this.isSuperUser;
  }

  get canViewOrganizationSettings() {
    return this.atLeastAdmin;
  }

  get canViewTeamActivities() {
    return this.atLeastAdmin;
  }

  get canViewTeamCommunications() {
    return this.atLeastAdmin;
  }

  get canViewEventLog() {
    return this.atLeastAdmin;
  }

  get canAccessDeveloperFeatures() {
    return this.atLeastAdmin;
  }

  get canCreateApplication() {
    return this.atLeastAdmin;
  }

  get canUseTutorial() {
    return this.atLeastAdmin;
  }

  get canViewContacts() {
    return this.atLeastAdmin;
  }

  get canAddContact() {
    return this.atLeastAdmin;
  }

  canViewContactDetails(id: number) {
    return this.atLeastAdmin || this.isSelf(id);
  }

  canEditContact(id: number, contactRole: UserRole) {
    if (contactRole === UserRole.SuperUser) {
      // Only SuperUser's can modify other super users
      return this.isSuperUser;
    }

    // Admins can edit anyone who isn't a super user and anyone can edit themselves
    return this.atLeastAdmin || this.isSelf(id);
  }

  canEditPermissions(role: UserRole) {
    // SuperUsers can edit anyone's permissions, but Admins can only edit
    // not SuperUser permissions
    return (
      this.isSuperUser || (this.atLeastAdmin && role !== UserRole.SuperUser)
    );
  }

  get canMakeSuperUser() {
    return this.isSuperUser;
  }

  get canDisableUser() {
    return this.atLeastAdmin;
  }

  get canDeleteUser() {
    return this.atLeastAdmin;
  }

  get canRevokeKey() {
    return this.atLeastAdmin;
  }
}
