import bind from 'bind-decorator';
import omit from 'lodash/omit';
import {
  GetContactResponse,
  UpdateContactRequest,
  UpdateContactResponse,
} from 'backend/api-types/dashboard';
import authController from 'auth/AuthenticationController';
import {
  UserRole,
  userRoleToNumberMap,
  UserStatus,
  UserSummary,
} from 'auth/user/types';
import UserController from 'auth/user/UserController';
import { action } from 'mobx';
import { dashboardService } from 'backend/services';
import useAsyncData from '../cache/useAsyncData';
import { UpdateContactFormValues } from './update/useUpdateContactForm';

export default class ContactDetailsController extends UserController<
  GetContactResponse,
  void
> {
  private _id: number;

  constructor(contactId: number) {
    super(`contact:${contactId}`, `/contact/${contactId}`);

    this._id = contactId;
  }

  @bind
  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: GetContactResponse): UserSummary {
    return {
      ...omit(data, ['last', 'more', 'n', 'state']),
      id: this._id,
      lastLogin: data.last,
      moreInfo: data.more ?? '',
      notificationsStatus: data.n,
      status: data.state as UserStatus,
      role: data.role as UserRole,
    };
  }

  @action
  public async update(
    id: number,
    values: UpdateContactFormValues
  ): Promise<UserSummary> {
    if (!this._data) {
      // This should never actually happen. Only here as a safety sanity check.
      throw new Error("Can't update a contact that hasn't been fetched yet.");
    }

    const requestBody: UpdateContactRequest = {
      ...values,
      n: values.notificationsStatus,
      role: userRoleToNumberMap[values.role],
    };

    await dashboardService.patch<UpdateContactResponse>(
      `/contact/${id}`,
      requestBody
    );

    // Update the cache
    this._data = {
      ...this._data,
      ...values,
    };
    return this._data;
  }

  @action
  public async disable(): Promise<UserSummary> {
    if (!this._data) {
      // This should never actually happen. Only here as a safety sanity check.
      throw new Error("Can't update a contact that hasn't been fetched yet.");
    }
    this._data.status = UserStatus.Disabled;

    // Update the cache
    this._data = {
      ...this._data,
    };
    return this._data;
  }
}

export function useContact(contactId: number): ContactDetailsController {
  return useAsyncData<ContactDetailsController>(
    new ContactDetailsController(contactId),
    !authController.isLoggedIn
  );
}
