import { Box, useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import SpinnerButton from 'shared/components/SpinnerButton';
import UpdateContactFormContent from './UpdateContactFormContent';
import useUpdateContactForm from './useUpdateContactForm';

interface ContactDataProps {
  userId: number;
}

const UpdateContactForm: React.FC<ContactDataProps> = ({ userId }) => {
  const history = useHistory();
  const toast = useToast();
  const { form, onSubmit, error } = useUpdateContactForm(
    userId,
    (updatedContact) => {
      toast({
        title: 'Contact successfully updated',
        description: updatedContact.firstName + ' ' + updatedContact.lastName,
        status: 'success',
        position: 'bottom-right',
      });

      history.push(routes.contacts.root);
    }
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <UpdateContactFormContent />
        <FormErrorAlert error={error} mt={8} />
        <Box textAlign="right" mt={8}>
          <SpinnerButton
            size="md"
            type="submit"
            disabled={!form.formState.isDirty}
            isLoading={form.formState.isSubmitting}
          >
            Update
          </SpinnerButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default observer(UpdateContactForm);
