import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

interface StepsCountProps extends TextProps {
  current: number;
  total: number;
}

const StepsCount: React.FC<StepsCountProps> = ({
  current,
  total,
  ...props
}) => (
  <Text fontWeight="bold" color="gray.500" {...props}>
    Step {current} of {total}
  </Text>
);

export default StepsCount;
