import React from 'react';
import { Icon, IconButton, IconButtonProps, Fade } from '@chakra-ui/react';
import { HiChevronUp } from 'react-icons/hi';
import { useDashboardContentContext } from 'dashboard/layout/DashboardContentContext';

interface BackToTopFABProps extends Omit<IconButtonProps, 'aria-label'> {
  'aria-label'?: string;
}

const BackToTopFAB: React.FC<BackToTopFABProps> = (props) => {
  const { scrollToTop, hasScrolling } = useDashboardContentContext();
  return (
    <Fade transition={{ timeout: 1000 }} in={hasScrolling()}>
      <IconButton
        type="button"
        isRound
        icon={<Icon as={HiChevronUp} boxSize={8} />}
        aria-label="Back to top"
        size="lg"
        colorScheme="blue"
        position="fixed"
        minWidth="3rem"
        bottom={8}
        right={16}
        onClick={scrollToTop}
        {...props}
      />
    </Fade>
  );
};

export default BackToTopFAB;
