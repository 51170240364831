import bind from 'bind-decorator';
import { action, observable } from 'mobx';

class ContactState {
  @observable
  public showCreateContactModal = false;

  @bind
  @action
  public openCreateContactModal() {
    this.showCreateContactModal = true;
  }

  @bind
  @action
  public closeCreateContactModal() {
    this.showCreateContactModal = false;
  }
}

const appState = new ContactState();
export default appState;
