import React from 'react';
import { BeaconRenderProps } from 'react-joyride';
import { Box, keyframes, useTheme } from '@chakra-ui/react';
import { CustomTheme } from 'theme/customChakraTheme';

const pulse = (theme: CustomTheme) => keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  70% {
    background-color: ${theme.colors.blue[600]};
    transform: scale(1.4);
    opacity: 0.8;
  }
`;

const JoyrideBeacon = React.forwardRef<HTMLDivElement, BeaconRenderProps>(
  (props, _ref) => {
    const theme: CustomTheme = useTheme();
    return (
      <Box
        ref={_ref}
        animation={`${pulse(theme)} 1.6s ease-in-out infinite`}
        bg="blue.500"
        borderRadius="full"
        display="inline-block"
        opacity={0.5}
        width={6}
        height={6}
        {...props}
      />
    );
  }
);

export default JoyrideBeacon;
