import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { StackedInputField } from 'shared/components/form';

interface APIKeyFormFieldsProps {
  initialFocusRef?: React.RefObject<HTMLInputElement>;
}

const APIKeyFormFields: React.FC<APIKeyFormFieldsProps> = ({
  initialFocusRef,
}) => {
  return (
    <SimpleGrid spacing={8}>
      <StackedInputField
        id="name"
        name="name"
        type="text"
        label="Name of key"
        placeholder="e.g. Production"
        initialFocusRef={initialFocusRef}
        isRequired
      />
    </SimpleGrid>
  );
};

export default APIKeyFormFields;
