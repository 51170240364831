import { AxiosInstance } from 'axios';
import bind from 'bind-decorator';
import { computed, observable } from 'mobx';
import { dashboardService } from 'backend/services';
import AsyncData from 'cache/AsyncData';
import { UserSummary } from './types';

export default abstract class UserController<
  ResponseType,
  RequestType
> extends AsyncData<ResponseType, RequestType, UserSummary> {
  protected _axios: AxiosInstance = dashboardService;

  @observable
  protected _avatar?: string;

  @bind
  protected async _execute(): Promise<UserSummary> {
    // Do the same this as the parent class
    const result = await super._execute();

    // Fetch the user's avatar. Do this here instead of using the 'src' in components
    // because we need to pass authentication headers when fetching the images.
    //
    // Do not wait for this to complete.
    this._axios
      .get(`/image/user/${result.id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        this._avatar = URL.createObjectURL(response.data);
      })
      .catch((err) => {
        // Failed to fetch the user's avatar. They most likely haven't uploaded one yet, so lets just leave
        // the field empty.
        console.info('Failed to fetch the users avatar: ', err.message);
      });

    return result;
  }

  @computed
  public get fullName(): string {
    if (!this._data) {
      return '';
    }

    return [this._data.firstName, this._data.lastName]
      .filter((e) => e)
      .join(' ');
  }

  @computed
  public get avatar(): string | undefined {
    if (!this._avatar) {
      // Explicitly returning undefined to avoid a react-scripts console warning
      return undefined;
    }

    return this._avatar;
  }
}
