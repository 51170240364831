import React from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import useAPIKeyForm from './useAPIKeyForm';
import APIKeyFormFields from './APIKeyFormFields';

interface APIKeyFormProps {
  appId: number;
  onSuccess: (key: string) => void;
}

const APIKeyForm: React.FC<APIKeyFormProps> = ({ appId, onSuccess }) => {
  const { onSubmit, form, error } = useAPIKeyForm(appId, onSuccess);
  const { isSubmitting } = form.formState;

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <FormErrorAlert error={error} />
        <APIKeyFormFields />
        <Box textAlign="right" mt={8}>
          <SpinnerButton type="submit" isLoading={isSubmitting}>
            Continue
          </SpinnerButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default APIKeyForm;
