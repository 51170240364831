import { AxiosInstance } from 'axios';
import { computed, ObservableMap } from 'mobx';
import authController from 'auth/AuthenticationController';
import { EventType, ListEventTypesResponse } from 'backend/api-types/dashboard';
import { dashboardService } from '../backend/services';
import AsyncData, { AxiosHeaders } from '../cache/AsyncData';
import useAsyncData from '../cache/useAsyncData';

export default class EventTypesController extends AsyncData<
  ListEventTypesResponse,
  void,
  Array<EventType>
> {
  protected _axios: AxiosInstance = dashboardService;

  // Backend is not happy with the default language header for this endpoint
  protected _headers: AxiosHeaders = {
    'Accept-Language': 'en_US',
  };

  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: ListEventTypesResponse): Array<EventType> {
    return data.data ?? [];
  }

  constructor() {
    super(`event-types`, '/eventtypes');
  }

  // This is a lookup map on the raw to be able to find an event type for the id
  // in a performant manner. It is cached by mobx and re-built any time the _data changes
  @computed
  protected get _lookup(): ObservableMap<string, EventType> {
    if (!this._data) {
      return new ObservableMap<string, EventType>();
    }

    const result = new Map();
    this._data.forEach((ev) => {
      result.set(ev.action, ev);
    });

    return new ObservableMap(result);
  }

  public getTitle(action: string): string {
    return this._lookup.get(action)?.title ?? action;
  }
}

export function useEventTypes(): EventTypesController {
  return useAsyncData<EventTypesController>(
    new EventTypesController(),
    !authController.isLoggedIn
  );
}
