import React from 'react';
import { Box } from '@chakra-ui/react';
import { PageContainer } from 'shared/components/containers';
import routes from 'routes';
import EncryptFileContext, {
  EncryptFileContextProvider,
} from './EncryptFileContext';
import EncryptWizard from './EncryptWizard';
import { ExpirationHOC } from './chapters/Expiration';
import { RecipientsHOC } from './chapters/Recipients';
import UploadFile from './chapters/UploadFile';

const chapters: React.ComponentType[] = [
  RecipientsHOC({
    context: EncryptFileContext,
    heading: 'Who will be able to view this encrypted file?',
    helperText:
      'Restrict who can decrypt this file by providing their email address.',
    optional: true,
  }),
  ExpirationHOC({
    context: EncryptFileContext,
    heading: 'Set your file expiration',
    helperText:
      'Once the time has elapsed, the file will no longer be available.',
    communicationName: 'File',
  }),
  UploadFile,
];

const EncryptFileScene: React.FC = () => {
  return (
    <PageContainer display="flex" flexDirection="column" flex={1}>
      <EncryptFileContextProvider
        finalRoute={routes.home}
        numChapters={chapters.length}
      >
        <Box>
          <EncryptWizard
            chapters={chapters}
            heading="Locally encrypt your file"
            helperText="Your file is encrypted locally. It never leaves your device."
            context={EncryptFileContext}
          />
        </Box>
      </EncryptFileContextProvider>
    </PageContainer>
  );
};

export default EncryptFileScene;
