import * as yup from 'yup';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { PlanType } from 'settings/account/plan/constants';
import { useInvoices } from 'settings/invoices/InvoicesController';
import { usePayMethods } from './PayMethodsController';

export type PlanPurchaseLocationState = {
  plan: PlanType;
  seats?: number;
};

export type ProcessPaymentFormValues = {
  name: string;
};

const paymentFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

const defaultValues: ProcessPaymentFormValues = {
  name: '',
};

export default function useCreatePayMethodForm(
  isMakingPurchase: boolean,
  onSuccess: () => void
): FormHandler<ProcessPaymentFormValues> {
  const stripe = useStripe();
  const elements = useElements();
  const { state } = useLocation<PlanPurchaseLocationState | undefined>();
  const business = useCurrentBusiness();
  const invoices = useInvoices();
  const payMethods = usePayMethods();

  const createPayMethod = useCallback(
    async (values: ProcessPaymentFormValues) => {
      const card = elements?.getElement(CardElement);
      if (!stripe || !card) {
        // The stripe components have not mounted or the context provider hasn't been provided.
        // Basically, the developer is doing something wrong...
        throw new Error("Stripe components haven't loaded yet");
      }

      const res = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: values.name,
        },
      });

      if (res.error || !res.paymentMethod) {
        throw new Error(
          res.error?.message ??
            'An unknown error has occurred. Please try again later'
        );
      }

      // Attach the new pay method to the business
      await payMethods.attachPayMethod(res.paymentMethod.id);

      // We are collecting the payment information for the purposes of upgrading the account
      if (isMakingPurchase) {
        if (!state || !state.plan) {
          // This should only ever happen if the developer made a mistake and forgot to set the location state
          console.error(
            'Trying to make a purchase without the corresponding location state'
          );
          throw new Error(
            'An unknown error has occurred. Please try again later'
          );
        }

        // NOTE: If this fails and the user retries with the same card, there will be two identical cards
        // attached to the client. If the user retries with a different card, they will still have the original card attached to their account.
        // There could be a future iteration to solve this if it becomes an issue.
        await business.updateSubscription(state.plan, state.seats);

        // Now that the subscription has been updated, we need to refetch to invoices in case a new one has been generated
        try {
          await invoices.execute();
        } catch (err) {
          // Ignore this error. The user will need to refresh there screen to see the invoice
          // but it should not be treated the same as the update failing
          console.warn(
            'The invoices failed to refetch after the subscription was updated: ',
            err.message
          );
        }
      }

      onSuccess();
    },
    [
      elements,
      stripe,
      payMethods,
      isMakingPurchase,
      onSuccess,
      state,
      business,
      invoices,
    ]
  );

  return useFormHandler<ProcessPaymentFormValues>(
    createPayMethod,
    defaultValues,
    paymentFormSchema
  );
}
