import { quantumService } from 'backend/services';
import { useEffect } from 'react';
import useAsyncAction from './useAsyncAction';

export default function useQuantumEntropy(size = 128) {
  const [execute, loading, meta] = useAsyncAction<string, []>(async () => {
    const res = await quantumService.get<string>('/', {
      params: { ks: size },
    });

    return res.data;
  });

  useEffect(() => {
    execute();

    // Only execute the function on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, ...meta };
}
