// Supported country codes
export enum CountryCode {
  Canada = 'CAN',
  UnitedStates = 'USA',
}

export const countryCodeMap: { [key in CountryCode]: string } = {
  [CountryCode.Canada]: 'Canada',
  [CountryCode.UnitedStates]: 'United States',
};
