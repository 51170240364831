import React from 'react';
import { Button, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { observer } from 'mobx-react';
import { PageContainer } from 'shared/components/containers';
import InstallProducts from 'welcome/InstallProducts/InstallProducts';
import shield from './shield.svg';
import { BorderedBox, Header, Body } from '../styled';

const CreateActivity: React.FC = () => {
  const history = useHistory();
  const location = useLocation<{ showInstallProducts: boolean } | undefined>();
  const currentUser = useCurrentUser();

  const handleClick = () => {
    history.replace(location.pathname, { showInstallProducts: true });
  };

  if (location.state?.showInstallProducts) {
    return <InstallProducts />;
  }

  return (
    <PageContainer flex={1} display="flex" flexDirection="column">
      <Header>
        <Heading size="lg">
          Welcome
          {currentUser.data?.firstName && ' ' + currentUser.data?.firstName},
        </Heading>
        This is your XQ Dashboard. See all your XQ encryption activities, create
        a team or if you&apos;re a developer create an application.
      </Header>
      <BorderedBox py={{ base: 8, md: 16 }} width="100%" display="flex">
        <Body>
          <Flex mb={[8, 8, 0]} alignItems="center" flexBasis={{ md: '60%' }}>
            <Image
              src={shield}
              boxSize={['100px', '200px', '250px']}
              mx="auto"
              objectFit="cover"
            />
          </Flex>
          <VStack spacing={4} flexBasis="50%" textAlign="center">
            <Heading size="md" mr="auto">
              Start protecting your data
            </Heading>
            <Text as="p" textAlign="left" fontSize="lg">
              You don&apos;t have any activity yet, let&apos;s create some by
              installing one of our products.
            </Text>
            <Button mr="auto" onClick={handleClick}>
              Next
            </Button>
          </VStack>
        </Body>
      </BorderedBox>
    </PageContainer>
  );
};

export default observer(CreateActivity);
