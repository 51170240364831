import React, { useEffect, useRef } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import routes from 'routes';
import contactState from 'contacts/ContactState';
import { CreateContactFormContent } from './CreateContactForm';
import useCreateContactForm, {
  CreateContactFormValues,
} from './CreateContactForm/useCreateContactForm';

const CreateContactModal: React.FC = () => {
  const history = useHistory();
  const initialFocusRef = useRef(null);
  const toast = useToast();
  const formValuesRef = useRef<CreateContactFormValues>();

  const { onSubmit, form, error } = useCreateContactForm((contact) => {
    toast({
      title: 'New contact successfully created',
      description: formValuesRef.current?.email,
      status: 'success',
      position: 'bottom-right',
    });

    // Send the user to the contact details screen
    history.push(routes.contacts.detail.activity(contact.id));

    // Close the create contact modal
    contactState.closeCreateContactModal();
  });
  const { isSubmitting } = form.formState;
  const { reset } = form;
  formValuesRef.current = form.getValues();

  const isOpen = contactState.showCreateContactModal;
  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size="3xl"
      initialFocusRef={initialFocusRef}
      onClose={contactState.closeCreateContactModal}
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <form onSubmit={onSubmit} noValidate>
            <ModalHeader>Add a Team Member</ModalHeader>

            <ModalBody>
              <FormErrorAlert error={error} />
              <CreateContactFormContent initialFocusRef={initialFocusRef} />
            </ModalBody>

            <ModalFooter>
              <Button
                type="button"
                variant="outline"
                colorScheme="gray"
                mr={3}
                onClick={contactState.closeCreateContactModal}
              >
                Cancel
              </Button>
              <SpinnerButton type="submit" isLoading={isSubmitting}>
                Create Contact
              </SpinnerButton>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default observer(CreateContactModal);
