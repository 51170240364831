import React from 'react';
import { Box, BoxProps, Text, Button, Image, Spinner } from '@chakra-ui/react';
import { ChakraStyleProps } from '@chakra-ui/system';
import {
  DropzoneRootProps,
  DropzoneInputProps,
  DropzoneState,
} from 'react-dropzone';
import fileImage from './file.png';

interface DropzoneBoxProps extends BoxProps, Partial<DropzoneState> {
  root: DropzoneRootProps;
  input: DropzoneInputProps;
  iconImage?: string;
  dropMessage?: string;
  isLoading?: boolean;
  loadingText?: string;
}
const DropzoneBox: React.FC<DropzoneBoxProps> = ({
  rootRef,
  inputRef,
  isDragActive,
  root,
  input,
  iconImage = fileImage,
  dropMessage = 'Drop your file here',
  isLoading = false,
  loadingText = 'Processing',

  // These properties are available to be passed in, but they are ignored and we don't
  // want to pass them through to the dom.
  /* eslint-disable @typescript-eslint/no-unused-vars */
  isFocused,
  isDragAccept,
  isDragReject,
  isFileDialogActive,
  draggedFiles,
  acceptedFiles,
  fileRejections,
  getRootProps,
  getInputProps,
  open,
  /* eslint-enable @typescript-eslint/no-unused-vars */

  // All other props can be safely passed to the "Box" component
  ...props
}) => {
  const boxProps: BoxProps & {
    _hover: ChakraStyleProps;
    _focus: ChakraStyleProps;
  } = {
    borderColor: isDragActive ? 'blue.500' : 'gray.100',
    cursor: 'pointer',
    _hover: {
      borderStyle: 'dashed',
      borderColor: 'blue.500',
      outline: 'none',
    },
    _focus: {
      borderStyle: 'dashed',
      borderColor: 'blue.500',
      outline: 'none',
    },
  };

  if (isLoading) {
    boxProps.cursor = 'disabled';
    boxProps.pointerEvents = 'none';
    if (boxProps._hover) {
      boxProps._hover.borderColor = 'gray.100';
    }
    if (boxProps._focus) {
      boxProps._focus.borderColor = 'gray.100';
    }
  }

  const iconHeight = '45px';

  return (
    <Box
      ref={rootRef as React.RefObject<HTMLDivElement>}
      border="2px"
      borderStyle="dashed"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="200px"
      fontWeight="bold"
      fontSize="sm"
      textTransform="uppercase"
      p={8}
      bg="white"
      {...boxProps}
      {...root}
      {...props}
    >
      <input ref={inputRef} {...input} />
      {isLoading ? (
        <Box textAlign="center" m="auto">
          <Text mb={2}>{loadingText}</Text>
          <Spinner
            display="block"
            width={iconHeight}
            height={iconHeight}
            mx="auto"
            color="blue.500"
          />
        </Box>
      ) : (
        <>
          {isDragActive ? (
            <Text>{dropMessage}</Text>
          ) : (
            <>
              <Text>Drag and drop file here</Text>
              <Text>or</Text>
              <Button type="button" variant="link" pointerEvents="none">
                Browse
              </Button>
            </>
          )}
          <Image
            src={iconImage}
            height={iconHeight}
            mt={2}
            mx="auto"
            alt="File"
            ignoreFallback
          />
        </>
      )}
    </Box>
  );
};

export default DropzoneBox;
