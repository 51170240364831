import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import routes from 'routes';
import useNavigate from 'shared/hooks/useNavigate';
import { TutorialName } from '../constants';
import {
  useTutorialAPIKeyForm,
  TutorialAPIKeyFormFields,
} from '../components/TutorialAPIKeyForm';
import {
  ChapterContainer,
  ChapterContent,
  ChapterFooterGetStarted,
  ChapterHeader,
  ChapterIntroBox,
  ChapterIntroContent,
  TutorialTableOfContents,
} from '../components';
import { useDecryptTutorial } from './DecryptTutorialContext';
import useTutorialAPIKey from '../useTutorialAPIKey';
import chapters from './chapters';

const CURRENT_CHAPTER = 0;

interface Chapter0Props {
  apiKey: string;
}

const Chapter0: React.FC<Chapter0Props> = ({ apiKey }) => {
  const tutorial = useDecryptTutorial();
  const { setAPIKey } = useTutorialAPIKey();
  const gotoNextStep = useNavigate(routes.tutorials.decrypt.chapter1);
  const { handleSubmit, form } = useTutorialAPIKeyForm(apiKey, (values) => {
    tutorial.reset();
    setAPIKey(values.apiKey);
    gotoNextStep();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <ChapterContainer>
          <ChapterIntroBox variant="bg-decoration">
            <ChapterHeader
              chapters={chapters}
              chapterNum={CURRENT_CHAPTER}
              tutorial={TutorialName.decrypt}
            />
            <ChapterIntroContent>
              <Text mb={4}>
                This tutorial will guide you through a series of tools and
                instructions to show you how to fetch an encryption key from the
                XQ key store and then use it to decrypt a message.{' '}
                <strong>
                  These tools will perform real operations using your
                  application API key.
                </strong>
              </Text>

              <TutorialAPIKeyFormFields />
            </ChapterIntroContent>
          </ChapterIntroBox>

          <ChapterContent>
            <Heading size="lg" mb={4}>
              What to expect in this tutorial
            </Heading>

            <Text mb={8}>
              With this tutorial we will cover the services you will need to
              implement in order to fetch your encryption key within your own
              applications.
            </Text>

            <TutorialTableOfContents chapters={chapters} />
          </ChapterContent>

          <ChapterFooterGetStarted chapters={chapters} />
        </ChapterContainer>
      </form>
    </FormProvider>
  );
};

export default Chapter0;
