import React from 'react';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { formatJSON } from './useBodyEditor';

interface ResponseProps extends BoxProps {
  response?: string;
  statusCode?: number;
  statusText?: string;
}

const Response: React.FC<ResponseProps> = ({
  response,
  statusCode,
  statusText,
  ...props
}) => {
  if (!response) {
    return null;
  }

  const isStatusError = statusCode && statusCode >= 400 && statusCode <= 599;

  let formattedResponse = response;

  try {
    formattedResponse = formatJSON(response);
  } catch {
    // Error responses are formatted as JSON.
    if (isStatusError) {
      formattedResponse = JSON.stringify(response, null, 2);
    }
  }

  return (
    <Box {...props}>
      <Flex justify="space-between">
        <Text fontWeight="bold" fontSize="lg" mb={2}>
          Response:
        </Text>
        <Text
          fontWeight="bold"
          fontSize="lg"
          mb={2}
          color={isStatusError ? 'danger.500' : 'success.500'}
        >
          {statusCode} {statusText}
        </Text>
      </Flex>
      <Box
        as="pre"
        fontFamily="mono"
        fontSize="sm"
        color="white"
        borderRadius="lg"
        bg="blue.900"
        whiteSpace="pre-wrap"
        p={8}
      >
        {formattedResponse}
      </Box>
    </Box>
  );
};

export default Response;
