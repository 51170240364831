import { useState } from 'react';
import { debounce } from 'lodash';
import { Filter } from 'shared/components/FilterMenu/FilterMenu';
import useFilterMenu, {
  FilterMeta,
} from 'shared/components/FilterMenu/useFilterMenu';
import { useContacts } from 'contacts/ContactsController';
import { CommunicationStatus, CommunicationType } from 'key-packet/types';

export type DateRange = {
  fromDate: Date;
  toDate: Date;
};

export type CommunicationFilters = {
  senders?: string[];
  recipients?: string[];
  types?: number[];
  statuses?: number[];
  threats?: number[];
  dateRange?: DateRange;
  search?: string;
};

function buildDefaultFilter(
  id: string | number,
  label: string,
  isChecked = false
): Filter {
  return {
    id,
    label,
    isChecked,
  };
}

function toIdArray<T extends string | number = string>(filters: Filter[]): T[] {
  return filters.filter((f) => f.isChecked).map((f) => f.id as T);
}

const initialTypeFilters: Filter[] = [
  buildDefaultFilter(CommunicationType.File, 'File'),
  buildDefaultFilter(CommunicationType.Email, 'Email'),
  buildDefaultFilter(CommunicationType.Unknown, 'Other'),
];

const initialStatusFilters: Filter[] = [
  buildDefaultFilter(CommunicationStatus.Available, 'Available'),
  buildDefaultFilter(CommunicationStatus.Revoked, 'Revoked'),
  buildDefaultFilter(CommunicationStatus.Expired, 'Expired'),
];

export type CommunicationFilterMeta = {
  types: FilterMeta;
  statuses: FilterMeta;
  search: string;
  senders: FilterMeta;
  recipients: FilterMeta;
  setSearchValue: (value: string) => void;
};

export default function useCommunicationFilters(
  initialStatuses: CommunicationStatus[] = []
): [CommunicationFilterMeta, CommunicationFilters] {
  const contacts = useContacts();

  const [searchValue, setSearchValue] = useState('');
  const typeFilters = useFilterMenu(initialTypeFilters);
  const statusFilters = useFilterMenu(
    initialStatusFilters.map((filter) => {
      return {
        ...filter,
        isChecked: initialStatuses.includes(Number(filter.id)),
      };
    })
  );
  const debouncedSetSearchValue = debounce(setSearchValue, 500);

  const senderFilters = useFilterMenu(
    contacts.data?.map((c) => {
      return {
        id: c.email,
        label: c.email,
        isChecked: false,
      };
    }) ?? []
  );

  const recipientFilters = useFilterMenu(
    contacts.data?.map((c) => {
      return {
        id: c.email,
        label: c.email,
        isChecked: false,
      };
    }) ?? []
  );

  return [
    {
      types: typeFilters,
      statuses: statusFilters,
      search: searchValue,
      setSearchValue: debouncedSetSearchValue,
      senders: senderFilters,
      recipients: recipientFilters,
    },
    {
      types: toIdArray<number>(typeFilters.filters),
      statuses: toIdArray<number>(statusFilters.filters),
      senders: toIdArray<string>(senderFilters.filters),
      recipients: toIdArray<string>(recipientFilters.filters),
    },
  ];
}
