import React from 'react';
import { Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import routes from 'routes';
import ProtectedRoute from 'auth/ProtectedRoute';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import KeyPacketsListContainer from './list/KeyPacketsListContainer';
import KeyPacketDetail from './detail/KeyPacketDetail';

const KeyPacketRoutes: React.FC = () => {
  const user = useCurrentUser();
  return (
    <Switch>
      <ProtectedRoute path={routes.keyPacket.list.user} exact>
        <KeyPacketsListContainer />
      </ProtectedRoute>
      <ProtectedRoute
        path={routes.keyPacket.list.team}
        exact
        canAccess={user.access.canViewTeamCommunications}
      >
        <KeyPacketsListContainer />
      </ProtectedRoute>
      <ProtectedRoute path={routes.keyPacket.detail()} exact>
        <KeyPacketDetail />
      </ProtectedRoute>
    </Switch>
  );
};

export default observer(KeyPacketRoutes);
