import React from 'react';
import {
  Flex,
  FlexProps,
  Heading,
  Divider as ChakraDivider,
  Stat,
  StatProps,
  StatLabel,
  StatLabelProps,
  StatNumber,
  StatNumberProps,
} from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

export const DashboardStat: React.FC<StatProps & Partial<RouterLinkProps>> = (
  props
) => {
  return (
    <Stat
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      textAlign="center"
      transition="all 0.1s linear"
      _hover={{
        color: 'blue.500',
      }}
      cursor="pointer"
      {...props}
    />
  );
};

export type DashboardStatNumberProps = StatNumberProps;
export const DashboardStatNumber: React.FC<DashboardStatNumberProps> = (
  props
) => {
  return <StatNumber fontWeight="bold" fontSize="2xl" {...props} />;
};

export const DashboardStatLabel: React.FC<StatLabelProps> = (props) => {
  return <StatLabel lineHeight="shorter" {...props} />;
};

export const Divider: React.FC = () => {
  const orientation = useBreakpointValue({
    base: 'horizontal',
    md: 'vertical',
  }) as 'vertical' | 'horizontal';
  return (
    <ChakraDivider
      orientation={orientation}
      height={{ base: '1px', md: 12 }}
      mt="auto"
    />
  );
};

export const BoxHeading: React.FC = (props) => (
  <Heading size="sm" mb={2} {...props} />
);

export const WrapperBox: React.FC<FlexProps> = (props) => (
  <Flex
    direction="column"
    alignItems="center"
    justify="space-between"
    border="1px"
    borderColor="gray.100"
    borderRadius="lg"
    bg="white"
    p={6}
    width="100%"
    {...props}
  />
);
