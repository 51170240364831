import React, { useContext } from 'react';
import { Box, BoxProps, SystemStyleObject } from '@chakra-ui/react';
import { mergeWith } from '@chakra-ui/utils';
import Tablelvl2Context, { Tablelvl2ContextValues } from './Tablelvl2Context';

const tablelvl2VariantStyles: Record<
  Tablelvl2ContextValues['variant'],
  BoxProps
> = {
  head: {
    fontSize: 'sm',
    py: 2,
    fontWeight: 'bold',
    bg: 'coolGray',
    borderTop: '1px',
    borderTopColor: 'gray.100',
  },
  body: {
    fontSize: 'md',
    lineHeight: 'short',
    py: 4,
    color: 'gray.700',
  },
};

const truncatedStyles: SystemStyleObject = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 0,
  overflow: 'hidden',
};

const numericStyles: SystemStyleObject = {
  fontVariantNumeric: 'tabular-nums',
};

export type TableCellProps = BoxProps &
  React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  > & {
    isTruncated?: boolean;
    isNumeric?: boolean;
    isSticky?: boolean;
    stickyTop?: number | string;
  };

const TableCell: React.FC<TableCellProps> = ({
  children,
  isTruncated,
  isNumeric = false,
  isSticky = false,
  stickyTop = 0,
  ...props
}) => {
  const tablelvl2 = useContext(Tablelvl2Context);
  const isHeadCell = tablelvl2.variant === 'head';
  const component = isHeadCell ? 'th' : 'td';
  const role = isHeadCell ? 'columnheader' : 'cell';
  const styleProps: BoxProps = {
    borderBottom: 'solid 1px',
    borderColor: 'gray.100',
    px: 5,
    _first: {
      pl: 6,
    },
    _last: {
      pr: 6,
    },
    textAlign: 'left',
  };

  const styles: SystemStyleObject = {};

  mergeWith(styleProps, tablelvl2VariantStyles[tablelvl2.variant]);

  if (isTruncated) {
    mergeWith(styles, truncatedStyles);
  }

  if (isNumeric) {
    mergeWith(styles, numericStyles);
  }

  if (isHeadCell && isSticky) {
    mergeWith(styleProps, {
      position: 'sticky',
      top: stickyTop,
      // Blocker behind the table cell that prevents the display of content when it
      // scrolls past a sticky position that has whitespace above it.
      _after: {
        display: 'block',
        content: '""',
        bg: 'backdrop',
        position: 'absolute',
        left: '-1px', // Compensate for borders that may be part of the sticky content
        right: '-1px',
        top: '-201px',
        height: '200px',
        zIndex: -1,
      },
    });
  }

  return (
    <Box as={component} role={role} sx={styles} {...styleProps} {...props}>
      {children}
    </Box>
  );
};

export default TableCell;
