import React from 'react';
import { CommunicationSummary } from '../types';
import SpinnerButton from '../../shared/components/SpinnerButton';

export interface RevokeButtonProps {
  label: string;
  recipient: string;
  communication: CommunicationSummary;
  isLoading: boolean;
  onRevoke: (recipient: string, record: CommunicationSummary) => void;
}

const RevokeButton: React.FC<RevokeButtonProps> = ({
  label,
  recipient,
  communication,
  isLoading,
  onRevoke,
}) => {
  return (
    <SpinnerButton
      colorScheme="danger"
      mr={3}
      isLoading={isLoading}
      onClick={() => {
        onRevoke(recipient, communication);
      }}
    >
      {label}
    </SpinnerButton>
  );
};

export default RevokeButton;
