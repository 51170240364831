import React from 'react';
import { Box, Text, Link } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import routes from 'routes';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import SpinnerButton from 'shared/components/SpinnerButton';
import { BorderedBox } from 'welcome/styled';
import { PageContainer } from 'shared/components/containers';
import { MultiEmailField } from 'shared/components/MultiEmail';
import useInviteTeamMembersForm from './useInviteTeamMembersForm';

const InviteTeamMembersScene: React.FC = () => {
  const history = useHistory();
  const { onSubmit, form, error } = useInviteTeamMembersForm(() =>
    history.push(routes.contacts.root)
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <PageContainer p={8}>
          <BorderedBox mr="auto">
            <Box maxWidth="md">
              <Text as="span" fontSize="sm" color="gray.300">
                Step 2 of 2
              </Text>

              <Box my={4}>
                <Text as="p" fontWeight="bold">
                  Invite your team to this workspace
                </Text>

                <Text as="p" maxWidth={490}>
                  Add teammates, vendors, customers. You can add multiple
                  emails.
                </Text>
              </Box>
              <MultiEmailField name="emails" />
              <FormErrorAlert error={error} />
              <SpinnerButton
                type="submit"
                isLoading={form.formState.isSubmitting}
              >
                Next
              </SpinnerButton>
              <Link as={RouterLink} to={routes.home} display="block" mt={4}>
                Skip for now
              </Link>
            </Box>
          </BorderedBox>
        </PageContainer>
      </form>
    </FormProvider>
  );
};

export default InviteTeamMembersScene;
