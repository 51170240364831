import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Heading, Tabs } from '@chakra-ui/react';
import ActivityTour from 'tours/ActivityTour';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { PageContainer } from 'shared/components/containers';
import DateRangeHeading from 'shared/components/DateRangeHeading';
import {
  dateRangePresetsMap,
  DateRangePreset,
  DateRange,
} from 'event-log/components/DatePicker/constants';
import ActivityFeed from 'activity/list/ActivityFeed';
import DashboardStats from './Dashboard/DashboardStats';
import DateFilterNav from './Dashboard/DateFilterNav';
import TeamFilterNav from './Dashboard/TeamFilterNav';
import ThreatGroupTabList from './Dashboard/ThreatGroupTabList';
import {
  ThreatGroupTab,
  threatLevelByTab,
} from './Dashboard/ThreatGroupTabList/constants';

const dateRangePresets: DateRangePreset[] = [
  dateRangePresetsMap.today,
  dateRangePresetsMap.last7Days,
  dateRangePresetsMap.last30Days,
  dateRangePresetsMap.last12Months,
];

const INITIAL_DATE_RANGE_PRESET = dateRangePresets[2];

type NoResultsContent = {
  heading?: string;
  content?: string;
};

const noResultsByTab: Record<number, NoResultsContent> = {
  [ThreatGroupTab.Good]: {
    heading: 'No activities found with good status',
  },
  [ThreatGroupTab.Warnings]: {
    heading: 'No activities found with warning status',
  },
  [ThreatGroupTab.Threats]: {
    heading: 'No activities found that need attention',
  },
};

const Dashboard: React.FC = () => {
  const currentUser = useCurrentUser();
  const [tabIndex, setTabIndex] = useState(ThreatGroupTab.All);
  const [selectedDateRangePreset, setSelectedDateRangePreset] = useState<
    DateRangePreset | undefined
  >(INITIAL_DATE_RANGE_PRESET);
  const [isTeamView, setIsTeamView] = useState(false);
  const selectedDateRange: DateRange | undefined =
    selectedDateRangePreset?.dateRange;

  const isPersonalized = !!currentUser.data?.firstName;

  return (
    <PageContainer>
      <Box position="relative" zIndex={2}>
        <Box mt={8}>
          <Heading as="p" size="lg" mb={1}>
            {isPersonalized
              ? `Hello, ${currentUser.data?.firstName}. Welcome back!`
              : 'Welcome back!'}
          </Heading>
          <Heading as="h1" size="md" fontWeight="normal">
            Here&rsquo;s a quick overview of what&rsquo;s happening
          </Heading>
        </Box>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          alignItems="center"
          mt={10}
        >
          <DateFilterNav
            data-tour-id="activity-date-filter"
            presets={dateRangePresets}
            selectedPreset={selectedDateRangePreset}
            onSelect={setSelectedDateRangePreset}
            mb={{ sm: 4, md: 0 }}
          />
          {currentUser.access.canViewTeamActivities && (
            <TeamFilterNav isTeamView={isTeamView} onSelect={setIsTeamView} />
          )}
        </Flex>

        <DashboardStats
          goodTabIndex={ThreatGroupTab.Good}
          warningsTabIndex={ThreatGroupTab.Warnings}
          threatsTabIndex={ThreatGroupTab.Threats}
          user={isTeamView ? undefined : currentUser.data?.id}
          fromDate={selectedDateRange?.fromDate}
          toDate={selectedDateRange?.toDate}
          onStatClick={setTabIndex}
          my={4}
        />

        <DateRangeHeading
          heading={isTeamView ? 'Team Activities' : 'My Activities'}
          dateRange={selectedDateRange}
          mt={12}
          mb={4}
        />
      </Box>

      <Tabs size="lg" index={tabIndex} onChange={setTabIndex}>
        <ActivityFeed
          tableNav={<ThreatGroupTabList />}
          isTeamView={isTeamView}
          dateRange={selectedDateRange}
          threatLevels={threatLevelByTab[tabIndex]}
          noResultsHeading={noResultsByTab[tabIndex]?.heading}
          noResultsContent={noResultsByTab[tabIndex]?.content}
        />
      </Tabs>

      <ActivityTour />
    </PageContainer>
  );
};

export default observer(Dashboard);
