import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export const SectionHeader: React.FC<FlexProps> = (props) => (
  <Flex
    as="header"
    direction={{ base: 'column', md: 'row' }}
    justifyContent="space-between"
    alignItems={{ base: 'flex-start', md: 'flex-end' }}
    pt={8}
    pb={2}
    px={6}
    borderBottom="1px"
    borderColor="gray.100"
    {...props}
  />
);
