import React, { useEffect, useRef } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { APIKeyFormFields } from './APIKeyForm';
import useAPIKeyForm from './APIKeyForm/useAPIKeyForm';

interface APIKeyModalProps {
  appId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (apiKey: string) => void;
}

const APIKeyModal: React.FC<APIKeyModalProps> = ({
  appId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const initialFocusRef = useRef(null);
  const { onSubmit, form, error } = useAPIKeyForm(appId, onSuccess);
  const { isSubmitting } = form.formState;
  const { reset } = form;

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  return (
    <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <form onSubmit={onSubmit} noValidate>
            <ModalHeader>Add a new API key</ModalHeader>

            <ModalBody>
              <FormErrorAlert error={error} />
              <APIKeyFormFields initialFocusRef={initialFocusRef} />
            </ModalBody>

            <ModalFooter>
              <Button
                type="button"
                variant="outline"
                colorScheme="gray"
                mr={3}
                onClick={onClose}
              >
                Cancel
              </Button>
              <SpinnerButton type="submit" isLoading={isSubmitting}>
                Continue
              </SpinnerButton>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default APIKeyModal;
