
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.4,"time":24000,"words":80}
/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const CodeView = makeShortcode("CodeView");
const Code = makeShortcode("Code");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "key-management"
    }}>{`Key Management`}</h1>
    <p>{`What is key Management, how does XQ handle my keys`}</p>
    <CodeView mdxType="CodeView">
    <Code language="bash" title="Command Line" mdxType="Code">
        $ uname -a
    </Code>
    </CodeView>
    <h2 {...{
      "id": "creating-a-key-packet"
    }}>{`Creating a key packet`}</h2>
    <p>{`How to create a key packet`}</p>
    <h2 {...{
      "id": "uploading-key-packet"
    }}>{`Uploading key packet`}</h2>
    <p>{`How to upload a key packet to XQ`}</p>
    <h2 {...{
      "id": "fetching-a-key"
    }}>{`Fetching a key`}</h2>
    <p>{`How to fetch a key from XQ`}</p>
    <h2 {...{
      "id": "revoking-a-key"
    }}>{`Revoking a key`}</h2>
    <p>{`How to revoke a key from XQ`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "creating-a-key-packet",
    level: 2,
    title: "Creating a key packet",
    children: [
        
      ]
  },
{
    id: "uploading-key-packet",
    level: 2,
    title: "Uploading key packet",
    children: [
        
      ]
  },
{
    id: "fetching-a-key",
    level: 2,
    title: "Fetching a key",
    children: [
        
      ]
  },
{
    id: "revoking-a-key",
    level: 2,
    title: "Revoking a key",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Key Management",
  "description": "Key Management",
  "route": "/docs/keys",
  "disableTableOfContents": false
}

