import React, { useContext } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import EncryptionTabs from '../components/EncryptionTabs';
import StepsCount from './components/StepsCount';
import { EncryptWizardValues } from './EncryptMessageContext';

type EncryptWizardProps = {
  chapters: React.ComponentType[];
  heading: string;
  helperText: string;
  context: React.Context<EncryptWizardValues>;
};

const EncryptWizard: React.FC<EncryptWizardProps> = ({
  chapters,
  context,
  heading,
  helperText,
}) => {
  const { chapter } = useContext(context);
  const Component = chapters[chapter - 1];
  return (
    <>
      <Box mb={2}>
        <EncryptionTabs />

        <Box mb={8}>
          <Heading size="md" mb={2}>
            {heading}
          </Heading>
          <Text>{helperText}</Text>
        </Box>

        <StepsCount current={chapter} total={chapters.length} />
      </Box>
      <Component />
    </>
  );
};

export default EncryptWizard;
