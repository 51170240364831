import React from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  SimpleGrid,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import CommunicationController from '../CommunicationController';
import useGrantAccessForm from './useGrantAccessForm';
import { MultiEmailField } from '../../shared/components/MultiEmail';

interface GrantAcessFormProps {
  communication: CommunicationController;
  onClose: () => void;
}

const GrantAcessForm: React.FC<GrantAcessFormProps> = ({
  communication,
  onClose,
}) => {
  const { onSubmit, form, error } = useGrantAccessForm(communication, onClose);
  const { isSubmitting } = form.formState;

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <FormErrorAlert error={error} />
        <SimpleGrid spacing={4}>
          <FormControl maxWidth="lg">
            <FormLabel>Add Recipients</FormLabel>
            <MultiEmailField id="recipients" name="recipients" mb={0} />
            <FormHelperText>
              Grant additional users access to this communication.
            </FormHelperText>
          </FormControl>
        </SimpleGrid>
        <Flex justify="flex-start" mt={8}>
          <ButtonGroup spacing={4}>
            <Button
              type="button"
              variant="outline"
              colorScheme="gray"
              onClick={onClose}
            >
              Cancel
            </Button>
            <SpinnerButton
              type="submit"
              isLoading={isSubmitting}
              onClick={onSubmit}
            >
              Grant Access
            </SpinnerButton>
          </ButtonGroup>
        </Flex>
      </form>
    </FormProvider>
  );
};

export default GrantAcessForm;
