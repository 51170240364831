import React from 'react';
import { SimpleGrid, Text } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { PostLoginReturnState } from 'auth/ProtectedRoute';
import {
  CenteredLayoutContent,
  CenteredLayoutContentInner,
  CenteredLayoutHeading,
} from 'shared/components/CenteredLayout';
import { StackedInputField } from 'shared/components/form';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import SpinnerButton from 'shared/components/SpinnerButton';
import { GoogleButton } from 'shared/components/sso';
import { useGoogleLogin } from 'shared/components/sso/useGoogleLogin';
import useEmailLoginForm from './useEmailLoginForm';

const EmailLoginForm: React.FC = () => {
  const { state } = useLocation<PostLoginReturnState | undefined>();
  const { onSubmit, form, error } = useEmailLoginForm();
  const { requestGoogleLogin, error: googleError } = useGoogleLogin(
    state?.returnTo
  );

  return (
    <FormProvider {...form}>
      <CenteredLayoutContent as="form" onSubmit={onSubmit} noValidate mb={0}>
        <CenteredLayoutHeading>Sign in to XQ</CenteredLayoutHeading>

        <CenteredLayoutContentInner>
          <FormErrorAlert error={error || googleError} />

          <SimpleGrid spacing={4}>
            <GoogleButton onClick={requestGoogleLogin} />

            <Text textAlign="center">or</Text>

            <StackedInputField
              id="email"
              name="email"
              type="email"
              label="Email address"
            />
            <SpinnerButton
              type="submit"
              size="lg"
              isLoading={form.formState.isSubmitting}
              mt={4}
              role="button"
            >
              Send me a magic link
            </SpinnerButton>
          </SimpleGrid>
        </CenteredLayoutContentInner>
      </CenteredLayoutContent>
    </FormProvider>
  );
};

export default EmailLoginForm;
