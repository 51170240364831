import React from 'react';
import { observer } from 'mobx-react';
import { SimpleGrid } from '@chakra-ui/react';
import { StackedInputField } from 'shared/components/form';
import StackedSelectField from 'shared/components/form/StackedSelect/StackedSelectField';
import { RolesCodes, NotificationsCodes } from 'shared/constants/contacts';
import { UserRole } from 'auth/user/types';
import { useCurrentUser } from 'auth/user/CurrentUserController';

const roles = RolesCodes;
const notifications = NotificationsCodes;
interface CreateContactFormContentProps {
  initialFocusRef?: React.RefObject<HTMLInputElement>;
}
const CreateContactFormContent: React.FC<CreateContactFormContentProps> = ({
  initialFocusRef,
}) => {
  const user = useCurrentUser();

  return (
    <SimpleGrid spacing={8}>
      <StackedInputField
        id="email"
        name="email"
        type="text"
        label="Email"
        initialFocusRef={initialFocusRef}
        isRequired
      />
      <StackedSelectField
        name="role"
        size="lg"
        label="Role"
        isRequired
        defaultValue=""
      >
        {roles.map(([label, value]) => (
          <option
            value={value}
            label={label}
            key={value}
            disabled={
              value === UserRole.SuperUser && !user.access.canMakeSuperUser
            }
          />
        ))}
      </StackedSelectField>
      <StackedInputField
        id="first_name"
        name="firstName"
        type="text"
        label="First Name"
        initialFocusRef={initialFocusRef}
      />
      <StackedInputField
        id="last_name"
        name="lastName"
        type="text"
        label="Last Name"
        initialFocusRef={initialFocusRef}
      />
      <StackedInputField
        id="title"
        name="title"
        type="text"
        label="Title"
        initialFocusRef={initialFocusRef}
      />

      <StackedInputField
        id="phone"
        name="phone"
        type="text"
        label="Phone"
        initialFocusRef={initialFocusRef}
      />

      <StackedSelectField
        name="notificationsStatus"
        size="lg"
        label="Notifications"
        defaultValue=""
      >
        {notifications.map(([label, value]) => (
          <option value={value} label={label} key={value} />
        ))}
      </StackedSelectField>
    </SimpleGrid>
  );
};

export default observer(CreateContactFormContent);
