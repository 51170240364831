import React, { useRef } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import useCreateApplicationForm, {
  CreateApplicationFormValues,
} from './useCreateApplicationForm';
import CreateApplicationFormContent from './CreateApplicationFormContent';

const CreateApplicationForm: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const formValuesRef = useRef<CreateApplicationFormValues>();
  const { onSubmit, form, error } = useCreateApplicationForm((app) => {
    toast({
      title: 'New application successfully created',
      description: formValuesRef.current?.name,
      status: 'success',
      position: 'bottom-right',
    });

    // Redirect to the application details upon success
    history.push(routes.application.detail(app.id));
  });
  const { isSubmitting } = form.formState;
  formValuesRef.current = form.getValues();

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <FormErrorAlert error={error} />
        <CreateApplicationFormContent />
        <Box textAlign="right" mt={8}>
          <SpinnerButton type="submit" isLoading={isSubmitting}>
            Create Application
          </SpinnerButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default CreateApplicationForm;
