const root = '/encryption';
const baseRoute = (path = '') => `${root}${path}`;

export default {
  root,
  encryptMessage: baseRoute('/encrypt-message'),
  encryptFile: baseRoute('/encrypt-file'),
  decryptMessage: baseRoute('/decrypt-message'),
  decryptFile: baseRoute('/decrypt-file'),
} as const;
