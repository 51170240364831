import React, { useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Divider,
  ModalCloseButton,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { ApplicationSummary } from 'application/ApplicationsController';
import UpdateApplicationForm from '../../update/UpdateApplicationForm';
import DeleteApplicationSection from './DeleteApplicationSection';

type EditOrDeleteApplicationModalProps = {
  app: ApplicationSummary;
  isOpen?: boolean;
  onClose: () => void;
};

const EditOrDeleteApplicationModal: React.FC<EditOrDeleteApplicationModalProps> = ({
  isOpen = false,
  onClose,
  app,
}) => {
  const initialFocusRef = useRef<HTMLInputElement>(null);
  return (
    <Modal
      isOpen={isOpen}
      size="3xl"
      autoFocus={false}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Application</ModalHeader>
        <ModalCloseButton size="md" color="white" right={6} top={6} />
        <ModalBody>
          <UpdateApplicationForm
            app={app}
            initialFocusRef={initialFocusRef}
            onClose={onClose}
          />
        </ModalBody>
        <Divider borderColor="gray.50" borderBottomWidth="2px" />
        <DeleteApplicationSection app={app} />
      </ModalContent>
    </Modal>
  );
};

export default observer(EditOrDeleteApplicationModal);
