import React from 'react';
import { Heading, Flex, StackProps } from '@chakra-ui/react';
import { ChaptersDict } from '../types';
import ChapterFooter from './ChapterFooter';
import ChapterNavLink from './ChapterNavLink';

interface ChapterFooterGetStartedProps extends StackProps {
  chapters: ChaptersDict;
}

const ChapterFooterGetStarted: React.FC<ChapterFooterGetStartedProps> = ({
  chapters,
  ...props
}) => {
  return (
    <ChapterFooter spacing={0} {...props}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'center', md: 'flex-end' }}
        ml={{ base: 0, md: 'auto' }}
      >
        <Heading size="lg" mr={{ base: 0, md: 10 }} mb={1}>
          Ready to get started?
        </Heading>

        <ChapterNavLink
          isButton
          type="submit"
          chapters={chapters}
          chapterNum={1}
        />
      </Flex>
    </ChapterFooter>
  );
};

export default ChapterFooterGetStarted;
