import React from 'react';
import { Stack, Flex, Text, Button, Box, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { validationServiceBaseURL } from 'backend/services';
import {
  RequestURL as TryItRequestURL,
  Headers as TryItHeaders,
  Response as TryItResponse,
  TryIt,
  ChildrenRenderProps as TryItChildrenRenderProps,
  TryItResult,
} from 'shared/components/TryIt';
import { StackedInput, StackedTextarea } from 'shared/components/form';
import SpinnerButton from 'shared/components/SpinnerButton';
import { useDashboardContentContext } from 'dashboard/layout/DashboardContentContext';
import routes from 'routes';
import { TutorialName } from '../constants';
import {
  ChapterContainer,
  ChapterContent,
  ChapterFooter,
  ChapterFormBox,
  ChapterHeader,
  ChapterIntroBox,
  ChapterIntroContent,
  ChapterNavLink,
  ChapterResponseAlert,
} from '../components';
import { useEncryptTutorial } from '../encrypt/EncryptTutorialContext';
import { useDecryptTutorial } from './DecryptTutorialContext';
import chapters from './chapters';

const CURRENT_CHAPTER = 1;

interface RequestFormValues {
  locatorToken: string;
  accessToken: string;
}

interface Chapter1Props {
  apiKey: string;
}

const Chapter1: React.FC<Chapter1Props> = ({ apiKey }) => {
  const tutorial = useDecryptTutorial();
  const encryptTutorial = useEncryptTutorial();
  const { scrollToBottom } = useDashboardContentContext();
  const chapterResults = tutorial.chapterResults[CURRENT_CHAPTER];
  const isChapterComplete = chapterResults?.status === 200;
  const hasEncryptExample =
    encryptTutorial.locatorToken && encryptTutorial.subscriberAccessToken;

  const { register, watch, setValue } = useForm<RequestFormValues>({
    defaultValues: {
      locatorToken: tutorial.locatorToken,
      accessToken: tutorial.accessToken,
    },
  });
  const locatorToken = watch('locatorToken');
  const accessToken = watch('accessToken');

  const keyRequestMethod = 'get';
  const keyRequestURL = `${validationServiceBaseURL}/key/${encodeURIComponent(
    locatorToken
  )}`;

  const headers = {
    'api-key': apiKey || 'API KEY MISSING',
    authorization: `Bearer ${accessToken || 'AUTHORIZATION MISSING'}`,
  };

  const handleSetFromEncryptionTutorial = () => {
    setValue('accessToken', encryptTutorial.subscriberAccessToken);
    setValue('locatorToken', encryptTutorial.locatorToken);
    tutorial.storeResult(CURRENT_CHAPTER)(undefined);
  };

  const handleSuccess = (result: TryItResult) => {
    tutorial.setLocatorToken(locatorToken);
    tutorial.setAccessToken(accessToken);
    tutorial.setEncryptionKey(result.response.trim());
    tutorial.storeResult(CURRENT_CHAPTER)(result);
    scrollToBottom();
  };

  const handleError = (result: TryItResult) => {
    tutorial.setLocatorToken('');
    tutorial.setAccessToken('');
    tutorial.setEncryptionKey('');
    tutorial.storeResult(CURRENT_CHAPTER)(result);
    scrollToBottom();
  };

  return (
    <ChapterContainer>
      <ChapterIntroBox>
        <ChapterHeader
          tutorial={TutorialName.decrypt}
          chapters={chapters}
          chapterNum={CURRENT_CHAPTER}
        />
        <ChapterIntroContent>
          <Text>
            Before we can decrypt the message we will need to retrieve the key
            that was used for encryption. To find this key we will use the
            locator token that XQ provides when the key is uploaded.
          </Text>
          <br />
          <Text>
            Now the locator token alone is not enough, we also need to ensure
            the user that is fetching the token has the permissions to return
            this key. In order to verify the identity of the caller we will need
            the authorization token of the subscriber we created.
          </Text>
          <br />
          <Text>
            See the tutorial{' '}
            <Link href={routes.tutorials.encrypt.chapter0} target="_blank">
              encrypting a message
            </Link>{' '}
            if you would like to generate these tokens
          </Text>
        </ChapterIntroContent>
      </ChapterIntroBox>

      <TryIt
        url={keyRequestURL}
        method={keyRequestMethod}
        headers={headers}
        onSuccess={handleSuccess}
        onError={handleError}
      >
        {({ execute, loading }: TryItChildrenRenderProps) => {
          const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault();
            execute();
          };
          return (
            <ChapterContent as="form" onSubmit={handleSubmit}>
              <TryItRequestURL
                requestURL={keyRequestURL}
                requestMethod={keyRequestMethod}
              />
              <TryItHeaders headers={headers} />
              <ChapterFormBox my={6}>
                <Stack spacing={6}>
                  <StackedInput
                    name="locatorToken"
                    label="Locator token for key packet"
                    helperText="This token is the response received when storing the key packet on the server."
                    ref={register}
                    isRequired
                  />
                  <StackedTextarea
                    name="accessToken"
                    label="Authorization access token"
                    helperText="This token is the response received when creating the subscriber."
                    ref={register}
                    isRequired
                  />
                  <Box>
                    {hasEncryptExample ? (
                      <Button
                        type="button"
                        variant="outline"
                        onClick={handleSetFromEncryptionTutorial}
                      >
                        Paste Values
                      </Button>
                    ) : (
                      <>
                        <Text fontWeight="bold">
                          Not sure what to use for these fields?
                        </Text>
                        <Link
                          as={RouterLink}
                          to={routes.tutorials.encrypt.chapter0}
                        >
                          Try the encrypt message tutorial first
                        </Link>
                      </>
                    )}
                  </Box>
                </Stack>
              </ChapterFormBox>

              <Flex justify="flex-end">
                <SpinnerButton type="submit" isLoading={loading}>
                  Send Request
                </SpinnerButton>
              </Flex>

              <TryItResponse
                response={chapterResults?.response}
                statusCode={chapterResults?.status}
                statusText={chapterResults?.statusText}
                mt={6}
              />

              {isChapterComplete && (
                <ChapterResponseAlert title="We found your encryption key!">
                  Success! We were able to use the provided locator token to
                  retrieve the encryption key. In doing so we have also verified
                  the subscriber is valid and still has access to the key.
                </ChapterResponseAlert>
              )}
            </ChapterContent>
          );
        }}
      </TryIt>

      <ChapterFooter>
        <ChapterNavLink direction="back" chapters={chapters} chapterNum={0} />
        {isChapterComplete && (
          <ChapterNavLink chapters={chapters} chapterNum={2} />
        )}
      </ChapterFooter>
    </ChapterContainer>
  );
};

export default Chapter1;
