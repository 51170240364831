import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import authController from 'auth/AuthenticationController';
import MagicLinkQueryStoreContext from 'auth/MagicLinkQueryStoreContext';
import routes from 'routes';

export enum Status {
  Initial,
  Checking,
  Complete,
  Invalid,
}

export function useLoginWithCode() {
  const magicLinkState = useContext(MagicLinkQueryStoreContext);
  const location = useLocation();
  const history = useHistory();
  const qs = useRef(new URLSearchParams(location.search.substr(1)));

  const [status, setStatus] = useState<Status>(Status.Initial);

  useEffect(() => {
    const token = qs.current.get('access_token');
    const run = async () => {
      if (token) {
        setStatus(Status.Checking);
        try {
          const hasAccessToDashboard = await authController.verifyAccount(
            token
          );

          if (hasAccessToDashboard) {
            // Go back to where the user originally wanted to go and then clear the state
            history.push(magicLinkState.returnTo || routes.home);
            magicLinkState.reset();
          } else {
            // This user doesn't have access to the dashboard. We need to send them to a thank you screen instead
            history.push(routes.auth.accountVerified);
          }
        } catch {
          setStatus(Status.Invalid);
        }
      } else {
        setStatus(Status.Invalid);
      }
    };
    run();
  }, [history, magicLinkState]);
  return {
    hasToken: qs.current.has('access_token'),
    status,
  };
}
