import React from 'react';
import { Badge, Flex, Text } from '@chakra-ui/react';
import { UserStatus, UserSummary } from 'auth/user/types';

const statusColorsMap: Record<UserStatus, string> = {
  [UserStatus.Disabled]: 'red',
  [UserStatus.Invited]: 'blue',
  [UserStatus.Validated]: 'green',
};

interface ContactNameCellContentProps {
  contact: UserSummary;
}

const ContactNameCellContent: React.FC<ContactNameCellContentProps> = ({
  contact,
}) => {
  const fullName = [contact.firstName, contact.lastName]
    .filter((n) => !!n)
    .join(' ');
  return (
    <>
      <Flex alignItems="center">
        {fullName && (
          <Text fontWeight="bold" mr={2}>
            {fullName}
          </Text>
        )}
        <Badge
          colorScheme={statusColorsMap[contact.status]}
          textTransform="initial"
        >
          {contact.status}
        </Badge>
      </Flex>
      {contact.email}
    </>
  );
};

export default ContactNameCellContent;
