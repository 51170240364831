import { observable } from 'mobx';
import Axios from 'axios';

export type TryItMethod = 'post' | 'get'; // Not supporting other methods for now

export interface TryItResult {
  body?: string;
  response: string;
  status: number;
  statusText: string;
}

export class TryItOperation {
  url: string;

  body?: string;

  headers?: Record<string, string>;

  method: TryItMethod;

  @observable
  statusCode?: number;

  @observable
  statusText?: string;

  @observable
  loading = false;

  @observable
  response?: string;

  onSuccess?: (result: TryItResult) => void;

  onError?: (result: TryItResult) => void;

  constructor(url: string, method: TryItMethod) {
    this.url = url;
    this.method = method;
    this.execute = this.execute.bind(this);
  }

  async execute() {
    const { body } = this;
    this.loading = true;
    this.response = undefined;
    this.statusCode = undefined;
    this.statusText = undefined;
    const response = await Axios({
      method: this.method,
      url: this.url,
      headers: this.headers,
      data: body,
      validateStatus: () => true,
      responseType: 'text',
    });
    this.statusCode = response.status;
    this.statusText = response.statusText;
    this.loading = false;
    this.response = response.data;
    if (this.onSuccess && this.statusCode === 200) {
      this.onSuccess({
        body,
        response: response.data,
        status: response.status,
        statusText: response.statusText,
      });
    }
    if (this.onError && this.statusCode >= 400 && this.statusCode <= 599) {
      this.onError({
        body,
        response: response.data,
        status: response.status,
        statusText: response.statusText,
      });
    }
  }
}
