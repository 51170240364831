import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'routes';
import authController from 'auth/AuthenticationController';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { PostLoginReturnState } from 'auth/ProtectedRoute';

export type LoginFormValues = {
  workspace: string;
  email: string;
  password: string;
};

const loginFormSchema = yup.object().shape({
  workspace: yup.string().required('Enter your workspace name'),
  email: yup
    .string()
    .required('Enter your email address')
    .email('Invalid email address'),
  password: yup.string().required('Enter your account password'),
});

const defaultValues: LoginFormValues = {
  workspace: '',
  email: '',
  password: '',
};

export default function useLoginForm(): FormHandler<LoginFormValues> {
  const { state } = useLocation<PostLoginReturnState | undefined>();

  const history = useHistory();
  return useFormHandler<LoginFormValues>(
    async ({ email, workspace, password }) => {
      await authController.login({
        email,
        workspace,
        pwd: password,
      });

      history.push(state?.returnTo || routes.home);
    },
    defaultValues,
    loginFormSchema,
    true
  );
}
