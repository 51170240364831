import omit from 'lodash/omit';
import { ContactSummary } from 'backend/api-types/dashboard';

export enum UserRole {
  SuperUser = 'SuperUser',
  Admin = 'Admin',
  User = 'User',
  Vendor = 'Vendor',
  Customer = 'Customer',
}

export enum UserStatus {
  Invited = 'Invited',
  Validated = 'Validated',
  Disabled = 'Disabled',
}

export enum UserNotificationStatus {
  None = 0,
  WarningsAndAlerts = 2,
  AlertsOnly = 3,
}

export type UserSummary = {
  id: number;
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  title?: string;
  lastLogin: number;
  moreInfo: string;
  role: UserRole;
  notificationsStatus: UserNotificationStatus;
  status: UserStatus;
};

/**
 * Convert some of the field names to something more intuitive and readable
 * @param contact
 */
export function convertContactToUser(contact: ContactSummary): UserSummary {
  return {
    ...omit(contact, ['fn', 'ln', 'last', 'more', 'n', 'state']),
    firstName: contact.fn,
    lastName: contact.ln,
    lastLogin: contact.last,
    moreInfo: contact.more ?? '',
    notificationsStatus: contact.n,
    status: contact.state as UserStatus,
    role: contact.role as UserRole,
  };
}

export const userRoleToNumberMap: Record<UserRole, 1 | 2 | 3 | 4 | 5> = {
  [UserRole.Admin]: 1,
  [UserRole.User]: 2,
  [UserRole.Vendor]: 3,
  [UserRole.Customer]: 4,
  [UserRole.SuperUser]: 5,
};
