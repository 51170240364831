import React, { useRef } from 'react';
import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDimensions,
  useMediaQuery,
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { observer } from 'mobx-react';
import { Waypoint } from 'react-waypoint';
import {
  TableCapWrapper,
  TableCap,
  TableLoadingRows,
  TableWrapper,
  TableOverflow,
  TableCapContent,
} from 'shared/components/Table';
import BackToTopFAB from 'shared/components/BackToTopFAB';
import { FilterMenuStack, FilterMenu } from 'shared/components/FilterMenu';
import { DateRange } from 'event-log/components/DatePicker/constants';
import { useEventTypes } from 'event-log/EventTypesController';
import useEventLog, { EventThreatLevel } from 'event-log/list/useEventLog';
import useEventFilters from 'event-log/list/useEventFilters';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { MINIMUM_STICKY_VIEWPORT_HEIGHT } from 'shared/components/Table/Table';
import { ACTIVITY_FEED_EVENTS, skeletonMockData } from '../constants';
import ActivityFeedTable from './ActivityFeedTable';

interface ActivityFeedProps {
  threatLevels?: EventThreatLevel[];
  tableNav?: React.ReactNode;
  dateRange?: DateRange;
  isTeamView?: boolean;
  noResultsHeading?: string;
  noResultsContent?: React.ReactNode;
}

const ActivityFeed: React.FC<ActivityFeedProps> = ({
  threatLevels = [1, 2, 3],
  tableNav,
  dateRange,
  noResultsHeading,
  noResultsContent,
  isTeamView = false,
}) => {
  let tableCapHeight;
  const tableCapRef = useRef<HTMLDivElement>(null);
  const currentUser = useCurrentUser();
  const [filterMeta, filters] = useEventFilters(ACTIVITY_FEED_EVENTS);
  useEventTypes();

  const selectedUsers = filterMeta.users.filters
    .filter((c) => {
      return c.isChecked;
    })
    .map((c) => {
      return c.id as number;
    });

  const userFilter = isTeamView ? selectedUsers : [currentUser.data?.id];
  const eventLog = useEventLog({
    ...filters,
    threatLevels,
    dateRange,
    user: userFilter,
    search: filterMeta.search,
  });
  const tableCapDimensions = useDimensions(tableCapRef, true);
  // Any smaller than this and the table will need horizontal scrolling,
  // which breaks the sticky table header cells.
  const [enableSticky] = useMediaQuery(
    `(min-width: 1140px) and (min-height: ${MINIMUM_STICKY_VIEWPORT_HEIGHT}px)`
  );

  const onNoMoreRows = ({ event }: Waypoint.CallbackArgs) => {
    // Check for an event to prevent waypoint from firing when it is visible when mounted.
    if (!eventLog.loading && event) {
      eventLog.loadMore();
    }
  };

  if (enableSticky && tableCapDimensions?.contentBox.height) {
    tableCapHeight = `${
      Math.round(tableCapDimensions?.contentBox.height) + 1
    }px`;
  }

  return (
    <>
      <TableCapWrapper isSticky={enableSticky} ref={tableCapRef}>
        <TableCap
          nav={tableNav}
          isTableWithSkeleton={eventLog.isInitialLoading}
        >
          <TableCapContent>
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems="center"
            >
              <FilterMenuStack>
                <Text fontWeight="bold">Filter By:</Text>
                <FilterMenu
                  name="Activity Type"
                  options={filterMeta.eventTypes.filters}
                  activeFilterCount={filterMeta.eventTypes.activeCount}
                  onFilterClick={filterMeta.eventTypes.update}
                  searchable
                />
                <FilterMenu
                  name="Users"
                  display={isTeamView ? '' : 'none'}
                  options={filterMeta.users.filters}
                  activeFilterCount={filterMeta.users.activeCount}
                  onFilterClick={filterMeta.users.update}
                  searchable
                />
              </FilterMenuStack>
              <InputGroup
                display="none"
                maxWidth={['auto', '280px']}
                size="md"
                ml="auto"
              >
                <Input
                  name="q"
                  placeholder="Search for a user or resource... "
                  onChange={(e) => {
                    filterMeta.setSearchValue(e.currentTarget.value);
                  }}
                />
                <InputRightElement overflow="hidden" borderRightRadius="md">
                  <IconButton
                    aria-label="Search"
                    icon={<Search2Icon />}
                    variant="link"
                    height="100%"
                    minWidth="auto"
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
          </TableCapContent>
        </TableCap>
      </TableCapWrapper>

      <TableWrapper>
        <TableOverflow overflowX={enableSticky ? 'visible' : 'auto'}>
          <ActivityFeedTable
            data={eventLog.data || skeletonMockData}
            isInitialLoading={eventLog.isInitialLoading}
            enableSticky={enableSticky}
            stickyTop={tableCapHeight}
            noResultsHeading={noResultsHeading}
            noResultsContent={noResultsContent}
          />
        </TableOverflow>
        {eventLog.hasData && (
          <Waypoint onEnter={onNoMoreRows} bottomOffset="-100px">
            <div>
              <TableLoadingRows
                isLoading={eventLog.loading}
                onLoadMore={eventLog.loadMore}
              />
            </div>
          </Waypoint>
        )}
      </TableWrapper>

      {eventLog.hasData && <BackToTopFAB />}
    </>
  );
};

export default observer(ActivityFeed);
