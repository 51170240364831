import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ExportHome from './ExportHome';
import routes from './routes';

const ExportRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.root} exact>
        <ExportHome />
      </Route>
    </Switch>
  );
};

export default ExportRoutes;
