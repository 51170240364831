import React from 'react';
import { Box, BoxProps, SystemStyleObject } from '@chakra-ui/react';
import bgDecoration from 'assets/svg/bg-decoration.svg';

type ChapterIntroBoxVariants = 'default' | 'bg-decoration';

const variantProps: Record<ChapterIntroBoxVariants, SystemStyleObject> = {
  'bg-decoration': {
    pb: '78px',
    bgImage: `url(${bgDecoration})`,
    bgPosition: 'right 24px bottom 24px',
    bgRepeat: 'no-repeat',
  },
  default: {},
};

interface ChapterIntroBoxProps extends BoxProps {
  variant?: ChapterIntroBoxVariants;
}

const ChapterIntroBox: React.FC<ChapterIntroBoxProps> = ({
  variant = 'default',
  ...props
}) => {
  return (
    <Box
      p={6}
      borderBottom="1px"
      borderColor="gray.50"
      sx={variantProps[variant]}
      {...props}
    />
  );
};

export default ChapterIntroBox;
