import { AxiosInstance } from 'axios';
import authController from 'auth/AuthenticationController';
import { dashboardService } from 'backend/services';
import { GetLogEventResponse } from 'backend/api-types/dashboard';
import AsyncData from '../cache/AsyncData';
import useAsyncData from '../cache/useAsyncData';
import { convertEventToLogItem, EventLogDetails } from './types';

export default class EventController extends AsyncData<
  GetLogEventResponse,
  void,
  EventLogDetails
> {
  protected _axios: AxiosInstance = dashboardService;

  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: GetLogEventResponse): EventLogDetails {
    return convertEventToLogItem(data);
  }

  constructor(id: number) {
    super(`event-details:${id}`, `/log/${id}`);
  }
}

export function useEventDetails(id: number): EventController {
  return useAsyncData<EventController>(
    new EventController(id),
    !authController.data?.sub
  );
}
