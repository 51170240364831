import { useState, useEffect } from 'react';
import { STATUS, CallBackProps } from 'react-joyride';
import useLocalStorageState from 'use-local-storage-state';
import authController from 'auth/AuthenticationController';

export default function useTour(key: string) {
  const [run, setRun] = useState(false);
  const [isFinished, setFinished] = useLocalStorageState(
    `tourFinished.${key}:${authController.data?.sub}`,
    false
  );

  const handleCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      setFinished(true);
    }
  };

  useEffect(() => {
    if (!isFinished) {
      setRun(true);
    }
  }, [isFinished, setRun]);

  return {
    isFinished,
    run,
    handleCallback,
  };
}
