import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import StackedSelect, { StackedSelectProps } from './StackedSelect';

interface StackedSelectFieldProps extends StackedSelectProps {
  initialFocusRef?: React.RefObject<HTMLSelectElement>;
}

const StackedSelectField: React.FC<StackedSelectFieldProps> = ({
  name,
  initialFocusRef = null,
  ...props
}) => {
  const { errors, register, control } = useFormContext();
  const fieldError = name && errors[name];

  const commonProps: Partial<StackedSelectProps> = {
    ...props,
    name,
    isInvalid: !!fieldError,
    errorText: fieldError?.message,
  };

  if (initialFocusRef) {
    return (
      <Controller
        name={name ?? ''}
        control={control}
        as={<StackedSelect {...commonProps} ref={initialFocusRef} />}
      />
    );
  }

  return <StackedSelect {...commonProps} ref={register} />;
};

export default StackedSelectField;
