import React from 'react';
import { TooltipProps } from 'recharts';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import { format } from 'date-fns';
import LevelIndicatorIcon from 'event-log/components/LevelIndicatorIcon';
import { EventThreatLevel } from 'event-log/list/useEventLog';
import { ChartPoint } from '../../hooks/useThreatLevelChart';
import { ThreatLevelFlags } from './ThreatLevelChart';

interface TooltipThreatLevelProps {
  threat: EventThreatLevel;
  label: string;
  count: number;
}

const TooltipThreatLevel: React.FC<TooltipThreatLevelProps> = ({
  threat,
  count,
  label,
}) => {
  return (
    <Flex justify="space-between" alignItems="center" minWidth={48}>
      <Flex alignItems="center" mr={4}>
        <LevelIndicatorIcon threat={threat} mr={2} />
        {label}
      </Flex>
      <Text
        as="span"
        textAlign="right"
        sx={{ fontVariantNumeric: 'tabular-nums' }}
      >
        {count.toLocaleString()}
      </Text>
    </Flex>
  );
};

type ThreatLevelChartTooltipProps = TooltipProps & ThreatLevelFlags;

const ThreatLevelChartTooltip: React.FC<ThreatLevelChartTooltipProps> = ({
  showBenign = true,
  showInfo = true,
  showWarn = true,
  showAlert = true,
  active,
  payload,
}) => {
  if (active && payload) {
    const record: ChartPoint = payload[0].payload;
    const recordDate = new Date(record.date);
    return (
      <Box
        bg="whiteAlpha.800"
        border="1px"
        borderColor="blue.500"
        borderRadius="md"
        p={3}
      >
        <Text fontWeight="bold" mb={2}>
          Alerts for {format(recordDate, 'LLL d, YYY')}
        </Text>
        <Stack spacing={2}>
          {showBenign && (
            <TooltipThreatLevel
              threat={EventThreatLevel.Benign}
              label="Benign"
              count={record.levelBenign}
            />
          )}
          {showInfo && (
            <TooltipThreatLevel
              threat={EventThreatLevel.Info}
              label="Info"
              count={record.levelInfo}
            />
          )}
          {showWarn && (
            <TooltipThreatLevel
              threat={EventThreatLevel.Warn}
              label="Warn"
              count={record.levelWarn}
            />
          )}
          {showAlert && (
            <TooltipThreatLevel
              threat={EventThreatLevel.Alert}
              label="Alert"
              count={record.levelAlert}
            />
          )}
        </Stack>
      </Box>
    );
  }

  return null;
};

export default ThreatLevelChartTooltip;
