import React from 'react';
import {
  Alert,
  AlertProps,
  AlertTitle,
  AlertDescription,
  Box,
  Icon,
} from '@chakra-ui/react';
import { CgCheckO } from 'react-icons/cg';

interface ChapterResponseAlertProps extends AlertProps {
  title?: string;
}

/**
 * Information alert that is displayed after an API response to give the user
 * more context about the data sent in the response and how it should be used.
 */
const ChapterResponseAlert: React.FC<ChapterResponseAlertProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <Alert
      pt={4}
      pb={6}
      px={4}
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="flex-start"
      borderRadius="lg"
      status="info"
      mt={6}
      {...props}
    >
      <Icon
        as={CgCheckO}
        color="blue.500"
        boxSize={8}
        mr={{ base: 0, md: 3 }}
        mb={{ base: 2, md: 0 }}
      />
      <Box>
        <AlertTitle fontSize="xl" mr={0} mt={1} mb={2}>
          {title}
        </AlertTitle>
        <AlertDescription display="block" maxWidth="xl">
          {children}
        </AlertDescription>
      </Box>
    </Alert>
  );
};

export default ChapterResponseAlert;
