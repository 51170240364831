import { useCallback } from 'react';
import * as yup from 'yup';
import pick from 'lodash/pick';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import {
  ApplicationSummary,
  useApplications,
} from 'application/ApplicationsController';

export type UpdateApplicationFormValues = {
  name: string;
  description?: string;
};

const updateApplicationFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

export default function useUpdateApplicationForm(
  app: ApplicationSummary,
  onSuccess?: (app: ApplicationSummary) => void
): FormHandler<UpdateApplicationFormValues> {
  const applications = useApplications();
  const defaultValues: UpdateApplicationFormValues = pick(app, [
    'name',
    'description',
  ]);
  const updateApplication = useCallback(
    async (values: UpdateApplicationFormValues) => {
      const res = await applications.updateApplication(
        app.id,
        values.name,
        values.description
      );

      if (onSuccess) {
        onSuccess(res);
      }
    },
    [app, applications, onSuccess]
  );

  return useFormHandler<UpdateApplicationFormValues>(
    updateApplication,
    defaultValues,
    updateApplicationFormSchema
  );
}
