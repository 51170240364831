import { useCallback } from 'react';
import * as yup from 'yup';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { useContacts } from 'contacts/ContactsController';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { usePlanUsage } from 'settings/account/plan/PlanUsageController';
import { UserNotificationStatus, UserRole, UserSummary } from 'auth/user/types';

export type CreateContactFormValues = {
  firstName: string;
  lastName: string;
  title: string;
  role: UserRole;
  notificationsStatus: UserNotificationStatus;
  email: string;
  phone: '';
};

const createContactFormSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Required')
    .email('Invalid email address'),
  phone: yup
    .string()
    .trim()
    .matches(
      /^(?:\+?(\d{1,3}))?[-.\s(]*(\d{3})[-.\s)]*(\d{3})[-.\s]*(\d{4})(?:\s*x(\d+))?$|^$/,
      'Must be a valid phone number'
    ),
});

const defaultValues: CreateContactFormValues = {
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  role: UserRole.User,
  notificationsStatus: UserNotificationStatus.WarningsAndAlerts,
  phone: '',
};

export default function useCreateContactForm(
  onSuccess?: (contact: UserSummary) => void
): FormHandler<CreateContactFormValues> {
  const contacts = useContacts();
  const currentBusiness = useCurrentBusiness();
  const planUsage = usePlanUsage();
  const createContact = useCallback(
    async (values: CreateContactFormValues) => {
      const contact = await contacts.addContact(
        values,
        planUsage.isLimitReached
      );

      if (onSuccess) {
        await currentBusiness.execute();
        await planUsage.execute();
        onSuccess(contact);
      }
    },
    [contacts, currentBusiness, planUsage, onSuccess]
  );

  return useFormHandler<CreateContactFormValues>(
    createContact,
    defaultValues,
    createContactFormSchema
  );
}
