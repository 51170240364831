import { useToast } from '@chakra-ui/react';
import {
  useConfirmDialog,
  useConfirmDialogAction,
  useConfirmDialogDisclosure,
} from 'shared/hooks/confirm-dialog';
import { formatErrorResponse } from 'shared/utils/errors';
import { CommunicationSummary } from '../types';
import { useRevokeAccess } from '../CommunicationController';

export default function useRevokeAccessDialog(
  keyPacketId: number,
  onFinish: () => void
) {
  const toast = useToast();
  const disclosure = useConfirmDialogDisclosure<string, CommunicationSummary>();
  const action = useConfirmDialogAction(
    useRevokeAccess(
      Number(keyPacketId),
      () => {
        toast({
          title: 'Access Revoked',
          description: 'User access was successfully revoked.',
          status: 'success',
          position: 'bottom-right',
        });
        disclosure.onClose();
        onFinish();
      },
      (err) => {
        toast({
          title: 'Revoke Access Failed',
          description: formatErrorResponse(err).message,
          status: 'error',
          position: 'bottom-right',
        });
        disclosure.onClose();
        onFinish();
      }
    )
  );

  return useConfirmDialog<string, CommunicationSummary>(disclosure, action);
}
