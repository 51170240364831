import { Grid } from '@chakra-ui/react';
import * as React from 'react';
import { plans } from 'settings/account/plan/constants';
import { PageContainer } from 'shared/components/containers';
import Plan from './Plan';
import PlanForm from './PlanForm';

const PlanList: React.FC = () => {
  return (
    <PageContainer>
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
        gap={8}
        my={8}
      >
        {plans.map((p) => {
          return (
            <PlanForm key={p.plan} plan={p.plan}>
              <Plan {...p} />
            </PlanForm>
          );
        })}
      </Grid>
    </PageContainer>
  );
};

export default PlanList;
