import React from 'react';
import { Box, Heading, Stack, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import { PageContainer } from 'shared/components/containers';

const EncryptionHome: React.FC = () => {
  return (
    <PageContainer display="flex" flexDirection="column" flex={1}>
      <Box pt={20}>
        <Heading
          as="h1"
          size="lg"
          mb={8}
          textAlign={{ base: 'center', md: 'left' }}
        >
          What type of communication do you want to encrypt?
        </Heading>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          maxWidth={{ base: 'xs', md: 'auto' }}
          mx={{ base: 'auto', md: 0 }}
          spacing={6}
        >
          <Button
            as={RouterLink}
            to={routes.encryption.encryptMessage}
            minWidth={60}
          >
            Messages
          </Button>
          <Button
            as={RouterLink}
            to={routes.encryption.encryptFile}
            minWidth={60}
          >
            Files
          </Button>
        </Stack>
      </Box>
    </PageContainer>
  );
};

export default EncryptionHome;
