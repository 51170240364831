/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { useCallback } from 'react';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { unzipContent } from './shared/utils/unzip';

function continueKeyUpload(named: string, newFileData: any[]) {
  let keyPayloads: any[] = [];

  try {
    for (const item of newFileData) {
      if (item[1].endsWith('.csv')) {
        const parts = item[2].split(/[\n]+/g);
        for (const elem of parts) {
          const kv = elem.split(/,/g);
          if (kv.length === 2) {
            keyPayloads.push({ key: kv[1], tok: kv[0] });
          }
        }
      }

      if (item[1].endsWith('.json')) {
        const js = JSON.parse(item[2]);
        keyPayloads = keyPayloads.concat(js);
      }
    }
    return keyPayloads;
  } catch (e) {
    throw new Error(e.message);
  }
}

export function useImportKeys(onSuccess: (contacts: any[]) => void) {
  const action = useCallback(
    async (file: File) => {
      return new Promise<string[][]>((resolve, reject) => {
        const reader = new FileReader();

        const named = file.name.toLowerCase();
        if (
          !named.endsWith('.csv') &&
          !named.endsWith('.json') &&
          !named.endsWith('.zip')
        ) {
          throw new Error(
            'This file is not a CSV or JSON or ZIP as the extension is not .csv or .json or .zip'
          );
        }

        reader.onload = (completionEvent) => {
          if (!completionEvent.target?.result) {
            throw new Error(
              'This file is not a CSV or JSON or ZIP as the extension is not .csv or .json or .zip'
            );
          } else {
            let rowCount = 0;
            let parseData: any[] | undefined = [];
            const data = completionEvent.target.result;
            const contentString = new TextDecoder().decode(
              new Uint8Array(data as ArrayBuffer)
            );

            if (named.endsWith('.csv')) {
              try {
                parseData = continueKeyUpload(named, [
                  ['text', named, contentString],
                ]);
              } catch (e) {
                reject(new Error(e));
              }
            } else if (named.endsWith('.json')) {
              try {
                parseData = continueKeyUpload(named, [
                  ['text', named, contentString],
                ]);
              } catch (e) {
                reject(new Error(e));
              }
            } else if (named.endsWith('.zip')) {
              unzipContent(data, ['.json', '.csv']).then((x) => {
                parseData = continueKeyUpload(named, x);

                if (parseData !== undefined) rowCount = parseData.length;

                if (parseData === undefined || rowCount === 0) {
                  reject(
                    new Error('Failed to read any valid rows from your file')
                  );
                }

                if (parseData !== undefined) {
                  onSuccess(parseData);
                  resolve(parseData);
                }
              });
            }
            // sync results block
            if (!named.endsWith('.zip')) {
              if (parseData !== undefined) rowCount = parseData.length;

              if (parseData === undefined || rowCount === 0) {
                reject(
                  new Error('Failed to read any valid rows from your file2')
                );
              }

              if (parseData !== undefined) onSuccess(parseData);

              resolve(parseData as string[][]);
            }
          }
        };

        reader.readAsArrayBuffer(file);
      });
    },
    [onSuccess]
  );
  return useAsyncAction(action);
}
