import React from 'react';
import {
  Box,
  Modal,
  ModalProps,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  Button,
  Stack,
  Icon,
  Link,
} from '@chakra-ui/react';
import { HiPlusCircle } from 'react-icons/hi';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { useListBusinesses } from 'team/ListBusinessesController';
import useSwitchTeam from '../Header/SwitchTeamMenu/useSwitchTeam';

interface SwitchTeamModalProps extends Omit<ModalProps, 'children'> {
  onCloseNavigation: () => void;
}

const SwitchTeamModal: React.FC<SwitchTeamModalProps> = ({
  onClose,
  onCloseNavigation,
  ...props
}) => {
  const business = useCurrentBusiness();
  const listBusinesses = useListBusinesses();
  const [switchTeam] = useSwitchTeam();

  const closeModalAndNavigation = () => {
    onCloseNavigation();
    onClose();
  };

  const handleSwitchTeam = (id: number, name: string) => () => {
    switchTeam(id, name);
    closeModalAndNavigation();
  };

  return (
    <Modal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent mx={8}>
        <ModalHeader>
          Switch Team
          <ModalCloseButton
            onClick={onClose}
            position="relative"
            top={0}
            right={0}
            ml="auto"
          />
        </ModalHeader>
        <ModalBody>
          <Stack spacing={4} mb={6}>
            {listBusinesses.data?.map((b) => (
              <Box key={b.id}>
                <Button
                  variant="link"
                  colorScheme={business.data?.id === b.id ? 'blue' : 'gray'}
                  borderRadius={0}
                  textTransform="initial"
                  minWidth="auto"
                  onClick={handleSwitchTeam(b.id, b.name)}
                >
                  {b.name}
                </Button>
              </Box>
            ))}
          </Stack>
          <Box>
            <Link
              as={RouterLink}
              to={routes.team.create}
              fontWeight="semibold"
              color="blue.500"
              textDecoration="none"
              onClick={closeModalAndNavigation}
            >
              <Icon as={HiPlusCircle} color="blue.500" boxSize={5} mr={1} />
              Create a new team
            </Link>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SwitchTeamModal;
