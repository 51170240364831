import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import Headers from './Headers';
import Response from './Response';
import Body from './Body';
import { TryItMethod, TryItOperation, TryItResult } from './TryItOperation';

type GetBodyProps = {
  hasBody: boolean;
  initialBody?: string;
  onStateChange?: (body: string) => void;
  onError?: (message?: string) => void;
};

export type ChildrenRenderProps = {
  headers?: Record<string, string>;
  getBodyProps: () => GetBodyProps;
  body?: string;
  bodyError?: string;
  statusCode?: number;
  statusText?: string;
  execute: () => Promise<void>;
  loading?: boolean;
  response?: string;
};

export interface TryItProps {
  /** URL for the request */
  url: string;
  /** HTTP Method */
  method: TryItMethod;
  /** Object containing the headers to send in the request */
  headers?: Record<string, string>;
  /** Raw text for the initial body to send */
  initialBody?: string;
  /** Callback that is triggered on 200 responses */
  onSuccess?: (result: TryItResult) => void;
  /** Callback that is triggered on 400 and 500 responses */
  onError?: (result: TryItResult) => void;
  children: ((renderProps: ChildrenRenderProps) => React.ReactElement) | null;
}

const TryIt: React.FC<TryItProps> = ({
  url,
  method,
  headers,
  initialBody,
  onSuccess,
  onError,
  children,
}) => {
  const [bodyError, setBodyError] = useState<string | undefined>();
  const hasBody = method === 'post';
  const opRef = useRef(new TryItOperation(url, method));

  opRef.current.url = url;
  opRef.current.headers = headers;
  opRef.current.method = method;
  opRef.current.onSuccess = onSuccess;
  opRef.current.onError = onError;

  // Only set the body on load
  useEffect(() => {
    opRef.current.body = initialBody;
  }, [initialBody, opRef]);

  // Update the body after it has changed (blur)
  const handleStateChange = useCallback(
    (body: string) => {
      opRef.current.body = body;
    },
    [opRef]
  );

  const {
    loading,
    execute,
    body,
    statusCode,
    statusText,
    response,
  } = opRef.current;

  const renderProps: ChildrenRenderProps = {
    headers,
    getBodyProps: () => ({
      hasBody,
      initialBody,
      onStateChange: handleStateChange,
      onError: setBodyError,
    }),
    body,
    bodyError,
    statusCode,
    statusText,
    execute,
    loading,
    response,
  };

  if (children) {
    return children(renderProps);
  }

  return (
    <>
      <Box>
        Url: {url} - {method}
      </Box>
      {headers && <Headers headers={headers} />}
      {hasBody && (
        <Body initialBody={initialBody} onStateChange={handleStateChange} />
      )}
      {loading && 'Loading'}
      <Box>{statusCode}</Box>
      <Button onClick={execute}>Try It</Button>
      {response && <Response response={response} />}
    </>
  );
};

export default observer(TryIt);
