import { useCallback, useEffect } from 'react';
import Crypto from 'crypto-js';
import { dashboardService } from 'backend/services';
import { GetCommunicationByTokenResponse } from 'backend/api-types/dashboard';
import { EncryptionAlgorithm } from 'encryption/encrypt/encryptServices';
import {
  CommunicationSummary,
  convertKeyPacketToCommunication,
} from 'key-packet/types';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { deconstructQueryToData, fetchEncryptionKey } from './decryptServices';

function decryptText(
  encrypted: string,
  key: string,
  algorithm: EncryptionAlgorithm
): string {
  switch (algorithm) {
    case EncryptionAlgorithm.AES:
      return Crypto.AES.decrypt(encrypted, key).toString(Crypto.enc.Utf8);
    case EncryptionAlgorithm.OTP: {
      const encoder = new TextEncoder();
      const keyBytes = encoder.encode(key);
      const encryptedBytes = encoder.encode(atob(encrypted));

      const decrypted = [];
      for (let idx = 0; idx < encryptedBytes.length; ++idx) {
        const mi = idx % keyBytes.length;
        // eslint-disable-next-line no-bitwise
        decrypted.push(encryptedBytes[idx] ^ keyBytes[mi]);
      }

      return decodeURIComponent(
        new TextDecoder('utf8').decode(new Uint8Array(decrypted))
      );
    }
    default:
      throw new Error('Unsupported encryption algorithm');
  }
}

type DecryptMessageReturn = {
  message: string;
  communication: CommunicationSummary;
};

export default function useDecryptMessage() {
  const [decrypt, loading, meta] = useAsyncAction<DecryptMessageReturn, []>(
    useCallback(async () => {
      const params = new URLSearchParams(window.location.search);
      const data = atob(params.get('b') ?? '');

      let locator: string;
      let encrypted: string;
      try {
        const deconstructed = deconstructQueryToData(data);
        locator = deconstructed.locator;
        encrypted = deconstructed.encrypted;
      } catch {
        throw new Error(
          "We can't decrypt this message because the data is malformed. Make sure you have the right link."
        );
      }

      const { key, algorithm } = await fetchEncryptionKey(locator);
      const communicationRequest = await dashboardService.get<GetCommunicationByTokenResponse>(
        `/communication/${window.encodeURIComponent(locator)}`
      );

      return {
        message: decryptText(encrypted, key, algorithm),
        communication: convertKeyPacketToCommunication({
          ...communicationRequest.data,
          // Convert field 'cstatus' to 'status' to avoid collision with BaseResponse 'status' field
          status: communicationRequest.data.cstatus,
        }),
      };
    }, [])
  );

  useEffect(() => {
    decrypt();
  }, [decrypt]);

  return {
    ...meta,
    loading,
  };
}
