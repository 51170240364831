import React from 'react';
import LoadingBox from 'shared/components/LoadingBox';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import useStats from 'monitoring/hooks/useStats';
import Dashboard from './Dashboard';
import Onboarding from './Onboarding';
import DashboardAccessControls from './DashboardAccessControls';

const Home: React.FC = () => {
  const currentUser = useCurrentUser();
  const stats = useStats();

  if (currentUser.loading || stats.loading) {
    return <LoadingBox text="Fetching Activity" />;
  }

  const dashboardAccess = new DashboardAccessControls(stats.data);

  if (!dashboardAccess.canViewActivityFeed) {
    return <Onboarding />;
  }

  return <Dashboard />;
};

export default Home;
