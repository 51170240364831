
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.755,"time":105300,"words":351}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "https://xqmessage.com/api/client/img/api_architecture.svg",
        "alt": null
      }}></img></p>
    <h1 {...{
      "id": "quick-start-guide"
    }}>{`Quick Start Guide`}</h1>
    <h2 {...{
      "id": "slow-your-roll"
    }}>{`Slow Your Roll`}</h2>
    <h3 {...{
      "id": "dashboard"
    }}>{`Dashboard`}</h3>
    <p>{`If you haven’t signed up for a dashboard account please do so. The dashboard is where your teams endpoint activity such as sending encrypted messages is managed and tracked. The dashboard provides you with:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Real time activity monitoring`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Secure customer / company network provisioning`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Verified Endpoint Tracking`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Secure Customer / Company Network Provisioning`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Own Your Data: Export your keys for data control`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Easy Audit Compliance: Unlock an search your encrypted data`}</strong></p>
      </li>
    </ul>
    <h3 {...{
      "id": "generate-an-application-key"
    }}>{`Generate an Application Key`}</h3>
    <p>{`You can generate a new API key from the developer section of you dashboard account.`}</p>
    <p>{`Your key will be bound to your app, and data from your API use will appear in your dashboard. You may access the API through RESTful calls as exampled in the SDKs XQ has provided. The encryption activity associated with your API key will be shown in the Monitor section of your Dashboard`}</p>
    <h2 {...{
      "id": "workflows"
    }}>{`Workflows`}</h2>
    <p>{`Let’s get into the swing of things. Now we’ll walk you through a basic encryption and decryption workflows using the API. What you’ll catch on to here is that we decouple data and encryption.`}</p>
    <h3 {...{
      "id": "encryption-api-tutorial"
    }}>{`Encryption API Tutorial`}</h3>
    <p><a parentName="p" {...{
        "href": "/docs/tutorials/encrypt"
      }}>{`Start the tutorial`}</a></p>
    <h3 {...{
      "id": "decryption-api-tutorial"
    }}>{`Decryption API Tutorial`}</h3>
    <p><a parentName="p" {...{
        "href": "/docs/tutorials/decrypt"
      }}>{`Start the tutorial`}</a></p>
    <h2 {...{
      "id": "encrypt-your-data-locally"
    }}>{`Encrypt your Data Locally`}</h2>
    <p>{`You are responsible for encrypting your data locally using the key. Our SDKs provide some models for how to do this.`}</p>
    <p><strong parentName="p">{`IMPORTANT:`}</strong>{` XQ APIs do not encrypt your data. We manage the keys and policies around that encryption.`}</p>
    <p>{`After you encrypt your data on the application side send it or store it however you like.`}</p>
    <p>{`If you want to encrypt data in your app, use an appropriate cryptographic library to do so after seeding it with quantum entropy.There are a multitude of libraries that can be used which are a Google search away.`}</p>
    <h3 {...{
      "id": "available-resources"
    }}>{`Available Resources`}</h3>
    <p>{`  `}<strong parentName="p"><em parentName="strong">{`Javascript`}</em></strong>{`: `}<a parentName="p" {...{
        "href": "http://code.google.com/p/crypto-js/"
      }}>{`CryptoJS`}</a>{`, `}<a parentName="p" {...{
        "href": "http://bitwiseshiftleft.github.io/sjcl/"
      }}>{`JCSL`}</a></p>
    <p><strong parentName="p"><em parentName="strong">{`Swift`}</em></strong>{`: `}<a parentName="p" {...{
        "href": "https://cryptoswift.io/"
      }}>{`CryptoSwift`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/RNCryptor/RNCryptor"
      }}>{`RNCryptor`}</a></p>
    <p><strong parentName="p"><em parentName="strong">{`Java,Scala`}</em></strong>{`: `}<a parentName="p" {...{
        "href": "https://docs.oracle.com/javase//1.5.0/docs/guide/security/jce/JCERefGuide.html"
      }}>{`JCE`}</a>{`, `}<a parentName="p" {...{
        "href": "https://nacl.cr.yp.to/"
      }}>{`NaCl`}</a>{`, `}<a parentName="p" {...{
        "href": "http://commons.apache.org/proper/commons-crypto/"
      }}>{`Commons Crypto`}</a></p>
    <p><strong parentName="p"><em parentName="strong">{`C++`}</em></strong>{`: `}<a parentName="p" {...{
        "href": "https://nacl.cr.yp.to/"
      }}>{`NaCl`}</a>{`, `}<a parentName="p" {...{
        "href": "https://botan.randombit.net/"
      }}>{`Botan`}</a></p>
    <p><strong parentName="p"><em parentName="strong">{`PHP`}</em></strong>{`: `}<a parentName="p" {...{
        "href": "https://www.openssl.org/"
      }}>{`OpenSSL`}</a></p>
    <p><strong parentName="p"><em parentName="strong">{`Android`}</em></strong>{`: `}<a parentName="p" {...{
        "href": "https://doc.libsodium.org/"
      }}>{`Libsodium (lazysodium)`}</a></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "slow-your-roll",
    level: 2,
    title: "Slow Your Roll",
    children: [
        {
              id: "dashboard",
              level: 3,
              title: "Dashboard",
              children: [
                      
                    ]
            },
      {
              id: "generate-an-application-key",
              level: 3,
              title: "Generate an Application Key",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "workflows",
    level: 2,
    title: "Workflows",
    children: [
        {
              id: "encryption-api-tutorial",
              level: 3,
              title: "Encryption API Tutorial",
              children: [
                      
                    ]
            },
      {
              id: "decryption-api-tutorial",
              level: 3,
              title: "Decryption API Tutorial",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "encrypt-your-data-locally",
    level: 2,
    title: "Encrypt your Data Locally",
    children: [
        {
              id: "available-resources",
              level: 3,
              title: "Available Resources",
              children: [
                      
                    ]
            }
      ]
  }
]

export const frontMatter = {
  "title": "Quick Start Guide",
  "description": "Getting started with XQ",
  "route": "/docs/quick-start",
  "disableTableOfContents": false
}

