import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { dashboardService } from 'backend/services';
import { useContacts } from 'contacts/ContactsController';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { dateRangePresetsMap } from 'event-log/components/DatePicker/constants';
import React, { useState } from 'react';
import { PageContainer } from 'shared/components/containers';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { RolesCodes } from 'shared/constants/contacts';
import useDownloadFile from 'shared/hooks/useDownloadFIle';
import { Range } from 'react-date-range';
import { AxiosResponse } from 'axios';
import DatePicker from './components/DatePicker/DatePicker';
import { UserRole, userRoleToNumberMap } from '../auth/user/types';
import { useCurrentUser } from '../auth/user/CurrentUserController';

const ExportHome: React.FC = () => {
  const last7 = dateRangePresetsMap.last7Days;
  const roles = RolesCodes;
  const contacts = useContacts();
  const [startDate, setStartDate] = useState(last7.dateRange.fromDate);
  const [endDate, setEndDate] = useState(last7.dateRange.toDate);
  const [role, setRole] = useState<UserRole | null>(null);
  const [contact, setContact] = useState('');
  const [fileFormat, setFileFormat] = useState('json');
  const [days, setDays] = useState(8);
  const [errorMessage, setErrorMessage] = useState(new Error());
  const toast = useToast();
  const user = useCurrentUser();

  const downloadFile = useDownloadFile();

  const exportKeys = async () => {
    const end =
      startDate.valueOf() === endDate.valueOf()
        ? addDays(startDate, 1)
        : endDate;
    let query = `/keys?from=${startDate.valueOf()}&to=${end.valueOf()}&format=${fileFormat}`;
    if (contact !== '') query += `&user=${contact}`;
    if (role !== null) query += `&role=${userRoleToNumberMap[role]}`;

    let blob: Blob | null = null;

    const dataNotFound = () => {
      toast({
        title: 'Export Failed',
        description:
          'No keys were found, please adjust the date range or search filters.',
        status: 'error',
        position: 'bottom-right',
      });
    };

    let keyRequest: AxiosResponse | null = null;
    try {
      keyRequest = await dashboardService.get<string>(query);
    } catch (err) {
      dataNotFound();
      return;
    }

    let blobType: string;
    let doNotDownload = false;
    if (fileFormat === 'json' || fileFormat === '') {
      if (keyRequest.data.length === 0) {
        dataNotFound();
        doNotDownload = true;
      }
      blobType = 'text/json;charset=utf-8;';
      blob = new Blob([JSON.stringify(keyRequest.data)], { type: blobType });
    } else if (keyRequest.data.trim() === '') {
      dataNotFound();
      doNotDownload = true;
    } else {
      blobType = 'text/csv;charset=utf-8;';
      blob = new Blob([keyRequest.data], { type: blobType });
    }
    if (!doNotDownload) {
      downloadFile(blob as Blob, 'xq-exported.' + fileFormat);

      toast({
        title: 'Export Complete',
        description:
          'The exported keys will be downloaded to your current device.',
        status: 'success',
        position: 'bottom-right',
      });
    }
  };
  const onChangeDates = (
    dates: Range | { selection: Range } | Date | { range1: Range }
  ) => {
    if ('range1' in dates) {
      const sel = dates.range1;
      const start: Date = sel.startDate ?? new Date();
      const end: Date = sel.endDate ?? start;
      setStartDate(start);
      setEndDate(end);
      const difference = differenceInCalendarDays(end, start);
      setDays(difference + 1);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setErrorMessage(new Error(''));
    const { value, name } = e.target;
    if (name === 'role') {
      if (value !== '') {
        setRole(value as UserRole);
      } else {
        setRole(null);
      }
    } else if (name === 'contact') {
      setContact(value);
    } else {
      setFileFormat(value);
    }
  };
  return (
    <PageContainer display="flex" flexDirection="column" flex={1}>
      <Box maxWidth="6xl" width="100%" mx="auto" my={8}>
        <Heading as="h1" size="lg" mb={1}>
          Export Message Keys
        </Heading>
        <Heading as="p" size="md" fontWeight="normal">
          Export message encryption keys belonging to members of your team.
          These can be used to restore messages, even after keys are no longer
          available online.
        </Heading>
      </Box>
      {errorMessage.message !== '' && <FormErrorAlert error={errorMessage} />}
      <Stack spacing={6} mt={8} mb={12}>
        <FormControl maxWidth="lg">
          <FormLabel htmlFor="startdate">Date Range</FormLabel>
          <DatePicker
            onChange={onChangeDates}
            range={{ startDate, endDate }}
            key="selection"
          />
          <FormHelperText>
            {Number.isNaN(days) || days === 1 ? <>1 day</> : <>{days} days</>}{' '}
            selected.
          </FormHelperText>

          {user.access.canViewContacts && (
            <>
              <FormLabel htmlFor="enddate" mt={4}>
                User Role
              </FormLabel>
              <Select name="role" onChange={handleChange} defaultValue="">
                <option value="" selected>
                  All Roles
                </option>
                {roles.map(([label, value]) => (
                  <option value={value} label={label} key={value} />
                ))}
              </Select>
              <FormHelperText>
                Restrict export to one role (Optional).
              </FormHelperText>

              <FormLabel htmlFor="enddate" mt={4}>
                Contact
              </FormLabel>
              <Select
                mb={0}
                name="contact"
                onChange={handleChange}
                defaultValue={contact}
              >
                <option value="" selected>
                  All Contacts
                </option>
                {contacts.data &&
                  contacts.data.map((c) => (
                    <option value={c.email} label={c.email} key={c.id} />
                  ))}
              </Select>
              <FormHelperText>
                Restrict export to one team member (Optional).
              </FormHelperText>
            </>
          )}

          <FormLabel htmlFor="enddate" mt={4}>
            Export Format
          </FormLabel>
          <Select
            mb={0}
            name="fileFormat"
            onChange={handleChange}
            defaultValue={fileFormat}
          >
            <option key="json" value="json" selected>
              JSON
            </option>
            <option key="csv" value="csv">
              CSV
            </option>
          </Select>
          <FormHelperText>Choose the export file format.</FormHelperText>

          <Button
            type="submit"
            minWidth={32}
            ml="auto"
            mt={4}
            onClick={exportKeys}
          >
            Export Keys
          </Button>
        </FormControl>
      </Stack>
    </PageContainer>
  );
};

export default ExportHome;
