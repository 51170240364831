import React, { useContext } from 'react';
import { Box } from '@chakra-ui/react';
import {
  ContentItems,
  OptionsProvider,
  StoreProvider,
  ThemeProvider,
  AppStore,
} from 'redoc';

type ReferencesProps = {
  Context: React.Context<AppStore | undefined>;
};

export const References: React.FC<ReferencesProps> = ({ Context }) => {
  const store = useContext(Context);
  if (!store) {
    return null;
  }

  return (
    <ThemeProvider theme={store.options.theme}>
      <StoreProvider value={store}>
        <OptionsProvider value={store.options}>
          <Box width="100%" zIndex={1} position="relative">
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <ContentItems items={store.menu.items as any} />
            <Box
              backgroundColor="#001A33"
              position="absolute"
              top={73}
              right={0}
              bottom={0}
              width="40%"
              zIndex={-1}
              display={{ base: 'none', md: 'block' }}
            />
          </Box>
        </OptionsProvider>
      </StoreProvider>
    </ThemeProvider>
  );
};
