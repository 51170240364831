import { useToast } from '@chakra-ui/react';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { formatErrorResponse } from 'shared/utils/errors';
import { useCommunicationDetails } from './CommunicationController';

export default function useRevokeKey(id: number) {
  const communication = useCommunicationDetails(id);
  const toast = useToast();

  const action = async () => {
    try {
      if (!communication.data) {
        throw new Error('Communication is not yet loaded');
      }

      await communication.revoke();
      toast({
        title: 'Access to this communication is revoked',
        description: 'This communication is now unable to be decrypted',
        status: 'success',
        position: 'bottom-right',
      });
    } catch (err) {
      toast({
        title: 'Could not revoke access',
        description: formatErrorResponse(err).message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  return useAsyncAction(action);
}
