import React from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';
import { DateRange } from 'event-log/components/DatePicker/constants';
import { describeDateRange } from 'event-log/components/DatePicker/helpers';

interface DateRangeHeadingProps extends HeadingProps {
  heading?: string;
  dateRange?: DateRange;
}
const DateRangeHeading: React.FC<DateRangeHeadingProps> = ({
  dateRange,
  heading,
  ...props
}) => {
  const dateDescriptor = dateRange
    ? describeDateRange(dateRange.fromDate, dateRange.toDate)
    : 'All Time';
  return (
    <Heading size="md" my={12} mb={4} {...props}>
      {heading} for {dateDescriptor}
    </Heading>
  );
};

export default DateRangeHeading;
