import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
import { HiChevronDown } from 'react-icons/hi';

// Omit the css prop since there is a bug in chakra that causes a type mismatch.
interface AccordionIconProps extends Omit<IconProps, 'css'> {
  isExpanded?: boolean;
}

const AccordionIcon: React.FC<AccordionIconProps> = ({
  isExpanded = false,
  ...props
}) => {
  return (
    <Icon
      as={HiChevronDown}
      boxSize={6}
      color="blue.500"
      transition="transform 0.2s ease-in-out"
      mr={2}
      transform={!isExpanded ? 'rotate(-90deg)' : undefined}
      {...props}
    />
  );
};

export default AccordionIcon;
