/* eslint-disable import/no-webpack-loader-syntax */
import * as QuickStart from '!babel-loader!mdx-loader!docs/QuickStart.mdx';
import * as Introduction from '!babel-loader!mdx-loader!docs/Introduction.mdx';
import * as Authentication from '!babel-loader!mdx-loader!docs/Authentication.mdx';
import * as Documentation from '!babel-loader!mdx-loader!docs/Documentation.mdx';
import * as APIReference from '!babel-loader!mdx-loader!docs/APIReference.mdx';
import * as WebHooks from '!babel-loader!mdx-loader!docs/WebHooks.mdx';
import * as Applications from '!babel-loader!mdx-loader!docs/Applications.mdx';
import * as Keys from '!babel-loader!mdx-loader!docs/Keys.mdx';
import * as Subscriber from '!babel-loader!mdx-loader!docs/Subscriber.mdx';
import * as ClientLibraries from '!babel-loader!mdx-loader!docs/ClientLibraries.mdx';
import * as SampleApplications from '!babel-loader!mdx-loader!docs/SampleApplications.mdx';
import * as ClientSDK from '!babel-loader!mdx-loader!docs/ClientSDK.mdx';
import * as AboutXQ from '!babel-loader!mdx-loader!docs/about/AboutXQ.mdx';
import * as ContactUS from '!babel-loader!mdx-loader!docs/about/ContactUS.mdx';
import * as TermsOfService from '!babel-loader!mdx-loader!docs/about/TermsOfService.mdx';
import * as SecurityAtXQ from '!babel-loader!mdx-loader!docs/about/SecurityAtXQ.mdx';
import * as PrivacyPolicy from '!babel-loader!mdx-loader!docs/about/PrivacyPolicy.mdx';

function routeFor(d: { frontMatter: { title?: string; route?: string } }) {
  return d.frontMatter.route || '/no-such-route';
}

export const routes = {
  root: routeFor(Documentation),
  apiReference: routeFor(APIReference),
  applications: routeFor(Applications),
  keys: routeFor(Keys),
  subscriber: routeFor(Subscriber),
  clients: routeFor(ClientLibraries),
  quickStart: routeFor(QuickStart),
  intro: routeFor(Introduction),
  auth: routeFor(Authentication),
  webHooks: routeFor(WebHooks),
  sampleApplications: routeFor(SampleApplications),
  sampleApps: routeFor(SampleApplications),
  clientSDKs: routeFor(ClientSDK),
  quantum: '/docs/api/quantum',
  subscription: '/docs/api/subscription',
  validation: '/docs/api/validation',
  about: routeFor(AboutXQ),
  security: routeFor(SecurityAtXQ),
  contact: routeFor(ContactUS),
  terms: routeFor(TermsOfService),
  privacy: routeFor(PrivacyPolicy),
} as const;

export const pages = [
  Documentation,
  QuickStart,
  Introduction,
  Applications,
  Authentication,
  WebHooks,
  SampleApplications,
  APIReference,
  ClientSDK,
  Keys,
  Subscriber,
  AboutXQ,
  SecurityAtXQ,
  ContactUS,
  TermsOfService,
  PrivacyPolicy,
];
