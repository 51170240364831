import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import authController from 'auth/AuthenticationController';

export type NavigationLinkProps = NavLinkProps & { isProtected?: boolean };

const NavigationLink: React.FC<NavigationLinkProps> = ({
  isProtected = false,
  ...props
}) => {
  if (isProtected && !authController.isLoggedIn) {
    return null;
  }

  return <NavLink activeClassName="active" {...props} />;
};

export default observer(NavigationLink);
