import React from 'react';
import {
  LineChart,
  Line,
  LineProps,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  ResponsiveContainer,
} from 'recharts';
import {
  Box,
  Flex,
  Spinner,
  Heading,
  useTheme,
  useBreakpointValue,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { format } from 'date-fns';
import { CustomTheme } from 'theme/customChakraTheme';
import NoResultsBox from 'shared/components/NoResultsBox';
import { getThreatLevel } from 'event-log/components/LevelIndicatorIcon';
import { EventThreatLevel } from 'event-log/list/useEventLog';
import { ChartPoint } from '../../hooks/useThreatLevelChart';
import ThreatLevelChartTooltip from './ThreatLevelChartTooltip';

export type ThreatLevelFlags = {
  showBenign?: boolean;
  showInfo?: boolean;
  showWarn?: boolean;
  showAlert?: boolean;
};

interface RenderThreatLineProps extends LineProps {
  lineColor?: string;
}

/**
 * Recharts does not support rendering wrapped components so it must be returned
 * from a function.
 */
const renderThreatLine = ({
  lineColor = 'black',
  ...props
}: RenderThreatLineProps) => {
  return (
    <Line
      type="monotone"
      animationDuration={1500}
      dot={{
        stroke: lineColor,
        strokeWidth: 2,
        fill: lineColor,
        r: 2,
      }}
      activeDot={{
        stroke: lineColor,
        strokeWidth: 2,
        fill: lineColor,
        r: 3,
      }}
      strokeWidth={2}
      stroke={lineColor}
      {...props}
    />
  );
};

/**
 * Calculate the start and end brush index values for the brush to be limited to
 * an initial size and positioned at the end of the track.
 * @param data Chart data.
 * @param brushWidth Number of chart ticks to limit the brush range in its initial state.
 */
function getBrushIndexes(data: ChartPoint[] | null, brushWidth = 30) {
  let startIndex = 0;
  let endIndex = 0;

  if (Array.isArray(data)) {
    if (data.length > brushWidth) {
      startIndex = data.length - brushWidth;
    }

    endIndex = data.length > 0 ? data.length - 1 : 0;
  }

  return [startIndex, endIndex];
}

export interface ThreatLevelChartProps extends ThreatLevelFlags {
  data: ChartPoint[] | null;
  loading: boolean;
  aspect?: number;
}

const ThreatLevelChart: React.FC<ThreatLevelChartProps> = ({
  data,
  loading,
  aspect = 3 / 1,
  showBenign = true,
  showInfo = true,
  showWarn = true,
  showAlert = true,
}) => {
  const theme = useTheme() as CustomTheme;
  const chartMarginLeft = useBreakpointValue({ base: -30, lg: 0 });
  const chartMarginRight = useBreakpointValue({ base: 0, lg: 60 });
  const responsiveAspectRatio = useBreakpointValue({ base: 1.25, lg: aspect });
  const responsiveInterval = useBreakpointValue({ base: 7, lg: 1 });
  const noData = !data || (Array.isArray(data) && !data.length);
  const showChart = !loading && !noData;
  const showBrush = Array.isArray(data) && data.length > 30;
  const [brushStartIndex, brushEndIndex] = getBrushIndexes(data);

  const getLineColor = (threat: EventThreatLevel) => {
    const threatLevel = getThreatLevel(threat);
    return get(theme.colors, threatLevel.bg);
  };

  const renderedLineChart = showChart && (
    <LineChart
      data={data || undefined}
      margin={{ bottom: 20, right: chartMarginRight, left: chartMarginLeft }}
    >
      <CartesianGrid strokeDasharray="1 3" />
      <XAxis
        dataKey="date"
        axisLine={{
          stroke: theme.colors.gray['500'],
          strokeWidth: 1,
        }}
        tickLine={{
          stroke: theme.colors.gray['500'],
        }}
        tick={{
          fontSize: 13,
          color: 'black',
        }}
        interval={responsiveInterval}
        tickFormatter={(value: string) => {
          const tickDate = new Date(value);
          return format(tickDate, 'LLL d');
        }}
      />
      <YAxis
        axisLine={{
          stroke: theme.colors.gray['500'],
          strokeWidth: 1,
        }}
        tickLine={{
          stroke: theme.colors.gray['500'],
        }}
        tick={{
          fontSize: 13,
          color: 'black',
        }}
        tickFormatter={(value: number) => value.toLocaleString()}
      />
      <Tooltip
        content={
          <ThreatLevelChartTooltip
            showBenign={showBenign}
            showInfo={showInfo}
            showWarn={showWarn}
            showAlert={showAlert}
          />
        }
      />
      {showBenign &&
        renderThreatLine({
          dataKey: 'levelBenign',
          lineColor: getLineColor(EventThreatLevel.Benign),
        })}
      {showInfo &&
        renderThreatLine({
          dataKey: 'levelInfo',
          lineColor: getLineColor(EventThreatLevel.Info),
        })}
      {showWarn &&
        renderThreatLine({
          dataKey: 'levelWarn',
          lineColor: getLineColor(EventThreatLevel.Warn),
        })}
      {showAlert &&
        renderThreatLine({
          dataKey: 'levelAlert',
          lineColor: getLineColor(EventThreatLevel.Alert),
        })}

      {showBrush && (
        <Brush
          dataKey="date"
          startIndex={brushStartIndex}
          endIndex={brushEndIndex}
          height={20}
          stroke={theme.colors.gray[200]}
          fill={theme.colors.white}
        />
      )}
    </LineChart>
  );

  return (
    <Box
      sx={{
        '.recharts-brush-texts': {
          display: 'none',
        },
      }}
    >
      <ResponsiveContainer aspect={responsiveAspectRatio} width="100%">
        {showChart ? (
          renderedLineChart
        ) : (
          <Box position="relative">
            <Flex
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              direction="column"
              justify="center"
              alignItems="center"
              border="1px"
              borderColor="gray.100"
              borderRadius="md"
              bg="white"
            >
              {loading ? (
                <>
                  <Spinner size="lg" color="blue.500" />
                  <Heading fontSize="2xl" mt={2}>
                    Fetching events
                  </Heading>
                </>
              ) : (
                noData && (
                  <NoResultsBox
                    width="100%"
                    height="100%"
                    heading="No events matched these filters"
                  >
                    Expand your filter criteria to find more events
                  </NoResultsBox>
                )
              )}
            </Flex>
          </Box>
        )}
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(ThreatLevelChart);
