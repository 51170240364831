import ApplicationRoutes from 'application/ApplicationRoutes';
import CreateApplicationModal from 'application/create/CreateApplicationModal';
import ProtectedRoute from 'auth/ProtectedRoute';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import ContactRoutes from 'contacts/ContactRoutes';
import CreateContactModal from 'contacts/create/CreateContactModal';
import DocumentRoutes from 'docs';
import { References } from 'docs/references/References';
import {
  QuantumReferencesContext,
  SubscriptionReferencesContext,
  ValidationReferencesContext,
} from 'docs/references/ReferencesContext';
import EncryptionRoutes from 'encryption/EncryptionRoutes';
import EventLogRoutes from 'event-log/EventLogRoutes';
import ExportRoutes from 'export/ExportRoutes';
import KeyPacketRoutes from 'key-packet/KeyPacketRoutes';
import { observer } from 'mobx-react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from 'routes';
import SettingsRoutes from 'settings/SettingsRoutes';
import TeamRoutes from 'team/TeamRoutes';
import TutorialRoutes from 'tutorials/TutorialRoutes';
import UnlockRoutes from 'unlock/UnlockRoutes';
import Home from './Home';
import DashboardLayout from './layout/DashboardLayout';

const DashboardRoutes: React.FC = () => {
  const user = useCurrentUser();
  return (
    <DashboardLayout>
      <Switch>
        <ProtectedRoute path={routes.home} exact>
          <Home />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.application.root}
          canAccess={user.access.canAccessDeveloperFeatures}
        >
          <ApplicationRoutes />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.tutorials.root}
          canAccess={user.access.canAccessDeveloperFeatures}
        >
          <TutorialRoutes />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.eventLog.root}
          canAccess={user.access.canViewEventLog}
        >
          <EventLogRoutes />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.settings.root}
          canAccess={
            user.access.canViewOrganizationSettings ||
            user.access.canViewBilling
          }
        >
          <SettingsRoutes />
        </ProtectedRoute>
        <ProtectedRoute path={routes.contacts.root}>
          <ContactRoutes />
        </ProtectedRoute>
        <ProtectedRoute path={routes.keyPacket.root}>
          <KeyPacketRoutes />
        </ProtectedRoute>
        <ProtectedRoute path={routes.team.root}>
          <TeamRoutes />
        </ProtectedRoute>
        <ProtectedRoute path={routes.encryption.root}>
          <EncryptionRoutes />
        </ProtectedRoute>

        <ProtectedRoute path={routes.export.root}>
          <ExportRoutes />
        </ProtectedRoute>
        <ProtectedRoute path={routes.unlock.root}>
          <UnlockRoutes />
        </ProtectedRoute>

        <Route path={routes.docs.subscription}>
          <References Context={SubscriptionReferencesContext} />
        </Route>
        <Route path={routes.docs.validation}>
          <References Context={ValidationReferencesContext} />
        </Route>
        <Route path={routes.docs.quantum}>
          <References Context={QuantumReferencesContext} />
        </Route>

        {/* This is also the default route for the documentation */}
        <DocumentRoutes />
      </Switch>

      <CreateContactModal />
      <CreateApplicationModal />
    </DashboardLayout>
  );
};

export default observer(DashboardRoutes);
