import React from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react';
import Logo from 'shared/components/Logo';

interface CenteredLayoutProps extends FlexProps {
  showLogo?: boolean;
}

/**
 * A full viewport height flex layout that intends its content to be centered.
 * An optional logo can be rendered.
 */
export const CenteredLayout: React.FC<CenteredLayoutProps> = ({
  showLogo,
  children,
  ...props
}) => {
  const logoBoxHeight = '95px';

  return (
    <Flex direction="column" width="100%" height="100vh" {...props}>
      {showLogo && (
        <Flex
          flex="none"
          alignItems="center"
          width="100%"
          height={logoBoxHeight}
          pl={6}
          mb="auto"
        >
          <Logo withText height="35px" />
        </Flex>
      )}
      <Flex
        direction="column"
        alignItems="center"
        my="auto"
        width="100%"
        height="100%"
      >
        {children}
      </Flex>
      {showLogo && <Box flex="none" height={logoBoxHeight} mt="auto" />}
    </Flex>
  );
};

export default CenteredLayout;
