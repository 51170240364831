import React, { useContext, useMemo } from 'react';
import * as yup from 'yup';
import { Heading, SimpleGrid } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import {
  StackedInputField,
  StackedTextareaField,
} from 'shared/components/form';
import { useFormHandler } from 'shared/hooks/useFormHandler';
import EncryptMessageContext from '../EncryptMessageContext';
import { StepHeader, StepContent, StepNavigation } from '../components';

type FormValues = {
  message: string;
  subject: string;
};

const schema = yup.object().shape({
  message: yup.string().trim().required('Required'),
  subject: yup.string().trim(),
});

const Message: React.FC = () => {
  const { goNext, message, subject } = useContext(EncryptMessageContext);
  const defaultValues = useMemo(() => {
    return {
      message,
      subject,
    };
  }, [message, subject]);

  const { onSubmit, form } = useFormHandler<FormValues>(
    goNext,
    defaultValues,
    schema,
    true
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <StepHeader>
          <Heading size="sm">Enter your message</Heading>
        </StepHeader>
        <StepContent>
          <SimpleGrid spacing={4}>
            <StackedInputField name="subject" label="Subject" autoFocus />
            <StackedTextareaField
              name="message"
              label="Message"
              rows={10}
              isRequired
            />
          </SimpleGrid>
          <StepNavigation />
        </StepContent>
      </form>
    </FormProvider>
  );
};

export default Message;
