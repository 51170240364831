/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  useDimensions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import Crypto from 'crypto-js';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import BackToTopFAB from 'shared/components/BackToTopFAB';
import { PageContainer } from 'shared/components/containers';
import {
  TableCap,
  TableCapContent,
  TableCapWrapper,
  TableCell,
  TableWrapper,
} from 'shared/components/Table';
import {
  MINIMUM_STICKY_VIEWPORT_HEIGHT,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from 'shared/components/Table/Table';
import useDownloadFile from 'shared/hooks/useDownloadFIle';
import {
  HiOutlineDocument,
  HiOutlineMail,
  HiOutlineCloudDownload,
  HiOutlineChevronLeft,
} from 'react-icons/hi';
import JSZip from 'jszip';
import UnlockKeysScene from './unlock/UnlockKeysScene';
import UnlockMessagesScene from './unlock/UnlockMessagesScene';
import LevelIndicatorIcon from '../event-log/components/LevelIndicatorIcon';

/** The opening tag for our encrypted data. */
const openTag = '[XQ MSG START]';

/** The closing tag for our encrypted data. */
const closeTag = '[XQ MSG END]';

const UnlockScene: React.FC = () => {
  let tableCapHeight: string | undefined;
  const filterFieldRef = useRef<HTMLInputElement>(null);
  const requiredFieldRef = useRef<HTMLInputElement>(null);
  const tableCapRef = useRef<HTMLDivElement>(null);
  const tableCapDimensions = useDimensions(tableCapRef, true);

  // Any smaller than this and the table will need horizontal scrolling,
  // which breaks the sticky table header cells.
  const [enableSticky] = useMediaQuery(
    `(min-width: 1280px) and (min-height: ${MINIMUM_STICKY_VIEWPORT_HEIGHT}px)`
  );

  // get the height of the table cap for sticky anchor point.
  if (tableCapDimensions?.contentBox.height) {
    tableCapHeight = `${
      Math.round(tableCapDimensions?.contentBox.height) + 1
    }px`;
  }

  const history = useHistory();
  const [messageData, setMessageData] = useState<any[]>([]);
  const [filesData, setFilesData] = useState<any[]>([]);
  const [keysData, setKeysData] = useState<any[]>([]);
  const [unlocking, setUnlocking] = useState<boolean>(false);
  const [decrypted, setDecrypted] = useState<any[]>([]);
  const toast = useToast();

  const downloadFile = useDownloadFile();

  const handleRowClick = (decryptedF: any) => {
    toast({
      title: 'Download Started',
      description:
        'The decrypted communication will be downloaded to your current device.',
      status: 'success',
      position: 'bottom-right',
    });
    downloadFile(new Blob([decryptedF.data]), decryptedF.name);
  };

  function zipContent(mappedData: any[], callback: (arg: Blob) => void) {
    const zip = new JSZip();

    for (const data of mappedData) {
      zip.file(data.name, data.data);
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      callback(content);
    });
  }

  const onDownloadAllClick = (communications: any[]) => {
    zipContent(communications, (result) => {
      if (result != null) {
        const d = new Date();
        toast({
          title: 'Download Started',
          description:
            'The decrypted communications will be downloaded to your current device.',
          status: 'success',
          position: 'bottom-right',
        });
        downloadFile(result, 'recovered-emails-' + d.getTime() + '.zip');
      }
    });
  };

  const showError = (title: string, desc: string) => {
    toast({
      title,
      description: desc,
      status: 'error',
      position: 'bottom-right',
    });
  };

  function decryptOTPv2(payloadP: any, key: any) {
    const payload = atob(payloadP);

    const encoder = new TextEncoder();
    const keyBytes = encoder.encode(key);
    const payloadBytes = encoder.encode(payload);
    const j = [];

    for (let idx = 0; idx < payloadBytes.length; idx++) {
      if (idx < keyBytes.length) {
        j.push(payloadBytes[idx] ^ keyBytes[idx]); // eslint-disable-line no-bitwise
      }
    }

    return decodeURIComponent(
      new TextDecoder('utf8').decode(new Uint8Array(j))
    );
  }

  function decryptOTPv1(payload: any, key: any) {
    const array = payload.match(/.{1,2}/g);
    let data = '';

    for (let idx = 0; idx < array.length; idx++) {
      if (idx >= key.length) break;
      data += String.fromCharCode(
        parseInt('0x' + array[idx], 16) ^ key[idx].charCodeAt(0) // eslint-disable-line no-bitwise
      );
    }

    return data;
  }
  function decryptFile(item: any) {
    const filename = item.n;
    const { data } = item;

    const originalFilename = filename.slice(0, -4);
    try {
      let tail = 0;

      const view = new Uint8Array(data);

      // Read the length of the file.
      const tokenSize = Uint32Array.from(view.slice(tail, tail + 4))[0];
      tail += 4;

      const token = new TextDecoder().decode(
        view.slice(tail, tail + tokenSize)
      );
      tail += tokenSize;

      const nameSize = Uint32Array.from(view.slice(tail, tail + 4))[0];
      tail += 4;

      const nameBytes = view.slice(tail, tail + nameSize);
      tail += nameSize;

      const contentBytes = view.slice(tail);
      const contentSize = view.length - tail;

      // LOOKUP THE KEY HERE USING THE TOKEN
      const match = keysData.find((el) => el.tok === token);

      if (match == null) {
        return {
          filetype: 'file',
          name: originalFilename,
          data: null,
          errors: 1,
        };
      }

      const { key } = match;

      if (key.length < 64) {
        return {
          filetype: 'file',
          name: originalFilename,
          data: null,
          errors: 1,
        };
      }

      const k = key.startsWith('.') ? key.substring(2) : key;

      const keyData = new TextEncoder().encode(k);

      let keyIndex = 0;

      const keyLength = k.length;

      for (let x = 0; x < nameSize; ++x) {
        nameBytes[x] ^= keyData[keyIndex++]; // eslint-disable-line no-bitwise
        if (keyIndex >= keyLength) keyIndex = 0;
      }

      // const decodedName = new TextDecoder().decode(nameBytes); // leave this for future ref
      keyIndex = 0;

      for (let x = 0; x < contentSize; ++x) {
        contentBytes[x] ^= keyData[keyIndex++]; // eslint-disable-line no-bitwise
        if (keyIndex >= keyLength) keyIndex = 0;
      }

      return {
        filetype: 'file',
        name: originalFilename,
        data: contentBytes,
        errors: 0,
      };
    } catch (e) {
      return {
        filetype: 'file',
        name: originalFilename,
        data: null,
        errors: 1,
      };
    }
  }

  function unlockMessages() {
    const recovered = [];

    try {
      for (const file of messageData) {
        let continued = false;
        let body = file.data;

        const filename = file.n;
        let failures = 0;

        // 1. extract token from url
        const idx = body.indexOf('https://xqmsg.net/applink');

        if (idx !== -1) {
          const link = body;
          let b64data = '';
          const ps = link.indexOf('b=');

          if (ps !== -1) {
            const bodyParams = new URLSearchParams(link.substring(ps));
            let b = bodyParams.get('b');

            if (b != null) {
              b64data += b;
              let idx2 = 1;
              b = bodyParams.get('b' + idx2);
              while (b != null) {
                b64data += b;
                ++idx2;
                b = bodyParams.get('b' + idx2);
              }
            }
          }

          if (b64data !== '') {
            try {
              body = atob('' + b64data);
            } catch (err) {
              recovered.push({
                filetype: 'text',
                name: filename,
                data: '',
                errors: 1,
              });
              continued = true;
            }

            if (!continued && body.indexOf(openTag) === -1) {
              body = openTag + '\n' + body + '\n' + closeTag;
            }
          }
        }

        if (!continued) {
          let newBody = '';
          let index = 0;
          const messageLength = body.length;

          do {
            try {
              const blocStart = body.indexOf(openTag, index);

              if (blocStart === -1) {
                if (messageLength > index) {
                  if (newBody.length > 0) {
                    newBody += '\n' + body.substring(index, messageLength);
                  }

                  index = messageLength; // No other encrypted block was found.
                }
              } else {
                if (blocStart > index) {
                  // If there was plain data before hand, we want to show that without modification.
                  newBody += '\n' + body.substring(index, blocStart);
                }

                index = blocStart + openTag.length; // Move the pointer to the start of the encrypted block.
                const blocEnd = body.indexOf(closeTag, index); // Find out where the encryped block ends.

                if (blocEnd !== -1) {
                  let encryptedData = body.substring(index, blocEnd).trim(); // Store the encrypted data.
                  index = blocEnd + closeTag.length; // Move the index so we dont forget to do so later.

                  // Decrypt the block
                  encryptedData = encryptedData.replace(/[> \n]/g, '');
                  const token = encryptedData.slice(0, 43); // The identifier is always 43 bytes.
                  const content = encryptedData.slice(43); // The encrypted content
                  let decryptedData = '';

                  // Look for the key in our map.
                  const match = keysData.find((el) => el.tok === token);

                  if (match == null) {
                    failures += 1;
                    continued = true;
                  }

                  if (!continued) {
                    let { key } = match;

                    if (!continued && key.startsWith('.')) {
                      const scheme = key[1];
                      key = key.substring(2);

                      switch (scheme) {
                        case 'A': // AES
                          decryptedData = Crypto.AES.decrypt(
                            content,
                            key
                          ).toString(Crypto.enc.Utf8);
                          break;

                        case 'X': // Quantum XOR
                          decryptedData = decryptOTPv1(content, key);
                          break;

                        case 'B': // Quantum XOR
                          decryptedData = decryptOTPv2(content, key);
                          break;

                        default:
                          decryptedData = decryptOTPv1(content, key);
                          break;
                      }
                    } else {
                      decryptedData = Crypto.AES.decrypt(content, key).toString(
                        Crypto.enc.Utf8
                      );
                    }

                    if (decryptedData !== '') {
                      newBody += '\n' + decryptedData;
                    } else {
                      failures += 1;
                    }
                  } // no key continued
                } else {
                  failures += 1;
                }
              }
            } catch (e) {
              failures += 1;
            }
          } while (index < messageLength);

          if (newBody !== '') {
            const visibleContent = newBody;

            if (failures === 0) {
              if (file.headers !== '')
                newBody = file.headers + '\n' + visibleContent;
            }

            recovered.push({
              name: filename,
              data: newBody,
              filetype: 'text',
              errors: failures,
              visibleContent,
            });
          } else {
            recovered.push({
              name: filename,
              data: '',
              filetype: 'text',
              errors: 1,
            });
          }
        } // continued
      } //  for ( const file of messageData )
      for (const file of filesData) {
        const c = decryptFile(file);
        recovered.push(c);
      }
    } catch (e) {
      console.error(e);
    }

    const searchParts: string[] =
      filterFieldRef.current != null
        ? filterFieldRef.current.value.split(',').map((e) => e.trim())
        : [];

    const union = (body: string) => {
      if (!body.indexOf) return false;
      const count = searchParts.filter((e) => body.indexOf(e) > -1).length;
      return count > 0; // At least one of the terms were found.
    };

    const intersects = (body: string) => {
      if (!body.indexOf) return false;
      const count = searchParts.filter((e) => body.indexOf(e) > -1).length;
      return count === searchParts.length; // At least one of the terms were found.
    };

    const comparator =
      requiredFieldRef.current && requiredFieldRef.current.checked
        ? intersects
        : union; // union

    const filteredResults = recovered.filter((elem: any) => {
      const filetype = elem.filetype.toUpperCase();
      const body = elem.data || '';
      const lcbody = filetype === 'TEXT' ? (body as string).toLowerCase() : '';
      return (
        searchParts.length === 0 ||
        (filetype === 'TEXT' && comparator(lcbody)) ||
        comparator(elem.name)
      );
    });
    setDecrypted(filteredResults);
  }
  const handleMessageData = (data: any[], files: any[]): void => {
    setMessageData(data);
    setFilesData(files);
  };

  const handleKeysData = (keys: any[]): void => {
    setKeysData(keys);
  };

  return (
    <PageContainer display="flex" flexDirection="column" flex={2}>
      <Box maxWidth="6xl" width="100%" mx="auto" my={8} zIndex={5}>
        <Heading as="h1" size="lg" mb={1}>
          Unlock Archived Communications
        </Heading>
        <Heading as="p" size="md" fontWeight="normal">
          Encrypted communications that you have saved locally can be decrypted
          on this page, provided you have the encryption keys that match each
          communication.
        </Heading>
      </Box>
      {!unlocking && (
        <div>
          <Flex alignItems="center">
            <Box>
              <Text mt={4}>
                <Button
                  variant="link"
                  colorScheme="primary"
                  size="md"
                  onClick={() => history.push(routes.export.root)}
                >
                  Please visit the Export section to backup your encryption
                  keys.
                </Button>
              </Text>
            </Box>
          </Flex>

          <Stack spacing={6} mt={8} mb={12}>
            <Grid
              templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr)',
              }}
              gap={8}
              maxWidth="4xl"
            >
              <Box flex="none" pt={3} pb={12} px={0} height={360}>
                <Text fontWeight="bold" color="gray.700">
                  Message Files
                </Text>
                <Text mb={4} noOfLines={2}>
                  Select any .eml, .olm, mbox or .zip file( containing encrypted
                  .eml or .txt files ) to decrypt below:
                </Text>
                <UnlockMessagesScene
                  onData={handleMessageData}
                  onError={(theError: Error) =>
                    showError('Import Message Failed', theError.message)
                  }
                />

                <Box flex="none" pt={3} pb={12} px={0}>
                  {messageData.length + filesData.length === 0 ? (
                    <>
                      <Heading size="md" color="gray.200">
                        No File Selected
                      </Heading>
                    </>
                  ) : (
                    <>
                      <Heading size="md">Communications Imported</Heading>
                      <Text color="gray.700">
                        {messageData.length + filesData.length} communication
                        {messageData.length + filesData.length === 1
                          ? ''
                          : 's'}{' '}
                        found
                      </Text>
                    </>
                  )}
                </Box>
              </Box>
              <Box flex="none" pt={3} pb={12} px={0} height={360}>
                <Text fontWeight="bold" color="gray.700">
                  Message Encryption Keys
                </Text>
                <Text mb={4} noOfLines={2}>
                  Select a JSON, CSV or zip archive containing the encryption
                  keys below:
                </Text>
                <UnlockKeysScene
                  onData={handleKeysData}
                  onError={(theError: Error) =>
                    showError('Import Keys Failed', theError.message)
                  }
                />
                <Box flex="none" pt={3} pb={12} px={0}>
                  {keysData.length === 0 ? (
                    <>
                      <Heading size="md" color="gray.200">
                        No File Selected
                      </Heading>
                    </>
                  ) : (
                    <>
                      <Heading size="md">Keys Imported</Heading>

                      <Text color="gray.700">
                        {keysData.length} key
                        {keysData.length === 1 ? '' : 's'} found
                      </Text>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
            <Stack orientation="vertical">
              <Text fontWeight="bold">Filter Results By Keyword</Text>
              <Input
                name="filter"
                init
                placeholder="Your Comma-Delimited Keyword List... "
                size="md"
                maxWidth="lg"
                ref={filterFieldRef}
              />

              <Checkbox name="requiredFilter" ref={requiredFieldRef}>
                All keywords required.
              </Checkbox>
            </Stack>
            <Box flex="none" width="100%" maxWidth="lg" mr={8}>
              <Button
                type="submit"
                minWidth={32}
                disabled={
                  keysData.length === 0 ||
                  (filesData.length === 0 && messageData.length === 0)
                }
                colorScheme="primary"
                onClick={() => {
                  setUnlocking(true);
                  unlockMessages();
                }}
              >
                Unlock Data
              </Button>
            </Box>
          </Stack>
        </div>
      )}

      {unlocking && (
        <div>
          <Box maxWidth="6xl" width="100%" mx="auto" my={8}>
            <TableCapWrapper
              alignItems="right"
              isSticky={enableSticky}
              ref={tableCapRef}
            >
              <TableCap>
                <TableCapContent>
                  <Stack direction="column" isInline>
                    <Heading size="md" width="calc(100% - 5vw)" mb={2}>
                      <Icon
                        as={HiOutlineChevronLeft}
                        color="gray.500"
                        boxSize={5}
                        title="Download"
                        cursor="pointer"
                        mr={2}
                        mb={1}
                        onClick={() => setUnlocking(false)}
                      />
                      Recovered Files
                    </Heading>
                    <Button
                      size="sm"
                      type="submit"
                      colorScheme="gray"
                      px={5}
                      onClick={() => onDownloadAllClick(decrypted)}
                    >
                      Download All
                    </Button>
                  </Stack>
                </TableCapContent>
              </TableCap>
            </TableCapWrapper>

            <TableWrapper overflowX={enableSticky ? 'visible' : 'auto'}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      isSticky={enableSticky}
                      stickyTop={tableCapHeight}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      isSticky={enableSticky}
                      stickyTop={tableCapHeight}
                    >
                      Communication
                    </TableCell>
                    <TableCell
                      isSticky={enableSticky}
                      stickyTop={tableCapHeight}
                    >
                      {}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {decrypted.map((communication) => (
                    <TableRow key={communication.name}>
                      <TableCell width="25px">
                        <LevelIndicatorIcon
                          threat={communication.errors === 0 ? 1 : 3}
                        />
                      </TableCell>
                      <TableCell minWidth="225px">
                        <Icon
                          as={
                            communication.filetype === 'file'
                              ? HiOutlineDocument
                              : HiOutlineMail
                          }
                          color="gray.500"
                          boxSize={5}
                          title={
                            communication.filetype === 'file'
                              ? `File`
                              : `Message`
                          }
                          mr={2}
                          mb={1}
                        />
                        {communication.name}
                      </TableCell>
                      <TableCell maxWidth="75px" textAlign="right">
                        {communication.errors === 0 ? (
                          <Icon
                            as={HiOutlineCloudDownload}
                            color="gray.500"
                            boxSize={8}
                            title="Download"
                            cursor="pointer"
                            mr={2}
                            mb={1}
                            onClick={() => handleRowClick(communication)}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>

            <BackToTopFAB />
          </Box>
        </div>
      )}
    </PageContainer>
  );
};

export default UnlockScene;
