import React from 'react';
import { Button, ButtonProps, Icon, Text } from '@chakra-ui/react';
import { ReactComponent as GoogleLogo } from './assets/GoogleLogo.svg';
import './fonts.css';

const GoogleButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      variant="plain"
      size="lg"
      borderRadius="2px"
      px={4}
      leftIcon={<Icon as={GoogleLogo} width={18} height={18} mr={3} />}
      fontFamily="Roboto, sans-serif"
      fontWeight="500"
      fontSize={14}
      backgroundColor="white"
      boxShadow="rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.24) 0px 2px 2px 0px"
    >
      <Text color="rgba(0, 0, 0, 0.54)">
        {children ?? 'Sign in with Google'}
      </Text>
    </Button>
  );
};

export default GoogleButton;
