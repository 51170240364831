import React, { useCallback, useContext, useMemo } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import useTutorialChapters, {
  UseTutorialChaptersReturn,
} from '../useTutorialChapters';

type DecryptTutorialContextProps = {
  encryptionKey: string;
  accessToken: string;
  locatorToken: string;
  setEncryptionKey: (key: string) => void;
  setAccessToken: (token: string) => void;
  setLocatorToken: (token: string) => void;
  reset: () => void;
} & Pick<UseTutorialChaptersReturn, 'chapterResults' | 'storeResult'>;

const DecryptTutorialContext = React.createContext(
  {} as DecryptTutorialContextProps
);

const makeKey = (key: string) => `tutorial.decrypt.${key}`;

export const DecryptTutorialProvider: React.FC = ({ children }) => {
  const {
    chapterResults,
    setChapterResults,
    storeResult,
  } = useTutorialChapters(makeKey('chapterResults'));
  const [encryptionKey, setEncryptionKey] = useLocalStorageState(
    makeKey('encryptionKey'),
    ''
  );
  const [accessToken, setAccessToken] = useLocalStorageState(
    makeKey('accessToken'),
    ''
  );
  const [locatorToken, setLocatorToken] = useLocalStorageState(
    makeKey('locatorToken'),
    ''
  );

  const reset = useCallback(() => {
    setEncryptionKey.reset();
    setAccessToken.reset();
    setLocatorToken.reset();
    setChapterResults.reset();
  }, [setEncryptionKey, setAccessToken, setLocatorToken, setChapterResults]);

  const contextValue: DecryptTutorialContextProps = useMemo(() => {
    return {
      encryptionKey,
      accessToken,
      locatorToken,
      chapterResults,
      setEncryptionKey,
      setAccessToken,
      setLocatorToken,
      storeResult,
      reset,
    };
  }, [
    encryptionKey,
    accessToken,
    locatorToken,
    chapterResults,
    setEncryptionKey,
    setAccessToken,
    setLocatorToken,
    storeResult,
    reset,
  ]);

  return (
    <DecryptTutorialContext.Provider value={contextValue}>
      {children}
    </DecryptTutorialContext.Provider>
  );
};

export function useDecryptTutorial() {
  return useContext(DecryptTutorialContext);
}

export default DecryptTutorialContext;
