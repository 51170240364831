import React from 'react';
import { Text } from '@chakra-ui/react';
import { format } from 'date-fns';

interface KeyPacketUpdatedDateProps {
  updatedDate: number;
}

const KeyPacketUpdatedDate: React.FC<KeyPacketUpdatedDateProps> = ({
  updatedDate,
}) => {
  if (!updatedDate) {
    return <>Not provided</>;
  }

  const updatedObj = new Date(updatedDate);

  return (
    <>
      {format(updatedObj, 'LLL d, YYY')}
      <br />
      <Text fontSize="sm">{format(updatedObj, 'h:mm:ss a')}</Text>
    </>
  );
};

export default KeyPacketUpdatedDate;
