import { useHistory } from 'react-router-dom';

/**
 * Wraps up useHistory hook.
 * It was created to handle situations when useNavigate can't be used.
 *
 * Note: please use useNavigate() whenever you can.
 */
export default function useNavigateLazy<T>() {
  const history = useHistory<T>();
  return (to: string, state?: T) => history.push(to, state);
}
