import React, { useCallback, useContext, useMemo } from 'react';

type DashboardContentContextType = {
  containerRef: React.RefObject<HTMLDivElement | null>;
  hasScrolling: () => boolean;
  scrollToTop: () => void;
  scrollToBottom: () => void;
};

const DashboardContentContext = React.createContext(
  {} as DashboardContentContextType
);

interface DashboardContentContextProviderProps {
  containerRef: React.RefObject<HTMLDivElement | null>;
}

export const DashboardContentContextProvider: React.FC<DashboardContentContextProviderProps> = ({
  containerRef,
  children,
}) => {
  const hasScrolling = useCallback(() => {
    if (containerRef.current) {
      const el = containerRef.current;
      return el.scrollHeight > el.offsetHeight;
    }

    return false;
  }, [containerRef]);

  const scrollToTop = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [containerRef]);

  const scrollToBottom = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [containerRef]);

  const contextValue: DashboardContentContextType = useMemo(() => {
    return {
      containerRef,
      hasScrolling,
      scrollToTop,
      scrollToBottom,
    };
  }, [containerRef, hasScrolling, scrollToTop, scrollToBottom]);

  return (
    <DashboardContentContext.Provider value={contextValue}>
      {children}
    </DashboardContentContext.Provider>
  );
};

export function useDashboardContentContext() {
  return useContext(DashboardContentContext);
}

export default DashboardContentContext;
