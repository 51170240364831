import React, { useRef } from 'react';
import {
  Box,
  Flex,
  Text,
  useDimensions,
  useMediaQuery,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { Waypoint } from 'react-waypoint';
import {
  TableCapWrapper,
  TableCap,
  TableLoadingRows,
  TableWrapper,
  TableCapContent,
} from 'shared/components/Table';
import BackToTopFAB from 'shared/components/BackToTopFAB';
import { FilterMenuStack, FilterMenu } from 'shared/components/FilterMenu';
import useEventLog from 'event-log/list/useEventLog';
import useEventFilters from 'event-log/list/useEventFilters';
import ActivityFeedTable from 'activity/list/ActivityFeedTable';
import { ACTIVITY_FEED_EVENTS } from 'activity/constants';
import {
  MINIMUM_STICKY_VIEWPORT_HEIGHT,
  TableNav,
} from 'shared/components/Table/Table';
import { TableNavItems } from './ContactDetailsScene';

interface ActivityFeedProps {
  contactId: number;
}

const ActivityFeed: React.FC<ActivityFeedProps> = ({ contactId }) => {
  let tableCapHeight: string | undefined;
  const tableCapRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(tableCapRef, true);
  const userFilter = [contactId];
  const [filterMeta, filters] = useEventFilters(ACTIVITY_FEED_EVENTS);
  const navItems = TableNavItems(contactId);
  const eventLog = useEventLog({
    ...filters,
    user: userFilter,
  });

  // Any smaller than this and the table will need horizontal scrolling,
  // which breaks the sticky table header cells.
  const [enableSticky] = useMediaQuery(
    `(min-width: 1280px) and (min-height: ${MINIMUM_STICKY_VIEWPORT_HEIGHT}px)`
  );

  // get the height of the table cap for sticky anchor point.
  if (enableSticky && dimensions?.contentBox.height) {
    tableCapHeight = `${Math.round(dimensions?.contentBox.height) + 1}px`;
  }

  const onNoMoreRows = ({ event }: Waypoint.CallbackArgs) => {
    // Check for an event to prevent waypoint from firing when it is visible when mounted.
    if (!eventLog.loading && event) {
      eventLog.loadMore();
    }
  };

  return (
    <Box
      maxWidth="100%"
      overflow={enableSticky ? 'visible' : 'hidden'}
      position="relative"
    >
      <TableCapWrapper isSticky={enableSticky} ref={tableCapRef}>
        <TableCap
          isLoading={eventLog.isInitialLoading}
          nav={<TableNav navItems={navItems} />}
        >
          <TableCapContent>
            <Flex flexDirection={{ base: 'column', md: 'row' }}>
              <FilterMenuStack mt={{ base: 2, md: 0 }}>
                <Text fontWeight="bold">Filter By:</Text>
                <FilterMenu
                  name="Activity"
                  options={filterMeta.eventTypes.filters}
                  activeFilterCount={filterMeta.eventTypes.activeCount}
                  onFilterClick={filterMeta.eventTypes.update}
                  searchable
                />
              </FilterMenuStack>
            </Flex>
          </TableCapContent>
        </TableCap>
      </TableCapWrapper>

      {!!eventLog.data && (
        <TableWrapper>
          <ActivityFeedTable
            data={eventLog.data}
            enableSticky={enableSticky}
            hideUser
            stickyTop={tableCapHeight}
          />
          {eventLog.hasData && (
            <Waypoint onEnter={onNoMoreRows} bottomOffset="-100px">
              <div>
                <TableLoadingRows
                  isLoading={eventLog.loading}
                  onLoadMore={eventLog.loadMore}
                />
              </div>
            </Waypoint>
          )}
        </TableWrapper>
      )}

      <BackToTopFAB />
    </Box>
  );
};

export default observer(ActivityFeed);
