import { AxiosInstance } from 'axios';
import bind from 'bind-decorator';
import {
  CreateHookRequest,
  CreateHookResponse,
  GetHooksResponse,
  GetHooksParams,
  HookSummary,
} from 'backend/api-types/dashboard';
import { action } from 'mobx';
import useAsyncAction, { AsyncAction } from 'shared/hooks/useAsyncAction';
import { dashboardService } from '../backend/services';
import AsyncData from '../cache/AsyncData';
import useAsyncData from '../cache/useAsyncData';

export default class WebhooksController extends AsyncData<
  GetHooksResponse,
  GetHooksParams,
  Array<HookSummary>
> {
  protected _axios: AxiosInstance = dashboardService;

  public appId: number;

  constructor(appId: number) {
    super(`webhooks-for-app:${appId}`, '/hooks', {
      id: appId,
    });

    this.appId = appId;
  }

  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: GetHooksResponse): Array<HookSummary> {
    return data.hooks ?? [];
  }

  /**
   * By default, webhooks are subscribed to all events.  If all event checkboxes
   * are checked, use the default value so they are automatically subscribed to
   * any future events that are created.
   */
  static eventsArrayFromCheckboxState(checkboxState: Record<string, boolean>) {
    const isAllChecked = Object.values(checkboxState).every(Boolean);
    const onlyChecked = Object.entries(checkboxState)
      .filter(([, checked]) => checked)
      .map(([name]) => name);
    return isAllChecked ? [] : onlyChecked;
  }

  @bind
  @action
  public async addHook(
    appId: number,
    events: Record<string, boolean>,
    body: CreateHookRequest
  ): Promise<HookSummary> {
    // Declaring this in a separate line so that Typescript enforces that we are sending the proper request body
    const requestBody: CreateHookRequest = {
      ...body,
      id: appId,
      events: WebhooksController.eventsArrayFromCheckboxState(events),
    };

    const res = await dashboardService.post<CreateHookResponse>(
      '/hook',
      requestBody
    );

    // Update the list of keys
    if (this._data) {
      this._data = [...this._data, res.data];
    }

    return res.data;
  }

  @bind
  @action
  public async deleteHook(id: number): Promise<void> {
    await dashboardService.delete(`/hook/${id}`);

    // Update the list of keys
    if (this._data) {
      this._data = this._data.filter((key) => key.id !== id);
    }
  }

  @bind
  @action
  public async updateHookStatus(id: number, enabled: boolean): Promise<void> {
    await dashboardService.patch(`/hook/${id}`, {
      enabled,
    });

    // Update the list of keys
    if (this._data) {
      const index = this._data.findIndex((hook) => hook.id === id);
      if (index >= 0) {
        this._data[index].enabled = enabled;
      }
    }
  }

  @bind
  public getById(id: number): HookSummary | undefined {
    if (!this._data) {
      return;
    }

    return this._data.find((key) => key.id === id);
  }
}

export function useWebhooks(appId: number): WebhooksController {
  return useAsyncData<WebhooksController>(new WebhooksController(appId));
}

export function useDeleteWebhook(
  appId: number,
  onSuccess?: (data: void) => void,
  onError?: (err: Error) => void
): AsyncAction<void, [number]> {
  const keys = useWebhooks(appId);
  return useAsyncAction<void, [number]>(keys.deleteHook, onSuccess, onError);
}

export function useUpdateWebhookStatus(
  appId: number,
  onSuccess?: (data: void) => void,
  onError?: (err: Error) => void
): AsyncAction<void, [number, boolean]> {
  const hooks = useWebhooks(appId);
  return useAsyncAction<void, [number, boolean]>(
    hooks.updateHookStatus,
    onSuccess,
    onError
  );
}
