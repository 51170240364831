export default {
  global: {
    'html, body, #root': {
      minHeight: '100vh',
    },
    body: {
      fontFamily: 'body',
      color: 'black',
      overflow: {
        base: 'visible',
        lg: 'hidden',
      },
    },
    '*, *::before, *::after': {
      borderColor: 'gray.200',
    },
    '*::placeholder': {
      color: 'primary',
    },
  },
};
