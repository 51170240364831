import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import {
  Flex,
  Box,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Button,
  Stack,
  useMediaQuery,
  useDimensions,
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { NavLink } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import routes from 'routes';
import {
  TableCapWrapper,
  TableCap,
  TableLoadingRows,
  TableWrapper,
  TableOverflow,
  TableCapContent,
} from 'shared/components/Table';
import { PageContainer } from 'shared/components/containers';
import BackToTopFAB from 'shared/components/BackToTopFAB';
import { FilterMenuStack, FilterMenu } from 'shared/components/FilterMenu';
import DateRangeHeading from 'shared/components/DateRangeHeading';
import { CommunicationSummary } from 'key-packet/types';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import DateFilterNav from 'dashboard/Dashboard/DateFilterNav';
import ThreatGroupTabList from 'dashboard/Dashboard/ThreatGroupTabList';
import { DateRangePreset } from 'event-log/components/DatePicker/constants';
import { MINIMUM_STICKY_VIEWPORT_HEIGHT } from 'shared/components/Table/Table';
import CommunicationsTour from 'tours/CommunicationsTour';
import { CommunicationFilterMeta } from './useCommunicationFilters';
import { UseCommunicationsReturn } from './useCommunications';
import KeyPacketTable from './KeyPacketTable';

// Pre-filled number of table rows to render as a loading skeleton.
export const skeletonMockData: CommunicationSummary[] = Array(10)
  .fill(1)
  .map((_, idx) => {
    return {
      id: idx + 1, // Skip id 0, which is reserved for locked events and has a different row style.
      user: 'LOADING',
      status: 0,
      accesses: 0,
      expires: 0,
      updated: 0,
      recipients: [],
      warnings: 0,
      alerts: 0,
      verified: false,
      threat: 0,
      meta: {
        subject: 'LOADING',
        title: 'LOADING',
        type: 'LOADING',
      },
      type: 0,
    };
  });

interface KeyPacketsListProps {
  communications: UseCommunicationsReturn;
  dateRangePresets: DateRangePreset[];
  selectedDateRangePreset?: DateRangePreset;
  filterMeta: CommunicationFilterMeta;
  isTeamFeed: boolean;
  onSelectDateRangePreset: (preset?: DateRangePreset) => void;
}

const KeyPacketsList: React.FC<KeyPacketsListProps> = ({
  communications,
  filterMeta,
  dateRangePresets,
  selectedDateRangePreset,
  isTeamFeed,
  onSelectDateRangePreset,
}) => {
  let tableCapHeight;
  const currentUser = useCurrentUser();
  const tableCapRef = useRef<HTMLDivElement>(null);
  const tableCapDimensions = useDimensions(tableCapRef, true);

  // Any smaller than this and the table will need horizontal scrolling,
  // which breaks the sticky table header cells.
  const [enableSticky] = useMediaQuery(
    `(min-width: 1200px) and (min-height: ${MINIMUM_STICKY_VIEWPORT_HEIGHT}px)`
  );

  const onNoMoreRows = ({ event }: Waypoint.CallbackArgs) => {
    // Check for an event to prevent waypoint from firing when it is visible when mounted.
    if (!communications.loading && event) {
      communications.loadMore();
    }
  };

  if (enableSticky && tableCapDimensions?.contentBox.height) {
    tableCapHeight = `${
      Math.round(tableCapDimensions?.contentBox.height) + 1
    }px`;
  }

  return (
    <PageContainer>
      {/* Wait until table has loaded so product tour can spotlight one of its rows. */}
      {!communications.loading && <CommunicationsTour />}

      <Box position="relative" zIndex={2}>
        <Box my={8}>
          <Heading as="h1" size="lg" mb={1}>
            Messages and Files
          </Heading>
          <Heading as="p" size="md" fontWeight="normal">
            Keep track of your secured communications here.
          </Heading>
        </Box>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={8}
        >
          <DateFilterNav
            data-tour-id="communications-date-filter"
            presets={dateRangePresets}
            selectedPreset={selectedDateRangePreset}
            onSelect={onSelectDateRangePreset}
          />

          {currentUser.access.canViewTeamCommunications && (
            <Stack
              direction={{ base: 'column', md: 'row' }}
              alignItems="center"
              spacing={{ base: 2, md: 4 }}
              mt={{ sm: 4, lg: 0 }}
              ml="auto"
              mr={{ base: 'auto', md: 0 }}
              data-tour-id="communications-tab-nav"
            >
              <Button
                to={routes.keyPacket.list.user}
                as={NavLink}
                type="button"
                variant="activeSolid"
                size="sm"
              >
                My Communications
              </Button>
              <Button
                to={routes.keyPacket.list.team}
                as={NavLink}
                type="button"
                variant="activeSolid"
                size="sm"
              >
                Team Communications
              </Button>
            </Stack>
          )}
        </Flex>

        <DateRangeHeading
          heading={isTeamFeed ? 'Team Communications' : 'My Communications'}
          dateRange={selectedDateRangePreset?.dateRange}
          mt={8}
          mb={4}
        />
      </Box>

      <TableCapWrapper isSticky={enableSticky} ref={tableCapRef}>
        <TableCap nav={<ThreatGroupTabList />}>
          <TableCapContent justifyContent="space-between" flexDirection="row">
            <Flex
              alignItems={{ base: 'flex-start', md: 'center' }}
              justifyContent="space-between"
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <FilterMenuStack flexShrink={0}>
                <Text fontWeight="bold">Filter By:</Text>
                <FilterMenu
                  name="Type"
                  data-tour-id="communications-filter-type"
                  options={filterMeta.types.filters}
                  activeFilterCount={filterMeta.types.activeCount}
                  onFilterClick={filterMeta.types.update}
                />
                <FilterMenu
                  name="Status"
                  data-tour-id="communications-filter-status"
                  options={filterMeta.statuses.filters}
                  activeFilterCount={filterMeta.statuses.activeCount}
                  onFilterClick={filterMeta.statuses.update}
                />
                <FilterMenu
                  name="Recipient"
                  data-tour-id="communications-filter-recipient"
                  options={filterMeta.recipients.filters}
                  activeFilterCount={filterMeta.recipients.activeCount}
                  onFilterClick={filterMeta.recipients.update}
                  searchable
                />
                {isTeamFeed && (
                  <FilterMenu
                    name="Sender"
                    options={filterMeta.senders.filters}
                    activeFilterCount={filterMeta.senders.activeCount}
                    onFilterClick={filterMeta.senders.update}
                    searchable
                  />
                )}
              </FilterMenuStack>
              <InputGroup
                maxWidth={['auto', '300px']}
                size="md"
                flexShrink={2}
                mt={{ base: 2, md: 0 }}
                ml={{ sm: 'auto' }}
                data-tour-id="communications-filter-keyword"
              >
                <Input
                  name="q"
                  placeholder="Search by resource..."
                  onChange={(e) => {
                    filterMeta.setSearchValue(e.currentTarget.value);
                  }}
                />
                <InputRightElement overflow="hidden" borderRightRadius="md">
                  <IconButton
                    aria-label="Search"
                    icon={<Search2Icon />}
                    variant="link"
                    colorScheme="gray"
                    height="100%"
                    minWidth="auto"
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
          </TableCapContent>
        </TableCap>
      </TableCapWrapper>
      <TableWrapper>
        <TableOverflow overflowX={enableSticky ? 'visible' : 'auto'}>
          <KeyPacketTable
            data={communications.data || skeletonMockData}
            isInitialLoading={communications.isInitialLoading}
            enableSticky={enableSticky}
            stickyTop={tableCapHeight}
            canViewSender={isTeamFeed}
          />
        </TableOverflow>
        {communications.hasData && (
          <Waypoint onEnter={onNoMoreRows} bottomOffset="-100px">
            <div>
              <TableLoadingRows
                isLoading={communications.loading}
                onLoadMore={communications.loadMore}
              />
            </div>
          </Waypoint>
        )}
      </TableWrapper>

      <BackToTopFAB />
    </PageContainer>
  );
};

export default observer(KeyPacketsList);
