import React, { useState } from 'react';
import {
  Text,
  Flex,
  Box,
  Button,
  Link,
  Heading,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { StackedTextarea } from 'shared/components/form';
import { useDashboardContentContext } from 'dashboard/layout/DashboardContentContext';
import routes from 'routes';
import { TutorialName } from '../constants';
import {
  ChapterContainer,
  ChapterContent,
  ChapterFooter,
  ChapterHeader,
  ChapterIntroBox,
  ChapterIntroContent,
  ChapterNavLink,
  ChapterResponseAlert,
} from '../components';
import { getCipherAlgorithm, Ciphers } from './helpers';
import { useEncryptTutorial } from './EncryptTutorialContext';
import chapters from './chapters';

const CURRENT_CHAPTER = 5;

type FormValues = {
  cipher: Ciphers;
  message: string;
};

const Chapter5: React.FC = () => {
  const [encryptedMessage, setEncryptedMessage] = useState('');
  const tutorial = useEncryptTutorial();
  const { scrollToBottom } = useDashboardContentContext();
  const { handleSubmit, register } = useForm<FormValues>({
    defaultValues: {
      cipher: Ciphers.AES,
      message: '',
    },
  });

  const onSubmit = ({ cipher, message }: FormValues) => {
    const cipherAlgorithm = getCipherAlgorithm(cipher);
    const encrypted = cipherAlgorithm
      .encrypt(message, tutorial.encryptionKey)
      .toString();
    tutorial.setCipherText(encrypted);
    setEncryptedMessage(encrypted);
    scrollToBottom();
  };

  return (
    <ChapterContainer>
      <ChapterIntroBox>
        <ChapterHeader
          tutorial={TutorialName.encrypt}
          chapters={chapters}
          chapterNum={CURRENT_CHAPTER}
        />
        <ChapterIntroContent>
          <Text>
            Now that your encryption key is stored in the XQ key server,
            you&apos;re ready to encrypt a message!
          </Text>
        </ChapterIntroContent>
      </ChapterIntroBox>
      <ChapterContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl mb={4}>
            <FormLabel>Cipher Algorithm</FormLabel>
            <Select
              name="cipher"
              ref={register}
              display="inline-block"
              width="auto"
            >
              {Object.entries(Ciphers).map(([cipher]) => (
                <option key={cipher} value={cipher}>
                  {cipher}
                </option>
              ))}
            </Select>
          </FormControl>

          <StackedTextarea
            variant="code"
            name="message"
            rows={10}
            ref={register}
            mb={4}
          />
          <Flex justify="flex-end">
            <Button type="submit">Encrypt Message</Button>
          </Flex>

          {encryptedMessage && (
            <>
              <Box
                border="1px"
                borderColor="gray.100"
                borderRadius="lg"
                p={6}
                mt={6}
              >
                {encryptedMessage}
              </Box>

              <ChapterResponseAlert title="Your message has been encrypted using your key">
                <Text>
                  Later, when you want to decrypt this message, you will use the
                  locator token to fetch the key that was used to encrypt this
                  message.
                </Text>
                <Text my={4} wordBreak="break-all">
                  <strong>Locator token:</strong>
                  <br />
                  {tutorial.locatorToken}
                </Text>

                <Heading fontSize="lg" mt={6} mb={2}>
                  Ready to decrypt this message?
                </Heading>

                <Link as={RouterLink} to={routes.tutorials.decrypt.chapter0}>
                  Try the decrypt message tutorial
                </Link>
              </ChapterResponseAlert>
            </>
          )}
        </form>
      </ChapterContent>

      <ChapterFooter>
        <ChapterNavLink direction="back" chapters={chapters} chapterNum={4} />
      </ChapterFooter>
    </ChapterContainer>
  );
};

export default Chapter5;
