import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export const StepHeader: React.FC<BoxProps> = (props) => (
  <Box as="header" mb={4} {...props} />
);

export const StepContent: React.FC<BoxProps> = (props) => (
  <Box maxWidth="2xl" {...props} />
);
