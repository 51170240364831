import { useForm } from 'react-hook-form';

interface FormValues {
  apiKey: string;
}

export default function useTutorialAPIKeyForm(
  apiKey = '',
  onSubmit: (values: FormValues) => void
) {
  const form = useForm<FormValues>({
    defaultValues: {
      apiKey,
    },
  });

  return {
    handleSubmit: form.handleSubmit(onSubmit),
    form,
  };
}
