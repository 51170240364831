import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import UnlockScene from './UnlockScene';

const UnlockRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.root} exact>
        <UnlockScene />
      </Route>
    </Switch>
  );
};

export default UnlockRoutes;
