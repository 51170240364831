import { useMemo, useState } from 'react';
import { useApplications } from 'application/ApplicationsController';
import { Filter } from 'shared/components/FilterMenu/FilterMenu';
import useFilterMenu, {
  FilterMeta,
} from 'shared/components/FilterMenu/useFilterMenu';
import { debounce } from 'lodash';
import { dateRangeFilters } from '../components/DatePicker/constants';
import { useEventTypes } from '../EventTypesController';
import { EventThreatLevel } from './useEventLog';
import { useContacts } from '../../contacts/ContactsController';

export type DateRange = {
  fromDate: Date;
  toDate: Date;
};

export type EventLogFilters = {
  apiKeys?: number[];
  apps?: number[];
  user?: (number | undefined)[];
  threatLevels?: EventThreatLevel[];
  dateRange?: DateRange;
  eventTypes?: string[];
  relatedTo?: string;
  search?: string;
};

function buildDefaultFilter(
  id: string | number,
  label: string,
  threat?: EventThreatLevel
): Filter {
  return {
    id,
    label,
    threat,
    isChecked: false,
  };
}

function buildDateFilter(
  id: string | number,
  label: string,
  dateRange: DateRange
): Filter {
  return {
    id,
    label,
    dateRange,
    isChecked: false,
  };
}

function toIdArray<T extends string | number = string>(filters: Filter[]): T[] {
  return filters.filter((f) => f.isChecked).map((f) => f.id as T);
}

const initialThreatFilters: Filter[] = [
  buildDefaultFilter(
    EventThreatLevel.Benign,
    'Benign',
    EventThreatLevel.Benign
  ),
  buildDefaultFilter(EventThreatLevel.Info, 'Info', EventThreatLevel.Info),
  buildDefaultFilter(EventThreatLevel.Warn, 'Warn', EventThreatLevel.Warn),
  buildDefaultFilter(EventThreatLevel.Alert, 'Alert', EventThreatLevel.Alert),
  buildDefaultFilter(
    EventThreatLevel.Critical,
    'Critical',
    EventThreatLevel.Critical
  ),
];

const initialDateFilters: Filter[] = dateRangeFilters.map((f) =>
  buildDateFilter(f.id, f.label, f.dateRange)
);

type EventFilterMeta = {
  threat: FilterMeta;
  apps: FilterMeta;
  date: FilterMeta;
  eventTypes: FilterMeta;
  users: FilterMeta;
  search: string;
  setSearchValue: (value: string) => void;
};

export default function useEventFilters(
  defaultActivityFilters?: string[]
): [EventFilterMeta, EventLogFilters] {
  const contacts = useContacts();
  const [searchValue, setSearchValue] = useState('');
  const eventTypes = useEventTypes();
  const eventFilters = useFilterMenu(
    useMemo(() => {
      let options =
        eventTypes.data?.map((ev) => buildDefaultFilter(ev.action, ev.title)) ??
        [];

      if (defaultActivityFilters && defaultActivityFilters.length) {
        // Restrict the available filters to the subset of options
        options = options.filter((ev) =>
          defaultActivityFilters.includes(ev.id as string)
        );
      }

      return options;
    }, [eventTypes.data, defaultActivityFilters])
  );
  const apps = useApplications();
  const appFilters = useFilterMenu(
    useMemo(
      () => apps.data?.map((app) => buildDefaultFilter(app.id, app.name)) ?? [],
      [apps.data]
    )
  );
  const userFilters = useFilterMenu(
    contacts.data?.map((c) => {
      return {
        id: c.id,
        label: c.email,
        isChecked: false,
      };
    }) ?? []
  );
  const threatFilters = useFilterMenu(initialThreatFilters);
  const dateFilters = useFilterMenu(initialDateFilters, 'radio');
  const dateRange = dateFilters.filters.find((f) => f.isChecked)?.dateRange;
  const debouncedSetSearchValue = debounce(setSearchValue, 500);

  // If no event filtered are specified, default the data to filtering by all available options
  // that are specified in the arguments. (Don't show all possible events)
  let allowedEvents = toIdArray<string>(eventFilters.filters);
  if (!allowedEvents.length && defaultActivityFilters) {
    allowedEvents = defaultActivityFilters;
  }

  return [
    {
      threat: threatFilters,
      apps: appFilters,
      date: dateFilters,
      eventTypes: eventFilters,
      users: userFilters,
      search: searchValue,
      setSearchValue: debouncedSetSearchValue,
    },
    {
      threatLevels: toIdArray<EventThreatLevel>(threatFilters.filters),
      apps: toIdArray<number>(appFilters.filters),
      user: toIdArray<number>(userFilters.filters),
      eventTypes: allowedEvents,
      dateRange,
    },
  ];
}
