import uniqueId from 'lodash/uniqueId';
import * as yup from 'yup';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  FormLabel,
  Input,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import { PropsOf, useTheme } from '@chakra-ui/system';
import { UserSummary } from 'auth/user/types';
import { usePlanUsage } from 'settings/account/plan/PlanUsageController';
import { useContact } from 'contacts/ContactDetailsController';
import { useContacts } from 'contacts/ContactsController';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import { StackedInputField } from 'shared/components/form';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import SpinnerButton from 'shared/components/SpinnerButton';
import { useFormHandler } from 'shared/hooks/useFormHandler';

type DeleteOrDisableContactFormValues = {
  name: string;
};

const defaultValues: DeleteOrDisableContactFormValues = {
  name: '',
};

type DeleteOrDisableContactFormProps = {
  contact: UserSummary;
  disable: boolean;
  isExpanded?: boolean;
};

const DeleteOrDisableContactForm: React.FC<DeleteOrDisableContactFormProps> = ({
  contact,
  disable,
  isExpanded = false,
}) => {
  const fieldId = useRef(uniqueId());
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const contacts = useContacts();
  const user = useContact(contact.id);
  const currentBusiness = useCurrentBusiness();
  const planUsage = usePlanUsage();
  const toast = useToast();
  const theme = useTheme();
  const formSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .equals([contact.email], 'Email does not match')
          .required('Required'),
      }),
    [contact]
  );

  useEffect(() => {
    if (nameFieldRef.current && isExpanded) {
      nameFieldRef.current.focus();
    }
  }, [isExpanded, nameFieldRef]);

  const {
    onSubmit,
    form,
    error,
  } = useFormHandler<DeleteOrDisableContactFormValues>(
    async () => {
      user.disable();
      await contacts.deleteContact(contact.id, !disable);
      await currentBusiness.execute();
      await planUsage.execute();

      toast({
        title: disable
          ? 'Contact successfully disabled'
          : 'Contact successfully deleted',
        description: contact.email,
        status: 'success',
        position: 'bottom-right',
      });

      // Send the user back to the listing applications page
      history.push(routes.contacts.root);
    },
    defaultValues,
    formSchema
  );
  const { isSubmitting } = form.formState;
  const watchName = form.watch('name');
  const isNameMatch = watchName === contact.email;
  const nameMatchProps: PropsOf<typeof Input> = {};

  if (isNameMatch) {
    nameMatchProps.bg = 'danger.50';
    nameMatchProps._focus = {
      borderColor: 'gray.900',
      boxShadow: `0 0 0 2px ${theme.colors.gray[100]}`,
    };
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <FormErrorAlert error={error} />
        <FormLabel
          htmlFor={fieldId.current}
          fontSize="sm"
          fontWeight="normal"
          mb={1}
        >
          Type the email of this contact and then confirm{' '}
          {disable ? 'disable' : 'delete'}.
        </FormLabel>
        <StackedInputField
          initialFocusRef={nameFieldRef}
          id={fieldId.current}
          name="name"
          type="text"
          placeholder={contact.email}
          fontWeight="bold"
          _placeholder={{
            fontWeight: 'normal',
            color: 'danger.300',
          }}
          rightElement={
            isNameMatch && (
              <InputRightElement>
                <CheckIcon color="green.700" />
              </InputRightElement>
            )
          }
          {...nameMatchProps}
        />
        <Box textAlign="right" mt={8}>
          <SpinnerButton
            type="submit"
            colorScheme="danger"
            isLoading={isSubmitting}
          >
            Confirm {disable ? 'Disable' : 'Delete'}
          </SpinnerButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default DeleteOrDisableContactForm;
