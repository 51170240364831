import { useCallback } from 'react';
import * as yup from 'yup';
import pick from 'lodash/pick';
import { FormHandler, useFormHandler } from 'shared/hooks/useFormHandler';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { useListBusinesses } from 'team/ListBusinessesController';

export type ProfileFormValues = {
  email: string;
  phone: string;
  street: string;
  country: string;
  city: string;
  state: string;
  workspaceName: string;
  domain: string;
  tag: string;
  regionCode: string;
};

const profileFormSchema = yup.object().shape({
  workspaceName: yup.string().trim().required('Required'),
  email: yup
    .string()
    .trim()
    .required('Required')
    .email('Must be a valid email address'),
  street: yup
    .string()
    .trim()
    .test('min-length-when-available', 'Must be at least 2 characters', (v) => {
      return typeof v === 'string' && (v.length === 0 || v.length >= 2);
    }),
  city: yup
    .string()
    .trim()
    .test('min-length-when-available', 'Must be at least 2 characters', (v) => {
      return typeof v === 'string' && (v.length === 0 || v.length >= 2);
    }),
  regionCode: yup
    .string()
    .trim()
    .test('min-length-when-available', 'Must be at least 2 characters', (v) => {
      return typeof v === 'string' && (v.length === 0 || v.length >= 2);
    }),
  tag: yup.string().trim(),
  phone: yup
    .string()
    .trim()
    .matches(
      /^(?:\+?(\d{1,3}))?[-.\s(]*(\d{3})[-.\s)]*(\d{3})[-.\s]*(\d{4})(?:\s*x(\d+))?$|^$/,
      'Must be a valid phone number'
    ),
});

export default function useProfileForm(): FormHandler<ProfileFormValues> {
  const business = useCurrentBusiness();
  const listBusinesses = useListBusinesses();
  const defaultValues = {
    ...pick(
      business.data,
      'email',
      'phone',
      'street',
      'country',
      'city',
      'state',
      'tag'
    ),
    workspaceName: business.data?.name,
    domain: business.data?.workspace,
    regionCode: business.data?.postal,
  };

  const updateBusiness = useCallback(
    async (values: ProfileFormValues) => {
      await business.update({
        ...pick(values, ['email', 'street', 'city', 'country', 'state', 'tag']),
        name: values.workspaceName,
        telephone: values.phone,
        postal: values.regionCode,
      });
      listBusinesses.execute();
    },
    [business, listBusinesses]
  );

  return useFormHandler<ProfileFormValues>(
    updateBusiness,
    defaultValues,
    profileFormSchema
  );
}
