import React from 'react';
import { Stack, StackProps, Text } from '@chakra-ui/react';

interface RequestURLProps extends StackProps {
  requestURL: string;
  requestMethod: string;
}

const RequestURL: React.FC<RequestURLProps> = ({
  requestURL,
  requestMethod,
  ...props
}) => {
  const method = requestMethod.toUpperCase();
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: 8, md: 12 }}
      mb={8}
      {...props}
    >
      <div>
        <Text fontWeight="bold" fontSize="lg" mb={2}>
          Request URL:
        </Text>
        <Text fontFamily="mono" fontSize="sm" wordBreak="break-all">
          <Text
            as="span"
            fontWeight="bold"
            fontFamily="mono"
            color="white"
            bg={method === 'GET' ? 'green.600' : 'blue.500'}
            py={1}
            px={2}
          >
            {method}
          </Text>{' '}
          {requestURL}
        </Text>
      </div>
    </Stack>
  );
};

export default RequestURL;
