import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'shared/components/Table';
import routes from 'routes';
import NoResultsBox from 'shared/components/NoResultsBox';
import KeyPacketRetrieved from 'key-packet/list/KeyPacketTable/KeyPacketRetrieved';
import KeyPacketStatus from 'key-packet/list/KeyPacketTable/KeyPacketStatus';
import KeyPacketRecipients from 'key-packet/list/KeyPacketTable/KeyPacketRecipients';
import KeyPacketDescription from 'key-packet/list/KeyPacketTable/KeyPacketDescription';
import { CommunicationSummary } from 'key-packet/types';
import useNavigateLazy from 'shared/hooks/useNavigateLazy';
import LockedEvent from 'monitoring/components/LockedEvent';

const LOCKED_EVENT_ID = 0;

interface KeyPacketTableProps {
  enableSticky?: boolean;
  stickyTop?: string;
  data: CommunicationSummary[] | null;
  canViewOwner?: boolean;
}

const KeyPacketTable: React.FC<KeyPacketTableProps> = ({
  data,
  enableSticky = true,
  stickyTop,
}) => {
  const navigateTo = useNavigateLazy();
  if (!data || !data.length) {
    return (
      <NoResultsBox heading="No communications matched these filters">
        Expand your filter criteria to find more communications
      </NoResultsBox>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            isSticky={enableSticky}
            stickyTop={stickyTop}
            textAlign="center"
          >
            Retrieved
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Resource
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Recipients
          </TableCell>
          <TableCell
            isSticky={enableSticky}
            stickyTop={stickyTop}
            textAlign="center"
          >
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((resource, idx) => {
          return resource.id === LOCKED_EVENT_ID ? (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={resource.id + idx}>
              <TableCell colSpan={4} pl={0}>
                <LockedEvent />
              </TableCell>
            </TableRow>
          ) : (
            <TableRow
              key={resource.id}
              onClick={() => navigateTo(routes.keyPacket.detail(resource.id))}
            >
              <TableCell>
                <KeyPacketRetrieved
                  type={resource.type}
                  retrieved={resource.accesses}
                />
              </TableCell>
              <TableCell>
                <KeyPacketDescription
                  type={resource.type}
                  meta={resource.meta}
                />
              </TableCell>
              <TableCell minWidth="200px" maxWidth="250px" isTruncated>
                <KeyPacketRecipients recipients={resource.recipients} />
              </TableCell>
              <TableCell textAlign="center">
                <KeyPacketStatus status={resource.status} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default KeyPacketTable;
