import { useMemo } from 'react';
import { UserRole, UserSummary } from 'auth/user/types';
import { useContacts } from 'contacts/ContactsController';
import useFilterMenu, {
  FilterMeta,
} from 'shared/components/FilterMenu/useFilterMenu';
import { Filter } from 'shared/components/FilterMenu/FilterMenu';

const initialRoleFilterOptions: Array<Filter> = Object.keys(UserRole).map(
  (id) => {
    return {
      id,
      label: id,
      isChecked: false,
    };
  }
);

type ContactsTableResponse = {
  data: Array<UserSummary>;
  loading: boolean;
  finished: boolean;
  filters: {
    roles: FilterMeta;
  };
  error?: Error | null;
};

export default function useContactsTable(): ContactsTableResponse {
  const controller = useContacts();
  const roleFilters = useFilterMenu(initialRoleFilterOptions);

  const data = useMemo(() => {
    const raw = controller.data;
    if (!raw) {
      return [];
    }

    // Filter by role
    const allowedRoles = roleFilters.filters
      .filter((f) => f.isChecked)
      .map((f) => f.id);
    if (allowedRoles && allowedRoles.length) {
      return raw.filter((contact) => {
        return allowedRoles.includes(contact.role);
      });
    }

    return raw;
  }, [controller.data, roleFilters.filters]);

  return {
    data,
    loading: controller.loading,
    finished: controller.finished,
    error: controller.error,
    filters: {
      roles: roleFilters,
    },
  };
}
