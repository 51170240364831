import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Redirect } from 'react-router-dom';
import routes from 'settings/routes';
import RestrictedRoute from 'auth/RestrictedRoute';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import Payment from './payment/Payment';
import { PlanList } from './components/plan';
import Settings from './Settings';
import PaymentSuccess from './payment/PaymentSuccess';

const SettingsRoutes: React.FC = () => {
  const user = useCurrentUser();
  return (
    <Switch>
      <RestrictedRoute
        path={routes.profile}
        exact
        canAccess={user.access.canViewOrganizationSettings}
      >
        <Settings />
      </RestrictedRoute>
      <RestrictedRoute
        path={routes.billing}
        exact
        canAccess={user.access.canViewBilling}
      >
        <Settings />
      </RestrictedRoute>
      <RestrictedRoute
        path={routes.plans}
        exact
        canAccess={user.access.canModifyPlan}
      >
        <PlanList />
      </RestrictedRoute>
      <RestrictedRoute
        path={routes.payment}
        exact
        canAccess={user.access.canModifyPlan}
      >
        <Payment />
      </RestrictedRoute>
      <RestrictedRoute
        path={routes.paymentSuccess}
        exact
        canAccess={user.access.canModifyPlan}
      >
        <PaymentSuccess />
      </RestrictedRoute>

      <Redirect
        from={routes.root}
        to={
          user.access.canViewOrganizationSettings
            ? routes.profile
            : routes.billing
        }
      />
    </Switch>
  );
};

export default observer(SettingsRoutes);
