import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';

export type ScrollSpyContextValues = {
  active: string | null;
  onEnter: (id: string, event: Waypoint.CallbackArgs) => void;
  onExit: (id: string, event: Waypoint.CallbackArgs) => void;
};

const ScrollSpyContext = createContext<ScrollSpyContextValues>({
  active: null,
  onEnter: (id: string, event: Waypoint.CallbackArgs) => {
    console.warn('Unimplemented onEnter: ', id, event);
  },
  onExit: (id: string, event: Waypoint.CallbackArgs) => {
    console.warn('Unimplemented onExit: ', id, event);
  },
});

export const ScrollSpyContextProvider: React.FC = ({ children }) => {
  const [active, setActive] = useState<string | null>(null);
  const [visible, setVisible] = useState<Array<string>>([]);
  const onEnter = useCallback((id: string, event: Waypoint.CallbackArgs) => {
    setVisible((prev) => {
      switch (event.previousPosition) {
        case 'below':
          // The element has entered from the bottom so add it to the end of the list
          return [...prev, id];
        case 'above':
          // The element has entered from the top so add it to the beginning of the list
          return [id, ...prev];
        default:
          // The component mounted with this one already visible. I tested this and they
          // are always trigger from top to bottom. So placing these at the end will maintain
          // the proper order.
          return [...prev, id];
      }
    });
  }, []);
  const onExit = useCallback((id: string) => {
    setVisible((prev) => prev.filter((i) => i !== id));
  }, []);

  const topOfVisible = visible[0] ?? null;
  useEffect(() => {
    // Never forget what was last active. This way if there are no visible waypoints
    // we can say that the most recently visible waypoint is the one that is active
    if (topOfVisible && topOfVisible !== active) {
      setActive(topOfVisible);
    }
  }, [active, topOfVisible]);

  return (
    <ScrollSpyContext.Provider
      value={{
        active,
        onEnter,
        onExit,
      }}
    >
      {children}
    </ScrollSpyContext.Provider>
  );
};

export default ScrollSpyContext;
