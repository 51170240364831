import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import routes from './routes';
import authController from './AuthenticationController';
import RestrictedRoute, { RestrictedRouteProps } from './RestrictedRoute';

export type PostLoginReturnState = {
  returnTo?: string;
};

const ProtectedRoute: React.FC<RestrictedRouteProps> = ({
  // Default this prop to true, overriding the default on RestrictedRoute.
  // Any logged in user will have access to this route by default.
  canAccess = true,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();

  const redirectToLogin = useCallback(
    (rememberLocation: boolean) => {
      const state: PostLoginReturnState = {
        // Return to this path after successfully logging in. If the user manually logged out, we don't want them
        // to return to the old location incase it is a new user the is logging in after them.
        returnTo: rememberLocation
          ? location.pathname + location.search
          : undefined,
      };

      history.replace(routes.login, state);
    },
    [history, location.pathname, location.search]
  );

  const { isLoggedIn, loading, isLoggingOut } = authController;
  useEffect(() => {
    if (!isLoggedIn && !loading) {
      redirectToLogin(!isLoggingOut);
    }
  }, [redirectToLogin, isLoggedIn, loading, isLoggingOut]);

  // Delay rendering the content until we are sure that the user is authenticated.
  // This avoids flashing the sensitive content and then redirecting them away
  return authController.isLoggedIn ? (
    <RestrictedRoute canAccess={canAccess} {...props} />
  ) : null;
};

export default observer(ProtectedRoute);
