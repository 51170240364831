import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from 'routes';
import useTutorialAPIKey from '../useTutorialAPIKey';
import { EncryptTutorialProvider } from '../encrypt/EncryptTutorialContext';
import { DecryptTutorialProvider } from './DecryptTutorialContext';
import Chapter0 from './Chapter0';
import Chapter1 from './Chapter1';
import Chapter2 from './Chapter2';

const {
  tutorials: { decrypt },
} = routes;

const DecryptRoutes: React.FC = () => {
  const { apiKey } = useTutorialAPIKey();
  return (
    <EncryptTutorialProvider>
      <DecryptTutorialProvider>
        <Switch>
          <Route path={decrypt.chapter0} exact>
            <Chapter0 apiKey={apiKey} />
          </Route>

          {!apiKey && <Redirect to={decrypt.chapter0} />}

          <Route path={decrypt.chapter1} exact>
            <Chapter1 apiKey={apiKey} />
          </Route>
          <Route path={decrypt.chapter2} exact>
            <Chapter2 />
          </Route>

          <Redirect to={decrypt.chapter0} />
        </Switch>
      </DecryptTutorialProvider>
    </EncryptTutorialProvider>
  );
};

export default DecryptRoutes;
