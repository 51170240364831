import {
  Box,
  Button,
  ButtonProps,
  Heading,
  Stack,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import DropzoneBox, {
  encryptFileImage,
} from 'encryption/components/DropzoneBox';
import ProductLink from 'encryption/components/ProductLink';
import React, { useCallback, useContext, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import SpinnerButton from 'shared/components/SpinnerButton';
import useDownloadFile from 'shared/hooks/useDownloadFIle';
import useNavigate from 'shared/hooks/useNavigate';
import { productsConfig } from 'welcome/InstallProducts/productsConfig';
import routes from 'routes';
import { StepContent, StepHeader, StepNavigation } from '../components';
import { useEncryptFile } from '../encryptFile';
import EncryptFileContext from '../EncryptFileContext';

const UploadFile: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [hasEncryptedFile, setHasEncryptedFile] = useState(false);
  const { recipients, expireInHours, reset, goPrev } = useContext(
    EncryptFileContext
  );
  const toast = useToast();
  const downloadBoxRef = useRef<HTMLDivElement>(null);
  const installProductsRef = useRef<HTMLDivElement>(null);

  const goHome = useNavigate(routes.home);
  const goToFirstStep = useNavigate(routes.encryption.encryptFile);
  const handleGoToDashboard = () => {
    reset();
    goHome();
  };
  const handleComposeAnother = () => {
    reset();
    goToFirstStep();
  };

  const { getRootProps, getInputProps, ...dropzoneState } = useDropzone({
    onDrop: useCallback(
      async (acceptedFiles: File[]) => {
        setFile(acceptedFiles[0]);
        downloadBoxRef.current?.scrollIntoView();
      },
      [setFile, downloadBoxRef]
    ),
  });

  const encryptedFileName = `${file?.name}.xqf`;
  const downloadFile = useDownloadFile();
  const [encryptFile, encrypting, { error }] = useEncryptFile(
    useCallback(
      ({ blob }) => {
        downloadFile(blob, encryptedFileName);
        toast({
          title: 'Your file has been encrypted',
          status: 'success',
          position: 'bottom-right',
          description:
            'You can find the encrypted file in your downloads folder',
        });
        setHasEncryptedFile(true);
        installProductsRef.current?.scrollIntoView();
      },
      [downloadFile, encryptedFileName, toast, installProductsRef]
    )
  );

  const handleEncryptFileClick = useCallback(() => {
    if (!file) {
      // This should never actually happen
      throw new Error('You must first upload a file to be encrypted');
    }

    encryptFile(file, expireInHours, recipients);
  }, [encryptFile, expireInHours, file, recipients]);

  // Use full width buttons for small screens so they can be stacked.
  const responsiveButtonProps: ButtonProps = {
    display: { base: 'flex', sm: 'inline-flex' },
    width: { base: '100%', sm: 'auto' },
  };

  const isFileAdded = !!file;
  return (
    <>
      <StepHeader>
        <Heading size="sm" mb={2}>
          Upload the file to be encrypted
        </Heading>
        <Text>
          The file will be encrypted from within the browser and never leave
          your device.
        </Text>
      </StepHeader>
      <StepContent>
        <FormErrorAlert error={error} />
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={8}
          alignItems={{ base: 'flex-start', md: 'center' }}
        >
          <Box flex="none" width="100%" maxWidth={{ base: '100%', md: 'xs' }}>
            <DropzoneBox
              root={getRootProps()}
              input={getInputProps()}
              iconImage={encryptFileImage}
              dropMessage="Drop your file to encrypt it"
              loadingText="Encrypting File"
              {...dropzoneState}
            />
          </Box>
          <Box
            ref={downloadBoxRef}
            textAlign="center"
            width="100%"
            maxWidth={{ base: '100%', md: 'md' }}
          >
            {isFileAdded && (
              <>
                <Text mb={2} isTruncated>
                  {encryptedFileName}
                </Text>
                <SpinnerButton
                  type="submit"
                  disabled={!isFileAdded}
                  isLoading={encrypting}
                  loadingText="Encrypting file..."
                  onClick={handleEncryptFileClick}
                >
                  Download Encrypted File
                </SpinnerButton>
              </>
            )}
          </Box>
        </Stack>

        <StepNavigation showNext={false} onPrevClick={goPrev} />

        {hasEncryptedFile && (
          <Box ref={installProductsRef} mt={16}>
            <Heading size="md" mb={2}>
              Install our products
            </Heading>
            <Text>
              Once you finish encrypting your file, install one our products to
              better protect your communications
            </Text>

            <Wrap mt={4} spacing={{ base: 6, sm: 8, md: 12 }}>
              <WrapItem>
                <ProductLink
                  href={productsConfig.googleSuite.link}
                  image={productsConfig.googleSuite.image}
                  name={productsConfig.googleSuite.name}
                />
              </WrapItem>
              <WrapItem>
                <ProductLink
                  href={productsConfig.gmail.link}
                  image={productsConfig.gmail.image}
                  name={productsConfig.gmail.name}
                />
              </WrapItem>
              <WrapItem>
                <ProductLink
                  href={productsConfig.outlook.link}
                  image={productsConfig.outlook.image}
                  name={productsConfig.outlook.name}
                />
              </WrapItem>
              <WrapItem>
                <ProductLink
                  href={productsConfig.slack.link}
                  image={productsConfig.slack.image}
                  name={productsConfig.slack.name}
                />
              </WrapItem>
            </Wrap>

            <Stack
              display="flex"
              direction={{ base: 'column', sm: 'row' }}
              alignItems="center"
              spacing={4}
              mt={8}
            >
              <Button
                variant="outline"
                onClick={handleComposeAnother}
                {...responsiveButtonProps}
              >
                Encrypt Another
              </Button>
              <Button onClick={handleGoToDashboard} {...responsiveButtonProps}>
                Go to XQ Dashboard
              </Button>
            </Stack>
          </Box>
        )}
      </StepContent>
    </>
  );
};

export default UploadFile;
