import React from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { Avatar, Box, Flex, Heading, SimpleGrid } from '@chakra-ui/react';
import ContactDetailsController from 'contacts/ContactDetailsController';

const UserData: React.FC<{ label: string; value: string | number }> = ({
  label,
  value,
}) => {
  return (
    <>
      <Box as="dt">{label}:</Box>
      <Box as="dd">{value}</Box>
    </>
  );
};

const UserSummaryFields: Record<string, string> = {
  id: 'ID',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  title: 'Title',
  lastLogin: 'Last Login',
  moreInfo: 'More Info',
  role: 'Role',
  status: 'Status',
};

interface ContactDataProps {
  user: ContactDetailsController;
}

const ContactData: React.FC<ContactDataProps> = ({ user }) => {
  const hasName = user.data?.firstName || user.data?.lastName;

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row', xl: 'column' }}
      justifyContent={{ sm: 'space-around' }}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb={{ base: 8, sm: 0, xl: 8 }}
      >
        <Avatar
          bg="blue.500"
          src={user.avatar}
          name={user.fullName}
          mb={4}
          size="xl"
        />
        {(hasName || user.data?.email) && (
          <Heading size="md">
            {hasName
              ? `${user.data?.firstName} ${user.data?.lastName}`
              : user.data?.email}
          </Heading>
        )}
      </Flex>
      <SimpleGrid
        pl={{ sm: 4, md: 0 }}
        columns={2}
        spacing={{ base: 4, sm: 2, xl: 4 }}
        gridTemplateColumns="100px auto"
      >
        {user.data?.email && (
          <UserData label={UserSummaryFields.email} value={user.data?.email} />
        )}

        {user.data?.title && (
          <UserData label={UserSummaryFields.title} value={user.data?.title} />
        )}

        {user.data?.status && (
          <UserData
            label={UserSummaryFields.status}
            value={user.data?.status}
          />
        )}

        {user.data?.role && (
          <UserData label={UserSummaryFields.role} value={user.data?.role} />
        )}

        {user.data?.lastLogin && (
          <UserData
            label={UserSummaryFields.lastLogin}
            value={format(user.data?.lastLogin, 'MM/dd/yyyy')}
          />
        )}
      </SimpleGrid>
    </Flex>
  );
};

export default observer(ContactData);
