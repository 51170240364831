import React from 'react';
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';

type SidebarContextType = UseDisclosureReturn;

const SidebarContext = React.createContext({} as SidebarContextType);

export const SidebarContextProvider: React.FC = ({ children }) => {
  const disclosure = useDisclosure();

  return (
    <SidebarContext.Provider value={disclosure}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
