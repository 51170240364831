import React, { useEffect } from 'react';
import { Text, Textarea } from '@chakra-ui/react';
import useBodyEditor from './useBodyEditor';

interface BodyProps {
  initialBody?: string;
  /** Controlled input to override internal state. */
  value?: string;
  rows?: number;
  /** Content type of formatting to transform the body into. */
  contentType?: string;
  /** Emitted after the body format has been transformed. */
  onFormatted?: (body: string) => void;
  /** Emitted after the internal body state is updated. */
  onStateChange?: (body: string) => void;
  onError?: (message?: string) => void;
}

const Body = React.forwardRef<HTMLTextAreaElement, BodyProps>(
  (
    {
      initialBody,
      contentType = 'application/json',
      rows = 5,
      value,
      onFormatted,
      onStateChange,
      onError,
    },
    _ref
  ) => {
    const { body, setBody, error, handleBlur, handleChange } = useBodyEditor(
      initialBody,
      onFormatted,
      contentType
    );

    useEffect(() => {
      if (onError) {
        onError(error);
      }
    }, [error, onError]);

    // Use value as a controlled input and update internal state when it changes.
    useEffect(() => {
      if (value !== undefined) {
        setBody(value);
      }
    }, [value, setBody]);

    useEffect(() => {
      if (onStateChange) {
        onStateChange(body);
      }
    }, [body, onStateChange]);

    return (
      <>
        <Text fontWeight="bold" fontSize="lg" mb={2}>
          Request Body:
        </Text>
        <Textarea
          ref={_ref}
          variant="code"
          onBlur={handleBlur}
          onChange={handleChange}
          value={body}
          rows={rows}
        />
      </>
    );
  }
);

export default Body;
