import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Filter } from './FilterMenu';

export type FilterMeta = {
  filters: Filter[];
  activeCount: number;
  update: (id: string | number) => void;
};

function filtersToChecked(filters: Filter[]): Array<string | number> {
  return filters.reduce<Array<string | number>>((acc, prev) => {
    if (prev.isChecked) {
      acc.push(prev.id);
    }

    return acc;
  }, []);
}

const useFilterMenu = (
  options: Filter[],
  menuType?: 'checkbox' | 'radio'
): FilterMeta => {
  const initOptions = useRef(options);
  const [checked, setChecked] = useState<Array<string | number>>(
    filtersToChecked(options)
  );

  useEffect(() => {
    if (!isEqual(initOptions.current, options)) {
      initOptions.current = options;
      setChecked(filtersToChecked(options));
    }
  }, [options]);

  const filters = useMemo(
    () =>
      initOptions.current.map((f) => {
        return {
          ...f,
          isChecked: checked.includes(f.id),
        };
      }),
    [checked]
  );

  const update = useCallback(
    (id: string | number) => {
      setChecked((prev) => {
        if (menuType === 'radio') {
          return prev[0] === id ? [] : [id];
        }

        if (prev.includes(id)) {
          return prev.filter((f) => f !== id);
        }

        return prev.concat([id]);
      });
    },
    [menuType]
  );

  const activeCount = filters.filter((f) => f.isChecked).length;
  return {
    filters,
    activeCount,
    update,
  };
};

export default useFilterMenu;
