import { EventLogItem } from 'event-log/types';
import { CommunicationType } from 'key-packet/types';

export const ACTIVITY_FEED_EVENTS: string[] = [];

// Pre-filled number of table rows to render as a loading skeleton.
export const skeletonMockData: EventLogItem[] = Array(6)
  .fill(1)
  .map((_, idx) => {
    return {
      id: idx,
      action: 'LOADING',
      app: 0,
      key: 0,
      val: 0,
      threat: 0,
      data: 'LOADING',
      ip: 'LOADING',
      location: 'LOADING',
      user: 'LOADING',
      created: 0,
      relatedTo: 'LOADING',
      meta: {
        communicationType: CommunicationType.Unknown,
      },
    };
  });
