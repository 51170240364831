import React from 'react';
import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { FormProvider } from 'react-hook-form';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import SpinnerButton from 'shared/components/SpinnerButton';
import useProfileForm from './useProfileForm';
import ProfileFormContent from './ProfileFormContent';

const ProfileForm: React.FC = () => {
  const { form, onSubmit, error } = useProfileForm();

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <ProfileFormContent />
        <FormErrorAlert error={error} mt={8} />
        <Box textAlign="right" as="footer" mt={8}>
          <SpinnerButton
            type="submit"
            disabled={!form.formState.isDirty}
            isLoading={form.formState.isSubmitting}
          >
            Update Profile
          </SpinnerButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default observer(ProfileForm);
