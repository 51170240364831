import React, { useContext, useMemo } from 'react';
import * as yup from 'yup';
import { Heading, Text } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { add, differenceInHours } from 'date-fns';
import { StackedSelectField } from 'shared/components/form';
import { useFormHandler } from 'shared/hooks/useFormHandler';
import { EncryptWizardValues } from '../EncryptMessageContext';
import { StepHeader, StepContent, StepNavigation } from '../components';

const DEFAULT_EXPIRE_IN_HOURS = 24;
const currentDate = new Date();

function expiryOptions(
  communication: 'Message' | 'File'
): Array<[number, string]> {
  return [
    [1, `${communication} expires in 1 hour`],
    [1 * 24, `${communication} expires in 1 day`],
    [2 * 24, `${communication} expires in 2 days`],
    [3 * 24, `${communication} expires in 3 days`],
    [7 * 24, `${communication} expires in 1 week`],
    [
      differenceInHours(
        add(currentDate, {
          months: 1,
        }),
        currentDate
      ),
      `${communication} expires in 1 month`,
    ],
    [
      differenceInHours(
        add(currentDate, {
          months: 2,
        }),
        currentDate
      ),
      `${communication} expires in 2 months`,
    ],
    [
      differenceInHours(
        add(currentDate, {
          months: 3,
        }),
        currentDate
      ),
      `${communication} expires in 3 months`,
    ],
  ];
}

type ExpirationProps = {
  context: React.Context<
    EncryptWizardValues & {
      expireInHours?: number;
    }
  >;
  heading: string;
  helperText: string;
  communicationName: 'Message' | 'File';
};

type FormValues = {
  expireInHours: number;
};

const schema = yup.object().shape({
  expireInHours: yup.number().required('Required'),
});

const Expiration: React.FC<ExpirationProps> = ({
  context,
  heading,
  helperText,
  communicationName,
}) => {
  const { goPrev, goNext, expireInHours } = useContext(context);

  const defaultValues = useMemo(() => {
    return {
      expireInHours: expireInHours ?? DEFAULT_EXPIRE_IN_HOURS,
    };
  }, [expireInHours]);

  const { onSubmit, form } = useFormHandler<FormValues>(
    goNext,
    defaultValues,
    schema,
    true
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <StepHeader>
          <Heading size="sm" mb={2}>
            {heading}
          </Heading>
          <Text>{helperText}</Text>
        </StepHeader>
        <StepContent>
          <StackedSelectField name="expireInHours" isRequired autoFocus>
            {expiryOptions(communicationName).map(([hours, label]) => (
              <option key={hours} value={hours}>
                {label}
              </option>
            ))}
          </StackedSelectField>
          <StepNavigation onPrevClick={goPrev} />
        </StepContent>
      </form>
    </FormProvider>
  );
};

export function ExpirationHOC(props: ExpirationProps) {
  return () => <Expiration {...props} />;
}

export default Expiration;
