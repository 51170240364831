import { useCallback } from 'react';
import * as yup from 'yup';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { HookSummary } from 'backend/api-types/dashboard';
import { useWebhooks } from 'webhooks/WebhooksController';
import { setAllCheckboxes } from '../helpers';

export const eventTypes = [
  'removecontact',
  'updatecontact',
  'addcontact',
  'exportkey',
  'revokekey',
  'exportkeys',
  'resendinvite',
  'requestreset',
  'completereset',
  'login',
  'registerbusiness',
  'updatebusiness',
  'updatepolicy',
  'newapikey',
  'removeapikey',
  'adddevapp',
  'updatedevapp',
  'removedevapp',
  'resolvejob',
  'assignjob',
];

export type WebhookFormValues = {
  url: string;
  events: Record<string, boolean>;
};

const webhookFormSchema = yup.object().shape({
  url: yup.string().required('Required').url('Invalid callback URL format'),
  events: yup
    .object()
    .test(
      'all-unchecked',
      'You must subscribe to at least one event',
      (events = {}) => {
        return (
          Object.values(events || {}).filter((checked) => checked).length > 0
        );
      }
    ),
});

const defaultValues: WebhookFormValues = {
  url: '',
  events: setAllCheckboxes(eventTypes, true),
};

export default function useWebhookForm(
  appId: number,
  onSuccess: (webhook: HookSummary) => void
): FormHandler<WebhookFormValues> {
  const webhooks = useWebhooks(appId);
  const createWebhook = useCallback(
    async ({ events, ...values }: WebhookFormValues) => {
      const res = await webhooks.addHook(appId, events, values);
      onSuccess(res);
    },
    [appId, webhooks, onSuccess]
  );

  return useFormHandler<WebhookFormValues>(
    createWebhook,
    defaultValues,
    webhookFormSchema
  );
}
