import React from 'react';
import { useFormContext } from 'react-hook-form';
import StackedTextarea, { StackedTextareaProps } from './StackedTextarea';

const StackedTextareaField: React.FC<StackedTextareaProps> = ({
  name,
  ...props
}) => {
  const { errors, register } = useFormContext();
  const fieldError = name && errors[name];
  return (
    <StackedTextarea
      {...props}
      name={name}
      isInvalid={!!fieldError}
      errorText={fieldError?.message}
      ref={register}
    />
  );
};

export default StackedTextareaField;
