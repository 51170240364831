import React, { useState } from 'react';
import { Box, Button, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import routes from 'routes';
import { observer } from 'mobx-react';
import { DeleteAlertDialog } from 'shared/components/alert-dialogs';
import LoadingBox from 'shared/components/LoadingBox';
import RequestErrorAlert from 'shared/components/RequestErrorAlert';
import { ApplicationSummary } from 'application/ApplicationsController';
import SaveAPIKeyWarningModal from 'api-keys/create/SaveAPIKeyWarningModal';
import APIKeyModal from 'api-keys/create/APIKeyModal';
import { useAPIKeys, APIKeySummary } from 'api-keys/APIKeysController';
import useDeleteAPIKeyDialog from 'api-keys/delete/useDeleteAPIKeyDialog';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import CreateFirstAPIKey from './CreateFirstAPIKey';
import APIKeyList from './APIKeysList';
import DocumentLink from './DocumentLink';
import { SectionHeader } from './styled';

interface APIKeysSectionProps {
  app: ApplicationSummary;
  showOnboarding?: boolean;
}

const APIKeysSection: React.FC<APIKeysSectionProps> = ({
  app,
  showOnboarding = false,
}) => {
  const keys = useAPIKeys();
  const currentUser = useCurrentUser();
  const [createdKey, setCreatedKey] = useState<string>('');
  const apiKeyModalDisclosure = useDisclosure();
  const deleteDialog = useDeleteAPIKeyDialog();
  const saveWarningDisclosure = useDisclosure({
    onClose: () => {
      // Wait until the modal finishes hiding before clearing its state so the
      // api key is not seen disappearing during the hide transition.
      setTimeout(() => {
        setCreatedKey('');
      }, 500);
    },
  });

  const handleAPIKeyFormSuccess = (apiKey: string) => {
    setCreatedKey(apiKey);
    apiKeyModalDisclosure.onClose();
    saveWarningDisclosure.onOpen();
  };

  if (keys.loading) {
    return <LoadingBox />;
  }

  const failedFetchingKeys = !!keys.error;
  return (
    <Flex flexDirection="column" flex={showOnboarding ? '1 100%' : 'none'}>
      <SectionHeader flex="none">
        <Heading fontSize="2xl" textTransform="uppercase" flex="none">
          API Keys
        </Heading>
        <DocumentLink
          href={`${routes.docs.subscription}#section/Authentication/API%20Key`}
          mt={2}
        >
          Learn more about API keys
        </DocumentLink>
      </SectionHeader>

      {!showOnboarding ? (
        <>
          {!failedFetchingKeys && (
            <>
              <APIKeyList
                appId={app.id}
                keys={keys.getByAppId(app.id) ?? []}
                onDelete={deleteDialog.onOpen}
              />
              <Box flex="none" my={8} px={6}>
                <Button
                  type="button"
                  size="xs"
                  colorScheme="darkgray"
                  px={4}
                  onClick={apiKeyModalDisclosure.onOpen}
                >
                  Add a new key
                </Button>
              </Box>
            </>
          )}
          {failedFetchingKeys && (
            <RequestErrorAlert canTryAgain m={3} width="auto">
              Failed to load API keys
            </RequestErrorAlert>
          )}
        </>
      ) : (
        <CreateFirstAPIKey
          appId={app.id}
          name={currentUser.data?.firstName}
          onSuccess={handleAPIKeyFormSuccess}
        />
      )}

      <APIKeyModal
        appId={app.id}
        isOpen={apiKeyModalDisclosure.isOpen}
        onClose={apiKeyModalDisclosure.onClose}
        onSuccess={handleAPIKeyFormSuccess}
      />
      <SaveAPIKeyWarningModal
        isOpen={saveWarningDisclosure.isOpen}
        apiKey={createdKey}
        onClose={saveWarningDisclosure.onClose}
      />
      <DeleteAlertDialog<number, APIKeySummary>
        title="Delete API Key"
        content="Are you sure you want to delete this API key?"
        renderDescription={(apiKey) => apiKey.name}
        {...deleteDialog}
      />
    </Flex>
  );
};

export default observer(APIKeysSection);
