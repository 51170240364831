import DropzoneBox, {
  importContactsFileImage,
} from 'encryption/components/DropzoneBox';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useImportKeys } from './importKeys';

type UnlockKeysSceneProps = {
  onError: (error: Error) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onData: (keys: any[]) => void;
};

const UnlockKeysScene: React.FC<UnlockKeysSceneProps> = ({
  onError,
  onData,
}) => {
  const [importKeys, loading, { error }] = useImportKeys(async (keys) => {
    onData(keys);
  });

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const res = await importKeys(acceptedFiles[0]);
      if (!res) {
        if (error) {
          onError(error);
        } else onError(new Error('Failed to load data'));
      }
    },
    [error, importKeys, onError]
  );

  const { getRootProps, getInputProps, ...dropzoneState } = useDropzone({
    onDrop,
  });

  return (
    <DropzoneBox
      root={getRootProps()}
      input={getInputProps()}
      iconImage={importContactsFileImage}
      dropMessage="Drop your key file"
      isLoading={loading}
      loadingText="Loading Keys"
      {...dropzoneState}
    />
  );
};

export default UnlockKeysScene;
