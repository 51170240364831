import React from 'react';
import { observer } from 'mobx-react';
import { Text } from '@chakra-ui/react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableCellProps,
} from 'shared/components/Table';
import NoResultsBox from 'shared/components/NoResultsBox';
import { useEventTypes } from 'event-log/EventTypesController';
import { EventLogItem } from 'event-log/types';
import { useApplications } from 'application/ApplicationsController';
import { useAPIKeys } from 'api-keys/APIKeysController';
import LockedEvent from 'monitoring/components/LockedEvent';
import EventLogRowItem from './EventLogRowItem';

const LOCKED_EVENT_ID = 0;

export interface EventLogTableProps {
  data: EventLogItem[];
  /** Use position sticky for the thead table cells. */
  isSticky?: boolean;
  /** Top position when sticky is applied */
  stickyTop?: string | number;
  /** What to do when the user click on a row */
  onRowClick?: (id: number) => void;
  /** Heading to display in the no results notice */
  noResultsHeading?: string;
  /** Message to display in the ne results message */
  noResultsMessage?: string;
  /** Skeleton table rows will be displayed while the initial data is loading. */
  isInitialLoading?: boolean;
}

const EventLogTable: React.FC<EventLogTableProps> = ({
  data,
  isSticky = false,
  stickyTop = 0,
  onRowClick,
  noResultsHeading = 'No events matched these filters',
  noResultsMessage = 'Expand your filter criteria to find more events',
  isInitialLoading = false,
}) => {
  const applications = useApplications();
  const keys = useAPIKeys();
  const events = useEventTypes();
  const hasData = data.length > 0;

  const thProps: TableCellProps = {
    isSticky,
    stickyTop,
    zIndex: 2,
  };

  return (
    <Table>
      {hasData && (
        <TableHead>
          <TableRow>
            <TableCell {...thProps} textAlign="center">
              Level
            </TableCell>
            <TableCell {...thProps}>Log #</TableCell>
            <TableCell {...thProps}>API Key</TableCell>
            <TableCell {...thProps}>Event Type</TableCell>
            <TableCell {...thProps}>Date</TableCell>
            <TableCell {...thProps}>Location + IP Address</TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {data.map((row, i) => {
          return row.id === LOCKED_EVENT_ID ? (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={row.id + i}>
              <TableCell colSpan={6}>
                <LockedEvent />
              </TableCell>
            </TableRow>
          ) : (
            <EventLogRowItem
              key={row.id}
              keys={keys}
              events={events}
              applications={applications}
              row={row}
              isInitialLoading={isInitialLoading}
              onRowClick={onRowClick}
            />
          );
        })}

        {!hasData && (
          <TableRow>
            <TableCell
              colSpan={6}
              p={0}
              borderBottom={0}
              _first={{ pl: 0 }}
              _last={{ pr: 0 }}
            >
              <NoResultsBox heading={noResultsHeading}>
                <Text>{noResultsMessage}</Text>
              </NoResultsBox>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default observer(EventLogTable);
