import React, { useContext } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { Box, Link } from '@chakra-ui/react';
import { Contents } from 'mdx';
import ScrollSpyContext from './ScrollSpyContext';

type TOCNavItemProps = {
  id: string;
  baseRoute: string;
  title: string;
  level: number;
  activeId: string | null;
  subContents?: Contents[];
};

const TOCNavItem: React.FC<TOCNavItemProps> = ({
  id,
  baseRoute,
  title,
  level,
  activeId,
  subContents = [],
}) => {
  if (level >= 4) {
    // We only support 2 deep navigation headings at this point. Top level is level 2.
    return null;
  }

  return (
    <>
      <Box ml={level === 3 ? 3 : 1} my={1}>
        <Link
          to={`${baseRoute}#${id}`}
          as={NavHashLink}
          smooth
          fontWeight={level === 3 ? '400' : '600'}
          color={activeId === id ? 'blue.500' : 'gray.400'}
          textDecoration="none"
        >
          {title}
        </Link>
      </Box>
      {subContents.map((c) => (
        <TOCNavItem
          baseRoute={baseRoute}
          id={c.id}
          level={c.level}
          subContents={c.children}
          key={c.id}
          title={c.title}
          activeId={activeId}
        />
      ))}
    </>
  );
};

type MDXTableOfContentsProps = {
  baseRoute: string;
  contents: Contents[];
};

const MDXTableOfContents: React.FC<MDXTableOfContentsProps> = ({
  baseRoute,
  contents,
}) => {
  const { active } = useContext(ScrollSpyContext);

  if (!contents.length) {
    return null;
  }

  return (
    <Box
      borderLeft="solid 1px"
      borderColor="gray.100"
      pl={3}
      py={4}
      top={0}
      position="sticky"
      fontSize="sm"
    >
      {contents.map((c) => (
        <TOCNavItem
          baseRoute={baseRoute}
          id={c.id}
          level={c.level}
          subContents={c.children}
          key={c.id}
          title={c.title}
          activeId={active}
        />
      ))}
    </Box>
  );
};

export default MDXTableOfContents;
