import { AxiosInstance } from 'axios';
import { action, computed } from 'mobx';
import bind from 'bind-decorator';
import authController from 'auth/AuthenticationController';
import {
  PayMethodSummary,
  ListPayMethodsResponse,
  AttachPayMethodRequest,
  AttachPayMethodResponse,
} from 'backend/api-types/dashboard';
import { dashboardService } from 'backend/services';
import AsyncData from 'cache/AsyncData';
import useAsyncData from 'cache/useAsyncData';

export default class PayMethodsController extends AsyncData<
  ListPayMethodsResponse,
  void,
  Array<PayMethodSummary>
> {
  protected _axios: AxiosInstance = dashboardService;

  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: ListPayMethodsResponse): Array<PayMethodSummary> {
    return data.payMethods ?? [];
  }

  constructor() {
    super('pay-methods', '/paymethod');
  }

  @computed
  get default(): PayMethodSummary | null {
    if (!this._data) {
      return null;
    }

    return this._data.find((p) => p.default) ?? null;
  }

  @bind
  @action
  public async attachPayMethod(stripePayMethodId: string): Promise<void> {
    // Declaring this in a separate line so that Typescript enforces that we are sending the proper request body
    const requestBody: AttachPayMethodRequest = {
      stripePayMethodId,

      // All new pay methods will be considered the default for now
      default: true,
    };

    await dashboardService.put<AttachPayMethodResponse>(
      '/paymethod',
      requestBody
    );

    // Refetch the pay methods
    this.execute();
  }
}

type UsePayMethodsOptions = {
  /** Skip the initial fetching of data. */
  skip?: boolean;
};

export function usePayMethods(
  options: UsePayMethodsOptions = {}
): PayMethodsController {
  const { skip = false } = options;
  return useAsyncData<PayMethodsController>(
    new PayMethodsController(),
    skip || !authController.isLoggedIn
  );
}
