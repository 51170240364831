// Unable to get this rule working with TypeScript and generic-typed JSX.
// https://github.com/microsoft/TypeScript/issues/31247
/* eslint-disable react/require-default-props */
import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import SpinnerButton from 'shared/components/SpinnerButton';
import { ConfirmAlertDialogProps } from './types';

type DeleteAlertDialogProps<V, R> = Omit<
  ConfirmAlertDialogProps<V, R>,
  'leastDestructiveRef'
>;

/**
 * An `AlertDialog` to prompt the user to confirm their action when deleting a record.
 * It can be used in conjunction with `useConfirmDialog`.
 */
function DeleteAlertDialog<V, R>({
  title = 'Confirm Delete',
  content = 'Are you sure you want to delete this?',
  isOpen,
  recordId,
  record,
  isSubmitting = false,
  renderDescription,
  onClose,
  onConfirm,
  ...rest
}: DeleteAlertDialogProps<V, R>) {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleConfirmClick = () => {
    // This recordId is optional because it is lazily set when the modal is opened
    // but it is a required parameter for the onConfirm action.
    if (recordId) {
      onConfirm(recordId);
    } else {
      // This error should never actually occur.
      console.error(
        'DeleteAlertDialog confirm failed because no recordId is defined.'
      );
    }
  };

  return (
    <AlertDialog
      {...rest}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader bg="danger.500" color="white">
          {title}
          <AlertDialogCloseButton
            ml="auto"
            position="relative"
            top="auto"
            right="auto"
            _focus={{
              boxShadow: 'outlineDanger600',
            }}
          />
        </AlertDialogHeader>
        <AlertDialogBody>
          <Text>{content}</Text>
          {renderDescription && record && (
            <Text py={3} px={4} bg="danger.50" mt={4} fontSize="sm">
              {renderDescription(record)}
            </Text>
          )}
        </AlertDialogBody>
        <AlertDialogFooter pt={0}>
          <Button
            type="button"
            ref={cancelRef}
            variant="outline"
            colorScheme="gray"
            onClick={onClose}
          >
            Cancel
          </Button>
          <SpinnerButton
            type="button"
            colorScheme="danger"
            ml={3}
            isLoading={isSubmitting}
            onClick={handleConfirmClick}
          >
            Confirm Delete
          </SpinnerButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default DeleteAlertDialog;
