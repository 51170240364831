import { useContext } from 'react';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { AuthenticationController } from 'auth/AuthenticationController';
import routes from 'auth/routes';
import { PostLoginReturnState } from 'auth/ProtectedRoute';
import MagicLinkQueryStoreContext from 'auth/MagicLinkQueryStoreContext';

export type SignupFormValues = {
  email: string;
  agreeEmail: boolean;
};

const defaultValues: SignupFormValues = {
  email: '',
  agreeEmail: true,
};

const emailFormSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Enter your email address')
    .email('Invalid email address'),
});

export default function useSignupForm(): FormHandler<SignupFormValues> {
  const { state } = useLocation<PostLoginReturnState | undefined>();
  const magicLinkState = useContext(MagicLinkQueryStoreContext);

  const history = useHistory();
  return useFormHandler<SignupFormValues>(
    async ({ email, agreeEmail }) => {
      await AuthenticationController.signUp(email, agreeEmail);

      // If we are tracking which location to return to after authentication, we need to elevate
      // that information to local storage so that it is accessible when the user
      // comes back after verifying their email address.
      if (state?.returnTo) {
        magicLinkState.update(state.returnTo);
      }

      history.push(routes.verify, { email });
    },
    defaultValues,
    emailFormSchema,
    true
  );
}
