import React from 'react';
import { Box, Checkbox, InputGroup, Text } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import routes from 'routes';
import { StackedInputField } from 'shared/components/form';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import SpinnerButton from 'shared/components/SpinnerButton';
import { BorderedBox } from 'welcome/styled';
import { PageContainer } from 'shared/components/containers';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import useCreateTeamForm from './useCreateTeamForm';

const CreateTeamScene: React.FC = () => {
  const history = useHistory();
  const business = useCurrentBusiness();
  const { onSubmit, form, error } = useCreateTeamForm(() =>
    history.push(routes.team.invite)
  );

  // Allow personal businesses to be converted into non-personal instead of creating a new team
  const canConvertFromExisting = business.data?.isPersonal;

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <PageContainer p={8}>
          <BorderedBox mr="auto">
            <Box maxWidth="lg">
              <Text as="span" fontSize="sm" color="gray.300">
                Step 1 of 2
              </Text>

              <Box my={4}>
                <Text as="p" fontWeight="bold">
                  What is the name of your team?
                </Text>

                <Text as="p" maxWidth={490}>
                  This will be the name of your XQ workspace.
                </Text>
              </Box>

              <InputGroup mb={4}>
                <StackedInputField
                  name="name"
                  placeholder="(eg. Company Name)"
                />
              </InputGroup>

              {canConvertFromExisting && (
                <InputGroup mb={4}>
                  <Checkbox name="convertFromExisting" ref={form.register}>
                    Add all contacts from my personal dashboard to this new team
                  </Checkbox>
                </InputGroup>
              )}

              <FormErrorAlert error={error} />
              <SpinnerButton
                type="submit"
                isLoading={form.formState.isSubmitting}
              >
                Next
              </SpinnerButton>
            </Box>
          </BorderedBox>
        </PageContainer>
      </form>
    </FormProvider>
  );
};

export default observer(CreateTeamScene);
