import { AxiosInstance } from 'axios';
import authController from 'auth/AuthenticationController';
import {
  InvoiceSummary,
  ListInvoicesResponse,
} from 'backend/api-types/dashboard';
import { dashboardService } from 'backend/services';
import AsyncData from 'cache/AsyncData';
import useAsyncData from 'cache/useAsyncData';

export default class InvoicesController extends AsyncData<
  ListInvoicesResponse,
  void,
  Array<InvoiceSummary>
> {
  protected _axios: AxiosInstance = dashboardService;

  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: ListInvoicesResponse): Array<InvoiceSummary> {
    // Create a copy of the array to avoid sorting in place and change the issue date to milliseconds instead of seconds
    const invoices = data.invoices.map((i) => ({
      ...i,
      issueDate: i.issueDate * 1000,
    }));

    // Sort the invoices by date descending.
    invoices.sort((a, b) => b.issueDate - a.issueDate);
    return invoices;
  }

  constructor() {
    super('invoices', '/invoice');
  }
}

type UseInvoicesOptions = {
  /** Skip the initial fetching of data. */
  skip?: boolean;
};

export function useInvoices(
  options: UseInvoicesOptions = {}
): InvoicesController {
  const { skip = false } = options;
  return useAsyncData<InvoicesController>(
    new InvoicesController(),
    skip || !authController.isLoggedIn
  );
}
