import React, { useEffect } from 'react';
import { Text, Link, Box } from '@chakra-ui/react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import routes from 'routes';
import {
  CenteredLayout,
  CenteredLayoutFooter,
} from 'shared/components/CenteredLayout';
import LoginForm from './LoginForm';
import authController from '../AuthenticationController';
import { PostLoginReturnState } from '../ProtectedRoute';
import EmailLoginForm from './EmailLoginForm/EmailLoginForm';

const LoginScene: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const qs = new URLSearchParams(location.search.substr(1));

  const manualLogin = qs.has('manual');

  const { state } = useLocation<PostLoginReturnState | undefined>();

  // If the user is already logged in, lets redirect to the home page or where ever they came from
  const { isLoggedIn } = authController;
  useEffect(() => {
    if (isLoggedIn) {
      history.replace(state?.returnTo ?? '');
    }
  }, [history, state, isLoggedIn]);

  // Don't show the login form while we are deciding whether or not the user is logged in.
  // This avoid "flashing" the login form before being redirected away
  return authController.loading ? null : (
    <CenteredLayout showLogo>
      {manualLogin && <LoginForm />}
      {!manualLogin && <EmailLoginForm />}

      <Box mt={8} mb="auto" textAlign="center">
        {manualLogin ? (
          <Link as={RouterLink} to={routes.auth.login}>
            Receive a magic link instead?
          </Link>
        ) : (
          <Link
            as={RouterLink}
            to={{
              pathname: routes.auth.login,
              search: '?manual=1',
              state,
            }}
            replace
          >
            Sign in with a password instead?
          </Link>
        )}
      </Box>

      <CenteredLayoutFooter>
        <Text>
          Don&apos;t have an account yet?{' '}
          <Link
            as={RouterLink}
            to={{
              pathname: routes.auth.signup,
              state,
            }}
          >
            Create one
          </Link>
        </Text>
      </CenteredLayoutFooter>
    </CenteredLayout>
  );
};

export default observer(LoginScene);
