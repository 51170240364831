import React from 'react';
import { Text } from '@chakra-ui/react';
import { format, formatDistance } from 'date-fns';

interface KeyPacketExpiryDateProps {
  expiryDate: number;
  isExpired?: boolean;
}

const KeyPacketExpiryDate: React.FC<KeyPacketExpiryDateProps> = ({
  expiryDate,
}) => {
  const expiryObj = new Date(expiryDate);
  const today = new Date();
  const isExpired = expiryDate < Date.now();
  const expiryDistance = formatDistance(expiryObj, today, { addSuffix: true });
  const expiryHuman = isExpired
    ? `Expired ${expiryDistance}`
    : `Expires ${expiryDistance}`;
  const expiresSoon = expiryHuman.endsWith('hours');

  return (
    <>
      {format(expiryObj, 'LLL d, YYY')}
      <br />
      <Text fontSize="sm" color={expiresSoon ? 'red.600' : 'inherit'}>
        {expiryHuman}
      </Text>
    </>
  );
};

export default KeyPacketExpiryDate;
