/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  List,
  ListItem,
  SimpleGrid,
} from '@chakra-ui/react';
import { AddIcon, CheckIcon, MinusIcon } from '@chakra-ui/icons';
import { useFormContext, Controller } from 'react-hook-form';
import {
  BUSINESS_PLAN_USERCOUNT,
  PlanType,
  PLAN_USER_INCREMENT,
  PLAN_USER_LIMITS,
  readablePlanTypeMap,
} from 'settings/account/plan/constants';
import { adjustUserCount } from 'settings/account/plan/helpers';
import usePlanDetails from './usePlanDetails';

const PLAN_COLORS = {
  [PlanType.Free]: 'blue.400',
  [PlanType.Pro]: 'blue.600',
  [PlanType.Enterprise]: 'blue.800',
};

const PLAN_PRICING_INFO = {
  [PlanType.Free]: 'Per month',
  [PlanType.Pro]: 'Per month - billed annually',
  [PlanType.Enterprise]: 'Annually',
};

interface PlanFeature {
  label: string;
  subFeatures?: (string | React.ReactNode)[];
}

export interface PlanProps extends BoxProps {
  plan: PlanType;
  features: PlanFeature[];
}

const ENTERPRISE_PRICE = '250K';

const Plan: React.FC<PlanProps> = ({ features, plan }) => {
  const { register, watch, setValue, control } = useFormContext();
  const userCount = watch('users');
  const { adjustedPlan, price } = usePlanDetails(
    userCount,
    plan === PlanType.Enterprise
  );
  const isEnterprisePlan = plan === PlanType.Enterprise;
  const isFreePlan = adjustedPlan === PlanType.Free;

  return (
    <>
      <Heading
        as="h2"
        fontSize="3xl"
        mx="auto"
        mb={4}
        p={8}
        background={PLAN_COLORS[adjustedPlan]}
        color="white"
        w="100%"
        textAlign="center"
        whiteSpace="nowrap"
        transition="background .25s ease-out"
      >
        {readablePlanTypeMap[adjustedPlan]}
      </Heading>

      <Flex
        textAlign="center"
        mt={8}
        direction="column"
        px={16}
        minHeight="100px"
        justifyContent="center"
      >
        {isEnterprisePlan ? (
          <Box
            fontWeight="bold"
            fontSize="4xl"
            sx={{
              fontVariantNumeric: 'tabular-nums',
            }}
          >
            ${ENTERPRISE_PRICE}
          </Box>
        ) : (
          <Box
            fontWeight="bold"
            fontSize="4xl"
            sx={{
              fontVariantNumeric: 'tabular-nums',
            }}
          >
            ${price}
          </Box>
        )}
        <Box color="gray.300">{PLAN_PRICING_INFO[plan]}</Box>
      </Flex>

      <SimpleGrid
        as={List}
        p={{ base: 4, md: 8 }}
        columns={{ base: 1, sm: 2, lg: 1, xl: 2 }}
        spacing={6}
      >
        {features.map((feature, i) => (
          <ListItem key={i}>
            <Flex alignItems="flex-start" flexDirection="column">
              <Flex>
                <Icon as={CheckIcon} boxSize={4} mr={4} mt={1} />
                <Box>
                  <Box>{feature.label}</Box>
                  {feature.subFeatures && (
                    <List>
                      {feature.subFeatures.map((f, idx) => (
                        <ListItem color="gray.400" key={idx}>
                          {f}
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Box>
              </Flex>
            </Flex>
          </ListItem>
        ))}
      </SimpleGrid>
      {!isEnterprisePlan && (
        <Flex
          py={{ base: 4, md: 8 }}
          px={{ base: 4, md: 16 }}
          textAlign="center"
          justifyContent="center"
        >
          <Flex flexDirection="column">
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Button
                aria-label="decrease users"
                variant="outline"
                disabled={userCount <= PLAN_USER_LIMITS.min}
                minWidth={{ base: 4, md: 16 }}
                onClick={() =>
                  setValue(
                    'users',
                    adjustUserCount(userCount, PLAN_USER_INCREMENT)
                  )
                }
              >
                <MinusIcon />
              </Button>
              <Box
                textAlign="center"
                fontSize="2xl"
                fontWeight="bold"
                px={4}
                width="150px"
                sx={{
                  fontVariantNumeric: 'tabular-nums',
                }}
              >
                {userCount} Users
              </Box>

              <Button
                aria-label="Increase users"
                variant="outline"
                disabled={userCount >= PLAN_USER_LIMITS.max}
                minWidth={{ base: 4, md: 16 }}
                onClick={() =>
                  setValue(
                    'users',
                    adjustUserCount(userCount, PLAN_USER_INCREMENT, true)
                  )
                }
              >
                <AddIcon />
              </Button>
            </Flex>

            <Box transition="opacity .25s ease-out" my={4}>
              {isFreePlan
                ? `Free plan allows up to ${BUSINESS_PLAN_USERCOUNT - 1} users`
                : 'Business plans increment in blocks of 5'}
            </Box>
          </Flex>

          <Controller
            display="none"
            name="users"
            control={control}
            as={
              <InputGroup alignItems="center" mx="auto" justifyContent="center">
                <Input
                  type="number"
                  required
                  maxWidth="100px"
                  textAlign="center"
                  size="lg"
                  fontSize={24}
                  mx={4}
                  value={userCount}
                  ref={register}
                  onBlur={() =>
                    setValue(
                      'users',
                      adjustUserCount(userCount, PLAN_USER_INCREMENT, true)
                    )
                  }
                  display="none"
                />
              </InputGroup>
            }
          />
        </Flex>
      )}
    </>
  );
};

export default Plan;
