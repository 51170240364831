import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import StackedInput, { StackedInputProps } from './StackedInput';

interface StackedInputFieldProps extends StackedInputProps {
  initialFocusRef?: React.RefObject<HTMLInputElement>;
}

const StackedInputField: React.FC<StackedInputFieldProps> = ({
  name,
  initialFocusRef = null,
  ...props
}) => {
  const { errors, register, control } = useFormContext();
  const fieldError = name && errors[name];

  const commonProps: Partial<StackedInputProps> = {
    ...props,
    name,
    isInvalid: !!fieldError,
    errorText: fieldError?.message,
  };

  if (initialFocusRef) {
    return (
      <Controller
        name={name ?? ''}
        control={control}
        as={<StackedInput {...commonProps} ref={initialFocusRef} />}
      />
    );
  }

  return <StackedInput {...commonProps} ref={register} />;
};

export default StackedInputField;
