import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';
import RestrictedRoute from 'auth/RestrictedRoute';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import routes from './routes';
import ListContactsScene from './list/ListContactsScene';
import ContactDetailsScene from './detail/ContactDetailsScene';

const ContactRoutes: React.FC = () => {
  const user = useCurrentUser();
  return (
    <Switch>
      <RestrictedRoute
        path={routes.root}
        exact
        canAccess={user.access.canViewContacts}
      >
        <ListContactsScene />
      </RestrictedRoute>
      <Route
        path={[routes.detail.activity(), routes.detail.communications()]}
        exact
      >
        <ContactDetailsScene />
      </Route>
    </Switch>
  );
};

export default observer(ContactRoutes);
