import { GetStatsResponse } from 'backend/api-types/dashboard';

export class DashboardAccessControls {
  private _stats?: GetStatsResponse | null = null;

  constructor(stats?: GetStatsResponse | null) {
    this._stats = stats;
  }

  private get hasAtLeastOneCommunication() {
    return this._stats?.communications && this._stats?.communications > 0;
  }

  get canViewActivityFeed() {
    return this.hasAtLeastOneCommunication;
  }

  get canViewCommunications() {
    return this.hasAtLeastOneCommunication;
  }
}

export default DashboardAccessControls;
