import React, { useRef } from 'react';
import { useParams, Link as RouteLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Flex, Link } from '@chakra-ui/react';
import LoadingBox from 'shared/components/LoadingBox';
import ModalState from 'shared/states/ModalState';
import RequestErrorAlert from 'shared/components/RequestErrorAlert';
import { useApplications } from 'application/ApplicationsController';
import { useAPIKeys } from 'api-keys/APIKeysController';
import { useWebhooks } from 'webhooks/WebhooksController';
import EditOrDeleteApplicationModal from 'application/update/EditOrDeleteApplicationModal';
import routes from 'routes';
import ApplicationSection from './ApplicationSection';
import APIKeysSection from './APIKeysSection';
import WebhooksSection from './WebhooksSection';

const ApplicationDetail: React.FC = () => {
  const { current: editAppModalSate } = useRef(new ModalState());
  const { applicationId } = useParams<{
    applicationId: string;
  }>();
  const applications = useApplications();
  const keys = useAPIKeys();
  const webhooks = useWebhooks(Number(applicationId));
  const app = applications.getById(Number(applicationId));

  if (applications.loading || keys.loading || webhooks.loading) {
    return <LoadingBox />;
  }

  const showOnboarding =
    !keys.getByAppId(Number(applicationId))?.length && !keys.error;

  if (!applications.data) {
    return (
      <RequestErrorAlert canTryAgain>
        Could not load your applications
      </RequestErrorAlert>
    );
  }

  // This means the applications have been properly fetched, but none exist with this ID
  if (!app) {
    return (
      <RequestErrorAlert>
        This application does not exist or does not belong to your team.
        <Link as={RouteLink} to={routes.application.root} ml={2}>
          My Applications
        </Link>
      </RequestErrorAlert>
    );
  }

  return (
    <>
      <Flex
        direction="column"
        bg="white"
        width="100%"
        border="1px"
        borderColor="gray.100"
        borderRadius="md"
        flex="1 0 auto"
        overflow="hidden"
      >
        <ApplicationSection
          app={app}
          onOpenEditApplicationModal={editAppModalSate.open}
        />
        <APIKeysSection app={app} showOnboarding={showOnboarding} />
        {!showOnboarding && <WebhooksSection app={app} />}
      </Flex>
      <EditOrDeleteApplicationModal
        app={app}
        isOpen={editAppModalSate.isOpen}
        onClose={editAppModalSate.close}
      />
    </>
  );
};

export default observer(ApplicationDetail);
