import React from 'react';
import { Box, Checkbox, SimpleGrid, Text, Link } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  CenteredLayoutHeading,
  CenteredLayoutContent,
  CenteredLayoutContentInner,
} from 'shared/components/CenteredLayout';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { StackedInputField } from 'shared/components/form';
import SpinnerButton from 'shared/components/SpinnerButton';
import { GoogleButton } from 'shared/components/sso';
import { useGoogleLogin } from 'shared/components/sso/useGoogleLogin';
import { PostLoginReturnState } from 'auth/ProtectedRoute';
import useSignupForm from './useSignupForm';

const SignupForm: React.FC = () => {
  const { state } = useLocation<PostLoginReturnState | undefined>();

  const { onSubmit, form, error } = useSignupForm();
  const { requestGoogleLogin, error: googleError } = useGoogleLogin(
    state?.returnTo
  );
  const { isSubmitting } = form.formState;

  return (
    <FormProvider {...form}>
      <CenteredLayoutContent as="form" onSubmit={onSubmit} noValidate mb={0}>
        <CenteredLayoutHeading mb={4}>Try XQ for free</CenteredLayoutHeading>

        <Text textAlign="center" mb={8}>
          We suggest using the email address you use for work
        </Text>

        <CenteredLayoutContentInner>
          <FormErrorAlert error={error || googleError} />

          <SimpleGrid spacing={4}>
            <GoogleButton onClick={requestGoogleLogin} />

            <Text textAlign="center">or</Text>

            <StackedInputField
              id="email"
              name="email"
              type="email"
              label="Email address"
              placeholder="jane@company.org"
            />

            <Box mt={4}>
              <Checkbox name="agreeEmail" ref={form.register}>
                It&apos;s OK to send me emails about XQ
              </Checkbox>
            </Box>

            <SpinnerButton
              type="submit"
              size="lg"
              isLoading={isSubmitting}
              role="button"
            >
              Continue
            </SpinnerButton>
          </SimpleGrid>
        </CenteredLayoutContentInner>
      </CenteredLayoutContent>

      <Box mt={8} mx="auto" px={6} textAlign="center">
        <Text>
          By continuing, I agree to the XQ{' '}
          <Link
            href="https://xqmsg.co/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </Link>{' '}
          and{' '}
          <Link
            href="https://xqmsg.co/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms of service
          </Link>
          .
        </Text>
      </Box>
    </FormProvider>
  );
};

export default SignupForm;
