import * as yup from 'yup';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { useAPIKeys } from 'api-keys/APIKeysController';
import { useCallback } from 'react';

export type APIKeyFormValues = {
  name: string;
};

const apiKeyFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

const defaultValues: APIKeyFormValues = {
  name: '',
};

export default function useAPIKeyForm(
  appId: number,
  onSuccess: (apiKey: string) => void
): FormHandler<APIKeyFormValues> {
  const apiKeys = useAPIKeys();
  const createAPIKey = useCallback(
    async (values: APIKeyFormValues) => {
      const res = await apiKeys.addKey(appId, values.name);
      onSuccess(res.key);
    },
    [appId, apiKeys, onSuccess]
  );

  return useFormHandler<APIKeyFormValues>(
    createAPIKey,
    defaultValues,
    apiKeyFormSchema
  );
}
