import React from 'react';
import { Text, Link } from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import routes from 'routes';
import {
  CenteredLayout,
  CenteredLayoutFooter,
} from 'shared/components/CenteredLayout';
import { PostLoginReturnState } from 'auth/ProtectedRoute';
import SignupForm from './SignupForm';

const SignupScene: React.FC = () => {
  const { state } = useLocation<PostLoginReturnState | undefined>();

  return (
    <CenteredLayout showLogo>
      <SignupForm />

      <CenteredLayoutFooter>
        <Text>
          Already have an account?{' '}
          <Link
            as={RouterLink}
            to={{
              pathname: routes.auth.login,
              state,
            }}
          >
            Log in
          </Link>
        </Text>
      </CenteredLayoutFooter>
    </CenteredLayout>
  );
};

export default SignupScene;
