import React from 'react';
import {
  Box,
  BoxProps,
  Heading,
  HeadingProps,
  Flex,
  FlexProps,
} from '@chakra-ui/react';

export const Section: React.FC<BoxProps> = (props) => {
  return (
    <Box
      as="section"
      py={8}
      px={6}
      borderBottom="1px"
      borderColor="gray.100"
      {...props}
    />
  );
};

export const SectionHeading: React.FC<HeadingProps> = (props) => {
  return <Heading as="h2" fontSize="xl" mb={6} {...props} />;
};

export const DT: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      as="dt"
      display="inline-flex"
      pr={4}
      alignItems="center"
      mt={2}
      _first={{
        mt: 0,
      }}
      {...props}
    >
      {children}:
    </Flex>
  );
};

export const DD: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      as="dd"
      display="inline-flex"
      fontWeight="semibold"
      mt={2}
      _first={{
        mt: 0,
      }}
      {...props}
    >
      {children}
    </Flex>
  );
};
