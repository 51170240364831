import { useCallback } from 'react';

export default function useDownloadFile() {
  return useCallback((blob: Blob, name: string) => {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = name;
    document.body.appendChild(element); // For firefox support
    element.click();
    document.body.removeChild(element);
  }, []);
}
