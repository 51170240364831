import applicationRoutes from './application/routes';
import authRoutes from './auth/routes';
import contactRoutes from './contacts/routes';
import { routes as documentationRoutes } from './docs/pages';
import encryptionRoutes from './encryption/routes';
import eventLogRoutes from './event-log/routes';
import exportRoutes from './export/routes';
import keyPacketRoutes from './key-packet/routes';
import settingsRoutes from './settings/routes';
import teamRoutes from './team/routes';
import tutorialRoutes from './tutorials/routes';
import unlockRoutes from './unlock/routes';
import welcomeRoutes from './welcome/routes';

export default {
  home: '/',
  auth: authRoutes,
  application: applicationRoutes,
  tutorials: tutorialRoutes,
  docs: documentationRoutes,
  eventLog: eventLogRoutes,
  settings: settingsRoutes,
  contacts: contactRoutes,
  keyPacket: keyPacketRoutes,
  welcome: welcomeRoutes,
  team: teamRoutes,
  encryption: encryptionRoutes,
  unlock: unlockRoutes,
  export: exportRoutes,
} as const;
