import { AxiosInstance } from 'axios';
import bind from 'bind-decorator';
import { action } from 'mobx';
import authController from 'auth/AuthenticationController';
import {
  dashboardService,
  subscriptionService,
  validationService,
} from 'backend/services';
import {
  DeleteKeyResponse,
  GetCommunicationResponse,
} from 'backend/api-types/dashboard';
import AsyncData from '../cache/AsyncData';
import useAsyncData from '../cache/useAsyncData';
import {
  CommunicationStatus,
  CommunicationSummary,
  convertKeyPacketToCommunication,
} from './types';
import {
  GrantUserAccessRequest,
  GrantUserAccessResponse,
  RevokeUserAccessRequest,
  RevokeUserAccessResponse,
} from '../backend/api-types/validation';
import useAsyncAction, { AsyncAction } from '../shared/hooks/useAsyncAction';

export default class CommunicationController extends AsyncData<
  GetCommunicationResponse,
  void,
  CommunicationSummary
> {
  protected _axios: AxiosInstance = dashboardService;

  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: GetCommunicationResponse): CommunicationSummary {
    return convertKeyPacketToCommunication({
      ...data,
      status: data.cstatus,
    });
  }

  constructor(id: number) {
    super(`communication-details:${id}`, `/communication/${id}`);
  }

  @bind
  @action
  public async revoke(): Promise<void> {
    await dashboardService.delete<DeleteKeyResponse>(
      `/key/${window.encodeURIComponent(this._data?.token ?? '')}`
    );

    if (this._data) {
      this._data.status = CommunicationStatus.Revoked;
    }
  }

  @bind
  @action
  public async revokeUser(recipients: string): Promise<void> {
    // If no recipients are provided, fallback and revoke the entire token..
    if (recipients === '*' || recipients.trim().length === 0) {
      return this.revoke();
    }

    // Create the subscriber
    const subscriber = await subscriptionService.get<string>('/exchange', {
      params: {
        request: 'dashboard',
      },
    });
    const splitRecipients = recipients.split(',');
    const requestBody: RevokeUserAccessRequest = {
      recipients: splitRecipients,
    };

    await validationService.patch<RevokeUserAccessResponse>(
      `/revoke/${window.encodeURIComponent(this._data?.token ?? '')}`,
      requestBody,
      {
        headers: {
          // Use the bearer token for the subscriber
          Authorization: `Bearer ${subscriber.data}`,
        },
      }
    );
  }

  @bind
  @action
  public async grantAccess(recipients: string[]): Promise<void> {
    // Create the subscriber
    const subscriber = await subscriptionService.get<string>('/exchange', {
      params: {
        request: 'dashboard',
      },
    });
    const requestBody: GrantUserAccessRequest = {
      recipients,
    };
    await validationService.post<GrantUserAccessResponse>(
      `/grant/${window.encodeURIComponent(this._data?.token ?? '')}`,
      requestBody,
      {
        headers: {
          // Use the bearer token for the subscriber
          Authorization: `Bearer ${subscriber.data}`,
        },
      }
    );
  }
}

export function useRevokeAccess(
  id: number,
  onSuccess?: (data: void) => void,
  onError?: (err: Error) => void
): AsyncAction<void, [string]> {
  const comm = useCommunicationDetails(id);
  return useAsyncAction<void, [string]>(comm.revokeUser, onSuccess, onError);
}

export function useCommunicationDetails(id: number): CommunicationController {
  return useAsyncData<CommunicationController>(
    new CommunicationController(id),
    !authController.data?.sub
  );
}
