import React from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Textarea,
} from '@chakra-ui/react';
import { TextareaFieldProps } from '../types';

export interface StackedTextareaProps extends TextareaFieldProps {
  label?: string;
  helperText?: React.ReactNode;
}

const StackedTextarea = React.forwardRef<
  HTMLTextAreaElement,
  StackedTextareaProps
>(
  (
    { id, label, isInvalid, isRequired, helperText, errorText, ...props },
    _ref
  ) => {
    return (
      <FormControl id={id} isInvalid={isInvalid} isRequired={isRequired}>
        {label && <FormLabel>{label}</FormLabel>}
        <Textarea ref={_ref} {...props} />
        {isInvalid ? (
          <FormErrorMessage>{errorText}</FormErrorMessage>
        ) : (
          helperText && <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default StackedTextarea;
