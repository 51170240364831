import React from 'react';
import { Flex, FlexProps, Heading } from '@chakra-ui/react';
import bgDecoration from 'assets/svg/bg-decoration.svg';

interface NoResultsBoxProps extends FlexProps {
  heading?: string;
}

const NoResultsBox: React.FC<NoResultsBoxProps> = ({
  heading = 'No results found',
  children,
  ...props
}) => {
  return (
    <Flex
      direction="column"
      justify="center"
      alignItems="center"
      pt={24}
      pb={32}
      px={16}
      textAlign="center"
      color="black"
      bgImage={`url(${bgDecoration})`}
      bgPosition="right 8px bottom 8px"
      bgRepeat="no-repeat"
      {...props}
    >
      {heading && <Heading fontSize="2xl">{heading}</Heading>}
      {children}
    </Flex>
  );
};

export default NoResultsBox;
