import React from 'react';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { Link, Link as RouterLink } from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react';
import { BusinessOverview } from 'backend/api-types/dashboard';
import UserProfile from 'shared/components/UserProfile/UserProfile';
import useNavigate from 'shared/hooks/useNavigate';
import routes from 'routes';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { PlanType } from 'settings/account/plan/constants';
import { useContacts } from 'contacts/ContactsController';
import SwitchTeamMenu from './SwitchTeamMenu';

export interface ToolbarProps {
  onToggle: () => void;
  handleAuthenticationClick: () => void;
  isOpen?: boolean;
  isLoggingOut: boolean;
  isLoggedIn: boolean;
  companyName?: string;
  showCreateTeam?: boolean;
  teams: BusinessOverview[] | null;
}

const Toolbar: React.FC<ToolbarProps> = ({
  onToggle,
  handleAuthenticationClick,
  isOpen,
  isLoggingOut,
  isLoggedIn,
  companyName,
  teams = null,
  showCreateTeam = false,
  children,
}) => {
  const goToTeamCreation = useNavigate(routes.team.create);
  const currentUser = useCurrentUser();
  const contacts = useContacts();
  const business = useCurrentBusiness();
  const showSettings =
    currentUser.access.canViewOrganizationSettings ||
    currentUser.access.canViewBilling;

  const canUpgrade =
    currentUser.access.canModifyPlan &&
    business.data?.subscription.plan === PlanType.Free &&
    contacts.data &&
    contacts.data.length > 1;

  const displayName =
    currentUser.data?.firstName || currentUser.data?.email || '';
  const showSwitchTeamMenu = companyName && !showCreateTeam;
  return (
    <Flex
      as="header"
      alignItems="center"
      px={6}
      boxShadow="0px 2px 10px rgba(0, 0, 0, 0.035)"
      borderRadius="lg"
      bg="white"
      height={16}
      position="relative"
      display="flex"
      flexDirection="row-reverse"
    >
      <IconButton
        size="sm"
        ml={4}
        variant="unstyled"
        aria-label="menu"
        display={{ base: 'block', lg: 'none' }}
        type="button"
        onClick={onToggle}
        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        _focus={{
          boxShadow: 'none',
        }}
      />
      <Flex justifyContent="space-between" width="100%" alignItems="center">
        <Flex flex="1 1 auto">{children}</Flex>
        <Flex
          flex="1 1 auto"
          flexWrap="nowrap"
          alignItems="center"
          display={{ base: 'none', md: 'flex' }}
        >
          {isLoggedIn ? (
            <Flex
              flex="1 1 auto"
              alignItems="center"
              justifyContent="flex-end"
              zIndex="dropdown"
            >
              {canUpgrade && (
                <Button
                  as={Link}
                  size="xs"
                  variant="outline"
                  mr={4}
                  colorScheme="green"
                  to={routes.settings.plans}
                >
                  Upgrade
                </Button>
              )}

              <Menu autoSelect={false}>
                <MenuButton mr={6}>
                  <UserProfile name={displayName} image={currentUser.avatar} />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={RouterLink}
                    to={routes.contacts.detail.activity(currentUser.data?.id)}
                    textDecoration="none"
                  >
                    My Profile
                  </MenuItem>
                  {showSettings && (
                    <>
                      <MenuItem
                        as={RouterLink}
                        to={routes.settings.billing}
                        textDecoration="none"
                      >
                        Billing
                      </MenuItem>
                      <MenuItem
                        as={RouterLink}
                        to={routes.settings.root}
                        textDecoration="none"
                      >
                        Settings
                      </MenuItem>
                    </>
                  )}
                  <MenuItem onClick={handleAuthenticationClick}>
                    Logout
                    {isLoggingOut && <Spinner size="xs" ml={2} />}
                  </MenuItem>
                </MenuList>
              </Menu>
              {showCreateTeam && (
                <Button
                  variant="outline"
                  size="xs"
                  type="button"
                  onClick={goToTeamCreation}
                >
                  Create Team
                </Button>
              )}
              {showSwitchTeamMenu && (
                <SwitchTeamMenu currentTeam={companyName} teams={teams} />
              )}
            </Flex>
          ) : (
            <Button
              display={{ base: 'none', lg: 'flex' }}
              type="button"
              variant="link"
              colorScheme="gray"
              onClick={handleAuthenticationClick}
              ml="auto"
            >
              Login
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default observer(Toolbar);
