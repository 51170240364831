import React from 'react';
import {
  ModalBody,
  Heading,
  Text,
  Icon,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { PseudoAccordionButton } from 'shared/components/accordion';
import { ApplicationSummary } from 'application/ApplicationsController';
import DeleteApplicationForm from './DeleteApplicationForm';

type DeleteApplicationSectionProps = {
  app: ApplicationSummary;
};

const DeleteApplicationSection: React.FC<DeleteApplicationSectionProps> = ({
  app,
}) => {
  const deleteDisclosure = useDisclosure();
  return (
    <>
      <PseudoAccordionButton
        isExpanded={deleteDisclosure.isOpen}
        onClick={deleteDisclosure.onToggle}
      >
        Delete Application
      </PseudoAccordionButton>
      <Collapse in={deleteDisclosure.isOpen}>
        <ModalBody pt={6}>
          <Heading
            display="flex"
            alignItems="center"
            fontSize="inherit"
            textTransform="uppercase"
          >
            <Icon
              as={HiOutlineExclamationCircle}
              flex="none"
              mr={1}
              boxSize="1.0675rem"
              color="danger.500"
            />
            Danger Zone
          </Heading>
          <Text color="gray.700" mt={1} mb={4}>
            Once deleted, you cannot retrieve an application or its data.
          </Text>
          <DeleteApplicationForm
            app={app}
            isExpanded={deleteDisclosure.isOpen}
          />
        </ModalBody>
      </Collapse>
    </>
  );
};

export default DeleteApplicationSection;
