import React from 'react';
import { Text } from '@chakra-ui/react';
import {
  CenteredLayoutHeading,
  CenteredLayoutContent,
  CenteredLayoutContentInner,
} from 'shared/components/CenteredLayout';
import SpinnerButton from 'shared/components/SpinnerButton';
import { useResendVerify } from './useResendVerify';
import VerifyError from './VerifyError';

const Verify: React.FC = () => {
  const { email, handleResend, sending } = useResendVerify();

  if (!email) {
    return <VerifyError />;
  }

  return (
    <CenteredLayoutContent>
      <CenteredLayoutHeading>
        Check your email to confirm your account
      </CenteredLayoutHeading>

      <CenteredLayoutContentInner maxWidth="2xl" textAlign="center">
        <Text mb={4}>
          We&apos;ve sent an email to <strong>{email}</strong> with instructions
          for confirming your new account.
        </Text>

        <Text mb={8}>
          Didn&apos;t receive an email? Check your junk mail folder or request
          another email from us.
        </Text>

        <SpinnerButton type="button" onClick={handleResend} isLoading={sending}>
          Resend Email
        </SpinnerButton>
      </CenteredLayoutContentInner>
    </CenteredLayoutContent>
  );
};

export default Verify;
