import { UserNotificationStatus, UserRole } from 'auth/user/types';

export type ContactCodeOption = [label: string, value: UserNotificationStatus];
export type RoleCodeOption = [label: string, value: UserRole];

export const RolesCodes: RoleCodeOption[] = [
  ['Super User', UserRole.SuperUser],
  ['Admin', UserRole.Admin],
  ['User', UserRole.User],
  ['Vendor', UserRole.Vendor],
  ['Customer', UserRole.Customer],
];

export const NotificationsCodes: ContactCodeOption[] = [
  ['None', UserNotificationStatus.None],
  ['Warnings and alerts', UserNotificationStatus.WarningsAndAlerts],
  ['Alerts only', UserNotificationStatus.AlertsOnly],
];
