import axios, { AxiosError, AxiosStatic, AxiosInstance } from 'axios';
import AsyncObservable from './AsyncObservable';

export type AxiosHeaders = Record<string, string>;

export default abstract class AsyncData<
  ResponseType = unknown,
  RequestType = unknown,
  // Use this data type to represent the actual data. Only specify if it is different
  // then the response type and if you also provide a _mapData function
  DataType = ResponseType
> extends AsyncObservable<DataType, AxiosError> {
  // The unique key for the data to be cached under
  public key: string;

  // The request path for the variable
  protected _path: string;

  // The request parameters
  protected _requestParams?: RequestType;

  protected _headers?: AxiosHeaders;

  // The axios instance to use
  protected _axios: AxiosStatic | AxiosInstance = axios;

  constructor(key: string, path: string, params?: RequestType) {
    super();

    this.key = key;
    this._path = path;
    this._requestParams = params;
  }

  protected async _execute(): Promise<DataType> {
    const req = await this._axios.get<ResponseType>(this._path, {
      params: this._requestParams,
      headers: this._headers,
    });

    return this._mapData(req.data);
  }

  /**
   * NOTE:
   * This method can not be static because it needs to use the generic types.
   * Implementing classes will probably see an eslint error regarding 'this' not being used,
   * do not disable the error globally (in .eslintrc.js) since react-scripts uses it's own
   * configuration and will fail to build the application. Instead, disable the rule at the line level.
   */
  protected abstract _mapData(data: ResponseType): DataType;
}

// The Simplest implementation of AsyncData where that return type and data type are the same
export class SimpleAsyncData<
  ResponseType = unknown,
  RequestType = unknown
> extends AsyncData<ResponseType, RequestType> {
  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: ResponseType): ResponseType {
    return data;
  }
}
