/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CloseButton, useTheme } from '@chakra-ui/react';
import React from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import './react-multi-email.css';

export interface MultiEmailProps {
  onChange?: (emails: string[]) => void;
  emails?: string[];
}

const MultiEmail: React.FC<MultiEmailProps> = (props) => {
  const { colors, radii, space } = useTheme();

  const styleObject = {
    border: 'solid 1px',
    borderColor: colors.gray[200],
    borderRadius: radii.md,
    paddingLeft: space[1],
    paddingRight: space[1],
    minHeight: space[12],
    alignContent: 'center',
  };

  return (
    <ReactMultiEmail
      {...props}
      style={styleObject}
      placeholder="Enter email address"
      getLabel={(
        email: string,
        index: number,
        removeEmail: (index: number) => void
      ) => {
        return (
          <div data-tag key={index}>
            {email}
            <CloseButton
              size="sm"
              borderRadius="xl"
              onClick={() => removeEmail(index)}
              ml={1}
              _hover={{ bg: 'blue.100' }}
            />
          </div>
        );
      }}
    />
  );
};

export default MultiEmail;
