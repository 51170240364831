import React from 'react';
import { TabList, Tab } from '@chakra-ui/react';
import ThreatIcon, { ThreatIconProps } from 'activity/components/ThreatIcon';

const TabIcon: React.FC<ThreatIconProps> = (props) => (
  <ThreatIcon
    boxSize={5}
    mr={1}
    top="-1px"
    position="relative"
    showTooltip={false}
    {...props}
  />
);

const ThreatGroupTabList: React.FC = () => {
  return (
    <TabList flexDirection={{ base: 'column', sm: 'row' }}>
      <Tab>All</Tab>
      <Tab>
        <TabIcon threatGroup="good" />
        Good
      </Tab>
      <Tab>
        <TabIcon threatGroup="warn" />
        Warnings
      </Tab>
      <Tab>
        <TabIcon threatGroup="threat" />
        Needs Attention
      </Tab>
    </TabList>
  );
};

export default ThreatGroupTabList;
