import React, { useCallback, useContext, useMemo } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import useTutorialChapters, {
  UseTutorialChaptersReturn,
} from '../useTutorialChapters';

type EncryptTutorialContextProps = {
  subscriberAccessToken: string;
  entropy: string;
  encryptionKey: string;
  keyPacket: string;
  locatorToken: string;
  cipherText: string;
  setSubscriberAccessToken: (id: string) => void;
  setEntropy: (entropy: string) => void;
  setEncryptionKey: (key: string) => void;
  setKeyPacket: (keyPacket: string) => void;
  setLocatorToken: (token: string) => void;
  setCipherText: (text: string) => void;
  reset: () => void;
} & Pick<UseTutorialChaptersReturn, 'chapterResults' | 'storeResult'>;

const EncryptTutorialContext = React.createContext(
  {} as EncryptTutorialContextProps
);

const makeKey = (key: string) => `tutorial.encrypt.${key}`;

export const EncryptTutorialProvider: React.FC = ({ children }) => {
  const {
    chapterResults,
    setChapterResults,
    storeResult,
  } = useTutorialChapters(makeKey('chapterResults'));
  const [
    subscriberAccessToken,
    setSubscriberAccessToken,
  ] = useLocalStorageState(makeKey('subscriberAccessToken'), '');
  const [entropy, setEntropy] = useLocalStorageState(makeKey('entropy'), '');
  const [encryptionKey, setEncryptionKey] = useLocalStorageState(
    makeKey('encryptionKey'),
    ''
  );
  const [keyPacket, setKeyPacket] = useLocalStorageState(
    makeKey('keyPacket'),
    ''
  );
  const [locatorToken, setLocatorToken] = useLocalStorageState(
    makeKey('locatorToken'),
    ''
  );
  const [cipherText, setCipherText] = useLocalStorageState(
    makeKey('cipherText'),
    ''
  );

  const reset = useCallback(() => {
    setSubscriberAccessToken.reset();
    setEntropy.reset();
    setEncryptionKey.reset();
    setKeyPacket.reset();
    setLocatorToken.reset();
    setCipherText.reset();
    setChapterResults.reset();
  }, [
    setSubscriberAccessToken,
    setEntropy,
    setEncryptionKey,
    setKeyPacket,
    setLocatorToken,
    setCipherText,
    setChapterResults,
  ]);

  const contextValue: EncryptTutorialContextProps = useMemo(() => {
    return {
      subscriberAccessToken,
      entropy,
      encryptionKey,
      keyPacket,
      locatorToken,
      cipherText,
      chapterResults,
      setSubscriberAccessToken,
      setEntropy,
      setEncryptionKey,
      setKeyPacket,
      setLocatorToken,
      setCipherText,
      storeResult,
      reset,
    };
  }, [
    subscriberAccessToken,
    entropy,
    encryptionKey,
    keyPacket,
    locatorToken,
    cipherText,
    chapterResults,
    setSubscriberAccessToken,
    setEntropy,
    setEncryptionKey,
    setKeyPacket,
    setLocatorToken,
    setCipherText,
    storeResult,
    reset,
  ]);

  return (
    <EncryptTutorialContext.Provider value={contextValue}>
      {children}
    </EncryptTutorialContext.Provider>
  );
};

export function useEncryptTutorial() {
  return useContext(EncryptTutorialContext);
}

export default EncryptTutorialContext;
