import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

/**
 * Defines the maximum width of a page and center aligns it.
 */
export const PageContainer: React.FC<BoxProps> = (props) => (
  <Box width="100%" maxWidth="6xl" mx="auto" {...props} />
);

/**
 * Uses PageContainer to create a page style with background, padding and borders
 * that stretches the full height of the layout.
 */
export const PageBox: React.FC<BoxProps> = ({ children, ...props }) => (
  <PageContainer
    border="1px"
    borderColor="gray.100"
    borderRadius="md"
    flex={1}
    p={6}
    bg="white"
    {...props}
  >
    {children}
  </PageContainer>
);
