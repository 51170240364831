import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Heading, Link, ListItem, UnorderedList } from '@chakra-ui/react';
import { Waypoint } from 'react-waypoint';
import CodeView, { Code } from 'shared/components/CodeView';
import ScrollSpyContext from './ScrollSpyContext';

function scrollable(Component: React.ElementType) {
  return (props: PropsWithChildren<{ id?: string }>): JSX.Element => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { hash } = useLocation();

    const { id } = props;
    useEffect(() => {
      if (hash === `#${id}` && !!ref.current) {
        ref.current.scrollIntoView();
      }

      // Only do this on mount because it is only for maintaining
      // the scroll position on refresh
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { onEnter, onExit } = useContext(ScrollSpyContext);

    if (!id) {
      // Make sure we aren't trying to create anchors for random components since the id would be missing
      throw new Error(
        'This scrollable factory should only be used for header components in the markdown content'
      );
    }

    return (
      <Waypoint
        onEnter={(args) => onEnter(id, args)}
        onLeave={(args) => onExit(id, args)}
        bottomOffset="50%"
      >
        <div ref={ref}>
          <Component {...props} />
        </div>
      </Waypoint>
    );
  };
}

const StyledParagraph: React.FC = (props) => <Box as="p" mb={6} {...props} />;

const StyledH1: React.FC = (props) => (
  <Heading as="h1" size="2xl" mb={6} {...props} />
);

const StyledH2: React.FC = (props) => (
  <Heading as="h2" size="xl" mb={6} {...props} />
);

const StyledH3: React.FC = (props) => (
  <Heading as="h3" size="lg" mb={6} {...props} />
);

const StyledLink: React.FC = (props) => <Link {...props} />;

const StyledUl: React.FC = ({ children }) => {
  return <UnorderedList>{children}</UnorderedList>;
};

const StyledLi: React.FC = ({ children }) => {
  return <ListItem>{children}</ListItem>;
};

const MDXComponentMap = {
  h1: StyledH1,
  h2: scrollable(StyledH2),
  h3: scrollable(StyledH3),
  p: StyledParagraph,
  a: StyledLink,
  ul: StyledUl,
  li: StyledLi,
  CodeView,
  Code,
};

export default MDXComponentMap;
