import { Link } from '@chakra-ui/react';
import React from 'react';
import routes from 'routes';
import { ChaptersDict } from '../types';

const {
  tutorials: { decrypt, encrypt },
} = routes;

export default {
  0: {
    name: 'What to expect in this tutorial',
    to: decrypt.chapter0,
  },
  1: {
    name: 'Fetching your encryption key',
    to: decrypt.chapter1,
    tocContent: (
      <>
        In the tutorial{' '}
        <Link href={encrypt.chapter0} target="_blank">
          encrypting a message
        </Link>{' '}
        we were given a locator token for our key and a authorization token for
        our user. With this information we will fetch the encryption key from
        XQ.
      </>
    ),
  },
  2: {
    name: 'Decrypting your message',
    to: decrypt.chapter2,
    tocContent: (
      <>
        Now with our encrypted message and our key we will be able to decrypt
        our message.
      </>
    ),
  },
} as ChaptersDict;
