import React, { useEffect, useRef } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { HookSummary } from 'backend/api-types/dashboard';
import WebhookFormFields from './WebhookForm/WebhookFormFields';
import useWebhookForm from './WebhookForm/useWebhookForm';

interface CreateWebhookModalProps {
  appId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (webhook: HookSummary) => void;
}

const CreateWebhookModal: React.FC<CreateWebhookModalProps> = ({
  appId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const { onSubmit, form, error } = useWebhookForm(appId, onSuccess);
  const { isSubmitting } = form.formState;
  const { reset } = form;

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size="3xl"
      initialFocusRef={cancelButtonRef}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <form onSubmit={onSubmit} noValidate>
            <ModalHeader>Add a new webhook</ModalHeader>
            <ModalCloseButton size="md" color="white" right={6} top={6} />

            {!!error && (
              <ModalBody>
                <FormErrorAlert error={error} />
              </ModalBody>
            )}

            <WebhookFormFields />

            <ModalFooter pt={8} pb={8}>
              <Button
                type="button"
                variant="outline"
                colorScheme="gray"
                mr={3}
                onClick={onClose}
                ref={cancelButtonRef}
              >
                Cancel
              </Button>
              <SpinnerButton type="submit" isLoading={isSubmitting}>
                Save
              </SpinnerButton>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default CreateWebhookModal;
