import {
  BUSINESS_PLAN_USERCOUNT,
  PlanType,
  PLAN_PRICE_PER_UNIT,
} from 'settings/account/plan/constants';

function usePlanDetails(
  seats: number,
  isEnterprise?: boolean
): { adjustedPlan: PlanType; price: number | undefined } {
  let price;
  let adjustedPlan;

  if (isEnterprise) {
    adjustedPlan = PlanType.Enterprise;
  } else if (seats >= BUSINESS_PLAN_USERCOUNT) {
    adjustedPlan = PlanType.Pro;
  } else {
    adjustedPlan = PlanType.Free;
  }

  switch (adjustedPlan) {
    case PlanType.Free:
      price = 0;
      break;
    case PlanType.Pro:
      price = PLAN_PRICE_PER_UNIT * seats;
      break;
    default:
      price = undefined;
  }

  return { adjustedPlan, price };
}

export default usePlanDetails;
