import { theme } from '@chakra-ui/react';
import React, { createContext } from 'react';
import { StoreBuilder, AppStore } from 'redoc';

export const SubscriptionReferencesContext = createContext<
  AppStore | undefined
>(undefined);

export const ValidationReferencesContext = createContext<AppStore | undefined>(
  undefined
);

export const QuantumReferencesContext = createContext<AppStore | undefined>(
  undefined
);

type ReferencesProviderProps = {
  url: string;
  Context: React.Context<AppStore | undefined>;
};

const ReferencesProvider: React.FC<ReferencesProviderProps> = ({
  url,
  Context,
  children,
}) => {
  return (
    <StoreBuilder
      specUrl={url}
      options={{
        theme: {
          breakpoints: {
            small: theme.breakpoints.sm,
            medium: theme.breakpoints.md,
            large: theme.breakpoints.lg,
          },
          colors: {
            primary: {
              main: 'hsl(209, 100%, 50%)',
              light: 'hsl(209, 100%, 70%)',
              dark: 'hsl(209, 100%, 20%)',
              contrastText: 'white',
            },
            success: {
              main: 'hsl(140, 42%, 36%)',
              light: 'hsl(141, 64%, 80%)',
              dark: 'hsl(141, 42%, 24%)',
              contrastText: 'black',
            },
            error: {
              main: 'hsl(0, 76%, 57%)',
              light: 'hsl(0, 95%, 75%)',
              dark: 'hsl(0, 54%, 33%)',
              contrastText: 'black',
            },
            warning: {
              main: 'hsl(24, 75%, 50%)',
              light: 'hsl(38, 93%, 77%)',
              dark: 'hsl(16, 65%, 37%)',
              contrastText: 'black',
            },
          },
          typography: {
            fontFamily: `proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
            headings: {
              fontFamily: `proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
              fontWeight: '600',
            },
          },
          codeBlock: {
            backgroundColor: 'hsl(209, 100%, 20%)',
          },
          rightPanel: {
            backgroundColor: '#001A33',
            width: '40%',
          },
        },
      }}
    >
      {({ store }) => {
        return <Context.Provider value={store}>{children}</Context.Provider>;
      }}
    </StoreBuilder>
  );
};

const AllReferencesProvider: React.FC = ({ children }) => {
  return (
    <ReferencesProvider
      url="/swagger/subscription-schema.yml"
      Context={SubscriptionReferencesContext}
    >
      <ReferencesProvider
        url="/swagger/validation-schema.yml"
        Context={ValidationReferencesContext}
      >
        <ReferencesProvider
          url="/swagger/quantum-schema.yml"
          Context={QuantumReferencesContext}
        >
          {children}
        </ReferencesProvider>
      </ReferencesProvider>
    </ReferencesProvider>
  );
};

export default AllReferencesProvider;
