import googleImage from './google.svg';
import gmailImage from './gmail.svg';
import outlookImage from './outlook.svg';
import slackImage from './slack.svg';

type ProductConfig = {
  link: string;
  image: string;
  name: string;
};

export const productsConfig: Record<string, ProductConfig> = {
  googleSuite: {
    link:
      'https://gsuite.google.com/marketplace/app/xq_secure_email/293580994869',
    image: googleImage,
    name: 'GSuite',
  },
  gmail: {
    link:
      'https://chrome.google.com/webstore/detail/xq-secure-messaging-for-g/fldhbblbhbmilgnkklhlfablfiahlhke',
    image: gmailImage,
    name: 'Gmail',
  },
  outlook: {
    link: 'https://appsource.microsoft.com/en-us/product/office/WA200000090',
    image: outlookImage,
    name: 'Outlook',
  },
  slack: {
    link: 'https://xqmsg.com/product/product_xq_slack_app.php',
    image: slackImage,
    name: 'Slack',
  },
};
