import React from 'react';
import { format } from 'date-fns';
import { Box, Flex, Link, Text, Icon } from '@chakra-ui/react';
import { IoMdDownload } from 'react-icons/io';
import { InvoiceSummary } from 'backend/api-types/dashboard';
import { centsToDollars } from 'shared/utils/formatters';

type InvoiceLineItemProps = {
  invoice: InvoiceSummary;
};

const InvoiceLineItem: React.FC<InvoiceLineItemProps> = ({ invoice }) => {
  return (
    <Flex mb={2} alignItems="center">
      <Text mr={4}>
        {format(invoice.issueDate, 'MMMM dd, yyyy')}:
        <Box as="span" fontWeight="semibold" ml={4}>
          {centsToDollars(invoice.total)}
        </Box>
      </Text>

      <Link href={invoice.url}>
        <Icon as={IoMdDownload} boxSize={5} />
      </Link>
    </Flex>
  );
};

export default InvoiceLineItem;
