import * as yup from 'yup';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import {
  ApplicationSummary,
  useApplications,
} from 'application/ApplicationsController';
import { useCallback } from 'react';

export type CreateApplicationFormValues = {
  name: string;
  description: string;
};

const createApplicationFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

const defaultValues: CreateApplicationFormValues = {
  name: '',
  description: '',
};

export default function useCreateApplicationForm(
  onSuccess?: (app: ApplicationSummary) => void
): FormHandler<CreateApplicationFormValues> {
  const applications = useApplications();
  const createApplication = useCallback(
    async (values: CreateApplicationFormValues) => {
      const app = await applications.addApplication({
        name: values.name,
        desc: values.description,
      });

      if (onSuccess) {
        onSuccess(app);
      }
    },
    [applications, onSuccess]
  );

  return useFormHandler<CreateApplicationFormValues>(
    createApplication,
    defaultValues,
    createApplicationFormSchema
  );
}
