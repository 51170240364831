import { useCallback } from 'react';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { CommunicationType } from 'key-packet/types';
import {
  EncryptionAlgorithm,
  quantumKeyForFile,
  storeEncryptionKey,
} from './encryptServices';

class EncryptedFileStreamSource {
  // Required to implement UnderlyingSource interface
  type?: undefined;

  private file: File;

  private key: Uint8Array;

  private token: Uint8Array;

  private keyPos = 0;

  constructor(file: File, key: string, token: string) {
    this.file = file;

    this.key = new TextEncoder().encode(key);
    this.token = new TextEncoder().encode(token);
  }

  encrypt(data: Uint8Array) {
    const result = new Uint8Array(data.length);
    for (let i = 0; i < result.length; i++) {
      // eslint-disable-next-line no-bitwise
      result[i] = data[i] ^ this.key[this.keyPos % this.key.length];
      this.keyPos += 1;
    }
    return result;
  }

  async start(
    controller: ReadableStreamDefaultController<unknown>
  ): Promise<void> {
    // Create the file header
    controller.enqueue(
      new Uint8Array(new Uint32Array([this.token.length]).buffer)
    );
    controller.enqueue(this.token);
    const name = this.encrypt(new TextEncoder().encode(this.file.name));
    this.keyPos = 0;
    controller.enqueue(new Uint8Array(new Uint32Array([name.length]).buffer));
    controller.enqueue(name);

    // Encrypt the file. Wrapping in a "Response" class since Safari does not have support for
    // Blob.arrayBuffer
    const chunk = await new Response(this.file).arrayBuffer();
    controller.enqueue(this.encrypt(new Uint8Array(chunk)));

    // Close the controller once we are done
    controller.close();
  }
}

export async function encryptFile(
  key: string,
  file: File,
  token: string
): Promise<Blob> {
  // Safari has no implementation for file.stream, so we need to implement our own for now.
  const stream = new ReadableStream<Uint8Array>(
    new EncryptedFileStreamSource(file, key, token)
  );

  const response = new Response(stream, {
    headers: { 'Content-Type': 'octet/stream' },
  });

  const blob = await response.blob();
  return blob;
}

export type EncryptedFileData = {
  key: string;
  file: File;
  blob: Blob;
};

type EncryptReturnType = {
  key: string;
  file: File;
  blob: Blob;
};
export function useEncryptFile(
  onSuccess?: (result: EncryptReturnType) => void
) {
  const action = useCallback(
    async (file: File, expireInHours?: number, recipients?: string[]) => {
      const key = await quantumKeyForFile(file.size);
      const locator = await storeEncryptionKey(
        key,
        EncryptionAlgorithm.XOR,
        CommunicationType.File,
        expireInHours,
        recipients,
        {
          title: file.name,
          type: file.type,
        }
      );
      const newBlob = await encryptFile(key, file, locator);
      const result = {
        key,
        file,
        blob: newBlob,
      };

      if (onSuccess) {
        onSuccess(result);
      }
      return result;
    },
    [onSuccess]
  );
  return useAsyncAction(action);
}
