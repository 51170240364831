import React from 'react';
import { Helmet } from 'react-helmet-async';
import { MDXProvider } from '@mdx-js/react';
import { Box, Flex } from '@chakra-ui/react';
import { Contents, FrontMatter } from 'mdx';
import MDXComponentMap from './MDXComponentMap';
import MDXTableOfContents from './MDXTableOfContents';
import { ScrollSpyContextProvider } from './ScrollSpyContext';

interface MDXPageProps {
  Component: React.ElementType;
  frontMatter: FrontMatter;
  tableOfContents: Contents[];
  baseRoute: string;
}

const MDXPage: React.FC<MDXPageProps> = ({
  frontMatter,
  tableOfContents,
  Component,
  baseRoute,
}) => {
  const { title, description, disableTableOfContents = false } = frontMatter;

  return (
    <>
      {/* Set the pages meta tags based on the front matter that has been defined */}
      <Helmet>
        {!!title && <title>{title}</title>}
        {!!description && <meta name="description" content={description} />}
      </Helmet>

      <ScrollSpyContextProvider>
        <MDXProvider components={MDXComponentMap}>
          <Flex
            maxWidth="6xl"
            width="100%"
            flexWrap={{
              base: 'nowrap',
              md: 'wrap',
            }}
            flexDirection={{
              base: 'column',
              md: 'row',
            }}
          >
            <Box
              flex={{ base: 'none', md: '1 1 25%' }}
              order={{ base: 2, md: 1 }}
              pr={[0, 8]}
              mt={[4, 4]}
            >
              <Component />
            </Box>
            {!disableTableOfContents && (
              <Box
                flex={{
                  base: 'none',
                  md: '0 1 300px',
                }}
                order={{ base: 1, md: 2 }}
              >
                <MDXTableOfContents
                  contents={tableOfContents}
                  baseRoute={baseRoute}
                />
              </Box>
            )}
          </Flex>
        </MDXProvider>
      </ScrollSpyContextProvider>
    </>
  );
};

export default MDXPage;
