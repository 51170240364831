import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from 'routes';
import ListApplicationsScene from './list/ListApplicationsScene';
import ApplicationDetail from './detail/ApplicationDetail';

const ApplicationRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.application.root} exact>
        <ListApplicationsScene />
      </Route>
      <Route path={routes.application.detail()}>
        <ApplicationDetail />
      </Route>
    </Switch>
  );
};

export default ApplicationRoutes;
