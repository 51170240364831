import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useMultiStyleConfig, SystemStyleObject } from '@chakra-ui/system';
import AccordionIcon from './AccordionIcon';

type ButtonSystemStyleObject = SystemStyleObject & {
  _expanded: SystemStyleObject;
};

interface PseudoAccordionButtonProps extends ButtonProps {
  isExpanded?: boolean;
}

/**
 * Apply styles to make a generic Button look like an AccordionButton without
 * the component needing to be nested under an Accordion to get access to its
 * StylesProvider.
 */
const PseudoAccordionButton: React.FC<PseudoAccordionButtonProps> = ({
  isExpanded = false,
  children,
  ...props
}) => {
  const styles = useMultiStyleConfig('Accordion', {});
  const { _expanded } = styles.button as ButtonSystemStyleObject;
  return (
    <Button
      __css={styles.button}
      sx={isExpanded ? _expanded : undefined}
      {...props}
    >
      <AccordionIcon isExpanded={isExpanded} />
      {children}
    </Button>
  );
};

export default PseudoAccordionButton;
