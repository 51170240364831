import React from 'react';
import { Box, Button, Wrap, Switch, Tag, Text } from '@chakra-ui/react';
import { HookSummary } from 'backend/api-types/dashboard';
import { Table, TableCell, TableHead, TableRow } from 'shared/components/Table';

export interface WebhooksListProps {
  appId: number;
  webhooks: HookSummary[];
  onDelete: (id: number, record: HookSummary) => void;
  onStatusChange: (id: number, enabled: boolean) => void;
}

const WebhooksList: React.FC<WebhooksListProps> = ({
  webhooks,
  onDelete,
  onStatusChange,
}) => {
  return (
    <Table>
      <TableHead borderTop={0}>
        <TableRow>
          <TableCell colSpan={3}>Callback URL</TableCell>
        </TableRow>
      </TableHead>
      <tbody>
        {webhooks.map((webhook) => {
          const events = webhook.events ? webhook.events.split(' ') : [];
          const isAllEvents = !events.length;
          const eventTags = (isAllEvents ? ['All events'] : events).map(
            (event) => (
              <Tag
                key={event}
                fontFamily="monospace"
                colorScheme={isAllEvents ? 'green' : 'blue'}
                whiteSpace="nowrap"
                mr={2}
                mb={2}
              >
                {event}
              </Tag>
            )
          );
          return (
            <TableRow key={webhook.id}>
              <TableCell pr={0} py={2} verticalAlign="top">
                <Switch
                  mt="6px"
                  size="lg"
                  colorScheme="green"
                  isChecked={webhook.enabled}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onStatusChange(webhook.id, e.target.checked)
                  }
                />
              </TableCell>
              <TableCell width="100%" opacity={webhook.enabled ? 1 : 0.5}>
                <Box mb={1}>{webhook.url}</Box>
                <Wrap display="inline-flex" spacing={2}>
                  <Text as="span" fontSize="sm" fontWeight="bold" mr={2}>
                    Events:
                  </Text>
                  {eventTags}
                </Wrap>
              </TableCell>
              {/* <TableCell pr={0} verticalAlign="top">
                <Button type="button" size="xs" px={3}>
                  Test Callback URL
                </Button>
              </TableCell> */}
              <TableCell textAlign="right" verticalAlign="top">
                <Button
                  variant="link"
                  colorScheme="gray"
                  size="xs"
                  onClick={() => onDelete(webhook.id, webhook)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
};

export default WebhooksList;
