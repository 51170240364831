import React from 'react';
import { Switch } from 'react-router-dom';
import routes from 'routes';
import ProtectedRoute from 'auth/ProtectedRoute';
import EventLog from './list/EventLog';
import EventDetails from './details/EventDetails';

const EventLogRoutes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute path={routes.eventLog.viewLog} exact>
        <EventLog />
      </ProtectedRoute>
      <ProtectedRoute path={routes.eventLog.details()}>
        <EventDetails />
      </ProtectedRoute>
    </Switch>
  );
};

export default EventLogRoutes;
