import React, { useContext } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Heading,
  Text,
  Link,
  Spinner,
  Stack,
  Icon,
} from '@chakra-ui/react';
import { HiCheckCircle } from 'react-icons/hi';
import { FormProvider } from 'react-hook-form';
import { observer } from 'mobx-react';
import routes from 'routes';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import RequestErrorAlert from 'shared/components/RequestErrorAlert';
import { StackedTextareaField } from 'shared/components/form';
import useNavigate from 'shared/hooks/useNavigate';
import EncryptMessageContext from '../EncryptMessageContext';
import { StepHeader, StepContent } from '../components';
import useEncryptMessageForm from './useEncryptMessageForm';

const Send: React.FC = () => {
  const goHome = useNavigate(routes.home);
  const goToFirstStep = useNavigate(routes.encryption.encryptMessage);
  const { goPrev, recipients, subject, reset } = useContext(
    EncryptMessageContext
  );
  const {
    onSubmit,
    form,
    loading,
    error,
    encryptionError,
  } = useEncryptMessageForm();

  const handleGoToDashboard = () => {
    reset();
    goHome();
  };

  const handleComposeAnother = () => {
    reset();
    goToFirstStep();
  };

  const content = form.watch('content');
  const contentForMailto = window.encodeURIComponent(content);
  const hasRecipients = !!(recipients && recipients.length);
  const canSendMessageNow = hasRecipients;
  const mailtoFormattedRecipients = recipients?.join('; ') ?? '';
  const subjectForMailTo = window.encodeURIComponent(subject ?? '');

  if (loading) {
    return (
      <StepHeader>
        <Heading size="sm" mb={2} display="flex" alignItems="flex-start">
          Encrypting your message
          <Spinner
            size="md"
            color="blue.500"
            ml={2}
            position="relative"
            top="-2px"
          />
        </Heading>
      </StepHeader>
    );
  }

  if (encryptionError) {
    return (
      <StepHeader>
        <Heading size="sm" mb={2} display="flex" alignItems="flex-start">
          Failed to encrypt your message
        </Heading>
        <RequestErrorAlert canTryAgain>
          The message has failed to properly encrypt.
        </RequestErrorAlert>
      </StepHeader>
    );
  }

  // Use full width buttons for small screens so they can be stacked.
  const responsiveButtonProps: ButtonProps = {
    display: { base: 'flex', sm: 'inline-flex' },
    width: { base: '100%', sm: 'auto' },
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <StepHeader>
          <Heading size="sm" mb={2}>
            <Box as="span" display="inline-block" position="relative" pr="28px">
              Your message has been encrypted
              <Icon
                as={HiCheckCircle}
                boxSize={6}
                ml={2}
                color="green.600"
                position="absolute"
                right={0}
                top="-3px"
              />
            </Box>
          </Heading>
          <Text>
            Copy this link to your encrypted message or select from the options
            below.
          </Text>
        </StepHeader>
        <StepContent>
          <StackedTextareaField name="content" rows={10} isRequired autoFocus />

          <FormErrorAlert error={error} mt={4} />

          <Stack
            display="flex"
            direction={{ base: 'column', sm: 'row' }}
            justifyContent="flex-end"
            alignItems="center"
            spacing={4}
            my={8}
          >
            <Button
              variant="outline"
              minWidth={32}
              mr="auto"
              onClick={goPrev}
              {...responsiveButtonProps}
            >
              Back
            </Button>
            <Button
              as={Link}
              href={`mailto:${mailtoFormattedRecipients}?body=${contentForMailto}&subject=${subjectForMailTo}`}
              target="_blank"
              rel="noopener noreferrer"
              textDecoration="none"
              transition="none"
              ml="auto"
              variant={canSendMessageNow ? 'outline' : 'solid'}
              _hover={{
                textDecoration: 'none',
                color: 'white',
                bg: 'blue.600',
              }}
              {...responsiveButtonProps}
            >
              Send using my email
            </Button>
            {canSendMessageNow && (
              <SpinnerButton
                type="submit"
                isLoading={form.formState.isSubmitting}
                {...responsiveButtonProps}
              >
                Send Now
              </SpinnerButton>
            )}
          </Stack>

          {!canSendMessageNow && (
            <>
              <Text mb={8}>
                After you have sent your message, you may compose another go to
                the XQ dashboard.
              </Text>

              <Stack
                direction={{ base: 'column', sm: 'row' }}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                spacing={4}
              >
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleComposeAnother}
                  {...responsiveButtonProps}
                >
                  Compose Another
                </Button>
                <Button
                  type="button"
                  onClick={handleGoToDashboard}
                  {...responsiveButtonProps}
                >
                  Go to XQ Dashboard
                </Button>
              </Stack>
            </>
          )}
        </StepContent>
      </form>
    </FormProvider>
  );
};

export default observer(Send);
