import React from 'react';
import { observer } from 'mobx-react';
import { Box, Grid } from '@chakra-ui/react';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import DescriptionPanel from './DescriptionPanel';
import CreateFirstApplication from './CreateFirstApplication';

const OnboardingLayout: React.FC = () => {
  const currentUser = useCurrentUser();
  return (
    <Grid
      gridTemplateColumns={{
        base: 'auto',
        lg: 'minmax(300px, 440px) minmax(340px, auto)',
      }}
      gap={5}
      mt={{ base: 0, lg: 4 }}
      mb="auto"
      mx="auto"
      width="100%"
      maxWidth="6xl"
    >
      <DescriptionPanel name={currentUser.data?.firstName} />
      <Box
        alignSelf="start"
        bg="white"
        border="1px"
        borderColor="gray.100"
        borderRadius="md"
      >
        <CreateFirstApplication />
      </Box>
    </Grid>
  );
};

export default observer(OnboardingLayout);
