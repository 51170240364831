import React from 'react';
import { Text } from '@chakra-ui/react';

interface LocationCellContentProps {
  location?: string;
  ip: string;
}

const LocationCellContent: React.FC<LocationCellContentProps> = ({
  location,
  ip,
}) => {
  return (
    <>
      <Text as="span" whiteSpace="nowrap">
        {location ?? 'Not provided'}
      </Text>
      <br />
      <Text fontSize="sm" sx={{ fontVariantNumeric: 'tabular-nums' }}>
        {ip}
      </Text>
    </>
  );
};

export default LocationCellContent;
