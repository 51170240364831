import React from 'react';
import { Text } from '@chakra-ui/react';
import { format } from 'date-fns';

interface DateCellContentProps {
  date: number;
}

const DateCellContent: React.FC<DateCellContentProps> = ({ date }) => {
  const dateObj = new Date(date);
  return (
    <>
      {format(dateObj, 'LLL d, YYY')}
      <br />
      <Text fontSize="sm">{format(dateObj, 'h:mm:ss a')}</Text>
    </>
  );
};

export default DateCellContent;
