import { useCallback } from 'react';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { fetchEncryptionKey } from './decryptServices';

interface ParsedFile {
  locator: string;
  nameEncrypted: Uint8Array;
  encrypted: Uint8Array;
}

/**
 * Take an uploaded file and return the token and, encrypted name and encrypted content
 * @param file Uploaded file to process
 */
export async function parseFileForDecrypt(file: File): Promise<ParsedFile> {
  // Fetch the length of the token and the actual token. Wrapping in the "Response"
  // class because Safari does not support Blob.arrayBuffer
  const buffer = await new Response(file).arrayBuffer();
  let pos = 0;
  const locatorSize = new Uint32Array(buffer.slice(pos, pos + 4))[0];
  if (locatorSize > 256) {
    throw new Error(
      'Unable to decrypt file, check that the file is valid and not damaged'
    );
  }
  pos += 4;
  const locator = new TextDecoder().decode(
    new Uint8Array(buffer.slice(pos, locatorSize + pos))
  );
  pos += locatorSize;
  const fileNameSize = new Uint32Array(buffer.slice(pos, pos + 4))[0];
  if (fileNameSize < 2 || fileNameSize > 2000) {
    throw new Error(
      'Unable to decrypt file, check that the file is valid and not damaged'
    );
  }
  pos += 4;
  const nameEncrypted = new Uint8Array(buffer.slice(pos, fileNameSize + pos));
  pos += fileNameSize;

  return {
    locator,
    nameEncrypted,
    encrypted: new Uint8Array(buffer.slice(pos)),
  };
}

/**
 * Decrypt a file that has been parsed already
 * @param parsed
 * @param keyString
 */
export function decryptFile(
  parsed: ParsedFile,
  keyString: string
): [file: Blob, name: string] {
  const key = new TextEncoder().encode(keyString);
  const data = decryptUint(parsed.encrypted, key);
  const fileName = decryptUint(parsed.nameEncrypted, key);
  return [
    new Blob([data], { type: 'octet/stream' }),
    new TextDecoder().decode(fileName),
  ];
}

function decryptUint(encrypted: Uint8Array, key: Uint8Array) {
  const result = new Uint8Array(encrypted.length);
  for (let i = 0; i < result.length; i++) {
    // eslint-disable-next-line no-bitwise
    result[i] = encrypted[i] ^ key[i % key.length];
  }
  return result;
}

export function useDecryptFile() {
  const action = useCallback(async (file: File) => {
    const parsed = await parseFileForDecrypt(file);
    const { key } = await fetchEncryptionKey(parsed.locator);
    return decryptFile(parsed, key);
  }, []);
  return useAsyncAction(action);
}
