import React from 'react';
import { Alert, AlertProps, AlertDescription } from '@chakra-ui/react';

const LivePlaygroundAlert: React.FC<AlertProps> = (props) => (
  <Alert variant="left-accent" status="warning" mb={6} {...props}>
    <AlertDescription>
      <strong>This tutorial is not a sandbox.</strong> Any operations here
      behave as if you executed them from our own server.
    </AlertDescription>
  </Alert>
);

export default LivePlaygroundAlert;
