import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { validationServiceBaseURL } from 'backend/services';
import {
  TryIt,
  TryItResult,
  RequestURL as TryItRequestURL,
  Headers as TryItHeaders,
  Body as TryItBody,
  Response as TryItResponse,
  ChildrenRenderProps as TryItChildrenRenderProps,
} from 'shared/components/TryIt';
import SpinnerButton from 'shared/components/SpinnerButton';
import { useDashboardContentContext } from 'dashboard/layout/DashboardContentContext';
import { TutorialName } from '../constants';
import {
  ChapterContainer,
  ChapterContent,
  ChapterFooter,
  ChapterHeader,
  ChapterIntroBox,
  ChapterIntroContent,
  ChapterNavLink,
  ChapterResponseAlert,
} from '../components';
import { useEncryptTutorial } from './EncryptTutorialContext';
import chapters from './chapters';

const CURRENT_CHAPTER = 4;

const requestURL = `${validationServiceBaseURL}/packet`;
const requestMethod = 'post';

interface Chapter4Props {
  apiKey: string;
}

const Chapter4: React.FC<Chapter4Props> = ({ apiKey }) => {
  const tutorial = useEncryptTutorial();
  const { scrollToBottom } = useDashboardContentContext();

  const headers = {
    'api-key': apiKey || 'API KEY MISSING',
    authorization: `Bearer ${
      tutorial.subscriberAccessToken ?? 'AUTHORIZATION MISSING'
    }`,
    'content-type': 'text/plain;charset=UTF-8',
  };

  const initialBody = tutorial.keyPacket;

  const handleSuccess = (result: TryItResult) => {
    tutorial.setLocatorToken(result.response);
    tutorial.storeResult(CURRENT_CHAPTER)(result);
    scrollToBottom();
  };

  const handleError = (result: TryItResult) => {
    tutorial.setLocatorToken('');
    tutorial.storeResult(CURRENT_CHAPTER)(result);
    scrollToBottom();
  };

  const chapterResult = tutorial.chapterResults[CURRENT_CHAPTER];
  const isChapterComplete = chapterResult?.status === 200;

  return (
    <ChapterContainer>
      <ChapterIntroBox>
        <ChapterHeader
          tutorial={TutorialName.encrypt}
          chapters={chapters}
          chapterNum={CURRENT_CHAPTER}
        />
        <ChapterIntroContent>
          <Text>
            This endpoint takes the key packet, encrypted by the subscription
            server, and inserts the embedded secret into the XQ database. It
            returns a locator token that can be used to retrieve the secret in
            the future.
          </Text>
        </ChapterIntroContent>
      </ChapterIntroBox>

      <TryIt
        url={requestURL}
        method={requestMethod}
        headers={headers}
        initialBody={chapterResult?.body ?? initialBody}
        onSuccess={handleSuccess}
        onError={handleError}
      >
        {({
          loading,
          bodyError,
          getBodyProps,
          execute,
        }: TryItChildrenRenderProps) => {
          const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault();
            execute();
          };
          return (
            <ChapterContent as="form" onSubmit={handleSubmit}>
              <TryItRequestURL
                requestURL={requestURL}
                requestMethod={requestMethod}
              />
              <TryItHeaders headers={headers} />
              <TryItBody
                {...getBodyProps()}
                contentType={headers['content-type']}
                rows={20}
              />
              <Flex alignItems="center" mt={4} mb={8} minHeight="56px">
                {bodyError && (
                  <Box flex={1} mr={8} bg="red.100" py={4} px={4}>
                    <Text as="span" fontWeight="bold" mr={4}>
                      Error
                    </Text>
                    {bodyError}
                  </Box>
                )}
                <SpinnerButton
                  flex="none"
                  ml="auto"
                  type="submit"
                  isLoading={loading}
                >
                  Send Request
                </SpinnerButton>
              </Flex>
              <TryItResponse
                response={chapterResult?.response}
                statusCode={chapterResult?.status}
                statusText={chapterResult?.statusText}
                mb={8}
              />

              {isChapterComplete && (
                <ChapterResponseAlert title="The key packet is now stored on the key server">
                  This response is the locator token used to retrieve your
                  encryption key in the future. In the next chapter, you will
                  use your key to encrypt a message.
                </ChapterResponseAlert>
              )}
            </ChapterContent>
          );
        }}
      </TryIt>

      <ChapterFooter>
        <ChapterNavLink direction="back" chapters={chapters} chapterNum={3} />
        {isChapterComplete && (
          <ChapterNavLink chapters={chapters} chapterNum={5} />
        )}
      </ChapterFooter>
    </ChapterContainer>
  );
};

export default Chapter4;
