import React from 'react';
import { format } from 'date-fns';

interface KeyPacketUpdatedDateProps {
  updatedDate: number;
}

const KeyPacketUpdatedDate: React.FC<KeyPacketUpdatedDateProps> = ({
  updatedDate,
}) => {
  if (!updatedDate) {
    return <>Not provided</>;
  }

  const updatedObj = new Date(updatedDate);

  return <>{format(updatedObj, 'LLL d, YYY h:mm:ss a')}</>;
};

export default KeyPacketUpdatedDate;
