import React from 'react';
import { Text } from '@chakra-ui/react';
import { KeyPacketSummary } from 'backend/api-types/dashboard';

interface KeyPacketRecipientsProps {
  recipients: KeyPacketSummary['recipients'];
  visibleCount?: number;
}

const KeyPacketRecipients: React.FC<KeyPacketRecipientsProps> = ({
  recipients,
  visibleCount = 1,
}) => {
  const visibileRecipients = recipients.slice(0, visibleCount);
  const hiddenRecipientsCount = recipients.length - visibileRecipients.length;

  return (
    <>
      {visibileRecipients.map((recipient) => (
        <Text id={recipient.key} key={recipient.key} isTruncated>
          {recipient.user}
        </Text>
      ))}
      {hiddenRecipientsCount > 0 && (
        <Text fontSize="sm" fontWeight="semibold">
          +{hiddenRecipientsCount} more recipients
        </Text>
      )}
    </>
  );
};
export default KeyPacketRecipients;
