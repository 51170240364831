import { useCallback, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { formatErrorResponse } from 'shared/utils/errors';
import { AuthenticationController } from 'auth/AuthenticationController';
import { useLocation } from 'react-router-dom';

export function useResendVerify(showToast = true) {
  const history = useLocation<{ email?: string }>();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const toast = useToast();

  const email = history.state?.email;
  const handleResend = useCallback(async () => {
    if (email) {
      setSuccess(false);
      setSending(true);
      setError(undefined);
      try {
        await AuthenticationController.requestLoginLink(email);
        setSuccess(true);
        if (showToast) {
          toast({
            title: 'Your verification email has been sent',
            description: 'Check your mailbox for an email from us',
            status: 'success',
            position: 'bottom',
          });
        }
      } catch (e) {
        setError(e);
        if (showToast) {
          toast({
            title: 'Could not send verification email',
            description: formatErrorResponse(e).message,
            status: 'error',
            position: 'bottom',
          });
        }
      }
      setSending(false);
    }
  }, [email, showToast, setSending, setError, setSuccess, toast]);
  return {
    email,
    handleResend,
    sending,
    error,
    success,
  };
}
