import React, { useRef } from 'react';
import { Flex, Text, Alert, AlertTitle } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { subscriptionServiceBaseURL } from 'backend/services';
import {
  RequestURL,
  Headers,
  Response,
  Body,
  TryIt,
  ChildrenRenderProps,
  TryItResult,
  useControlledJSONTextarea,
  useJSONFormatted,
} from 'shared/components/TryIt';
import SpinnerButton from 'shared/components/SpinnerButton';
import { StackedInput } from 'shared/components/form';
import { useDashboardContentContext } from 'dashboard/layout/DashboardContentContext';
import { TutorialName } from '../constants';
import {
  ChapterContainer,
  ChapterContent,
  ChapterFooter,
  ChapterFormBox,
  ChapterHeader,
  ChapterIntroBox,
  ChapterIntroContent,
  ChapterNavLink,
  ChapterResponseAlert,
} from '../components';
import { useEncryptTutorial } from './EncryptTutorialContext';
import chapters from './chapters';

const CURRENT_CHAPTER = 1;
const requestURL = `${subscriptionServiceBaseURL}/authorizealias`;
const requestMethod = 'post';

type BodyFormValues = {
  user: string;
};

const defaultBody = {
  user: '',
};

interface Chapter1Props {
  apiKey: string;
}

const Chapter1: React.FC<Chapter1Props> = ({ apiKey }) => {
  const tutorial = useEncryptTutorial();
  const { scrollToBottom } = useDashboardContentContext();
  const chapterResults = tutorial.chapterResults[CURRENT_CHAPTER];
  const isChapterComplete = chapterResults?.status === 200;
  const initialBody =
    chapterResults?.body ?? JSON.stringify(defaultBody, null, 2);
  const defaultValues: BodyFormValues = JSON.parse(initialBody);
  const bodyRef = useRef<HTMLTextAreaElement>(null);
  const { register, watch, setValue } = useForm<BodyFormValues>({
    defaultValues,
  });
  const allFields = watch();
  const handleBodyFormatted = useJSONFormatted<BodyFormValues>((body) => {
    setValue('user', body.user);
  });
  const { bodyValue } = useControlledJSONTextarea<BodyFormValues>(
    bodyRef,
    allFields,
    initialBody
  );

  const headers = {
    'api-key': apiKey || 'API KEY MISSING',
    'content-type': 'application/json',
  };

  const handleSuccess = (result: TryItResult) => {
    tutorial.setSubscriberAccessToken(result.response);
    tutorial.storeResult(CURRENT_CHAPTER)(result);
    scrollToBottom();
  };

  const handleError = (result: TryItResult) => {
    tutorial.setSubscriberAccessToken('');
    tutorial.storeResult(CURRENT_CHAPTER)(result);
    scrollToBottom();
  };

  return (
    <ChapterContainer>
      <ChapterIntroBox>
        <ChapterHeader
          tutorial={TutorialName.encrypt}
          chapters={chapters}
          chapterNum={CURRENT_CHAPTER}
        />
        <ChapterIntroContent>
          <Text>
            A subscriber is an email address that has been granted an
            authorization token which will be used to access the protected
            encryption key.
          </Text>
        </ChapterIntroContent>
      </ChapterIntroBox>

      <TryIt
        url={requestURL}
        method={requestMethod}
        headers={headers}
        initialBody={initialBody}
        onSuccess={handleSuccess}
        onError={handleError}
      >
        {({
          getBodyProps,
          bodyError,
          execute,
          loading,
        }: ChildrenRenderProps) => {
          const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault();
            execute();
          };
          return (
            <ChapterContent as="form" onSubmit={handleSubmit}>
              <RequestURL
                requestURL={requestURL}
                requestMethod={requestMethod}
              />
              <Headers headers={headers} />
              <Body
                ref={bodyRef}
                {...getBodyProps()}
                onFormatted={handleBodyFormatted}
                initialBody={initialBody}
                value={bodyValue}
              />
              {bodyError && (
                <Alert status="error" variant="subtle" my={6} borderRadius="lg">
                  <AlertTitle>Error</AlertTitle>
                  {bodyError}
                </Alert>
              )}
              <ChapterFormBox my={6}>
                <StackedInput
                  name="user"
                  label="User"
                  helperText="An email address that will access the protected encryption key."
                  ref={register}
                  isRequired
                />
              </ChapterFormBox>
              <Flex mt={6}>
                <SpinnerButton
                  flex="none"
                  ml="auto"
                  type="submit"
                  isLoading={loading}
                >
                  Send Request
                </SpinnerButton>
              </Flex>
              <Response
                response={chapterResults?.response}
                statusCode={chapterResults?.status}
                statusText={chapterResults?.statusText}
                mt={6}
              />

              {isChapterComplete && (
                <ChapterResponseAlert title="You have authorized a subscriber!">
                  This response is the authorization token that will allow the
                  subscriber to use your encryption key. In the next chapter,
                  you will create your encryption key.
                </ChapterResponseAlert>
              )}
            </ChapterContent>
          );
        }}
      </TryIt>

      <ChapterFooter>
        <ChapterNavLink direction="back" chapters={chapters} chapterNum={0} />
        {isChapterComplete && (
          <ChapterNavLink chapters={chapters} chapterNum={2} />
        )}
      </ChapterFooter>
    </ChapterContainer>
  );
};

export default Chapter1;
