import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import {
  CenteredLayoutHeading,
  CenteredLayoutContent,
  CenteredLayoutContentInner,
} from 'shared/components/CenteredLayout';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { StackedInputField } from 'shared/components/form';
import useLoginForm from './useLoginForm';

interface LoginFormProps {
  /** Controlled state indicating the form is submitting. */
  isSubmitting?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  isSubmitting: controlledIsSubmitting,
}) => {
  const { onSubmit, form, error } = useLoginForm();
  const isSubmitting = controlledIsSubmitting || form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <CenteredLayoutContent as="form" onSubmit={onSubmit} noValidate mb={0}>
        <CenteredLayoutHeading>Sign in to XQ</CenteredLayoutHeading>

        <CenteredLayoutContentInner>
          <FormErrorAlert error={error} />

          <SimpleGrid spacing={4}>
            <StackedInputField
              id="workspace"
              name="workspace"
              type="text"
              label="Team"
            />
            <StackedInputField
              id="email"
              name="email"
              type="email"
              label="Email address"
            />
            <StackedInputField
              id="password"
              name="password"
              type="password"
              label="Password"
            />
            <SpinnerButton
              type="submit"
              size="lg"
              isLoading={isSubmitting}
              mt={4}
              role="button"
            >
              Sign in
            </SpinnerButton>
          </SimpleGrid>
        </CenteredLayoutContentInner>
      </CenteredLayoutContent>
    </FormProvider>
  );
};

export default LoginForm;
