import React from 'react';
import { Flex, FlexProps, Icon, Text, Tooltip } from '@chakra-ui/react';
import {
  HiOutlineCheckCircle,
  HiOutlineXCircle,
  HiOutlineClock,
} from 'react-icons/hi';
import { CommunicationStatus, COMMUNICATION_STATUSES } from 'key-packet/types';

type KeyPacketStatusConfig = {
  icon: React.ElementType;
  iconColor: string;
  title?: string;
  tooltip: string;
  color: string;
};

const statusConfig: Record<CommunicationStatus, KeyPacketStatusConfig> = {
  [CommunicationStatus.Available]: {
    icon: HiOutlineCheckCircle,
    iconColor: 'green.600',
    title: COMMUNICATION_STATUSES.get(CommunicationStatus.Available),
    tooltip: 'Resource is available',
    color: 'green.600',
  },
  [CommunicationStatus.Revoked]: {
    icon: HiOutlineXCircle,
    iconColor: 'red.600',
    title: COMMUNICATION_STATUSES.get(CommunicationStatus.Revoked),
    tooltip: 'Resource has been revoked',
    color: 'red.600',
  },
  [CommunicationStatus.Expired]: {
    icon: HiOutlineClock,
    iconColor: 'gray.500',
    title: COMMUNICATION_STATUSES.get(CommunicationStatus.Expired),
    tooltip: 'Resource has expired',
    color: 'gray.600',
  },
};

interface KeyPacketStatusProps extends FlexProps {
  status: CommunicationStatus;
  showTooltip?: boolean;
  showLabel?: boolean;
}

const KeyPacketStatus: React.FC<KeyPacketStatusProps> = ({
  status,
  direction = 'column',
  showTooltip = false,
  showLabel = false,
  ...props
}) => {
  const config = statusConfig[status];
  const title = COMMUNICATION_STATUSES.get(status) ?? 'Unknown';

  const renderedContent = (
    <Flex
      direction={direction}
      alignItems="center"
      fontSize="sm"
      color={config.color}
      {...props}
    >
      <Icon
        as={config.icon}
        color={config.iconColor}
        boxSize={6}
        mb={showLabel && direction === 'column' ? 1 : 0}
        mr={showLabel && direction === 'row' ? 1 : 0}
      />
      {showLabel && <Text fontWeight="semibold">{title}</Text>}
    </Flex>
  );

  if (showTooltip) {
    return (
      <Tooltip
        hasArrow
        bg={config.iconColor}
        label={config.tooltip}
        placement="top"
      >
        {renderedContent}
      </Tooltip>
    );
  }

  return renderedContent;
};

export default KeyPacketStatus;
