import React, { useEffect, useRef } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import routes from 'routes';
import appState from 'application/ApplicationState';
import { CreateApplicationFormContent } from './CreateApplicationForm';
import useCreateApplicationForm, {
  CreateApplicationFormValues,
} from './CreateApplicationForm/useCreateApplicationForm';

const CreateApplicationModal: React.FC = () => {
  const history = useHistory();
  const initialFocusRef = useRef(null);
  const toast = useToast();
  const formValuesRef = useRef<CreateApplicationFormValues>();

  const { onSubmit, form, error } = useCreateApplicationForm((app) => {
    toast({
      title: 'New application successfully created',
      description: formValuesRef.current?.name,
      status: 'success',
      position: 'bottom-right',
    });

    // Send the user to the application details screen
    history.push(routes.application.detail(app.id));

    // Close the create application modal
    appState.closeCreateApplicationModal();
  });
  const { isSubmitting } = form.formState;
  const { reset } = form;
  formValuesRef.current = form.getValues();

  const isOpen = appState.showCreateApplicationModal;
  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size="3xl"
      initialFocusRef={initialFocusRef}
      onClose={appState.closeCreateApplicationModal}
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <form onSubmit={onSubmit} noValidate>
            <ModalHeader>Add a new application</ModalHeader>

            <ModalBody>
              <FormErrorAlert error={error} />
              <CreateApplicationFormContent initialFocusRef={initialFocusRef} />
            </ModalBody>

            <ModalFooter>
              <Button
                type="button"
                variant="outline"
                colorScheme="gray"
                mr={3}
                onClick={appState.closeCreateApplicationModal}
              >
                Cancel
              </Button>
              <SpinnerButton type="submit" isLoading={isSubmitting}>
                Create Application
              </SpinnerButton>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default observer(CreateApplicationModal);
