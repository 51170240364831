import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Badge, Text, Skeleton } from '@chakra-ui/react';
import { TableRow, TableCell } from 'shared/components/Table';
import LevelIndicatorIcon from 'event-log/components/LevelIndicatorIcon';
import {
  DateCellContent,
  LocationCellContent,
} from 'event-log/components/table-cells';
import EventTypesController from 'event-log/EventTypesController';
import { EventLogItem } from 'event-log/types';
import ApplicationsController from 'application/ApplicationsController';
import APIKeysController from 'api-keys/APIKeysController';

export interface EventLogRowItemProps {
  row: EventLogItem;
  applications: ApplicationsController;
  keys: APIKeysController;
  events: EventTypesController;
  /** Skeleton table rows will be displayed while the initial data is loading. */
  isInitialLoading?: boolean;
  onRowClick?: (id: number) => void;
}

const EventLogRowItem: React.FC<EventLogRowItemProps> = ({
  row,
  applications,
  keys,
  events,
  isInitialLoading = false,
  onRowClick,
}) => {
  const appName = applications.getById(row.app)?.name;
  const apiKeyName = keys.getById(row.key)?.name;
  const handleRowClick = useCallback(() => {
    if (onRowClick) {
      onRowClick(row.id);
    }
  }, [row.id, onRowClick]);

  return (
    <TableRow key={row.id} onClick={onRowClick ? handleRowClick : undefined}>
      <TableCell width="60px" textAlign="center">
        <Skeleton isLoaded={!isInitialLoading}>
          <LevelIndicatorIcon threat={row.threat} />
        </Skeleton>
      </TableCell>
      <TableCell isNumeric>
        <Skeleton isLoaded={!isInitialLoading}>{row.id}</Skeleton>
      </TableCell>
      <TableCell width="20%" isTruncated>
        <Skeleton isLoaded={!isInitialLoading}>
          {apiKeyName ? (
            <>
              {apiKeyName}
              <br />
              <Text fontSize="sm" isTruncated>
                {appName}
              </Text>
            </>
          ) : (
            <Badge textTransform="none">
              {row.key === 0 ? (
                <>Not Applicable</>
              ) : (
                <>&nbsp;External App&nbsp;</>
              )}
            </Badge>
          )}
        </Skeleton>
      </TableCell>
      <TableCell width="25%" minWidth="200px" isTruncated>
        {isInitialLoading ? (
          <Skeleton>LOADING</Skeleton>
        ) : (
          events.getTitle(row.action)
        )}
      </TableCell>
      <TableCell width="150px" whiteSpace="nowrap" isNumeric>
        <Skeleton isLoaded={!isInitialLoading}>
          <DateCellContent date={row.created} />
        </Skeleton>
      </TableCell>
      <TableCell width="200px">
        <Skeleton isLoaded={!isInitialLoading}>
          <LocationCellContent location={row.location} ip={row.ip} />
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};

export default observer(EventLogRowItem);
