import authController from 'auth/AuthenticationController';
import { useGlobalStore } from 'store/GlobalStoreContext';
import AsyncData from './AsyncData';

export default function useAsyncData<T extends AsyncData>(
  controller: T,
  skip = false
): T {
  const { cache } = useGlobalStore();

  // Bind the key to the user's unique id. This way, if a different user logs in,
  // it will not show any data that has been previously cached by the first user.
  // Only really acts a safety in case some information is left in the cache.
  //
  // NOTE: As a downside, cached data that does not require any authentication will
  // also be re-fetched onces the user logs in.
  const key = `${controller.key}:${authController.data?.sub}`;

  // Use a cache controller instead if there is one available
  let observable = cache.getItem<T>(key);
  if (!observable) {
    observable = controller;

    // Update the cache with this controller so it can be used across the app
    cache.setItem<T>(key, observable);
  }

  // If the data still needs to be fetched, let trigger it here
  if (!observable.loading && !observable.finished && !skip) {
    observable.execute();
  }

  return observable;
}
