import React from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import {
  StackedInputField,
  StackedTextareaField,
} from 'shared/components/form';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { ApplicationSummary } from 'application/ApplicationsController';
import useUpdateApplicationForm from './useUpdateApplicationForm';

interface UpdateApplicationFormProps {
  app: ApplicationSummary;
  initialFocusRef?: React.RefObject<HTMLInputElement>;
  onClose: () => void;
}

const UpdateApplicationForm: React.FC<UpdateApplicationFormProps> = ({
  app,
  initialFocusRef,
  onClose,
}) => {
  const toast = useToast();
  const { onSubmit, form, error } = useUpdateApplicationForm(
    app,
    (updatedApp) => {
      toast({
        title: 'Application successfully updated',
        description: updatedApp.name,
        status: 'success',
        position: 'bottom-right',
      });

      onClose();
    }
  );
  const { isSubmitting } = form.formState;

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <FormErrorAlert error={error} />
        <SimpleGrid spacing={4}>
          <StackedInputField
            id="name"
            name="name"
            type="text"
            label="Name"
            initialFocusRef={initialFocusRef}
            isRequired
          />
          <StackedTextareaField
            id="description"
            name="description"
            label="Description"
            rows={2}
          />
        </SimpleGrid>
        <Flex justify="flex-end" mt={8}>
          <ButtonGroup spacing={4}>
            <Button
              type="button"
              variant="outline"
              colorScheme="gray"
              onClick={onClose}
            >
              Cancel
            </Button>
            <SpinnerButton type="submit" isLoading={isSubmitting}>
              Save
            </SpinnerButton>
          </ButtonGroup>
        </Flex>
      </form>
    </FormProvider>
  );
};

export default UpdateApplicationForm;
