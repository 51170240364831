import { useCallback, useMemo, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { ConfirmAlertDialogProps } from 'shared/components/alert-dialogs';

export type UseConfirmDialogDisclosureReturn<V, R> = {
  onOpen: (selectedRecordId: V, selectedRecord: R) => void;
} & Pick<
  ConfirmAlertDialogProps<V, R>,
  'isOpen' | 'recordId' | 'record' | 'onClose' | 'onExited'
>;

/**
 * Disclosure props adapted to match confirm dialog props.
 * @template V Type of the ID passed to AsyncAction function.
 * @template R Type of the record selected for confirmation.
 */
export default function useConfirmDialogDisclosure<
  V,
  R
>(): UseConfirmDialogDisclosureReturn<V, R> {
  const [recordId, setRecordId] = useState<V>();
  const [record, setRecord] = useState<R>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = useCallback(
    (selectedRecordId: V, selectedRecord: R) => {
      setRecordId(selectedRecordId);
      setRecord(selectedRecord);
      onOpen();
    },
    [setRecordId, setRecord, onOpen]
  );

  const handleExited = useCallback(() => {
    setRecordId(undefined);
    setRecord(undefined);
  }, [setRecordId, setRecord]);

  return useMemo(() => {
    return {
      isOpen,
      recordId,
      record,
      onOpen: handleOpen,
      onClose,
      onExited: handleExited,
    };
  }, [isOpen, recordId, record, handleOpen, onClose, handleExited]);
}
