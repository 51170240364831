import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import {
  StackedInputField,
  StackedTextareaField,
} from 'shared/components/form';

interface CreateApplicationFormContentProps {
  initialFocusRef?: React.RefObject<HTMLInputElement>;
}
const CreateApplicationFormContent: React.FC<CreateApplicationFormContentProps> = ({
  initialFocusRef,
}) => {
  return (
    <SimpleGrid spacing={8}>
      <StackedInputField
        id="name"
        name="name"
        type="text"
        label="Name your application"
        initialFocusRef={initialFocusRef}
        isRequired
      />
      <StackedTextareaField
        id="description"
        name="description"
        label="Describe the purpose of this application"
        rows={2}
      />
    </SimpleGrid>
  );
};

export default CreateApplicationFormContent;
