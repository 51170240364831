import React from 'react';
import FooterNavLink, {
  FooterNavLinkProps,
} from 'shared/components/FooterNavLink';
import { ChaptersDict } from '../types';

interface ChapterNavLinkProps
  extends Omit<FooterNavLinkProps, 'to' | 'description'> {
  chapters: ChaptersDict;
  chapterNum: number;
  isButton?: boolean;
}

const ChapterNavLink: React.FC<ChapterNavLinkProps> = ({
  chapters,
  chapterNum,
  isButton,
  ...props
}) => {
  const chapter = chapters[chapterNum];
  const description =
    chapterNum === 0 ? 'Introduction' : `Chapter ${chapterNum}`;
  return (
    <FooterNavLink
      to={isButton ? undefined : chapter.to}
      description={description}
      {...props}
    >
      {chapter.name}
    </FooterNavLink>
  );
};

export default ChapterNavLink;
