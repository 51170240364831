import React, { useRef } from 'react';
import { Flex, Alert, AlertTitle, Text, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { subscriptionServiceBaseURL } from 'backend/services';
import {
  TryIt,
  TryItResult,
  RequestURL as TryItRequestURL,
  Headers as TryItHeaders,
  Body as TryItBody,
  Response as TryItResponse,
  ChildrenRenderProps as TryItChildrenRenderProps,
  useControlledJSONTextarea,
  useJSONFormatted,
} from 'shared/components/TryIt';
import SpinnerButton from 'shared/components/SpinnerButton';
import { StackedInput, StackedTextarea } from 'shared/components/form';
import { useDashboardContentContext } from 'dashboard/layout/DashboardContentContext';
import { TutorialName } from '../constants';
import {
  ChapterContainer,
  ChapterContent,
  ChapterFooter,
  ChapterFormBox,
  ChapterHeader,
  ChapterIntroBox,
  ChapterIntroContent,
  ChapterNavLink,
  ChapterResponseAlert,
} from '../components';
import { useEncryptTutorial } from './EncryptTutorialContext';
import chapters from './chapters';

const CURRENT_CHAPTER = 3;

const requestURL = `${subscriptionServiceBaseURL}/packet`;
const requestMethod = 'post';

type RequestFormValues = {
  recipients: string;
  expires: string;
  key: string;
};

interface Chapter3Props {
  apiKey: string;
}

const Chapter3: React.FC<Chapter3Props> = ({ apiKey }) => {
  const tutorial = useEncryptTutorial();
  const { scrollToBottom } = useDashboardContentContext();
  const chapterResults = tutorial.chapterResults[CURRENT_CHAPTER];
  const isChapterComplete = chapterResults?.status === 200;
  const defaultBody = {
    recipients: '',
    expires: '',
    key: tutorial.encryptionKey,
  };
  const initialBody =
    chapterResults?.body ?? JSON.stringify(defaultBody, null, 2);
  const defaultValues: RequestFormValues = JSON.parse(initialBody);
  const { register, watch, setValue } = useForm<RequestFormValues>({
    defaultValues,
  });
  const bodyRef = useRef<HTMLTextAreaElement>(null);
  const allFields = watch();
  const handleBodyFormatted = useJSONFormatted<RequestFormValues>((body) => {
    setValue('recipients', body.recipients);
    setValue('expires', body.expires);
    setValue('key', body.key);
  });
  const { bodyValue } = useControlledJSONTextarea<RequestFormValues>(
    bodyRef,
    allFields,
    initialBody
  );

  const headers = {
    'accept-language': 'en_US',
    'api-key': apiKey || 'API KEY MISSING',
    authorization: `Bearer ${
      tutorial.subscriberAccessToken ?? 'AUTHORIZATION MISSING'
    }`,
    'content-type': 'application/json',
  };

  const handleSuccess = (result: TryItResult) => {
    tutorial.setKeyPacket(result.response);
    tutorial.storeResult(CURRENT_CHAPTER)(result);
    scrollToBottom();
  };

  const handleError = (result: TryItResult) => {
    tutorial.setKeyPacket('');
    tutorial.storeResult(CURRENT_CHAPTER)(result);
    scrollToBottom();
  };

  return (
    <ChapterContainer>
      <ChapterIntroBox>
        <ChapterHeader
          tutorial={TutorialName.encrypt}
          chapters={chapters}
          chapterNum={CURRENT_CHAPTER}
        />
        <ChapterIntroContent>
          <Text>
            Takes the secret key that a user wants to protect along with a list
            of the identities that are allowed to access it, and creates a new
            encrypted packet ready to be submitted to the key server. The main
            purpose of this step is to validate the users credentials. Once
            validated, a short-lived encrypted packet containing the same
            information is created and returned to the user.
          </Text>
        </ChapterIntroContent>
      </ChapterIntroBox>

      <TryIt
        url={requestURL}
        method={requestMethod}
        headers={headers}
        initialBody={initialBody}
        onSuccess={handleSuccess}
        onError={handleError}
      >
        {({
          getBodyProps,
          bodyError,
          execute,
          loading,
        }: TryItChildrenRenderProps) => {
          const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault();
            execute();
          };
          return (
            <ChapterContent as="form" onSubmit={handleSubmit}>
              <TryItRequestURL
                requestURL={requestURL}
                requestMethod={requestMethod}
              />
              <TryItHeaders headers={headers} />
              <TryItBody
                {...getBodyProps()}
                ref={bodyRef}
                onFormatted={handleBodyFormatted}
                value={bodyValue}
                rows={15}
              />

              {bodyError && (
                <Alert status="error" variant="subtle" my={6} borderRadius="lg">
                  <AlertTitle>Error</AlertTitle>
                  {bodyError}
                </Alert>
              )}

              <ChapterFormBox my={6}>
                <Stack spacing={6}>
                  <StackedInput
                    label="Recipients"
                    name="recipients"
                    helperText="Comma-delimited list of email addresses or phone numbers who
                  are allowed to access the key."
                    ref={register}
                    isRequired
                  />
                  <StackedInput
                    label="Expires"
                    name="expires"
                    helperText="Number of hours that this key will remain valid for. After
                    this time, it will no longer be accessible."
                    maxWidth={20}
                    ref={register}
                    isRequired
                  />
                  <StackedTextarea
                    label="Key"
                    name="key"
                    helperText="The secret encryption key you want to protect."
                    rows={10}
                    ref={register}
                    isRequired
                  />
                </Stack>
              </ChapterFormBox>

              <Flex mt={6}>
                <SpinnerButton
                  flex="none"
                  ml="auto"
                  type="submit"
                  isLoading={loading}
                >
                  Send Request
                </SpinnerButton>
              </Flex>

              <TryItResponse
                response={chapterResults?.response}
                statusCode={chapterResults?.status}
                statusText={chapterResults?.statusText}
                mt={6}
              />

              {isChapterComplete && (
                <ChapterResponseAlert title="Your key packet is created!">
                  This response is the encrypted key packet. In the next
                  chapter, it will be submitted to the XQ key store.
                </ChapterResponseAlert>
              )}
            </ChapterContent>
          );
        }}
      </TryIt>

      <ChapterFooter>
        <ChapterNavLink direction="back" chapters={chapters} chapterNum={2} />
        {isChapterComplete && (
          <ChapterNavLink chapters={chapters} chapterNum={4} />
        )}
      </ChapterFooter>
    </ChapterContainer>
  );
};

export default Chapter3;
