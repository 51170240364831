import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertStatus,
  CloseButton,
  Flex,
  BoxProps,
} from '@chakra-ui/react';
import React from 'react';

export interface NotificationBannerProps extends BoxProps {
  status?: AlertStatus;
  content?: React.ReactNode;
  onClose?: () => void;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  status = 'error',
  onClose,
  content,
  ...props
}) => {
  if (!content) {
    return null;
  }

  return (
    <Alert
      status={status}
      borderRadius="md"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        a: {
          color: 'danger.500',
          _hover: {
            color: 'black',
          },
        },
      }}
      {...props}
    >
      <Flex alignItems="center">
        <AlertIcon boxSize={6} flexShrink={0} mr={2} />
        <AlertDescription pr={2}>{content}</AlertDescription>
      </Flex>
      {onClose && <CloseButton onClick={onClose} alignSelf="flex-start" />}
    </Alert>
  );
};

export default NotificationBanner;
