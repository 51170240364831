import { useCallback, useEffect, useReducer, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { ListCommunicationsResponse } from 'backend/api-types/dashboard';
import { dashboardService, timezoneOffset } from 'backend/services';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { CommunicationFilters } from './useCommunicationFilters';
import {
  CommunicationSummary,
  convertKeyPacketToCommunication,
} from '../types';

const DEFAULT_PAGE_SIZE = 50;

type State = {
  data: Array<CommunicationSummary> | null;
  total: number;
  page: number;
};

enum CommunicationActionType {
  DataLoaded = 'DATA_LOADED',
  Reset = 'RESET',
}

type Action = {
  type: CommunicationActionType;
  data?: Array<CommunicationSummary> | null;
  total?: number;
  page?: number;
};

const initialState: State = {
  data: null,
  total: 0,
  page: 0,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case CommunicationActionType.DataLoaded: {
      const newData =
        action.data?.reduce(
          (acc, curr) => {
            // Ensure uniqueness of the communications in case they are still streaming in
            // as the page cursor moves.
            if (!state.data?.find((i) => i.id === curr.id)) {
              acc.push(curr);
            }

            return acc;
          },
          [...(state.data ?? [])]
        ) ?? [];

      return {
        data: newData,

        // The last page returns a total of zero. This will prevent that from overwriting
        // the actual total.
        total: action.total ? action.total : state.total,

        // Only update the current page if it is larger than the existing value.
        // This will handle any race conditions if multiple 'loadMore' are called
        // at the same time.
        page:
          action.page && action.page > state.page ? action.page : state.page,
      };
    }
    case CommunicationActionType.Reset:
      return {
        ...initialState,
      };
    default:
      throw new Error('Un-Implemented action type');
  }
}

type FetchDataArguments = [filters: CommunicationFilters, pageSize: number];

export default function useCommunications(
  filters: CommunicationFilters = {},
  pageSize = DEFAULT_PAGE_SIZE
) {
  const filterRef = useRef<CommunicationFilters | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fetch, loading, { error }] = useAsyncAction<
    ListCommunicationsResponse,
    FetchDataArguments
  >(
    useCallback(
      async (f: CommunicationFilters, page: number) => {
        const res = await dashboardService.get<ListCommunicationsResponse>(
          '/communications',
          {
            params: {
              page,
              users: f.senders?.join(',') || undefined,
              recipients: f.recipients?.join(',') || undefined,
              limit: pageSize,
              from: f.dateRange?.fromDate?.getTime(),
              to: f.dateRange?.toDate?.getTime(),
              search: f.search || undefined,
              type: f.types?.join(',') || undefined,
              status: f.statuses?.join(',') || undefined,
              threats: f.threats?.join(',') || undefined,
              tz: timezoneOffset,
            },
          }
        );

        return res.data;
      },
      [pageSize]
    ),

    // On Success we want to append the existing data and update the page cursor
    useCallback(
      (res: ListCommunicationsResponse, [, page]: FetchDataArguments) => {
        dispatch({
          type: CommunicationActionType.DataLoaded,
          data: res.communications.map(convertKeyPacketToCommunication),
          total: res.total,
          page,
        });
      },
      []
    )
  );

  useEffect(() => {
    if (!isEqual(filterRef.current, filters)) {
      // If the filters have changed, we need to clear that data that is already loaded before replacing
      // with the updated data.
      if (!isEqual(state, initialState)) {
        dispatch({
          type: CommunicationActionType.Reset,
        });
      }

      // Start fetching the data as soon as the component mounts or if the filters change
      fetch(filters, 0);

      // Update the filter ref for the future. This is required to do a deep equal on the filters
      // and prevent a render cycle when non-memoized filters are provided.
      filterRef.current = filters;
    }
  }, [fetch, filters, state]);

  const loadMore = useCallback(() => {
    fetch(filters, state.page + 1);
  }, [fetch, filters, state.page]);

  const hasData = Array.isArray(state.data) && state.data.length > 0;
  const isInitialLoading = !state.data && loading;

  return {
    data: state.data,
    total: state.total,
    loading,
    error,
    loadMore,
    hasData,
    isInitialLoading,
  };
}

export type UseCommunicationsReturn = ReturnType<typeof useCommunications>;
