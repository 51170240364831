import { useCallback, useEffect, useState } from 'react';
import { GetStatsParams, GetStatsResponse } from 'backend/api-types/dashboard';
import { dashboardService } from 'backend/services';
import useAsyncAction from 'shared/hooks/useAsyncAction';

const initialFilters: Partial<GetStatsParams> = {};

export default function useStats(
  filters = initialFilters,
  currentBusinessId?: number
) {
  const [data, setData] = useState<GetStatsResponse | undefined>();
  const [fetch, loading, { error, success }] = useAsyncAction<
    GetStatsResponse,
    [GetStatsParams]
  >(
    useCallback(async (params: GetStatsParams) => {
      const res = await dashboardService.get<GetStatsResponse>('/stats', {
        params,
      });
      return res.data;
    }, []),

    useCallback(
      (res: GetStatsResponse) => {
        setData(res);
      },
      [setData]
    )
  );

  useEffect(() => {
    fetch(filters);
  }, [filters, currentBusinessId, fetch]);

  return {
    data,
    loading,
    error,
    success,
    fetch,
  };
}
