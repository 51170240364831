
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.83,"time":49800,"words":166}
/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const CodeView = makeShortcode("CodeView");
const Code = makeShortcode("Code");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "authentication"
    }}>{`Authentication`}</h1>
    <p>{`How to authorize a request.`}</p>
    <h2 {...{
      "id": "access-token-types"
    }}>{`Access Token Types`}</h2>
    <p>{`Donec sed tristique massa. Morbi convallis ante quam. Sed pulvinar pretium faucibus. Fusce imperdiet ligula a lectus dictum placerat. Quisque eget nunc justo. Donec mattis commodo risus, at vestibulum nisi auctor id. Cras at odio vel lectus vehicula condimentum. Fusce mi tellus, viverra vel lacus et, dictum consectetur justo. Etiam eu consectetur leo. Integer non metus sed nisi fringilla sodales sit amet at nisi. Donec euismod augue urna. In ac metus id quam tincidunt sollicitudin in nec diam. Nullam sed efficitur est. Duis in nibh vel felis dignissim laoreet. Aenean viverra justo purus, non accumsan nisi tempor a.`}</p>
    <CodeView mdxType="CodeView">
    <Code language="javascript" title='Javascript' mdxType="Code">
        {`
var a = 1;
console.log('Hello');
`}
    </Code>
    <Code language="php" title='PHP' mdxType="Code">
        {`
<?php
  echo "Hello World!";
  echo "PHP is so easy!";
?>
`}
    </Code>
    </CodeView>
    <h2 {...{
      "id": "request-headers"
    }}>{`Request Headers`}</h2>
    <p>{`How are the request headers formed`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "access-token-types",
    level: 2,
    title: "Access Token Types",
    children: [
        
      ]
  },
{
    id: "request-headers",
    level: 2,
    title: "Request Headers",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Authorization and Access",
  "description": "Authorization and Access",
  "route": "/docs/authentication",
  "disableTableOfContents": false
}

