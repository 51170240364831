import { useToast } from '@chakra-ui/react';
import {
  useConfirmDialog,
  useConfirmDialogAction,
  useConfirmDialogDisclosure,
} from 'shared/hooks/confirm-dialog';
import { formatErrorResponse } from 'shared/utils/errors';
import { HookSummary } from 'backend/api-types/dashboard';
import { useDeleteWebhook } from 'webhooks/WebhooksController';

export default function useDeleteWebhookDialog(appId: number) {
  const toast = useToast();
  const disclosure = useConfirmDialogDisclosure<number, HookSummary>();
  const action = useConfirmDialogAction(
    useDeleteWebhook(
      appId,
      () => {
        toast({
          title: 'Webhook successfully deleted',
          description: disclosure.record?.url,
          status: 'success',
          position: 'bottom-right',
        });
        disclosure.onClose();
      },
      (err) => {
        toast({
          title: 'Error deleting webhook',
          description: formatErrorResponse(err).message,
          status: 'error',
          position: 'bottom-right',
        });
      }
    )
  );
  return useConfirmDialog<number, HookSummary>(disclosure, action);
}
