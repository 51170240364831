const root = '/contacts';
const baseRoute = (path = '') => `${root}${path}`;

export default {
  root,
  detail: {
    activity: (id: number | string = ':contactId') =>
      baseRoute(`/${id}/activity`),
    communications: (id: number | string = ':contactId') =>
      baseRoute(`/${id}/communications`),
  },
} as const;
