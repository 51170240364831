import React from 'react';
import { Box, BoxProps, Tab, TabList, Tabs } from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import Tablelvl2Context, { Tablelvl2ContextValues } from './Tablelvl2Context';

export const MINIMUM_STICKY_VIEWPORT_HEIGHT = 500;

export type TableProps = BoxProps &
  React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >;

export interface TableNavItem {
  label: string;
  route: string;
}
export interface TableNavProps {
  navItems: TableNavItem[];
}

export const TableNav: React.FC<TableNavProps> = ({ navItems, ...props }) => {
  // Maintain the route state across tab navigation
  const { state } = useLocation();

  return (
    <Tabs index={-1} size="lg" {...props}>
      <TabList>
        {navItems.map(({ label, route }) => (
          <Tab
            as={NavLink}
            to={{
              pathname: route,
              state,
            }}
            key={label}
            replace
          >
            {label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export const Table: React.FC<TableProps> = ({ children, ...props }) => {
  return (
    <Box
      as="table"
      cellSpacing={0}
      cellPadding={0}
      border={0}
      width="100%"
      sx={{
        borderCollapse: 'separate',
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const tablelvl2TableHead: Tablelvl2ContextValues = {
  variant: 'head',
};

export type TableHeadProps = BoxProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >;

export const TableHead: React.FC<TableHeadProps> = ({ children, ...props }) => {
  return (
    <Tablelvl2Context.Provider value={tablelvl2TableHead}>
      <Box as="thead" {...props}>
        {children}
      </Box>
    </Tablelvl2Context.Provider>
  );
};

export type TableBodyProps = BoxProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >;

export const TableBody: React.FC<TableBodyProps> = ({ children, ...props }) => {
  return (
    <Box as="tbody" {...props}>
      {children}
    </Box>
  );
};

export type TableFooterProps = BoxProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >;

export const TableFooter: React.FC<TableFooterProps> = ({
  children,
  ...props
}) => {
  return (
    <Box as="tfoot" {...props}>
      {children}
    </Box>
  );
};

export type TableRowProps = BoxProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >;

export const TableRow: React.FC<TableRowProps> = ({ children, ...props }) => {
  const { onClick } = props;
  return (
    <Box
      as="tr"
      _hover={{ bg: onClick ? 'blue.50' : 'inherit' }}
      cursor={onClick ? 'pointer' : 'inherit'}
      {...props}
    >
      {children}
    </Box>
  );
};
