import React from 'react';
import { Link, LinkProps, Image } from '@chakra-ui/react';

interface ProductLinkProps extends LinkProps {
  image: string;
  name?: string;
}

export const ProductLink: React.FC<ProductLinkProps> = ({
  children,
  name,
  image,
  ...props
}) => {
  return (
    <Link
      justifyContent="center"
      alignItems="center"
      display="inline-flex"
      flexDirection="column"
      mb={2}
      textDecoration="none"
      color="gray.500"
      isExternal
      {...props}
    >
      <Image height={10} src={image} alt={name} ignoreFallback />
      {name && name}
      {children}
    </Link>
  );
};

export default ProductLink;
