import { Avatar, Flex, Text } from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';
import * as React from 'react';

export interface UserProfileProps {
  image?: string;
  name: string;
}

const UserProfile: React.FC<UserProfileProps> = ({ image, name }) => {
  return (
    <Flex alignItems="center">
      <Flex alignItems="center" flexWrap="nowrap">
        <Avatar bg="blue.500" src={image} name={name} size="sm" />
        <Flex as="span" fontWeight="bold" ml={3} whiteSpace="nowrap">
          <Text as="span" maxWidth={{ md: '150px', xl: 'none' }} isTruncated>
            {name}
          </Text>
        </Flex>
        <TriangleDownIcon boxSize={2} mx={1} />
      </Flex>
    </Flex>
  );
};

export default UserProfile;
