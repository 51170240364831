import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { ChaptersDict } from '../types';
import LivePlaygroundAlert from './LivePlaygroundAlert';

interface ChapterHeaderProps {
  tutorial: string;
  chapters: ChaptersDict;
  chapterNum: number;
}

const ChapterHeader: React.FC<ChapterHeaderProps> = ({
  tutorial,
  chapters,
  chapterNum,
  ...props
}) => {
  const chapter = chapters[chapterNum];
  const headingText = chapterNum === 0 ? `Tutorial: ${tutorial}` : chapter.name;
  return (
    <Box as="header" mb={6}>
      <LivePlaygroundAlert />
      {chapterNum > 0 && (
        <Text fontWeight="bold" color="gray.500" fontSize="sm" {...props}>
          <Text as="span" color="black" mr={4}>
            Chapter {chapterNum}
          </Text>
          {tutorial}
        </Text>
      )}
      <Heading as="h1" size="xl">
        {headingText}
      </Heading>
    </Box>
  );
};

export default ChapterHeader;
