import React from 'react';
import {
  AlertDescription,
  Alert,
  AlertIcon,
  Flex,
  Button,
  AlertProps,
} from '@chakra-ui/react';

type RequestErrorAlertProps = AlertProps & {
  canTryAgain?: boolean;

  // Make children required
  children: React.ReactNode;
};

const RequestErrorAlert: React.FC<RequestErrorAlertProps> = ({
  canTryAgain = false,
  children,
  ...props
}) => {
  return (
    <Alert
      status="error"
      variant="subtle"
      borderRadius="md"
      flexDirection={{ base: 'column', sm: 'row' }}
      {...props}
    >
      <Flex>
        <AlertIcon boxSize={6} flexShrink={0} />
        <AlertDescription>{children}</AlertDescription>
      </Flex>
      {canTryAgain && (
        <Button
          flexShrink={0}
          mx={{ base: 0, sm: 3 }}
          my={{ base: 3, sm: 0 }}
          variant="solid"
          size="sm"
          type="button"
          colorScheme="red"
          onClick={() => window.location.reload()}
        >
          Try Again
        </Button>
      )}
    </Alert>
  );
};

export default RequestErrorAlert;
