import bind from 'bind-decorator';
import { action, observable } from 'mobx';

class ApplicationState {
  @observable
  public showCreateApplicationModal = false;

  @bind
  @action
  public openCreateApplicationModal() {
    this.showCreateApplicationModal = true;
  }

  @bind
  @action
  public closeCreateApplicationModal() {
    this.showCreateApplicationModal = false;
  }
}

const appState = new ApplicationState();
export default appState;
