import { AxiosInstance } from 'axios';
import bind from 'bind-decorator';
import { computed } from 'mobx';
import {
  ListContactsParams,
  ListContactsResponse,
} from 'backend/api-types/dashboard';
import { dashboardService } from 'backend/services';
import useAsyncData from 'cache/useAsyncData';
import authController from '../AuthenticationController';
import { AccessControls } from '../AccessControls';
import UserController from './UserController';
import { convertContactToUser, UserSummary } from './types';

export default class CurrentUserController extends UserController<
  ListContactsResponse,
  ListContactsParams
> {
  protected _axios: AxiosInstance = dashboardService;

  private _email: string;

  constructor(email: string) {
    super(`current-user:${email}`, '/contact', {
      filter: email,
    });

    this._email = email;
  }

  @bind
  protected _mapData(data: ListContactsResponse): UserSummary {
    // There should only be 1 user per email address, so let's just grab the first one no matter what.
    const contact = data.contacts[0];
    if (!contact) {
      // We couldn't wind a user with this email address. This should never happen but
      // we need to account for the possibility. Throwing here will trigger the same error handling as
      // if the request failed.
      throw new Error(
        `Could not find a user with that email address: ${this._email}`
      );
    }

    return convertContactToUser(contact);
  }

  @computed
  get access(): AccessControls {
    return new AccessControls(this._data);
  }
}

export function useCurrentUser(): CurrentUserController {
  return useAsyncData<CurrentUserController>(
    new CurrentUserController(authController.data?.sub ?? ''),
    !authController.data?.sub
  );
}
