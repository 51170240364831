import React, { useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Grid, Flex, FlexProps } from '@chakra-ui/react';
import routes from 'routes';
import { observer } from 'mobx-react';
import LoadingBox from 'shared/components/LoadingBox';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import authController from 'auth/AuthenticationController';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { useListBusinesses } from 'team/ListBusinessesController';
import Header from './Header';
import Sidebar from './Sidebar';
import { SidebarContextProvider } from './SidebarContext';
import useResetScrollTop from './useResetScrollTop';
import { DashboardContentContextProvider } from './DashboardContentContext';
import backdropBg from './backdrop-bg.svg';

interface BackdropProps extends FlexProps {
  variant?: 'docPadding' | 'default';
}

const Backdrop = React.forwardRef<HTMLDivElement, BackdropProps>(
  ({ variant = 'default', ...props }, _ref) => {
    const variantStyles = {
      default: {},
      docPadding: {
        px: {
          base: 0,
          lg: 1,
        },
        py: 0,
      },
    };

    return (
      <Flex
        ref={_ref}
        py={{ base: 4, lg: 6 }}
        px={{ base: 6, lg: 12 }}
        flex={1}
        direction="column"
        width="100%"
        overflowY={{ base: 'visible', lg: 'auto' }}
        css={{
          WebkitOverflowScrolling: 'touch',
        }}
        // Fixes an issue with the switch component that breaks the height scrolling grid
        // layout, causing a page scroll to appear instead of being contained within the grid.
        // I think it's the absolute position of the hidden checkbox used by this component
        // that breaks out of the bounds of the switch and causes this layout breakage.
        position="relative"
        {...variantStyles[variant]}
        {...props}
      />
    );
  }
);

const DashboardLayout: React.FC = ({ children }) => {
  const user = useCurrentUser();
  const currentBusiness = useCurrentBusiness();
  const listBusinesses = useListBusinesses();

  let isFinished = !authController.loading;
  if (authController.isLoggedIn) {
    // If the user is logged in, we also require some basic information to be fetched
    // before we can render the application.
    isFinished =
      user.finished && currentBusiness.finished && listBusinesses.finished;
  }

  const isRouteWithBackground = useRouteMatch({
    path: [],
    exact: true,
  });
  const isRouteRedoc = useRouteMatch({
    path: [
      routes.docs.subscription,
      routes.docs.validation,
      routes.docs.quantum,
    ],
    exact: true,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  useResetScrollTop(containerRef);

  const bgImage = isRouteWithBackground ? `url(${backdropBg})` : 'none';

  if (!isFinished) {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        h="100vh"
      >
        <LoadingBox text="Loading Application" />
      </Flex>
    );
  }

  return (
    <SidebarContextProvider>
      <Grid
        gridTemplateColumns={{
          base: '100%',
          lg: '240px minmax(0, 1fr)',
        }}
        minHeight={{ base: '100vh', md: 'auto' }}
        height={{ base: 'auto', md: '100vh' }}
        bgColor="backdrop"
        bgImage={bgImage}
        bgAttachment="fixed"
        bgPosition="bottom left"
        bgSize="auto 100%"
        bgRepeat="no-repeat"
      >
        <Sidebar />
        <Flex
          direction="column"
          minHeight={{ base: '100vh', md: 'auto' }}
          height={{ base: 'auto', md: '100vh' }}
          // Top padding spacer to hold space for the fixed position header
          pt={{ base: 16, lg: 0 }}
        >
          <Header />
          <DashboardContentContextProvider containerRef={containerRef}>
            <Backdrop
              ref={containerRef}
              variant={isRouteRedoc ? 'docPadding' : 'default'}
            >
              {children}
            </Backdrop>
          </DashboardContentContextProvider>
        </Flex>
      </Grid>
    </SidebarContextProvider>
  );
};

export default observer(DashboardLayout);
