import React from 'react';

export type Tablelvl2ContextValues = {
  variant: 'head' | 'body';
};

const Tablelvl2Context = React.createContext<Tablelvl2ContextValues>({
  variant: 'body',
});

if (process.env.NODE_ENV !== 'production') {
  Tablelvl2Context.displayName = 'Tablelvl2Context';
}

export default Tablelvl2Context;
