/**
 * Used with the Body component to parse the emitted JSON body into a typed object.
 */
export default function useJSONFormatted<T>(callback: (parsed: T) => void) {
  const handleFormatted = (value: string) => {
    if (value) {
      try {
        const parsed: T = JSON.parse(value);
        callback(parsed);
      } catch (err) {
        // Do nothing
      }
    }
  };

  return handleFormatted;
}
