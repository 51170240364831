import React from 'react';
import { Box, Link } from '@chakra-ui/react';
import { PlanProps } from 'settings/components/plan/Plan';
import routes from 'welcome/routes';

export enum PlanType {
  /**
   * Free account
   */
  Free = 0,

  /**
   * Paid for subscription to the XQ service
   */
  Pro,
  Enterprise,
}

/**
 * Map the available plan types to a more readable alternative
 */
export const readablePlanTypeMap: Record<PlanType, string> = {
  [PlanType.Free]: 'Free',
  [PlanType.Pro]: 'Business',
  [PlanType.Enterprise]: 'Enterprise',
};

export enum SubscriptionStatus {
  /**
   * The workspace is up to date with payments.
   */
  Current = 0,

  /**
   * The last payment failed
   */
  PaymentFailure,
}

export const PLAN_USER_LIMITS = {
  min: 1,
  max: 500,
};

export const BUSINESS_PLAN_USERCOUNT = 5;
export const PLAN_USER_INCREMENT = 5;
export const PLAN_PRICE_PER_UNIT = 1;

export const plans: PlanProps[] = [
  {
    plan: PlanType.Pro,
    features: [
      {
        label: 'Cross platform encryption',
        subFeatures: ['Send data outside your network safely'],
      },
      { label: 'Data Threat Protection', subFeatures: ['Real time alerts'] },
      {
        label: 'Email and Text Encryption Suite of Apps',
        subFeatures: ['Text, Gmail, Outlook, IOS, Android, Slack'],
      },
      {
        label: 'File Encryption',
        subFeatures: ['Send encrypted files to anyone'],
      },
      { label: 'Tracking', subFeatures: ['Know where your data is read'] },
      {
        label: 'Decryption expiration',
        subFeatures: ['Messages expire and are no longer accessible'],
      },
      {
        label: 'Revoke message',
        subFeatures: ['Remove the right to read your data'],
      },
      {
        label: 'API access',
        subFeatures: [
          <Box>
            Connect all your apps.{' '}
            <Link href={routes.install} isExternal whiteSpace="nowrap">
              Learn more
            </Link>
          </Box>,
        ],
      },
      {
        label: 'Revoke user access',
        subFeatures: ['Remove a user or device’s ability to read data'],
      },
    ],
  },
  {
    plan: PlanType.Enterprise,
    features: [
      { label: 'All Business plan features' },
      { label: '500,000 seats per server instance' },
      {
        label: 'On premises XQ deployment',
        subFeatures: [
          'XQ key and policy management in your cloud instance or on site',
        ],
      },
      { label: 'Unlimited API calls' },
      { label: 'Integrate with existing solutions via full API access' },
      { label: '...and more' },
    ],
  },
];
