import * as yup from 'yup';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { useToast } from '@chakra-ui/react';
import CommunicationController from '../CommunicationController';

export type GrantAccessFormValues = {
  recipients: [];
};

const grantAccessFormSchema = yup.object().shape({
  recipients: yup.array<string>().required('Enter y name'),
});

const defaultValues: GrantAccessFormValues = {
  recipients: [],
};

export default function useGrantAccessForm(
  communication: CommunicationController,
  onClose: () => void
): FormHandler<GrantAccessFormValues> {
  const toast = useToast();
  return useFormHandler<GrantAccessFormValues>(
    async ({ recipients }) => {
      await communication.grantAccess(recipients).then(() => {
        communication.execute();
        toast({
          title: 'Access Granted',
          description: 'Recipients updated for this communication',
          status: 'success',
          position: 'bottom-right',
        });
        onClose();
      });
    },
    defaultValues,
    grantAccessFormSchema,
    true
  );
}
