import bind from 'bind-decorator';
import { action, observable } from 'mobx';

export default class ModalState {
  @observable
  public isOpen;

  constructor(isOpen = false) {
    this.isOpen = isOpen;
  }

  @bind
  @action
  public open() {
    this.isOpen = true;
  }

  @bind
  @action
  public close() {
    this.isOpen = false;
  }
}
