import * as React from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  VStack,
  Image,
  Flex,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { StackedInputField } from 'shared/components/form';
import StackedSelectField from 'shared/components/form/StackedSelect/StackedSelectField';
import { ProvincesCodes, StateCodes } from 'shared/constants/regions';
import { CountryCode, countryCodeMap } from 'shared/constants/countries';
import { ProfileFormValues } from './useProfileForm';

const ProfileFormContent: React.FC = () => {
  const business = useCurrentBusiness();
  const { setValue, watch } = useFormContext<ProfileFormValues>();
  const country = watch('country');

  const regions = country === CountryCode.Canada ? ProvincesCodes : StateCodes;
  return (
    <>
      <Flex>
        <Image boxSize={32} src={business.logo} mr={4} objectFit="cover" />
        <Box>
          <Heading as="h2" size="md">
            {business?.data?.name}
          </Heading>
          <Box as="span" display="block">
            {business?.data?.tag}
          </Box>
        </Box>
      </Flex>
      <SimpleGrid columns={[1, 1, 2]} spacing={[4, 4, 16]} mt={[4, 4, 8]}>
        <Box>
          <Heading as="h2" size="md" my={4}>
            Contact Information
          </Heading>
          <VStack spacing={4}>
            <SimpleGrid columns={[1, 2]} spacing={[4, 8]} width="100%">
              <StackedInputField
                name="email"
                type="text"
                label="Primary Email"
                isRequired
              />
              <StackedInputField
                name="phone"
                type="text"
                label="Primary Phone"
              />
            </SimpleGrid>

            <StackedInputField
              name="street"
              type="text"
              label="Street Address"
            />
            <SimpleGrid columns={[1, 2]} spacing={[4, 8]} width="100%">
              <StackedSelectField
                name="country"
                size="lg"
                label="Country"
                onChange={() => {
                  setValue('regionCode', '');
                  setValue('state', '');
                }}
              >
                <option value="" label="Select..." />
                <option value={CountryCode.Canada} label={countryCodeMap.CAN} />
                <option
                  value={CountryCode.UnitedStates}
                  label={countryCodeMap.USA}
                />
              </StackedSelectField>
              <StackedInputField name="city" type="text" label="City" />
            </SimpleGrid>

            <SimpleGrid columns={[1, 2]} spacing={[4, 8]} width="100%">
              <StackedSelectField
                name="state"
                size="lg"
                label={country === CountryCode.Canada ? 'Province' : 'State'}
              >
                <option value="" label="Select..." />
                {regions.map(([label, value]) => (
                  <option key={value} value={value} label={label} />
                ))}
              </StackedSelectField>
              <StackedInputField
                name="regionCode"
                type="text"
                label={country === CountryCode.Canada ? 'Postal Code' : 'ZIP'}
              />
            </SimpleGrid>
          </VStack>
        </Box>
        <Box>
          <Heading as="h2" size="md" my={4}>
            Workspace
          </Heading>
          <VStack spacing={4} mb={8}>
            <StackedInputField
              name="workspaceName"
              type="text"
              label="Organization Name"
              isRequired
            />
            <StackedInputField
              name="domain"
              type="text"
              label="Domain"
              disabled
            />
            <StackedInputField name="tag" type="text" label="Tag Line" />
          </VStack>
        </Box>
      </SimpleGrid>
    </>
  );
};

export default observer(ProfileFormContent);
