import React from 'react';
import { ButtonGroup, ButtonGroupProps, Button } from '@chakra-ui/react';

interface StepNavigationProps extends ButtonGroupProps {
  showNext?: boolean;
  onPrevClick?: () => void;
}

const StepNavigation: React.FC<StepNavigationProps> = ({
  showNext = true,
  onPrevClick,
  ...props
}) => {
  return (
    <ButtonGroup
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      spacing={4}
      mt={8}
      {...props}
    >
      {onPrevClick && (
        <Button
          type="button"
          variant="outline"
          minWidth={32}
          onClick={onPrevClick}
        >
          Back
        </Button>
      )}
      {showNext && (
        <Button type="submit" minWidth={32} ml="auto">
          Next
        </Button>
      )}
    </ButtonGroup>
  );
};

export default StepNavigation;
