import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { useContact } from 'contacts/ContactDetailsController';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { UserStatus } from 'auth/user/types';
import DeleteOrDisableContactSection from './DeleteOrDisableContactSection';
import UpdateContactForm from './UpdateContactForm';

type EditContactModalProps = {
  id: number;
  isOpen?: boolean;
  onClose: () => void;
};

const UpdateContactModal: React.FC<EditContactModalProps> = ({
  isOpen = false,
  onClose,
  id,
}) => {
  const initialFocusRef = useRef(null);

  const contact = useContact(id);
  const currentUser = useCurrentUser();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Team Member</ModalHeader>
        <ModalBody>
          <UpdateContactForm userId={id} />
        </ModalBody>
        {contact.data &&
          currentUser.access.canDisableUser &&
          (contact.data.status === UserStatus.Validated ||
            contact.data.status === UserStatus.Invited) && (
            <>
              <Divider borderColor="gray.50" borderBottomWidth="2px" />
              <DeleteOrDisableContactSection contact={contact.data} disable />
            </>
          )}
        {contact.data && currentUser.access.canDeleteUser && (
          <>
            <Divider borderColor="gray.50" borderBottomWidth="2px" />
            <DeleteOrDisableContactSection
              contact={contact.data}
              disable={false}
            />
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default observer(UpdateContactModal);
