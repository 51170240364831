import React from 'react';
import LoadingBox from 'shared/components/LoadingBox';
import { useListBusinesses } from 'team/ListBusinessesController';
import WelcomeTour from 'tours/WelcomeTour';
import CreateActivity from 'welcome/CreateActivity/CreateActivity';
import CreateTeam from 'welcome/CreateTeam/CreateTeam';

const Onboarding: React.FC = () => {
  const listBusinesses = useListBusinesses();

  if (!listBusinesses.data) {
    return <LoadingBox />;
  }

  return (
    <>
      <WelcomeTour />
      {listBusinesses.data.length ? <CreateActivity /> : <CreateTeam />}
    </>
  );
};

export default Onboarding;
