import { RefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useResetScrollTop(
  ref: RefObject<HTMLDivElement>,
  scrollWindow = true
) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }

    // Mobile screen does not constrain content within a scrollable container,
    // so make sure the window is also scrolled back to the top.
    if (scrollWindow) {
      window.scrollTo(0, 0);
    }
  }, [ref, scrollWindow, pathname]);
}
