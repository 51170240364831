import * as yup from 'yup';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import routes from 'routes';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { PlanType, PLAN_USER_LIMITS } from 'settings/account/plan/constants';
import { usePlanUsage } from 'settings/account/plan/PlanUsageController';
import { PlanPurchaseLocationState } from './useCreatePayMethodForm';
import { usePayMethods } from './PayMethodsController';

export type PlanFormValues = {
  users: number;
};

const planFormSchema = yup.object().shape({
  users: yup.number().required(),
});

export default function usePlanForm(
  plan: PlanType
): FormHandler<PlanFormValues> {
  const business = useCurrentBusiness();
  const payMethods = usePayMethods();
  const planUsage = usePlanUsage();
  const history = useHistory();
  const toast = useToast();

  const currentSeats = business.data?.subscription.seats ?? 0;
  const defaultValues: PlanFormValues = {
    users:
      currentSeats < PLAN_USER_LIMITS.min ? PLAN_USER_LIMITS.min : currentSeats,
  };

  const handleSubmit = async (args: PlanFormValues) => {
    if (payMethods.default) {
      // The business already has a pay method, so we can just update the subscription right away
      await business.updateSubscription(plan, args.users);
      await planUsage.execute();

      // Display a toast notification before moving to the next screen
      toast({
        title: 'Account Upgraded',
        description: `You can now have up to ${args.users} active users`,
        status: 'success',
        position: 'bottom-right',
      });

      history.push(routes.settings.root);
      return;
    }

    // The business doesn't have a pay method yet. We need to create one first
    const locationState: PlanPurchaseLocationState = {
      plan,
      seats: args.users,
    };
    history.push(routes.settings.payment, locationState);
  };

  return useFormHandler<PlanFormValues>(
    handleSubmit,
    defaultValues,
    planFormSchema
  );
}
