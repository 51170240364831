import React from 'react';

import {
  Text,
  Box,
  BoxProps,
  Flex,
  Link,
  LinkProps,
  ImageProps,
  Image,
  FlexProps,
  Heading,
} from '@chakra-ui/react';

export const BorderedBox: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      border="1px"
      borderColor="gray.100"
      borderRadius="md"
      flex={1}
      p={[4, 8]}
      bg="white"
      maxWidth="7xl"
      mx="auto"
      {...props}
    >
      {children}
    </Box>
  );
};

export const Body: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="center"
      maxWidth="3xl"
      {...props}
    >
      {children}
    </Flex>
  );
};

export const Header: React.FC<{ name?: string }> = ({ name }) => {
  return (
    <Box p={[4, 8]} mb={[2, 0]}>
      <Heading size="lg">
        Welcome
        {name && ' ' + name},
      </Heading>
      <Text maxWidth={490} fontSize="xl">
        This is your XQ Dashboard. See all your XQ encryption activities, create
        a team or if you&apos;re a developer create an application.
      </Text>
    </Box>
  );
};

interface ProductLinkProps extends LinkProps {
  image: string;
  name?: string;
}

export const ProductLink: React.FC<ProductLinkProps> = ({
  children,
  name,
  image,
  ...props
}) => {
  return (
    <Box textAlign="center">
      <Link
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        mb={2}
        isExternal
        {...props}
      >
        <Image
          objectFit="scale-down"
          boxSize="100%"
          src={image}
          alt={name}
          height="45px"
        />
        {children}
      </Link>
      {name && (
        <Text
          my={2}
          as="span"
          fontSize="lg"
          textDecoration="none"
          color="gray.500"
        >
          {name}
        </Text>
      )}
    </Box>
  );
};

export const ProductImage: React.FC<ImageProps> = ({ ...props }) => (
  <Box>
    <Image
      objectFit="cover"
      boxSize={{ base: '100%', md: '100%' }}
      {...props}
    />
  </Box>
);
