import DropzoneBox, {
  importContactsFileImage,
} from 'encryption/components/DropzoneBox';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useImportMessages } from './importMessages';

type UnlockMessagesSceneProps = {
  onError: (error: Error) => void;
  onData: (messages: string[][], files: string[][]) => void;
};
const UnlockMessagesScene: React.FC<UnlockMessagesSceneProps> = ({
  onError,
  onData,
}) => {
  const [importMessages, loading, { error }] = useImportMessages(
    async (encryptedMessages, encryptedFiles) => {
      onData(encryptedMessages, encryptedFiles);
    }
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const res = await importMessages(acceptedFiles[0]);
      if (!res) {
        if (error) {
          onError(error);
        } else onError(new Error('Failed to load data'));
      }
    },
    [error, importMessages, onError]
  );

  const { getRootProps, getInputProps, ...dropzoneState } = useDropzone({
    onDrop,
  });

  return (
    <DropzoneBox
      root={getRootProps()}
      input={getInputProps()}
      iconImage={importContactsFileImage}
      dropMessage="Drop your message(s) file"
      isLoading={loading}
      loadingText="Searching for Encrypted Messages"
      {...dropzoneState}
    />
  );
};

export default UnlockMessagesScene;
