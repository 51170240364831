import { AxiosInstance } from 'axios';
import { computed } from 'mobx';
import authController from 'auth/AuthenticationController';
import { SeatsUsageSummary } from 'backend/api-types/dashboard';
import { dashboardService } from 'backend/services';
import { SimpleAsyncData } from 'cache/AsyncData';
import useAsyncData from 'cache/useAsyncData';

/**
 * This represents the current plan usage for a business. The data should probably be moved to reside in the
 * CurrentBusinessController, but it is okay here for now.
 */
export default class PlanUsageController extends SimpleAsyncData<
  SeatsUsageSummary,
  void
> {
  protected _axios: AxiosInstance = dashboardService;

  constructor() {
    super('seats', '/seats');
  }

  @computed
  public get isLimitReached() {
    if (!this.data) {
      return false;
    }

    return this.data?.used >= this.data?.plan;
  }
}

type UsePlanUsageOptions = {
  /** Skip the initial fetching of data. */
  skip?: boolean;
};

export function usePlanUsage(
  options: UsePlanUsageOptions = {}
): PlanUsageController {
  const { skip = false } = options;
  return useAsyncData<PlanUsageController>(
    new PlanUsageController(),
    skip || !authController.isLoggedIn
  );
}
