import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from 'routes';
import useTutorialAPIKey from '../useTutorialAPIKey';
import { EncryptTutorialProvider } from './EncryptTutorialContext';
import Chapter0 from './Chapter0';
import Chapter1 from './Chapter1';
import Chapter2 from './Chapter2';
import Chapter3 from './Chapter3';
import Chapter4 from './Chapter4';
import Chapter5 from './Chapter5';

const {
  tutorials: { encrypt },
} = routes;

const EncryptRoutes: React.FC = () => {
  const { apiKey } = useTutorialAPIKey();
  return (
    <EncryptTutorialProvider>
      <Switch>
        <Route path={encrypt.chapter0} exact>
          <Chapter0 apiKey={apiKey} />
        </Route>

        {!apiKey && <Redirect to={encrypt.chapter0} />}

        <Route path={encrypt.chapter1} exact>
          <Chapter1 apiKey={apiKey} />
        </Route>
        <Route path={encrypt.chapter2} exact>
          <Chapter2 />
        </Route>
        <Route path={encrypt.chapter3} exact>
          <Chapter3 apiKey={apiKey} />
        </Route>
        <Route path={encrypt.chapter4} exact>
          <Chapter4 apiKey={apiKey} />
        </Route>
        <Route path={encrypt.chapter5} exact>
          <Chapter5 />
        </Route>

        <Redirect to={encrypt.chapter0} />
      </Switch>
    </EncryptTutorialProvider>
  );
};

export default EncryptRoutes;
