import React from 'react';
import {
  ButtonGroup,
  ButtonGroupProps,
  Button,
  Select,
} from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import cx from 'classnames';
import { DateRangePreset } from 'event-log/components/DatePicker/constants';

interface DateFilterNavProps extends Omit<ButtonGroupProps, 'onSelect'> {
  presets: DateRangePreset[];
  selectedPreset?: DateRangePreset;
  onSelect: (preset?: DateRangePreset) => void;
}

const DateFilterNav: React.FC<DateFilterNavProps> = ({
  presets,
  selectedPreset,
  onSelect,
  ...props
}) => {
  const isSelectNav = useBreakpointValue({ base: true, sm: false });
  const allTimeValue = undefined;

  // Display the nav as a dropdown select when the viewport is too small to show the button group.
  if (isSelectNav) {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      onSelect(presets[Number(value)]);
    };

    const selectedPresetIndex = presets.findIndex(
      (p) => p.label === selectedPreset?.label
    );

    const value = selectedPresetIndex >= 0 ? selectedPresetIndex : allTimeValue;

    return (
      <Select mb={4} onChange={handleChange} value={value}>
        {presets.map((filter, idx) => (
          <option key={filter.label} value={idx}>
            {filter.label}
          </option>
        ))}
        <option value={allTimeValue}>All Time</option>
      </Select>
    );
  }

  return (
    <ButtonGroup flexWrap="wrap" spacing={4} {...props}>
      {presets.map((preset) => (
        <Button
          className={cx({ active: preset.label === selectedPreset?.label })}
          variant="activeSolid"
          size="xs"
          type="button"
          key={preset.label}
          onClick={() => onSelect(preset)}
        >
          {preset.label}
        </Button>
      ))}
      <Button
        className={cx({ active: selectedPreset === undefined })}
        variant="activeSolid"
        size="xs"
        type="button"
        onClick={() => onSelect(undefined)}
      >
        All Time
      </Button>
    </ButtonGroup>
  );
};

export default React.memo(DateFilterNav);
