import React from 'react';
import { Box, Button, Flex, Heading, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

const PaymentSuccess: React.FC = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push(routes.settings.root);
  };
  return (
    <Flex
      direction="column"
      mx="auto"
      mt={8}
      p={8}
      px={16}
      border="solid 1px"
      borderColor="gray.50"
      maxWidth="100%"
      minWidth="350px"
      align="center"
    >
      <VStack spacing={8}>
        <Heading as="h1" size="lg">
          Payment Successful
        </Heading>
        <Box>
          <Button ml="auto" onClick={handleClick}>
            Back to Settings
          </Button>
        </Box>
      </VStack>
    </Flex>
  );
};

export default PaymentSuccess;
