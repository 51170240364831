import { useCallback, useContext, useEffect, useMemo } from 'react';
import Crypto from 'crypto-js';
import { format } from 'date-fns';
import { useToast } from '@chakra-ui/react';
import { dashboardService } from 'backend/services';
import {
  SendEmailRequest,
  SendEmailResponse,
} from 'backend/api-types/dashboard';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import useQuantumEntropy from 'shared/hooks/useQuantumEntropy';
import { useFormHandler } from 'shared/hooks/useFormHandler';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { CommunicationType } from 'key-packet/types';
import EncryptMessageContext from '../EncryptMessageContext';
import {
  buildDecryptionLink,
  EncryptionAlgorithm,
  storeEncryptionKey,
} from '../encryptServices';

const salt = Crypto.lib.WordArray.random(64);

// This is a backup incase the fetching the quantum entropy fails.
const backupPassword = Crypto.lib.WordArray.random(64);

export default function useEncryptMessageForm() {
  const toast = useToast();

  const currentUser = useCurrentUser();
  const currentUserEmail = currentUser.data?.email;

  const { goNext, message, expireInHours, recipients, subject } = useContext(
    EncryptMessageContext
  );
  const entropy = useQuantumEntropy();
  const finishedFetchingEntropy = !!(entropy.success || entropy.error);

  // Build the key with the quantum entropy
  const key = Crypto.PBKDF2(entropy.data ?? backupPassword.toString(), salt, {
    keySize: 128,

    // We don't need very many iterations since we will not be reusing this password ever again.
    // We are going to put faith in the quantum entropy being random.
    iterations: 10,
  }).toString();

  // Create a safe function for storing the encryption key. We can't actually call the function
  // until the quantum entropy is finished fetching though.
  const [storeKey, , keyPacketMeta] = useAsyncAction(storeEncryptionKey);
  const finishedStoringKey = !!(keyPacketMeta.data || keyPacketMeta.error);

  const expireInMilliseconds = (expireInHours ?? 0) * 60 * 60 * 1000;
  const defaultValues = useMemo(() => {
    if (!finishedFetchingEntropy) {
      return {
        content: '',
      };
    }

    const encryptedMessage = message
      ? Crypto.AES.encrypt(message, key).toString()
      : '';

    const expirationFormatted = format(
      Date.now() + expireInMilliseconds,
      'LLL d, YYY h:mm:ss a'
    );
    const expirationHumanized = expireInMilliseconds
      ? `and will expire on ${expirationFormatted}`
      : '';
    const link = buildDecryptionLink(
      encryptedMessage,
      keyPacketMeta.data ?? '',
      recipients ?? []
    );
    return {
      content: `XQ Secure Message - click to view:

${link}

This message is from ${currentUserEmail} ${expirationHumanized}.
`,
    };
  }, [
    finishedFetchingEntropy,
    message,
    key,
    expireInMilliseconds,
    keyPacketMeta.data,
    recipients,
    currentUserEmail,
  ]);

  // Store the key as soon as the quantum entropy is finished fetching.
  //
  // NOTE: We need the locator token from storing the key to embed in the message link,
  // so we can't generate the link until the entropy is fetched, and the key is stored.
  // This means that a new key will be stored anytime that the user refreshes the screen.
  //
  // NOTE:
  // Make sure the message is present before actually storing the key. Their is a small
  // race condition after a message has been sent where the local storage state is cleared,
  // but we haven't actually sent the user to a different screen yet. This can cause a second
  // key to be stored for an empty message.
  useEffect(() => {
    if (finishedFetchingEntropy && key && message) {
      storeKey(
        key,
        EncryptionAlgorithm.AES,
        CommunicationType.Email,
        expireInHours,
        recipients,
        {
          // Apply a default subject to replicate how GMail handles missing subjects
          subject: subject || '(no subject)',
        }
      );
    }
  }, [
    expireInHours,
    finishedFetchingEntropy,
    key,
    message,
    recipients,
    storeKey,
    subject,
  ]);

  const sendMessage = useCallback(async () => {
    if (!recipients || !recipients.length) {
      throw new Error('Missing list of recipients');
    }

    if (!keyPacketMeta.data) {
      throw new Error('Key has not finished being stored yet');
    }

    if (!expireInMilliseconds) {
      throw new Error('You must first set an expiration date');
    }

    if (!message) {
      throw new Error('Missing message');
    }

    const encryptedMessage = Crypto.AES.encrypt(message, key).toString();
    const request: SendEmailRequest = {
      encrypted: encryptedMessage,
      token: keyPacketMeta.data,
      recipients,
      expiration: Date.now() + expireInMilliseconds,
      subject,
    };
    await dashboardService.post<SendEmailResponse>('/encryptedmail', request);

    toast({
      title: 'Your encrypted message has been sent',
      status: 'success',
      position: 'bottom-right',
    });
    goNext({});
  }, [
    expireInMilliseconds,
    goNext,
    key,
    keyPacketMeta.data,
    message,
    recipients,
    subject,
    toast,
  ]);

  const formHandler = useFormHandler<{ content: string }>(
    sendMessage,
    defaultValues,
    undefined,
    true
  );
  return {
    ...formHandler,
    loading: !finishedStoringKey,
    error: formHandler.error,
    encryptionError: keyPacketMeta.error,
  };
}
