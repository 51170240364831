import React from 'react';
import { Route } from 'react-router-dom';
import MDXPage from './MDXPage';
import { pages } from './pages';

const routes = pages.map((p) => {
  if (!p.frontMatter.route) {
    return null;
  }
  return (
    <Route key={p.frontMatter.route} path={p.frontMatter.route} exact>
      <MDXPage
        Component={p.default}
        frontMatter={p.frontMatter}
        tableOfContents={p.tableOfContents()}
        baseRoute={p.frontMatter.route}
      />
    </Route>
  );
});

const DocumentRoutes: React.FC = () => {
  return <>{routes}</>;
};

export default DocumentRoutes;
