import bind from 'bind-decorator';
import { action, observable } from 'mobx';
import AsyncObservable from './AsyncObservable';

export default class Cache {
  @observable
  private requests: Map<string, AsyncObservable> = new Map();

  @bind
  public getItem<T extends AsyncObservable>(key: string): T | undefined {
    if (this.requests.has(key)) {
      return this.requests.get(key) as T;
    }
  }

  @bind
  public setItem<T extends AsyncObservable>(key: string, item: T): void {
    this.requests.set(key, item);
  }

  @bind
  @action
  public reset(): void {
    this.requests.clear();
  }

  @bind
  @action
  public removeAllExcept(...exemptKeys: string[]): void {
    for (const k of this.requests.keys()) {
      // Using 'startsWith' because the user's email address if appended to end of keys
      // to make them unique per user.
      const isExempt = exemptKeys.find((e) => k.startsWith(e));
      if (!isExempt) {
        this.requests.delete(k);
      }
    }
  }
}
