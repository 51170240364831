import { subscriptionService, validationService } from 'backend/services';
import {
  EncryptionAlgorithm,
  XQ_MESSAGE_END_TAG,
  XQ_MESSAGE_START_TAG,
} from 'encryption/encrypt/encryptServices';

export function deconstructQueryToData(data: string) {
  // Remove any leading/trailing whitespace around the message tags
  let trimmed = data.trim();

  if (
    !trimmed ||
    !trimmed.startsWith(XQ_MESSAGE_START_TAG) ||
    !trimmed.endsWith(XQ_MESSAGE_END_TAG)
  ) {
    throw new Error('Malformed Data');
  }

  trimmed = trimmed
    .substring(
      XQ_MESSAGE_START_TAG.length,
      trimmed.length - XQ_MESSAGE_END_TAG.length
    )
    // Remove any leading/trailing whitespace between the message tags and the actual data
    .trim();

  const locator = trimmed.substr(0, 43);
  const encrypted = trimmed.substring(43);

  if (!locator || locator.length !== 43 || !encrypted) {
    // This should never actually happen. It means the the link was created on an edge client that is not respecting the
    // expected format.
    throw new Error('Malformed Data');
  }

  return { locator, encrypted };
}

export function prefixToAlgorithm(prefix: string): EncryptionAlgorithm {
  switch (prefix) {
    case '.A':
      return EncryptionAlgorithm.AES;
    case '.X':
      return EncryptionAlgorithm.XOR;
    case '.B':
      return EncryptionAlgorithm.OTP;
    default:
      throw new Error('Unsupported key prefix');
  }
}

export async function fetchEncryptionKey(locator: string) {
  const subscriber = await subscriptionService.get<string>('/exchange', {
    params: {
      request: 'dashboard',
    },
  });

  const keyRequest = await validationService.get<string>(
    `/key/${encodeURIComponent(locator)}`,
    {
      headers: {
        // Use the bearer token for the subscriber
        Authorization: `Bearer ${subscriber.data}`,
      },
      validateStatus: (status) => status < 500,
    }
  );

  if (keyRequest.status === 410) {
    throw new Error(
      "We're sorry, but access to this data has been revoked or has expired"
    );
  }
  if (keyRequest.status !== 200) {
    throw new Error("We're sorry, access to decrypt this is denied");
  }

  const prefix = keyRequest.data.substring(0, 2);
  const key = keyRequest.data.substring(2);
  return {
    key,
    algorithm: prefixToAlgorithm(prefix),
  };
}
