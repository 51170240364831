import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Flex, Heading, Button, Icon } from '@chakra-ui/react';
import { IoIosAdd } from 'react-icons/io';
import { observer } from 'mobx-react';
import routes from 'routes';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import contactState from './ContactState';

const ContactsHeader: React.FC = () => {
  const user = useCurrentUser();

  return (
    <Flex alignItems="center">
      <Heading fontSize="2xl">Team</Heading>
      <Switch>
        <Route path={routes.contacts.root} exact>
          {user.access.canAddContact && (
            <Button
              variant="outline"
              size="xs"
              type="button"
              ml={3}
              leftIcon={<Icon as={IoIosAdd} boxSize={6} />}
              onClick={contactState.openCreateContactModal}
            >
              Add New
            </Button>
          )}
        </Route>
      </Switch>
    </Flex>
  );
};

export default observer(ContactsHeader);
