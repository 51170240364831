import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  Checkbox,
  Flex,
  Icon,
  Portal,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuGroup,
  Input,
  Text,
  MenuItemProps,
  MenuDivider,
  Circle,
} from '@chakra-ui/react';
import LevelIndicatorIcon from 'event-log/components/LevelIndicatorIcon';
import { DateRange } from 'event-log/list/useEventFilters';
import { EventThreatLevel } from 'event-log/list/useEventLog';
import { HiChevronDown } from 'react-icons/hi';

const ActiveCount: React.FC<BoxProps> = (props) => (
  <Circle
    display="inline-flex"
    justifyContent="center"
    alignItems="center"
    fontSize="xs"
    fontWeight="bold"
    size={4}
    color="white"
    bg="blue.500"
    position="relative"
    top="-1px"
    {...props}
  />
);

interface FilterMenuItemProps extends Filter {
  onClick: (id: string | number) => void;
}

const FilterMenuItem: React.FC<FilterMenuItemProps> = React.memo(
  ({ onClick, id, isChecked, children }) => {
    return (
      <MenuItem
        value={String(id)}
        onClick={() => {
          onClick(id);
        }}
      >
        <Flex>
          <Checkbox
            isChecked={isChecked}
            mr={2}
            boxSize={5}
            onChange={() => {
              onClick(id);
            }}
          />
          {children}
        </Flex>
      </MenuItem>
    );
  }
);

export interface Filter {
  id: string | number;
  isChecked: boolean;
  label: string;
  threat?: EventThreatLevel;
  dateRange?: DateRange;
}

function stopPropagation(
  e:
    | React.KeyboardEvent<HTMLInputElement>
    | React.MouseEvent<HTMLInputElement, MouseEvent>
) {
  e.stopPropagation();
}

const PlainMenuItem: React.FC<MenuItemProps> = React.memo(
  ({ children, ...props }) => {
    return (
      <MenuItem
        _hover={{ cursor: 'default' }}
        _focus={{ background: 'inherit' }}
        _active={{ background: 'inherit' }}
        {...props}
      >
        {children}
      </MenuItem>
    );
  }
);

export type FilterMenuProps = BoxProps & {
  name: string;
  activeFilterCount?: number;
  closeOnSelect?: boolean;
  options: Filter[];
  onFilterClick: (id: string | number) => void;
  searchable?: boolean;
  searchPlaceholder?: string;
};

const FilterMenu: React.FC<FilterMenuProps> = ({
  name,
  options,
  activeFilterCount = 0,
  onFilterClick,
  closeOnSelect = false,
  searchable = false,
  searchPlaceholder = 'Filter...',
  ...props
}) => {
  const [filterValue, setFilterValue] = useState('');
  const filteredOptions = useMemo(() => {
    const filtered = options.filter((f) => {
      if (!filterValue) {
        return true;
      }

      return (
        f.isChecked || f.label.toUpperCase().includes(filterValue.toUpperCase())
      );
    });

    if (searchable) {
      // If the menu is searchable, we want to float the selected items to the top of the menu.
      // This is done in place, so make sure the array is copied first.
      filtered.sort((a, b) => {
        return Number(b.isChecked) - Number(a.isChecked);
      });
    }

    return filtered;
  }, [options, filterValue, searchable]);

  const onChangeFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterValue(e.currentTarget.value);
      e.stopPropagation();
    },
    []
  );

  return (
    <Box {...props}>
      <Menu closeOnSelect={closeOnSelect} closeOnBlur>
        <MenuButton
          as={Box}
          cursor="pointer"
          userSelect="none"
          whiteSpace="nowrap"
        >
          {activeFilterCount > 0 && (
            <ActiveCount mr={1}>{activeFilterCount}</ActiveCount>
          )}
          {name}
          <Icon boxSize={5} as={HiChevronDown} />
        </MenuButton>
        <Portal>
          <MenuList maxHeight="400px" overflowY="auto">
            {searchable && (
              <PlainMenuItem py={2}>
                <Input
                  onChange={onChangeFilter}
                  onKeyDown={stopPropagation}
                  onClick={stopPropagation}
                  placeholder={searchPlaceholder}
                  size="sm"
                />
              </PlainMenuItem>
            )}
            <MenuGroup>
              {filteredOptions.map((f) => (
                <FilterMenuItem {...f} key={f.id} onClick={onFilterClick}>
                  <Flex alignItems="center">
                    {f.threat !== undefined && (
                      <Box mr={3}>
                        <LevelIndicatorIcon threat={f.threat} />
                      </Box>
                    )}
                    {f.label}
                  </Flex>
                </FilterMenuItem>
              ))}
              {filteredOptions.length === 0 && (
                <>
                  <MenuDivider />
                  <PlainMenuItem display="flex" justifyContent="center">
                    <Text color="gray.500" fontWeight="bold" fontSize="lg">
                      No Results
                    </Text>
                  </PlainMenuItem>
                </>
              )}
            </MenuGroup>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

export default React.memo(FilterMenu);
