import React from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import routes from 'routes';
import { HookSummary } from 'backend/api-types/dashboard';
import { DeleteAlertDialog } from 'shared/components/alert-dialogs';
import LoadingBox from 'shared/components/LoadingBox';
import { formatErrorResponse } from 'shared/utils/errors';
import RequestErrorAlert from 'shared/components/RequestErrorAlert';
import { ApplicationSummary } from 'application/ApplicationsController';
import {
  useWebhooks,
  useUpdateWebhookStatus,
} from 'webhooks/WebhooksController';
import useDeleteWebhookDialog from 'webhooks/delete/useDeleteWebhookDialog';
import CreateWebhookModal from 'webhooks/CreateWebhookModal';
import DocumentLink from './DocumentLink';
import WebhooksList from './WebhooksList';
import { SectionHeader } from './styled';

interface WebhooksSectionProps {
  app: ApplicationSummary;
}

const WebhooksSection: React.FC<WebhooksSectionProps> = ({ app }) => {
  const webhooks = useWebhooks(app.id);
  const toast = useToast();
  const webhookModalDisclosure = useDisclosure();
  const deleteDialog = useDeleteWebhookDialog(app.id);
  const [handleStatusChange] = useUpdateWebhookStatus(
    app.id,
    undefined,
    (err) => {
      toast({
        title: 'Error changing webhook status',
        description: formatErrorResponse(err).message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  );

  if (webhooks.loading) {
    return <LoadingBox />;
  }

  const handleCreateSuccess = (webhook: HookSummary) => {
    toast({
      title: 'New webhook successfully created',
      description: webhook.url,
      status: 'success',
      position: 'bottom-right',
    });
    webhookModalDisclosure.onClose();
  };

  const hasWebhooks = !!webhooks?.data?.length;
  const failedWebHooks = !hasWebhooks && !!webhooks.error;
  return (
    <>
      <SectionHeader>
        <div>
          <Heading fontSize="2xl" textTransform="uppercase" flex="none">
            Webhooks
          </Heading>
          <Text fontSize="sm" mt={2}>
            Webhooks are triggered when events take place in your application.
          </Text>
        </div>
        <DocumentLink href={routes.docs.webHooks} mt={2}>
          Learn more about webhooks
        </DocumentLink>
      </SectionHeader>

      {hasWebhooks && (
        <Box
          overflowX={{ base: 'auto', md: 'visible' }}
          css={{ WebkitOverflowScrolling: 'touch' }}
        >
          <WebhooksList
            appId={app.id}
            webhooks={webhooks.data ?? []}
            onDelete={deleteDialog.onOpen}
            onStatusChange={handleStatusChange}
          />
        </Box>
      )}
      {failedWebHooks && (
        <RequestErrorAlert canTryAgain m={3} width="auto">
          Failed to load webhooks
        </RequestErrorAlert>
      )}
      {!failedWebHooks && (
        <Box my={8} px={6}>
          <Button
            type="button"
            size="xs"
            px={4}
            colorScheme="darkgray"
            onClick={webhookModalDisclosure.onOpen}
          >
            {hasWebhooks ? 'Add a new webhook' : 'Add your first webhook'}
          </Button>
        </Box>
      )}

      <CreateWebhookModal
        appId={app.id}
        isOpen={webhookModalDisclosure.isOpen}
        onClose={webhookModalDisclosure.onClose}
        onSuccess={handleCreateSuccess}
      />
      <DeleteAlertDialog<number, HookSummary>
        title="Delete Webhook"
        content="Are you sure you want to delete this webhook?"
        renderDescription={(webhook) => webhook.url}
        {...deleteDialog}
      />
    </>
  );
};

export default observer(WebhooksSection);
