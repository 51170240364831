import { observable, computed } from 'mobx';

export default abstract class AsyncObservable<T = unknown, E = Error> {
  @observable
  protected _data: T | null = null;

  @observable
  private _loading = false;

  @observable
  private _error: E | null = null;

  @observable
  private _finished = false;

  @computed
  public get loading(): boolean {
    return this._loading;
  }

  @computed
  public get error(): E | null {
    return this._error;
  }

  @computed
  public get data(): T | null {
    return this._data;
  }

  // Whether or not the execution has ever been run to completion
  @computed
  public get finished(): boolean {
    return this._finished;
  }

  public async execute(): Promise<T | undefined> {
    let result: T | undefined = undefined;

    this._error = null;
    this._loading = true;
    try {
      result = await this._execute();
      this._data = observable(result);
    } catch (err) {
      this._error = err;
    }

    /**
     * NOTE: It is important that the finished state is set to true before the
     * loading state is reset. Otherwise there is a very small window where
     * loading is false, and finished is also false. That can cause a second
     * redundant fetching of the data
     */
    this._finished = true;
    this._loading = false;

    return result;
  }

  protected abstract _execute(): Promise<T>;
}
