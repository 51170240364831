import React from 'react';
import { Icon, IconProps, Tooltip } from '@chakra-ui/react';
import {
  HiCheckCircle,
  HiExclamationCircle,
  HiQuestionMarkCircle,
} from 'react-icons/hi';
import {
  EventThreatLevel,
  EventThreatGroup,
  eventThreatGroupsByLevel,
} from 'event-log/list/useEventLog';

type IconPropsConfig = {
  as: React.ElementType;
  tooltip: string;
  color: string;
};

const iconPropsByThreatGroup: Record<EventThreatGroup, IconPropsConfig> = {
  good: {
    as: HiCheckCircle,
    color: 'success.600',
    tooltip: 'There are no threats',
  },
  warn: {
    as: HiExclamationCircle,
    color: 'warning.400',
    tooltip: 'Flagged with warnings',
  },
  threat: {
    as: HiExclamationCircle,
    color: 'danger.500',
    tooltip: 'Flagged with needs attention',
  },
  unknown: {
    as: HiQuestionMarkCircle,
    color: 'gray.300',
    tooltip: 'Unknown threat status',
  },
};

export interface ThreatIconProps extends Omit<IconProps, 'css'> {
  threatGroup?: EventThreatGroup;
  threatLevel?: EventThreatLevel;
  showTooltip?: boolean;
}

const ThreatIcon: React.FC<ThreatIconProps> = ({
  threatGroup,
  threatLevel,
  showTooltip = true,
  ...props
}) => {
  let group: EventThreatGroup;

  if (threatGroup) {
    group = threatGroup;
  } else if (threatLevel) {
    // If the group is not provided, detect the group from the level
    group = eventThreatGroupsByLevel[threatLevel];
  } else {
    group = 'unknown';
  }

  const iconProps = iconPropsByThreatGroup[group];
  const renderedContent = <Icon {...iconProps} {...props} />;

  if (showTooltip) {
    return (
      <Tooltip
        hasArrow
        placement="top"
        label={iconProps.tooltip}
        bg={iconProps.color}
      >
        <div>{renderedContent}</div>
      </Tooltip>
    );
  }

  return renderedContent;
};

export default ThreatIcon;
