import {
  EventThreatLevel,
  eventThreatLevelsByGroup,
} from 'event-log/list/useEventLog';

export enum ThreatGroupTab {
  All = 0,
  Good = 1,
  Warnings = 2,
  Threats = 3,
}

export const threatLevelByTab: Record<
  number,
  EventThreatLevel[] | undefined
> = {
  [ThreatGroupTab.All]: undefined,
  [ThreatGroupTab.Good]: eventThreatLevelsByGroup.good,
  [ThreatGroupTab.Warnings]: eventThreatLevelsByGroup.warn,
  [ThreatGroupTab.Threats]: eventThreatLevelsByGroup.threat,
};
