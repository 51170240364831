import React from 'react';
import { Button, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { observer } from 'mobx-react';
import { PageContainer } from 'shared/components/containers';
import { BorderedBox, Header, Body } from '../styled';
import teamShield from './team.svg';

const CreateTeam: React.FC = () => {
  const history = useHistory();
  const currentUser = useCurrentUser();

  const handleClick = () => {
    history.push(routes.team.create);
  };

  return (
    <PageContainer flex={1} display="flex" flexDirection="column">
      <Header>
        <Heading size="lg">
          Welcome
          {currentUser.data?.firstName && ' ' + currentUser.data?.firstName},
        </Heading>
        This is your XQ Dashboard. See all your XQ encryption activities, create
        a team or if you&apos;re a developer create an application.
      </Header>
      <BorderedBox py={{ base: 8, md: 16 }} width="100%" display="flex">
        <Body>
          <Flex mb={[8, 8, 0]} alignItems="center" flexBasis={{ md: '60%' }}>
            <Image
              src={teamShield}
              boxSize={{ base: '150px', md: '200px' }}
              mx="auto"
              objectFit="cover"
            />
          </Flex>
          <VStack spacing={4} textAlign="center">
            <Heading size="md" mr="auto">
              Create a Team
            </Heading>
            <Text as="p" textAlign="left" fontSize="lg">
              XQ gives your team a place to protect all your communications. To
              create a team, click the button below.
            </Text>
            <Button mr="auto" onClick={handleClick}>
              Create a Team
            </Button>
          </VStack>
        </Body>
      </BorderedBox>
    </PageContainer>
  );
};

export default observer(CreateTeam);
