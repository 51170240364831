import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import routes from 'routes';
import { FormProvider } from 'react-hook-form';
import useNavigate from 'shared/hooks/useNavigate';
import { TutorialName } from '../constants';
import {
  useTutorialAPIKeyForm,
  TutorialAPIKeyFormFields,
} from '../components/TutorialAPIKeyForm';
import {
  ChapterContainer,
  ChapterContent,
  ChapterFooterGetStarted,
  ChapterHeader,
  ChapterIntroBox,
  ChapterIntroContent,
  TutorialTableOfContents,
} from '../components';
import { useEncryptTutorial } from './EncryptTutorialContext';
import useTutorialAPIKey from '../useTutorialAPIKey';
import chapters from './chapters';

const CURRENT_CHAPTER = 0;

interface Chapter0Props {
  apiKey?: string;
}

const Chapter0: React.FC<Chapter0Props> = ({ apiKey }) => {
  const { setAPIKey } = useTutorialAPIKey();
  const tutorial = useEncryptTutorial();
  const gotoNextStep = useNavigate(routes.tutorials.encrypt.chapter1);
  const { handleSubmit, form } = useTutorialAPIKeyForm(apiKey, (values) => {
    tutorial.reset();
    setAPIKey(values.apiKey);
    gotoNextStep();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <ChapterContainer>
          <ChapterIntroBox variant="bg-decoration">
            <ChapterHeader
              chapters={chapters}
              chapterNum={CURRENT_CHAPTER}
              tutorial={TutorialName.encrypt}
            />
            <ChapterIntroContent>
              <Text>
                This tutorial will guide you through a series of tools and
                instructions to show you how to store an encryption key in the
                XQ key store and then use it to encrypt a message.{' '}
                <strong>
                  These tools will perform real operations using your
                  application API key.
                </strong>
              </Text>

              <TutorialAPIKeyFormFields />
            </ChapterIntroContent>
          </ChapterIntroBox>

          <ChapterContent>
            <Heading size="lg" mb={4}>
              What to expect in this tutorial
            </Heading>

            <Text>
              This tutorial will show you how to use each of the services needed
              for storing an encryption key with XQ, along with creating an
              encryption key and using it to encrypt a real message.
            </Text>

            <TutorialTableOfContents chapters={chapters} />
          </ChapterContent>

          <ChapterFooterGetStarted chapters={chapters} />
        </ChapterContainer>
      </form>
    </FormProvider>
  );
};

export default Chapter0;
