import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const ChapterFormBox: React.FC<BoxProps> = (props) => {
  return (
    <Box
      border="1px"
      borderColor="gray.100"
      borderRadius="lg"
      bg="gray.50"
      p={6}
      mb={8}
      sx={{
        '.chakra-form__helper-text': {
          color: 'gray.800',
        },
      }}
      {...props}
    />
  );
};

export default ChapterFormBox;
