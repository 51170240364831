import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import php from 'react-syntax-highlighter/dist/esm/languages/hljs/php';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import CodeView from './CodeView';

SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('php', php);
SyntaxHighlighter.registerLanguage('javascript', javascript);

export * from './CodeView';
export default CodeView;
