import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  TextProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { PageBox } from 'shared/components/containers';
import SpinnerButton from 'shared/components/SpinnerButton';
import LoadingBox from 'shared/components/LoadingBox';
import ActivityFeedTable from 'activity/list/ActivityFeedTable';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import useEventLog from 'event-log/list/useEventLog';
import ThreatIcon from 'activity/components/ThreatIcon';
import {
  CommunicationStatus,
  CommunicationSummary,
  CommunicationType,
} from '../types';
import KeyPacketStatus from '../list/KeyPacketTable/KeyPacketStatus';
import useRevokeKey from '../useRevokeKey';
import { keyPacketTypeConfig } from '../list/KeyPacketTable/KeyPacketRetrieved';
import {
  RecipientsVerifiedBadge,
  RecipientsUnverifiedBadge,
} from '../list/KeyPacketTable/RecipientsVerificationBadge';
import KeyPacketRecipientsTable from './KeyPacketRecipientsTable';
import KeyPacketExpiryDate from './KeyPacketExpiryDate';
import KeyPacketUpdatedDate from './KeyPacketUpdatedDate';
import { useCommunicationDetails } from '../CommunicationController';
import GrantAccessModal from '../grant/GrantAccessModal';

import useRevokeAccessDialog from '../revoke/useRevokeAccessDialog';
import RevokeButton from '../revoke/RevokeButton';
import { DeleteAlertDialog } from '../../shared/components/alert-dialogs';

const GridLabel: React.FC<TextProps> = (props) => {
  return (
    <Text as="span" fontWeight="bold" mt={{ base: 4, md: 0 }} {...props} />
  );
};

const SubjectText: React.FC<TextProps> = (props) => {
  return <Text as="span" fontWeight="bold" fontSize="3xl" {...props} />;
};

const KeyPacketDetail: React.FC = () => {
  const user = useCurrentUser();
  const { keyPacketId } = useParams<{ keyPacketId: string }>();
  const [, isRevokeKeyPending] = useRevokeKey(Number(keyPacketId));
  const communication = useCommunicationDetails(Number(keyPacketId));
  const revokeDialog = useRevokeAccessDialog(Number(keyPacketId), () => {
    communication.execute();
  });
  const grantAccessModalDisclosure = useDisclosure();
  const events = useEventLog(
    {
      relatedTo: communication.data?.token,
    },
    undefined,
    true
  );
  const typeConfig = keyPacketTypeConfig[communication.data?.type ?? 0];

  const hasToken = !!communication.data?.token;
  useEffect(() => {
    if (hasToken) {
      events.loadMore();
    }

    // Once we have the locator token, we can begin fetching the activity. Only do this
    // When the locator token initially loads.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasToken]);

  if (communication.loading) {
    return <LoadingBox />;
  }

  if (!communication.data) {
    return <>Communication record not found</>;
  }

  const isStatusRevocable =
    communication.data.status === CommunicationStatus.Available;

  const getCommunicationInfo = (value: string, comm: CommunicationSummary) => {
    switch (value) {
      case 'Email':
        return ['Subject', comm.meta.subject];
      case 'File':
        return ['Title', comm.meta.title];
      default:
        return ['', ''];
    }
  };

  const [commInfoTitle, commInfoValue] = getCommunicationInfo(
    typeConfig.title,
    communication.data
  );

  return (
    <>
      <PageBox>
        {communication.data.type === CommunicationType.File && (
          <SubjectText>{communication.data.meta?.title}</SubjectText>
        )}
        {communication.data.type === CommunicationType.Email && (
          <SubjectText>{communication.data.meta?.subject}</SubjectText>
        )}

        <Grid
          templateColumns={{ base: '1fr', md: '170px 1fr' }}
          columnGap={4}
          rowGap={{ base: 0, md: 2 }}
          my={6}
        >
          <GridLabel>Status:</GridLabel>
          <Box>
            <KeyPacketStatus
              status={communication.data.status}
              direction="row"
              fontSize="inherit"
              showLabel
            />
          </Box>

          <GridLabel>Recipients verified:</GridLabel>
          <Box>
            {communication.data.verified ? (
              <RecipientsVerifiedBadge showTooltip={false}>
                All recipients are verified
              </RecipientsVerifiedBadge>
            ) : (
              <RecipientsUnverifiedBadge showTooltip={false}>
                One or more recipients is not verified
              </RecipientsUnverifiedBadge>
            )}
          </Box>

          <GridLabel>ID:</GridLabel>
          <Text>{communication.data.id}</Text>

          <GridLabel>Type:</GridLabel>
          <Text>{typeConfig.title}</Text>

          {commInfoTitle && commInfoTitle.length > 0 && (
            <>
              <GridLabel>{commInfoTitle}:</GridLabel>
              <Text>{commInfoValue}</Text>
            </>
          )}

          <GridLabel>Retrieved count:</GridLabel>
          <Text>{communication.data.accesses.toLocaleString()}</Text>

          <GridLabel>Last updated date:</GridLabel>
          <Box>
            <KeyPacketUpdatedDate updatedDate={communication.data.updated} />
          </Box>

          <GridLabel>Expiry date:</GridLabel>
          <Box>
            <KeyPacketExpiryDate expiryDate={communication.data.expires} />
          </Box>

          <GridLabel>Owner:</GridLabel>
          <Text>{communication.data.user}</Text>

          <GridLabel>Threat level:</GridLabel>
          <ThreatIcon
            threatLevel={communication.data.threat}
            boxSize={6}
            showTooltip={false}
          />
        </Grid>

        {user.access.canRevokeKey && isStatusRevocable && (
          <Box mt={4}>
            <RevokeButton
              label="Revoke All"
              recipient="*"
              communication={communication.data}
              isLoading={isRevokeKeyPending}
              onRevoke={revokeDialog.onOpen}
            />

            <SpinnerButton
              colorScheme="gray"
              isLoading={isRevokeKeyPending}
              onClick={grantAccessModalDisclosure.onOpen}
            >
              Grant Access
            </SpinnerButton>
          </Box>
        )}

        <Tabs mt={8} mx={-6}>
          <TabList>
            <Tab>Recipients</Tab>
            <Tab>Activity Log</Tab>
          </TabList>

          <TabPanels mt="-1px">
            <TabPanel p={0}>
              <KeyPacketRecipientsTable
                communication={communication}
                isRevokeKeyPending={isRevokeKeyPending}
                recipients={communication.data.recipients}
              />
            </TabPanel>
            <TabPanel p={0}>
              <Box overflowX="auto">
                <ActivityFeedTable
                  data={events.data}
                  enableSticky={false}
                  isInitialLoading={events.isInitialLoading}
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageBox>
      <GrantAccessModal
        communication={communication}
        isOpen={grantAccessModalDisclosure.isOpen}
        onClose={grantAccessModalDisclosure.onClose}
      />
      <DeleteAlertDialog<string, CommunicationSummary>
        title="Confirm Revoke"
        content="Are you sure you want to revoke this communication? All recipients ( including yourself ) will lose access once revoked."
        {...revokeDialog}
      />
    </>
  );
};

export default observer(KeyPacketDetail);
