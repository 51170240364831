import React from 'react';
import { Tooltip, Flex, Icon, Box } from '@chakra-ui/react';
import { HiXCircle, HiCheckCircle } from 'react-icons/hi';

export const RecipientsVerifiedIcon = HiCheckCircle;
export const RecipientsUnverifiedIcon = HiXCircle;

interface RecipientsUnverifiedBadgeProps {
  showTooltip?: boolean;
}

export const RecipientsUnverifiedBadge: React.FC<RecipientsUnverifiedBadgeProps> = ({
  showTooltip = true,
  children,
}) => {
  const renderedContent = (
    <Flex alignItems="center" fontWeight="semibold" color="gray.500">
      <Icon as={RecipientsUnverifiedIcon} color="gray.500" boxSize={6} />
      {children && (
        <Box as="span" ml={1}>
          {children}
        </Box>
      )}
    </Flex>
  );

  if (showTooltip) {
    return (
      <Tooltip
        label="One or more recipient is not verified"
        bg="gray.500"
        hasArrow
        placement="top"
      >
        {renderedContent}
      </Tooltip>
    );
  }

  return renderedContent;
};

interface RecipientsVerifiedBadgeProps {
  showTooltip?: boolean;
}

export const RecipientsVerifiedBadge: React.FC<RecipientsVerifiedBadgeProps> = ({
  showTooltip = true,
  children,
}) => {
  const renderedContent = (
    <Flex alignItems="center" fontWeight="semibold" color="blue.500">
      <Icon as={RecipientsVerifiedIcon} color="blue.500" boxSize={6} />
      {children && (
        <Box as="span" ml={1}>
          {children}
        </Box>
      )}
    </Flex>
  );

  if (showTooltip) {
    return (
      <Tooltip
        label="All of the recipients are verified"
        bg="blue.500"
        color="white"
        hasArrow
        placement="top"
      >
        {renderedContent}
      </Tooltip>
    );
  }

  return renderedContent;
};

interface RecipientsVerificationBadgeProps {
  isVerified: boolean;
}

const RecipientsVerificationBadge: React.FC<RecipientsVerificationBadgeProps> = ({
  isVerified,
}) => {
  return isVerified ? (
    <RecipientsVerifiedBadge />
  ) : (
    <RecipientsUnverifiedBadge />
  );
};

export default RecipientsVerificationBadge;
