import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import BackToTopFAB from 'shared/components/BackToTopFAB';
import {
  TableCapWrapper,
  TableCap,
  TableCapContent,
  TableWrapper,
} from 'shared/components/Table';
import EventLogTable from 'event-log/list/EventLogTable';
import { skeletonMockData } from 'event-log/list/EventLog';
import useEventLog from 'event-log/list/useEventLog';

export type RelatedEventsTableProps = {
  relationKey: string;
};

const RelatedEventsTable: React.FC<RelatedEventsTableProps> = ({
  relationKey,
}) => {
  const eventLog = useEventLog(
    {
      relatedTo: relationKey,
    },
    25
  );

  return (
    <Box flex="none" width="100%" maxWidth="6xl" mx="auto">
      <TableCapWrapper>
        <TableCap isLoading={false}>
          <TableCapContent>
            <Heading size="md">Related Events</Heading>
          </TableCapContent>
        </TableCap>
      </TableCapWrapper>

      <TableWrapper overflowX="auto">
        <EventLogTable
          data={eventLog.data || skeletonMockData}
          isInitialLoading={eventLog.isInitialLoading}
          noResultsHeading="There are no other relevant events"
          noResultsMessage=""
        />
      </TableWrapper>

      <BackToTopFAB />
    </Box>
  );
};

export default observer(RelatedEventsTable);
