import { useToast } from '@chakra-ui/react';
import {
  useConfirmDialog,
  useConfirmDialogAction,
  useConfirmDialogDisclosure,
} from 'shared/hooks/confirm-dialog';
import { formatErrorResponse } from 'shared/utils/errors';
import { useDeleteAPIKey, APIKeySummary } from 'api-keys/APIKeysController';

export default function useDeleteAPIKeyDialog() {
  const toast = useToast();
  const disclosure = useConfirmDialogDisclosure<number, APIKeySummary>();
  const action = useConfirmDialogAction(
    useDeleteAPIKey(
      () => {
        toast({
          title: 'API key successfully deleted',
          description: disclosure.record?.name,
          status: 'success',
          position: 'bottom-right',
        });
        disclosure.onClose();
      },
      (err) => {
        toast({
          title: 'Error deleting API key',
          description: formatErrorResponse(err).message,
          status: 'error',
          position: 'bottom-right',
        });
      }
    )
  );
  return useConfirmDialog<number, APIKeySummary>(disclosure, action);
}
