import React from 'react';
import { CenteredLayout } from 'shared/components/CenteredLayout';
import Verify from './Verify/Verify';

const VerifyScene: React.FC = () => {
  return (
    <CenteredLayout showLogo>
      <Verify />
    </CenteredLayout>
  );
};

export default VerifyScene;
