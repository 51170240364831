const root = '/settings';
const baseRoute = (path = '') => `${root}${path}`;

export const accountErrorHashId = 'account-error';

export default {
  root,
  billing: baseRoute('/billing'),
  profile: baseRoute('/profile'),
  plans: baseRoute('/plans'),
  payment: baseRoute('/payment'),
  accountError: baseRoute(`/billing#${accountErrorHashId}`),
  paymentSuccess: baseRoute('/payment/success'),
} as const;
