import { useMemo } from 'react';
import { UseConfirmDialogDisclosureReturn } from './useConfirmDialogDisclosure';
import { UseConfirmDialogActionReturn } from './useConfirmDialogAction';

/**
 * Creates a set of props that can power an alert dialog that prompts for confirmation
 * before submitting an asychronous action.
 * @param disclosure Disclosure props adapted to match confirm dialog props.
 * @param action An AsyncAction that is adapted to match confirm dialog props.
 */
export default function useConfirmDialog<V, R>(
  disclosure: UseConfirmDialogDisclosureReturn<V, R>,
  action: UseConfirmDialogActionReturn<V, R>
) {
  return useMemo(() => {
    return {
      ...disclosure,
      ...action,
    };
  }, [disclosure, action]);
}
