import React, { useEffect } from 'react';
import {
  Button,
  Box,
  Grid,
  Text,
  Icon,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react';
import useLocalStorageState from 'use-local-storage-state';
import { HiChevronDown } from 'react-icons/hi';

interface HeadersProps {
  headers: Record<string, string>;
}

const Headers: React.FC<HeadersProps> = React.memo(({ headers }) => {
  const [defaultIsOpen, setDefaultIsOpen] = useLocalStorageState(
    'tryItHeaders.defaultIsOpen',
    true
  );
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });
  useEffect(() => {
    setDefaultIsOpen(isOpen);
  }, [isOpen, setDefaultIsOpen]);

  const headerItems = Object.entries(headers).map(([name, value]) => (
    <React.Fragment key={name}>
      <Text textAlign={{ base: 'left', md: 'right' }}>{name}:</Text>
      <Text whiteSpace="pre-wrap" wordBreak="break-all">
        {value}
      </Text>
    </React.Fragment>
  ));
  return (
    <Box mb={6}>
      <Button
        variant="unstyled"
        fontWeight="bold"
        fontSize="lg"
        textTransform="none"
        height="auto"
        borderRadius={0}
        display="inline-flex"
        onClick={onToggle}
      >
        Request Headers
        <Icon
          as={HiChevronDown}
          boxSize={6}
          display="block"
          transform={isOpen ? 'rotate(-180deg)' : undefined}
        />
      </Button>
      <Collapse in={isOpen}>
        <Grid
          templateColumns={{ base: '1fr', md: 'fit-content(300px) 1fr' }}
          columnGap={4}
          rowGap={{ base: 4, md: 2 }}
          fontFamily="mono"
          fontSize="sm"
          border="1px"
          borderColor="gray.100"
          borderRadius="lg"
          p={6}
          mt={2}
        >
          {headerItems}
        </Grid>
      </Collapse>
    </Box>
  );
});

export default Headers;
