import React from 'react';
import { KeyPacketSummary } from 'backend/api-types/dashboard';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'shared/components/Table';
import RevokeButton from '../revoke/RevokeButton';
import { CommunicationStatus, CommunicationSummary } from '../types';
import useRevokeAccessDialog from '../revoke/useRevokeAccessDialog';
import { DeleteAlertDialog } from '../../shared/components/alert-dialogs';
import CommunicationController from '../CommunicationController';

interface KeyPacketRecipientsTableProps {
  communication: CommunicationController | null;
  isRevokeKeyPending: boolean;
  recipients: KeyPacketSummary['recipients'];
}

const KeyPacketRecipientsTable: React.FC<KeyPacketRecipientsTableProps> = ({
  communication,
  isRevokeKeyPending,
  recipients,
}) => {
  const revokeDialog = useRevokeAccessDialog(
    communication?.data?.id ?? 0,
    () => {
      communication?.execute();
    }
  );
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Recipient</TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recipients.map((recipient) => (
            <TableRow key={recipient.key}>
              <TableCell>{recipient.user}</TableCell>
              <TableCell textAlign="right">
                {communication != null &&
                  communication.data != null &&
                  communication.data.status ===
                    CommunicationStatus.Available && (
                    <RevokeButton
                      label="Revoke"
                      recipient={recipient.key}
                      communication={communication.data}
                      isLoading={isRevokeKeyPending}
                      onRevoke={revokeDialog.onOpen}
                    />
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DeleteAlertDialog<string, CommunicationSummary>
        title="Revoke Access"
        content="Are you sure you want to revoke access for this user? They will lose all access to this communication."
        {...revokeDialog}
      />
    </>
  );
};

export default KeyPacketRecipientsTable;
