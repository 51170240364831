import React from 'react';
import { Button } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { APIKeySummary } from 'api-keys/APIKeysController';
import { Table, TableCell, TableHead, TableRow } from 'shared/components/Table';

export interface APIKeyListProps {
  appId: number;
  keys: APIKeySummary[];
  onDelete: (id: number, record: APIKeySummary) => void;
}

const APIKeyList: React.FC<APIKeyListProps> = ({ keys, onDelete }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell borderTop={0} colSpan={3}>
            Name
          </TableCell>
        </TableRow>
      </TableHead>
      <tbody>
        {keys.map((key) => (
          <TableRow key={key.id}>
            <TableCell width="90%" fontWeight="bold">
              {key.name}
            </TableCell>
            {/* <TableCell>
              <Button variant="ghost" colorScheme="gray" size="xs">
                Edit key
              </Button>
            </TableCell> */}
            <TableCell textAlign="right">
              <Button
                variant="link"
                colorScheme="gray"
                size="xs"
                onClick={() => onDelete(key.id, key)}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default observer(APIKeyList);
