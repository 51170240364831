export function centsToDollars(cents: number): string {
  const stringCents = String(cents % 100).padStart(2, '0');
  return `$${Math.floor(cents / 100)}.${stringCents.slice(
    stringCents.length - 2
  )}`;
}

export function toHex(plain: string): string {
  let hex = '';
  for (let i = 0; i < plain.length; i++) {
    hex += plain.charCodeAt(i).toString(16);
  }

  return hex;
}
