import { useCallback } from 'react';
import * as yup from 'yup';
import pick from 'lodash/pick';
import { FormHandler, useFormHandler } from 'shared/hooks/useFormHandler';
import { useContacts } from 'contacts/ContactsController';
import { UserNotificationStatus, UserRole, UserSummary } from 'auth/user/types';
import { useContact } from 'contacts/ContactDetailsController';

export type UpdateContactFormValues = {
  role: UserRole;
  firstName: string;
  lastName: string;
  title: string;
  phone: string;
  notificationsStatus: UserNotificationStatus;
};

const updateContactFormSchema = yup.object().shape({
  firstName: yup.string().trim(),
  lastName: yup.string().trim(),
  phone: yup.string().trim(),
  title: yup.string().trim(),
  notificationsStatus: yup.number().required('Required'),
});

export default function useUpdateContactForm(
  id: number,
  onSuccess?: (contact: UserSummary) => void
): FormHandler<UpdateContactFormValues> {
  const contacts = useContacts();
  const contact = useContact(id);
  if (!contact.data) {
    // This should never actually happen. Only here as a safety sanity check.
    throw new Error("Can't update a contact that hasn't been fetched yet.");
  }

  const defaultValues: UpdateContactFormValues = {
    ...pick(contact.data, 'email', 'notificationsStatus', 'role'),
    // Default these fields to be empty string instead of undefined
    firstName: contact.data.firstName ?? '',
    lastName: contact.data.lastName ?? '',
    phone: contact.data.phone ?? '',
    title: contact.data.title ?? '',
    role: contact.data.role,
  };

  const updateContact = useCallback(
    async (values: UpdateContactFormValues) => {
      const res = await contact.update(id, values);

      // Refetch the list of contacts
      await contacts.execute();

      if (onSuccess) {
        onSuccess(res);
      }
    },
    [id, contacts, onSuccess, contact]
  );

  return useFormHandler<UpdateContactFormValues>(
    updateContact,
    defaultValues,
    updateContactFormSchema
  );
}
