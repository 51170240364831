import { AxiosInstance } from 'axios';
import {
  ListBusinessesResponse,
  BusinessOverview,
} from 'backend/api-types/dashboard';
import authController from 'auth/AuthenticationController';
import { dashboardService } from '../backend/services';
import AsyncData from '../cache/AsyncData';
import useAsyncData from '../cache/useAsyncData';

export default class ListBusinessesController extends AsyncData<
  ListBusinessesResponse,
  void,
  Array<BusinessOverview>
> {
  protected _axios: AxiosInstance = dashboardService;

  constructor() {
    super('businesses', '/businesses');
  }

  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: ListBusinessesResponse): Array<BusinessOverview> {
    return data.businesses.filter((b) => b.canAccessBusiness) ?? [];
  }
}

export function useListBusinesses(): ListBusinessesController {
  return useAsyncData<ListBusinessesController>(
    new ListBusinessesController(),
    !authController.isLoggedIn
  );
}
