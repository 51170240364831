import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Text, FlexProps, Link, useToast } from '@chakra-ui/react';
import { EventLogItem } from 'event-log/types';
import { CommunicationType } from 'key-packet/types';
import routes from 'routes';
import EventTypesController, {
  useEventTypes,
} from 'event-log/EventTypesController';
import ThreatIcon from './ThreatIcon';

type ResourceProps = {
  communicationId?: number;
};

const Resource: React.FC<ResourceProps> = ({ communicationId, children }) => {
  const toast = useToast();
  return (
    <Link
      as={RouterLink}
      to={routes.keyPacket.detail(communicationId ?? 0)}
      onClick={(e) => {
        e.stopPropagation();

        if (!communicationId) {
          e.preventDefault();
          toast({
            title: 'Resource not available',
            status: 'error',
            position: 'bottom-right',
          });
        }
      }}
    >
      {children}
    </Link>
  );
};

export interface ActivityDescriptionProps extends FlexProps {
  event: EventLogItem;
  hideUser?: boolean;
  eventTypes?: EventTypesController;
}

type ActivityContentProps = {
  event: EventLogItem;
};

type ActivityContent = React.ElementType<ActivityContentProps>;

class Activity extends React.PureComponent<ActivityDescriptionProps> {
  File?: ActivityContent;

  FileShort?: ActivityContent;

  Email?: ActivityContent;

  EmailShort?: ActivityContent;

  Default?: ActivityContent;

  DefaultShort?: ActivityContent;

  get Content(): React.ElementType {
    const { event, hideUser } = this.props;

    if (event.meta.communicationType === CommunicationType.File && this.File) {
      if (hideUser && this.FileShort) {
        return this.FileShort;
      }

      return this.File;
    }

    if (
      event.meta.communicationType === CommunicationType.Email &&
      this.Email
    ) {
      if (hideUser && this.EmailShort) {
        return this.EmailShort;
      }

      return this.Email;
    }

    if (this.Default) {
      if (hideUser && this.DefaultShort) {
        return this.DefaultShort;
      }

      return this.Default;
    }

    if (hideUser) {
      return () => <>{event.action}</>;
    }

    return () => (
      <>
        {this.user}&nbsp;{event.action}
      </>
    );
  }

  get user(): React.ReactNode {
    const {
      event: { user },
    } = this.props;

    if (!user) {
      return null;
    }

    return (
      <Text as="span" fontWeight="bold">
        {user}
      </Text>
    );
  }

  get resource(): React.ReactNode {
    const {
      event: { meta },
    } = this.props;

    switch (meta.communicationType) {
      case CommunicationType.File:
        return (
          <Resource communicationId={meta.communication}>
            {meta.title || 'Unknown File'}
          </Resource>
        );
      case CommunicationType.Email:
        return (
          <Resource communicationId={meta.communication}>
            {meta.subject || 'Unknown Email'}
          </Resource>
        );
      default:
        return null;
    }
  }

  render() {
    // Don't pass hideUser to the Flex component
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { event, hideUser, eventTypes, ...props } = this.props;
    const ContentComponent = this.Content;
    return (
      <Flex {...props}>
        <ThreatIcon threatLevel={event.threat} mr={2} boxSize={6} />
        <div>
          <ContentComponent event={event} />
        </div>
      </Flex>
    );
  }
}

class AuthorizeActivity extends Activity {
  Default: ActivityContent = () => <>{this.user}&nbsp;authorized</>;

  DefaultShort: ActivityContent = () => <>Authorized</>;
}

class DefaultActivity extends Activity {
  Default: ActivityContent = () => (
    <>
      {this.user}&nbsp;
      {this.props.eventTypes?.getTitle(this.props.event.action)}
    </>
  );

  DefaultShort: ActivityContent = () => (
    <>{this.props.eventTypes?.getTitle(this.props.event.action)}</>
  );
}

class NewKeyActivity extends Activity {
  Default: ActivityContent = () => <>{this.user}&nbsp;created a new API key</>;

  DefaultShort: ActivityContent = () => <>Created API key</>;
}

class RemoveKeyActivity extends Activity {
  Default: ActivityContent = () => <>{this.user}&nbsp;removed a new API key</>;

  DefaultShort: ActivityContent = () => <>Removed API key</>;
}

class FetchKey extends Activity {
  Default: ActivityContent = () => <>{this.user}&nbsp;read message</>;

  DefaultShort: ActivityContent = () => <>Read message</>;

  File: ActivityContent = () => (
    <>
      {this.user}&nbsp;decrypted file {this.resource}
    </>
  );

  FileShort: ActivityContent = () => <>Decrypted file {this.resource}</>;

  Email: ActivityContent = () => (
    <>
      {this.user}&nbsp;viewed email {this.resource}
    </>
  );

  EmailShort: ActivityContent = () => <>Viewed email {this.resource}</>;
}

class FetchKeySender extends Activity {
  Default: ActivityContent = () => <>Message decrypted by recipient</>;

  File: ActivityContent = () => <>File {this.resource} was decrypted</>;

  Email: ActivityContent = () => <>Email {this.resource} was viewed</>;
}

class RecipientKeyCreated extends Activity {
  Default: ActivityContent = () => <>Added as a message recipient</>;

  File: ActivityContent = () => (
    <>
      {this.user} was added to file {this.resource}
    </>
  );

  FileShort: ActivityContent = () => <>Added to file {this.resource}</>;

  Email: ActivityContent = () => (
    <>
      {this.user} was added to an email {this.resource}
    </>
  );

  EmailShort: ActivityContent = () => <>Added to email {this.resource}</>;
}

class KeyCreated extends Activity {
  Default: ActivityContent = () => <>{this.user} encrypted a message</>;

  DefaultShort: ActivityContent = () => <>Encrypted message</>;

  File: ActivityContent = () => (
    <>
      {this.user}&nbsp;encrypted file {this.resource}
    </>
  );

  FileShort: ActivityContent = () => <>Encrypted file {this.resource}</>;

  Email: ActivityContent = () => (
    <>
      {this.user}&nbsp;sent an encrypted email {this.resource}
    </>
  );

  EmailShort: ActivityContent = () => (
    <>Sent an encrypted email {this.resource}</>
  );
}

class AdminGrantedAccessToResource extends Activity {
  Default: ActivityContent = () => <>Granted access to another user</>;

  File: ActivityContent = () => (
    <>
      {this.user} granted a user access to email {this.resource}
    </>
  );

  FileShort: ActivityContent = () => <>{this.user} granted file access</>;

  Email: ActivityContent = () => (
    <>
      {this.user} granted a user access to file {this.resource}
    </>
  );

  EmailShort: ActivityContent = () => <>{this.user} granted email access</>;
}

class UserReceivedAccessToResource extends Activity {
  Default: ActivityContent = () => (
    <>Message access granted by admininistrator</>
  );

  File: ActivityContent = () => (
    <>
      {this.user} was granted access to file {this.resource}
    </>
  );

  Email: ActivityContent = () => (
    <>
      {this.user} was granted access to email {this.resource}
    </>
  );
}

class KeyRevokedByAdmin extends Activity {
  Default: ActivityContent = () => <>Message access revoked by administrator</>;

  File: ActivityContent = () => (
    <>File {this.resource} access revoked by administrator</>
  );

  Email: ActivityContent = () => (
    <>Email {this.resource} access revoked by administrator</>
  );
}

class KeyRevoked extends Activity {
  Default: ActivityContent = () => (
    <>{this.user}&nbsp;revoked access to a message</>
  );

  DefaultShort: ActivityContent = () => <>Revoked access to a message</>;

  File: ActivityContent = () => (
    <>
      {this.user}&nbsp;revoked access to file {this.resource}
    </>
  );

  FileShort: ActivityContent = () => (
    <>Revoked access to file {this.resource}</>
  );

  Email: ActivityContent = () => (
    <>
      {this.user}&nbsp;revoked access to email {this.resource}
    </>
  );

  EmailShort: ActivityContent = () => (
    <>Revoked access to email {this.resource}</>
  );
}

class AttemptedToAccessExpiredKey extends Activity {
  Default: ActivityContent = () => (
    <>{this.user}&nbsp;attempted to access an expired message</>
  );

  DefaultShort: ActivityContent = () => (
    <>Attempted to access an expired message</>
  );

  File: ActivityContent = () => (
    <>
      {this.user}&nbsp;attempted to access an expired file {this.resource}
    </>
  );

  FileShort: ActivityContent = () => (
    <>Attempted to access an expired file {this.resource}</>
  );

  Email: ActivityContent = () => (
    <>
      {this.user}&nbsp;attempted to access an expired email {this.resource}
    </>
  );

  EmailShort: ActivityContent = () => (
    <>Attempted to access an expired email {this.resource}</>
  );
}
class RawPacketActivity extends Activity {
  Default: ActivityContent = () => <>{this.user}&nbsp;sent a raw data packet</>;
}

class ExpiredReadReceipt extends Activity {
  Default: ActivityContent = () => (
    <>Recipient attempted to access an expired message</>
  );

  File: ActivityContent = () => (
    <>Recipient attempted to access an expired file {this.resource}</>
  );

  Email: ActivityContent = () => (
    <>Recipient attempted to access an expired email {this.resource}</>
  );
}

const ActivityDescription: React.FC<ActivityDescriptionProps> = ({
  event,
  hideUser,
}) => {
  const eventTypes = useEventTypes();
  switch (event.action) {
    case 'sub://authorize':
      return <AuthorizeActivity event={event} hideUser={hideUser} />;
    case 'saas://client/newapikey':
      return <NewKeyActivity event={event} hideUser={hideUser} />;
    case 'saas://client/removeapikey':
      return <RemoveKeyActivity event={event} hideUser={hideUser} />;
    case 'val://fetchkey':
      return <FetchKey event={event} hideUser={hideUser} />;
    case 'val://fetchkey/sender':
      return <FetchKeySender event={event} hideUser={hideUser} />;
    case 'val://addpacket/file':
    case 'val://addpacket/msg':
      return <KeyCreated event={event} hideUser={hideUser} />;
    case 'val://addpacket/readers':
      return <RecipientKeyCreated event={event} hideUser={hideUser} />;
    case 'saas://client/revokekey':
      return <KeyRevokedByAdmin event={event} hideUser={hideUser} />;
    case 'val://revoke':
      return <KeyRevoked event={event} hideUser={hideUser} />;
    case 'val://fetchkey/expired':
      return <AttemptedToAccessExpiredKey event={event} hideUser={hideUser} />;
    case 'val://fetchkey/sender/expired':
      return <ExpiredReadReceipt event={event} hideUser={hideUser} />;
    case 'val://addpacket/Unknown':
      return <RawPacketActivity event={event} hideUser={hideUser} />;
    case 'val://grantuseraccess':
      return <AdminGrantedAccessToResource event={event} hideUser={hideUser} />;
    case 'val://grantuseraccess/readers':
      return <UserReceivedAccessToResource event={event} hideUser={hideUser} />;
    default:
      return (
        <DefaultActivity
          event={event}
          eventTypes={eventTypes}
          hideUser={hideUser}
        />
      );
  }
};

export default ActivityDescription;
