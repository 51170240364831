import { Box } from '@chakra-ui/react';
import * as React from 'react';
import ProfileForm from './ProfileForm';

const Profile: React.FC = () => {
  return (
    <Box p={6}>
      <ProfileForm />
    </Box>
  );
};

export default Profile;
