import React from 'react';
import { Flex, FlexProps, Icon } from '@chakra-ui/react';
import { EventThreatLevel } from 'event-log/list/useEventLog';
import { IconType } from 'react-icons/lib';
import {
  HiCheckCircle,
  HiExclamationCircle,
  HiQuestionMarkCircle,
} from 'react-icons/hi';

type Level = {
  icon?: IconType;
  description: string;
  bg: string;
};

const unknownLevel: Level = {
  icon: HiQuestionMarkCircle,
  description: 'Unknown',
  bg: 'gray.500',
};

const levels: Record<EventThreatLevel, Level> = {
  [EventThreatLevel.Benign]: {
    icon: HiCheckCircle,
    description: 'Benign',
    bg: 'green.300',
  },
  [EventThreatLevel.Info]: {
    icon: HiCheckCircle,
    description: 'Info',
    bg: 'green.600',
  },
  [EventThreatLevel.Warn]: {
    icon: HiExclamationCircle,
    description: 'Warning',
    bg: 'warning.400',
  },
  [EventThreatLevel.Alert]: {
    icon: HiExclamationCircle,
    description: 'Alert',
    bg: 'danger.500',
  },
  [EventThreatLevel.Critical]: {
    icon: HiExclamationCircle,
    description: 'Critical',
    bg: 'danger.900',
  },
};

export function getThreatLevel(threat: EventThreatLevel): Level {
  return levels[threat] ?? unknownLevel;
}

export interface LevelIndicatorIconProps extends FlexProps {
  threat: EventThreatLevel;
}

/**
 * Describes the threat level of an event log as an indicator dot.
 */
const LevelIndicatorIcon: React.FC<LevelIndicatorIconProps> = ({
  threat,
  ...props
}) => {
  const level = getThreatLevel(threat);
  return (
    <Flex {...props} justifyContent="center">
      <Icon
        as={level.icon}
        color={level.bg}
        boxSize={6}
        aria-label={level.description}
      />
    </Flex>
  );
};

export default LevelIndicatorIcon;
