import React from 'react';
import {
  Box,
  Button,
  Stack,
  Text,
  Flex,
  HStack,
  Heading,
  FormLabel,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Checkbox,
  Collapse,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import Crypto from 'crypto-js';
import { useForm } from 'react-hook-form';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import php from 'react-syntax-highlighter/dist/esm/languages/hljs/php';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import { quantumServiceBaseURL } from 'backend/services';
import {
  TryIt,
  RequestURL as TryItRequestURL,
  Response as TryItResponse,
  ChildrenRenderProps as TryItChildrenRenderProps,
  TryItResult,
} from 'shared/components/TryIt';
import { StackedTextarea } from 'shared/components/form';
import SpinnerButton from 'shared/components/SpinnerButton';
import { useDashboardContentContext } from 'dashboard/layout/DashboardContentContext';
import useHLJSTheme from 'theme/useHLJSTheme';
import { TutorialName } from '../constants';
import {
  ChapterContainer,
  ChapterContent,
  ChapterFooter,
  ChapterHeader,
  ChapterIntroBox,
  ChapterIntroContent,
  ChapterNavLink,
  ChapterResponseAlert,
} from '../components';
import { useEncryptTutorial } from './EncryptTutorialContext';
import chapters from './chapters';

SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('php', php);
SyntaxHighlighter.registerLanguage('javascript', javascript);

const CURRENT_CHAPTER = 2;

// This is just a random string. Can be anything really. It isn't actually all
// that secret either since the key from this tutorial will never be used in real life.
const CLIENT_SECRET =
  'Nzk2ZTY0NmY2ZTQwNmU2NTZmNmUyZTYzNjNAYXBwaWQtNDEtMjIxLmxvY2FsIi';

const codeCreateKeyCLI = (hasCustomEntropy = false, entropy?: string) => {
  const showCustomEntropy = hasCustomEntropy && entropy;
  const outFile = './custom-entropy.txt';
  const entropyCommand = `echo "${entropy}" | base64 --decode > ${outFile}`;
  return `
${showCustomEntropy ? entropyCommand : ''}
openssl genrsa${
    showCustomEntropy ? ` -rand ${outFile}` : ''
  } -out privatekey.pem 1024
`.trim();
};

const codeCreateKeyNode = (hasCustomEntropy = false, entropy?: string) => {
  const showCustomEntropy = hasCustomEntropy && entropy;
  return `
const crypto = require('crypto');

// The salt needs to be randomly generated.
const salt = '${showCustomEntropy ? entropy : 'random salt'}'
const password = 'My super secret password';
const key = crypto.scryptSync(password, salt, 128);

console.log(key.toString('hex'));
`.trim();
};

interface EncryptionKeyFormValues {
  encryptionKey: string;
  entropyBits: string;
}

const Chapter2: React.FC = () => {
  const hljsTheme = useHLJSTheme();
  const tutorial = useEncryptTutorial();
  const { scrollToBottom } = useDashboardContentContext();
  const entropyDisclosure = useDisclosure({
    defaultIsOpen: true,
  });
  const {
    handleSubmit,
    register,
    watch,
    setValue,
  } = useForm<EncryptionKeyFormValues>({
    defaultValues: {
      encryptionKey: tutorial.encryptionKey ?? '',
      entropyBits: '36',
    },
  });

  const handlePasteKey = async () => {
    try {
      await navigator.clipboard.readText().then((text) => {
        tutorial.setEncryptionKey('');
        setValue('encryptionKey', text);
      });
    } catch (err) {
      // eslint-disable-next-line no-alert
      window.alert('Unable to access your clipboard');
    }
  };

  const handleGenerateKey = () => {
    const salt = Crypto.lib.WordArray.random(32);
    tutorial.setEncryptionKey('');
    setValue(
      'encryptionKey',
      Crypto.PBKDF2(CLIENT_SECRET, salt, {
        keySize: 128,
      }).toString()
    );
  };

  const handleEntropySuccess = (result: TryItResult) => {
    const entropy = result.response.trim();
    tutorial.setEntropy(entropy);
    tutorial.storeResult('2-entropy')(result);
  };

  const handleEntropyError = (result: TryItResult) => {
    tutorial.setEntropy('');
    tutorial.storeResult('2-entropy')(result);
  };

  const onSubmit = ({ encryptionKey }: EncryptionKeyFormValues) => {
    tutorial.setEncryptionKey(encryptionKey);
    scrollToBottom();
  };

  const entropyBits = watch('entropyBits');
  const quantumEntropyURL = `${quantumServiceBaseURL}?ks=${entropyBits}`;
  const entropyResponse = tutorial.chapterResults['2-entropy'];
  const isChapterComplete = !!tutorial.encryptionKey;

  return (
    <ChapterContainer>
      <ChapterIntroBox>
        <ChapterHeader
          tutorial={TutorialName.encrypt}
          chapters={chapters}
          chapterNum={CURRENT_CHAPTER}
        />
        <ChapterIntroContent>
          <Text>
            Your key is a random string of bits used by an encryption algorithm
            for rewriting data so it appears to be random. In this chapter, you
            will create an encryption key that will be used later to encrypt a
            message.
          </Text>
          <br />
          <Text>
            Optionally to help increase the security of your key you can supply
            entropy from our quantum entropy service.
          </Text>
        </ChapterIntroContent>
      </ChapterIntroBox>

      <ChapterContent>
        <Box
          mb={8}
          bg="#F7F8FC"
          border="1px"
          borderColor="gray.100"
          borderRadius="lg"
          p={4}
        >
          <Checkbox
            fontWeight="bold"
            name="hasCustomEntropy"
            defaultIsChecked={entropyDisclosure.isOpen}
            onChange={entropyDisclosure.onToggle}
          >
            Yes, I want to seed an encryption key with quantum entropy
          </Checkbox>

          <Collapse in={entropyDisclosure.isOpen}>
            <TryIt
              url={quantumEntropyURL}
              method="get"
              onSuccess={handleEntropySuccess}
              onError={handleEntropyError}
            >
              {({ execute, loading }: TryItChildrenRenderProps) => {
                const handleEntropySubmit = (e: React.FormEvent) => {
                  e.preventDefault();
                  execute();
                };
                return (
                  <>
                    <Text mt={4} mb={8}>
                      XQ offers a quantum entropy service that you can use to
                      provide an alternate seed to your encryption key.
                    </Text>

                    <TryItRequestURL
                      requestURL={quantumEntropyURL}
                      requestMethod="get"
                    />

                    <HStack
                      as="form"
                      onSubmit={handleEntropySubmit}
                      alignItems="center"
                      spacing={4}
                      mb={4}
                    >
                      <FormLabel m={0}>
                        Number of entropy bits to fetch:
                      </FormLabel>
                      <Input
                        maxWidth={16}
                        size="md"
                        type="number"
                        name="entropyBits"
                        ref={register}
                        required
                      />
                      <SpinnerButton type="submit" isLoading={loading}>
                        Generate Quantum Entropy
                      </SpinnerButton>
                    </HStack>

                    {entropyResponse && (
                      <>
                        <TryItResponse
                          response={entropyResponse?.response}
                          statusCode={entropyResponse?.status}
                          statusText={entropyResponse?.statusText}
                          mt={8}
                          mb={4}
                        />
                        <ChapterResponseAlert
                          title="Quantum entropy is generated!"
                          mb={0}
                          mt={4}
                        >
                          <Text mb={2}>
                            The entropy response is a base64-encoded hex string.
                            While this hex string itself can be used as entropy,
                            to retrieve the actual bits, the string should be
                            decoded from base-64 and each hex character in the
                            sequence converted to its 4-bit binary
                            representation.
                          </Text>
                        </ChapterResponseAlert>
                      </>
                    )}
                  </>
                );
              }}
            </TryIt>
          </Collapse>
        </Box>

        <Tabs mb={12}>
          <TabList mb={4}>
            <Tab>Command Line</Tab>
            <Tab>Node.js</Tab>
          </TabList>

          <TabPanels>
            <TabPanel p={0}>
              <SyntaxHighlighter language="bash" style={hljsTheme}>
                {codeCreateKeyCLI(entropyDisclosure.isOpen, tutorial.entropy)}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel p={0}>
              <SyntaxHighlighter language="javascript" style={hljsTheme}>
                {codeCreateKeyNode(entropyDisclosure.isOpen, tutorial.entropy)}
              </SyntaxHighlighter>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Heading size="lg" mb={4}>
            Set your encryption key
          </Heading>

          <Text mb={4}>
            Paste your encryption key in the field below. Alternatively, you may
            generate an encryption key online for the purpose of this tutorial.
          </Text>

          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} mb={4}>
            <Button type="button" variant="outline" onClick={handlePasteKey}>
              Paste key from clipboard
            </Button>
            <Button type="button" variant="outline" onClick={handleGenerateKey}>
              Generate a key
            </Button>
          </Stack>

          <StackedTextarea
            label="Your encryption key:"
            variant="code"
            name="encryptionKey"
            rows={12}
            ref={register}
            required
            mb={4}
          />
          <Flex justify="flex-end">
            <Button type="submit">Done</Button>
          </Flex>
        </form>

        {isChapterComplete && (
          <ChapterResponseAlert
            title="Your encryption key is used to create a key packet"
            mt={8}
          >
            The key packet takes your encryption key along with a list of the
            identities that are allowed to access it and creates a new encrypted
            packet to be stored on the XQ key server. In the next chapter, we
            will create this key packet.
          </ChapterResponseAlert>
        )}
      </ChapterContent>

      <ChapterFooter>
        <ChapterNavLink direction="back" chapters={chapters} chapterNum={1} />
        {isChapterComplete && (
          <ChapterNavLink chapters={chapters} chapterNum={3} />
        )}
      </ChapterFooter>
    </ChapterContainer>
  );
};

export default Chapter2;
