import React, { useCallback } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { Switch, useHistory, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import routes from 'routes';
import authController, { useLogout } from 'auth/AuthenticationController';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { useListBusinesses } from 'team/ListBusinessesController';
import ApplicationsHeader from 'application/ApplicationsHeader';
import ContactsHeader from 'contacts/ContactsHeader';
import NotificationBanner from 'shared/components/NotificationBanner/NotificationBanner';
import useNotificationBanner from 'shared/components/NotificationBanner/useNotificationBanner';
import Toolbar from './Toolbar';
import useSidebar from '../useSidebar';

const Header: React.FC = () => {
  const history = useHistory();
  const { onToggle, isOpen } = useSidebar();
  const [logout, isLoggingOut] = useLogout();
  const business = useCurrentBusiness();
  const listBusinesses = useListBusinesses();
  const error = useNotificationBanner();
  const handleAuthenticationClick = useCallback(() => {
    if (authController.isLoggedIn) {
      return logout();
    }

    history.push(routes.auth.login);
  }, [history, logout]);

  return (
    <Box
      position={{ base: 'fixed', lg: 'static' }}
      top={0}
      left={0}
      width="100%"
      px={{ base: 0, lg: 12 }}
      pt={{ base: 0, lg: 4 }}
      zIndex="docked"
    >
      <Toolbar
        onToggle={onToggle}
        isOpen={isOpen}
        handleAuthenticationClick={handleAuthenticationClick}
        isLoggingOut={isLoggingOut}
        isLoggedIn={authController.isLoggedIn}
        companyName={business.data?.name}
        teams={listBusinesses.data}
      >
        <Switch>
          <Route path={routes.application.root}>
            <ApplicationsHeader />
          </Route>
          <Route path={routes.settings.root}>
            <Heading fontSize="2xl">Settings</Heading>
          </Route>

          <Route path={routes.home} exact>
            <Heading fontSize="2xl">Activity</Heading>
          </Route>
          <Route path={routes.keyPacket.detail()}>
            <Heading fontSize="2xl">Communications</Heading>
          </Route>
          <Route path={routes.export.root}>
            <Heading fontSize="2xl">Export</Heading>
          </Route>
          <Route path={routes.unlock.root}>
            <Heading fontSize="2xl">Unlock</Heading>
          </Route>
          <Route path={routes.encryption.root}>
            <Heading fontSize="2xl">Secure Messaging</Heading>
          </Route>
          <Route
            path={[
              routes.contacts.detail.activity(),
              routes.contacts.detail.communications(),
            ]}
          >
            <Heading fontSize="2xl">Team Member Details</Heading>
          </Route>
          <Route path={routes.contacts.root} exact>
            <ContactsHeader />
          </Route>
          <Route path={routes.eventLog.root} exact>
            <Heading fontSize="2xl">Event Log</Heading>
          </Route>
          <Route path={routes.eventLog.details()} exact>
            <Heading fontSize="2xl">Event Details</Heading>
          </Route>
          <Route path={routes.docs.root}>
            <Heading fontSize="2xl">Documentation</Heading>
          </Route>

          {/* Nothing will render here if it doesn't match one of the above routes */}
        </Switch>
      </Toolbar>
      <NotificationBanner mt={2} {...error} />
    </Box>
  );
};

export default observer(Header);
