import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { Link, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import routes from 'routes';
import { BusinessSummary } from 'backend/api-types/dashboard';
import { accountErrorHashId } from 'settings/routes';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { NotificationBannerProps } from './NotificationBanner';

function isAccountWithOverflowUsers(summary: BusinessSummary | null) {
  return summary?.subscription?.overflow && summary?.subscription?.overflow > 0;
}

function isAccountGracePeriodExpired(summary: BusinessSummary | null) {
  return (
    summary?.subscription?.graceExpires &&
    summary?.subscription?.graceExpires < Date.now()
  );
}

const LinkToAccountError: React.FC = () => {
  return (
    <Link
      to={routes.settings.accountError}
      as={NavHashLink}
      smooth
      whiteSpace="nowrap"
      ml={2}
    >
      Manage Billing
    </Link>
  );
};

const GenericContactAdminMessage: React.FC = () => {
  return (
    <Text as="span">
      Contact your administrator and request an upgrade for your plan.
    </Text>
  );
};

interface NotificationContentProps {
  canModifyPlan?: boolean;
}

const NotificationContent: React.FC<NotificationContentProps> = ({
  canModifyPlan = false,
  children,
}) => {
  return (
    <>
      {children}
      {canModifyPlan ? <LinkToAccountError /> : <GenericContactAdminMessage />}
    </>
  );
};

const useNotificationBanner = (): NotificationBannerProps => {
  const user = useCurrentUser();
  const location = useLocation();
  const business = useCurrentBusiness();
  const isViewingAccountError = location.hash === `#${accountErrorHashId}`;
  let accountTroubles = business.data?.subscription.reason;
  if (accountTroubles && /[^.!]$/.test(accountTroubles)) {
    accountTroubles += '.';
  }

  const canShowSubscriptionProblemNotification = !!accountTroubles;

  const canShowOverflowUsersNotification =
    user.access.canModifyPlan && isAccountWithOverflowUsers(business.data);

  const canShowExpiredGracePeriodNotification =
    user.access.canModifyPlan && isAccountGracePeriodExpired(business.data);

  if (!isViewingAccountError) {
    if (canShowOverflowUsersNotification) {
      return {
        status: 'error',
        content: (
          <NotificationContent canModifyPlan={user.access.canModifyPlan}>
            You have exceeded the number of seats your billing plan allows.
          </NotificationContent>
        ),
      };
    }

    if (canShowExpiredGracePeriodNotification) {
      return {
        status: 'error',
        content: (
          <NotificationContent canModifyPlan={user.access.canModifyPlan}>
            The grace period for your overflow seats has expired.
          </NotificationContent>
        ),
      };
    }

    if (canShowSubscriptionProblemNotification) {
      return {
        status: 'error',
        content: (
          <NotificationContent canModifyPlan={user.access.canModifyPlan}>
            {accountTroubles}
          </NotificationContent>
        ),
      };
    }
  }

  return {};
};

export default useNotificationBanner;
