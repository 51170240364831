import React from 'react';
import { CenteredLayoutContent } from 'shared/components/CenteredLayout';
import LoadingBox from 'shared/components/LoadingBox';
import CodeError from './CodeError';

interface CodeSuccessProps {
  invalid?: boolean;
}
const CodeSuccess: React.FC<CodeSuccessProps> = ({ invalid }) => {
  if (invalid) {
    return <CodeError />;
  }
  return (
    <CenteredLayoutContent textAlign="center">
      <LoadingBox text="Logging In" />
    </CenteredLayoutContent>
  );
};

export default CodeSuccess;
