import React, { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Box, Divider, Heading, useToast, VStack } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'routes';
import PaymentForm from './PaymentForm';
import { PlanPurchaseLocationState } from './useCreatePayMethodForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '');

const Payment: React.FunctionComponent = () => {
  const history = useHistory();
  const toast = useToast();

  // This form is reached when creating a new credit card or collecting the card details
  // for the purposes of making a purchase. We can use the location state to determine what the use case is.
  const { state } = useLocation<PlanPurchaseLocationState | undefined>();
  const isMakingPurchase = !!(state && state.plan);

  const onCreateCard = useCallback(() => {
    if (isMakingPurchase) {
      // Go to a dedicated success screen for payment success
      history.push(routes.settings.paymentSuccess);
      return;
    }

    // Only updating the payment information. A toast message is enough.
    toast({
      title: 'Payment Information Updated',
      status: 'success',
      position: 'bottom-right',
    });

    // Go back to the settings billing screen
    history.push(routes.settings.billing);
  }, [history, isMakingPurchase, toast]);

  return (
    <>
      <Box
        flex="none"
        width="100%"
        maxWidth="md"
        mx="auto"
        bg="white"
        border="solid 1px"
        borderColor="gray.50"
        p={8}
        mt={8}
      >
        <Heading>Payment Information</Heading>
        {state?.seats && (
          <VStack my={4} spacing={2}>
            <Box width="100%">
              <strong>Pro Subscription - {state.seats} Users</strong>
            </Box>
            <Box width="100%">
              Your card will be charged{' '}
              <strong>${state.plan * state.seats}</strong> monthly starting
              today.
            </Box>
            <Divider />
          </VStack>
        )}
        <Elements stripe={stripePromise}>
          <PaymentForm
            isMakingPurchase={isMakingPurchase}
            onSuccess={onCreateCard}
          />
        </Elements>
      </Box>
    </>
  );
};

export default Payment;
