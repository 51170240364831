import React from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { ApplicationSummary } from 'application/ApplicationsController';

interface ApplicationSectionProps {
  app: ApplicationSummary;
  onOpenEditApplicationModal: () => void;
}

const ApplicationSection: React.FC<ApplicationSectionProps> = ({
  app,
  onOpenEditApplicationModal,
}) => {
  return (
    <Box
      flex="none"
      pt={3}
      pb={12}
      px={6}
      borderBottom="1px"
      borderColor="gray.100"
    >
      <Flex justifyContent="space-between" mb={1}>
        <Text fontSize="sm" color="gray.300">
          Application
        </Text>
        <Button
          variant="link"
          colorScheme="primary"
          size="xs"
          onClick={onOpenEditApplicationModal}
        >
          Edit Info
        </Button>
      </Flex>
      <Text fontWeight="bold">{app.name}</Text>
      {app.description && (
        <>
          <Text
            fontSize="xs"
            fontWeight="bold"
            color="gray.500"
            textTransform="uppercase"
            mt={6}
            mb={3}
          >
            Description
          </Text>
          <Text color="gray.600">{app.description}</Text>
        </>
      )}
    </Box>
  );
};

export default ApplicationSection;
