import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDimensions,
  useMediaQuery,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Search2Icon } from '@chakra-ui/icons';
import { FilterMenuStack, FilterMenu } from 'shared/components/FilterMenu';
import BackToTopFAB from 'shared/components/BackToTopFAB';
import {
  TableCapWrapper,
  TableCap,
  TableWrapper,
  TableOverflow,
  TableLoadingRows,
  TableCapContent,
} from 'shared/components/Table';
import DateRangeHeading from 'shared/components/DateRangeHeading';
import { MINIMUM_STICKY_VIEWPORT_HEIGHT } from 'shared/components/Table/Table';
import { EventLogItem } from 'event-log/types';
import { useEventTypes } from 'event-log/EventTypesController';
import {
  DateRangePreset,
  dateRangePresetsMap,
} from 'event-log/components/DatePicker/constants';
import ThreatLevelChart from 'monitoring/components/ThreatLevelChart';
import useThreatLevelChart from 'monitoring/hooks/useThreatLevelChart';
import DateFilterNav from 'dashboard/Dashboard/DateFilterNav';
import routes from 'routes';
import useEventLog, { EventThreatLevel } from './useEventLog';
import useEventFilters from './useEventFilters';
import EventLogTable from './EventLogTable/EventLogTable';

const dateRangePresets: DateRangePreset[] = [
  dateRangePresetsMap.today,
  dateRangePresetsMap.last7Days,
  dateRangePresetsMap.last30Days,
  dateRangePresetsMap.last12Months,
];

const INITIAL_DATE_RANGE_PRESET = dateRangePresets[2];

// Pre-filled number of table rows to render as a loading skeleton.
export const skeletonMockData: EventLogItem[] = Array(10)
  .fill(1)
  .map((_, idx) => {
    return {
      id: idx + 1, // Skip id 0, which is designated for locked events.
      action: 'LOADING',
      app: 0,
      key: 0,
      val: 0,
      threat: 0,
      data: 'LOADING',
      ip: 'LOADING',
      location: 'LOADING',
      user: 'LOADING',
      created: 0,
      relatedTo: 'LOADING',
      meta: {
        token: 'LOADING',
        communication: 0,
        communicationType: 0,
      },
    };
  });

const EventLog: React.FC = () => {
  const history = useHistory();
  const eventTypes = useEventTypes();

  let tableCapHeight: string | undefined;
  const tableCapRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(tableCapRef, true);

  const [selectedDateRangePreset, setSelectedDateRangePreset] = useState<
    DateRangePreset | undefined
  >(INITIAL_DATE_RANGE_PRESET);

  const [filterMeta, filters] = useEventFilters();
  const eventLog = useEventLog({
    ...filters,
    search: filterMeta.search,
    dateRange: selectedDateRangePreset?.dateRange,
  });
  const threatLevelChart = useThreatLevelChart({
    ...filters,
    dateRange: selectedDateRangePreset?.dateRange,
  });

  const { threatLevels: threatLevelsFilter } = filters;
  const hasThreatLevelChartData =
    Array.isArray(threatLevelChart.data) && threatLevelChart.data.length > 0;
  const canShowThreatLevelChart =
    hasThreatLevelChartData || threatLevelChart.loading;

  const chartThreatProps = useMemo(() => {
    const isDefaultEnabled = !(
      threatLevelsFilter && threatLevelsFilter.length > 0
    );
    return {
      showBenign:
        isDefaultEnabled ||
        !!threatLevelsFilter?.includes(EventThreatLevel.Benign),
      showInfo:
        isDefaultEnabled ||
        !!threatLevelsFilter?.includes(EventThreatLevel.Info),
      showWarn:
        isDefaultEnabled ||
        !!threatLevelsFilter?.includes(EventThreatLevel.Warn),
      showAlert:
        isDefaultEnabled ||
        !!threatLevelsFilter?.includes(EventThreatLevel.Alert),
    };
  }, [threatLevelsFilter]);

  // Any smaller than this and the table will need horizontal scrolling,
  // which breaks the sticky table header cells.
  const [enableSticky] = useMediaQuery(
    `(min-width: 1140px) and (min-height: ${MINIMUM_STICKY_VIEWPORT_HEIGHT}px)`
  );

  if (enableSticky && dimensions?.contentBox.height) {
    tableCapHeight = `${Math.round(dimensions?.contentBox.height) + 1}px`;
  }

  const onNoMoreRows = ({ event }: Waypoint.CallbackArgs) => {
    // Check for an event to prevent waypoint from firing when it is visible when mounted.
    if (!eventLog.loading && event) {
      eventLog.loadMore();
    }
  };

  const goToEventDetails = useCallback(
    (id: number) => {
      history.push(routes.eventLog.details(id));
    },
    [history]
  );

  return (
    <Box flex="none" width="100%" maxWidth="6xl" mx="auto">
      <Box zIndex={4} position="relative">
        <Box my={8}>
          <Heading as="h1" size="lg" mb={1}>
            Log of all events
          </Heading>
          <Heading as="p" size="md" fontWeight="normal">
            A detailed ledger of all the activity that occurs on your team.
          </Heading>
        </Box>

        <DateFilterNav
          presets={dateRangePresets}
          selectedPreset={selectedDateRangePreset}
          onSelect={setSelectedDateRangePreset}
          mb={8}
        />

        {canShowThreatLevelChart && (
          <Box position="relative" zIndex={4} mb={4}>
            <ThreatLevelChart
              data={threatLevelChart.data}
              loading={threatLevelChart.loading}
              {...chartThreatProps}
            />
          </Box>
        )}
        <DateRangeHeading
          heading="Team Activity"
          dateRange={selectedDateRangePreset?.dateRange}
          mt={8}
          mb={4}
        />
      </Box>

      <TableCapWrapper isSticky={enableSticky} ref={tableCapRef} zIndex={3}>
        <TableCap>
          <TableCapContent
            display="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex
              justifyContent="space-between"
              flexDirection={{ base: 'column', sm: 'row' }}
              alignItems={{ base: 'flex-start', sm: 'center' }}
              flexWrap="wrap"
            >
              <FilterMenuStack>
                <Text fontWeight="bold">Filter By:</Text>
                <FilterMenu
                  name="Application"
                  options={filterMeta.apps.filters}
                  activeFilterCount={filterMeta.apps.activeCount}
                  onFilterClick={filterMeta.apps.update}
                />
                <FilterMenu
                  name="Threat Level"
                  options={filterMeta.threat.filters}
                  activeFilterCount={filterMeta.threat.activeCount}
                  onFilterClick={filterMeta.threat.update}
                />
                <FilterMenu
                  name="Event Type"
                  options={filterMeta.eventTypes.filters}
                  activeFilterCount={filterMeta.eventTypes.activeCount}
                  onFilterClick={filterMeta.eventTypes.update}
                  searchable
                  searchPlaceholder="Filter event types..."
                />
                <FilterMenu
                  name="Users"
                  options={filterMeta.users.filters}
                  activeFilterCount={filterMeta.users.activeCount}
                  onFilterClick={filterMeta.users.update}
                  searchable
                  searchPlaceholder="Filter Users..."
                />
              </FilterMenuStack>
            </Flex>

            <Flex alignItems="center">
              <InputGroup
                maxWidth={['auto', '250px']}
                size="md"
                display="none"
                mt={{ base: 2 }}
                ml={{ base: 'auto' }}
              >
                <Input
                  name="q"
                  placeholder="Search for an event... "
                  onChange={(e) => {
                    filterMeta.setSearchValue(e.currentTarget.value);
                  }}
                />
                <InputRightElement overflow="hidden" borderRightRadius="md">
                  <IconButton
                    aria-label="Search"
                    icon={<Search2Icon />}
                    variant="link"
                    height="100%"
                    minWidth="auto"
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
          </TableCapContent>
        </TableCap>
      </TableCapWrapper>

      {eventTypes.finished && (
        <TableWrapper>
          <TableOverflow overflowX={enableSticky ? 'visible' : 'auto'}>
            <EventLogTable
              data={eventLog.data || skeletonMockData}
              isInitialLoading={eventLog.isInitialLoading}
              stickyTop={tableCapHeight}
              isSticky={enableSticky}
              onRowClick={goToEventDetails}
            />
          </TableOverflow>
          {eventLog.hasData && (
            <Waypoint onEnter={onNoMoreRows} bottomOffset="-100px">
              <div>
                <TableLoadingRows
                  isLoading={eventLog.loading}
                  onLoadMore={eventLog.loadMore}
                />
              </div>
            </Waypoint>
          )}
        </TableWrapper>
      )}

      <BackToTopFAB />
    </Box>
  );
};

export default observer(EventLog);
