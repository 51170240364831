import React from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { chakra, SystemStyleObject } from '@chakra-ui/system';
import { observer } from 'mobx-react';
import Logo from 'shared/components/Logo';
import { merge } from 'lodash';
import { CloseIcon } from '@chakra-ui/icons';
import routes from 'routes';
import useNavigate from 'shared/hooks/useNavigate';
import useSidebar from '../useSidebar';
import Navigation from './Navigation';

const styles: SystemStyleObject = {
  position: {
    base: 'fixed',
    lg: 'relative',
  },
  width: '100%',
  maxWidth: '400px',
  height: '100vh',
  top: {
    base: 0,
    lg: 'auto',
  },
  right: {
    base: 0,
    lg: 'auto',
  },
  bottom: {
    base: 0,
    lg: 'auto',
  },
  transform: {
    base: 'translateX(100%)',
    lg: 'none',
  },
  transition: {
    base: 'transform 0.2s ease-in-out',
    lg: 'none',
  },
  boxShadow: '0 0 40px -20px rgba(1, 28, 53, 0.45)',
  bg: 'white',
  zIndex: 'overlay',
};

const Sidebar: React.FC = () => {
  const goHome = useNavigate(routes.home);
  const { isOpen, onClose } = useSidebar();
  const handleLogoClick = () => {
    if (isOpen) {
      onClose();
    }

    goHome();
  };

  merge(styles, {
    transform: {
      base: `translateX(${isOpen ? '0' : '100%'})`,
      lg: 'none',
    },
  });

  return (
    <chakra.div sx={styles} data-tour-id="sidebar">
      <Flex direction="column" height="100%">
        <Flex
          flex="none"
          height={{ base: 16, lg: 24 }}
          alignItems="center"
          px={6}
          borderBottom="solid 1px"
          borderColor="gray.100"
          justifyContent="space-between"
        >
          <Logo
            height={35}
            withText
            onClick={handleLogoClick}
            cursor="pointer"
          />
          <IconButton
            size="sm"
            variant="unstyled"
            aria-label="menu"
            display={{ base: 'block', lg: 'none' }}
            type="button"
            onClick={onClose}
            icon={<CloseIcon />}
            zIndex="docked"
            _focus={{
              boxShadow: 'none',
            }}
          />
        </Flex>

        <Navigation onClose={onClose} />
      </Flex>
    </chakra.div>
  );
};

export default observer(Sidebar);
