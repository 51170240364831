import { useCallback } from 'react';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { useContacts } from 'contacts/ContactsController';

export type InviteTeamMembersFormValues = {
  emails: string[];
};

const defaultValues: InviteTeamMembersFormValues = {
  emails: [],
};

export default function useInviteTeamMembersForm(
  onSuccess?: () => void
): FormHandler<InviteTeamMembersFormValues> {
  const contacts = useContacts();
  const createTeam = useCallback(
    async (values: InviteTeamMembersFormValues) => {
      if (values.emails.length > 3) {
        throw new Error(
          'You can only invite up to 3 team members while on a free plan'
        );
      }

      await Promise.all(values.emails.map((e) => contacts.invite(e)));
      if (onSuccess) {
        onSuccess();
      }
    },
    [contacts, onSuccess]
  );

  return useFormHandler<InviteTeamMembersFormValues>(createTeam, defaultValues);
}
