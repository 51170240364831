import React from 'react';
import { Icon, Link, LinkProps } from '@chakra-ui/react';
import { IoIosDocument } from 'react-icons/io';

interface DocumentLinkProps extends LinkProps {
  href: string;
}

const DocumentLink: React.FC<DocumentLinkProps> = ({
  href,
  children,
  ...props
}) => {
  return (
    <Link
      flex="none"
      display="flex"
      alignItems="center"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      fontSize="xs"
      fontWeight="bold"
      textTransform="uppercase"
      textDecoration="none"
      color="gray.600"
      _hover={{
        color: 'blue.500',
        textDecoration: 'underline',
      }}
      {...props}
    >
      <Icon as={IoIosDocument} boxSize={4} mr={1} />
      {children}
    </Link>
  );
};

export default DocumentLink;
