import React from 'react';
import {
  Button,
  Input,
  InputGroup,
  FormLabel,
  ModalBody,
  Text,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import { PseudoAccordionButton } from 'shared/components/accordion';
import { TutorialName } from 'tutorials/constants';
import useTutorialAPIKey from 'tutorials/useTutorialAPIKey';

export interface TutorialSectionProps {
  apiKey: string;
}

const TutorialSection: React.FC<TutorialSectionProps> = ({ apiKey }) => {
  const tutorialDisclosure = useDisclosure();
  const history = useHistory();
  const { setAPIKey } = useTutorialAPIKey();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setAPIKey(apiKey);
    history.push(routes.tutorials.encrypt.root);
  };

  return (
    <>
      <PseudoAccordionButton
        isExpanded={tutorialDisclosure.isOpen}
        onClick={tutorialDisclosure.onToggle}
      >
        {TutorialName.encrypt}
        <Text ml={4} color="gray.300" textTransform="initial">
          Tutorial
        </Text>
      </PseudoAccordionButton>
      <Collapse in={tutorialDisclosure.isOpen}>
        <ModalBody pt={4} px={6}>
          <form onSubmit={handleSubmit}>
            <Text mb={6}>
              Learn how to create a subscriber, store an encryption key with XQ
              and encrypt a message.
            </Text>
            <FormLabel>Paste your API key to get started</FormLabel>
            <InputGroup>
              <Input variant="filled" name="apiKey" required />
              <Button type="submit" colorScheme="darkgray" size="lg" ml={4}>
                Continue
              </Button>
            </InputGroup>
          </form>
        </ModalBody>
      </Collapse>
    </>
  );
};

export default TutorialSection;
