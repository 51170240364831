import { observer } from 'mobx-react';
import React from 'react';
import { Grid } from '@chakra-ui/react';
import LoadingBox from 'shared/components/LoadingBox';
import RequestErrorAlert from 'shared/components/RequestErrorAlert';
import { useApplications } from '../ApplicationsController';
import ApplicationSummaryCard from './ApplicationSummaryCard';
import CreateFirstApplicationLayout from '../create/CreateFirstApplication/CreateFirstApplicationLayout';

const ListApplicationsScene: React.FC = () => {
  const apps = useApplications();

  if (apps.loading) {
    return <LoadingBox />;
  }

  if (apps.error) {
    return (
      <RequestErrorAlert canTryAgain>
        Failed to load your applications
      </RequestErrorAlert>
    );
  }

  const cards = apps.data?.map((app) => {
    return <ApplicationSummaryCard summary={app} key={app.id} />;
  });

  const hasApplications = cards && cards.length > 0;

  if (hasApplications) {
    return (
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        gap={8}
        maxWidth="4xl"
      >
        {cards}
      </Grid>
    );
  }

  return <CreateFirstApplicationLayout />;
};

export default observer(ListApplicationsScene);
