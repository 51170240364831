import React from 'react';
import {
  Button,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { PageContainer } from 'shared/components/containers';
import { Body, BorderedBox, Header, ProductLink } from 'welcome/styled';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { productsConfig } from './productsConfig';

const InstallProducts: React.FC = () => {
  const currentUser = useCurrentUser();
  return (
    <PageContainer flex={1} display="flex" flexDirection="column">
      <Header name={currentUser.data?.firstName} />
      <BorderedBox py={{ base: 8, md: 16 }} width="100%" display="flex">
        <Body maxWidth="5xl">
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <VStack
              spacing={6}
              textAlign="center"
              pr={{ md: 8 }}
              flex={{ md: '0 1 550px' }}
              mb={{ base: 16, md: 0 }}
              mt={{ md: 16 }}
            >
              <Heading size="md" mr="auto">
                Install Our Products
              </Heading>
              <Text as="p" textAlign="left" fontSize="lg">
                Once you have installed our products, your communications within
                the product will be reflected in the XQ dashboard.
              </Text>
              <SimpleGrid
                columns={{ base: 2, sm: 4 }}
                spacing={{ base: 8, xl: 16 }}
                mt={{ sm: 16 }}
              >
                <ProductLink
                  href={productsConfig.googleSuite.link}
                  image={productsConfig.googleSuite.image}
                  name={productsConfig.googleSuite.name}
                />
                <ProductLink
                  href={productsConfig.gmail.link}
                  image={productsConfig.gmail.image}
                  name={productsConfig.gmail.name}
                />
                <ProductLink
                  href={productsConfig.outlook.link}
                  image={productsConfig.outlook.image}
                  name={productsConfig.outlook.name}
                />
                <ProductLink
                  href={productsConfig.slack.link}
                  image={productsConfig.slack.image}
                  name={productsConfig.slack.name}
                />
              </SimpleGrid>
            </VStack>
            <Divider
              orientation="vertical"
              height={310}
              display={{ base: 'none', md: 'block' }}
            />{' '}
            <VStack
              spacing={6}
              textAlign="left"
              flexDirection="column"
              pt={{ md: 16 }}
              pl={{ md: 8 }}
            >
              <Heading size="md" mr="auto">
                Try XQ encryption now
              </Heading>
              <Text as="p">
                Select to the type of communication you want to encrypt:
              </Text>
              <Flex
                justifyContent="center"
                w="100%"
                flexWrap="wrap"
                alignItems="center"
                pt={8}
              >
                <Button
                  as={Link}
                  to={routes.encryption.encryptMessage}
                  minWidth={40}
                  m={2}
                >
                  Messages
                </Button>
                <Button
                  as={Link}
                  to={routes.encryption.encryptFile}
                  minWidth={40}
                  m={2}
                >
                  Files
                </Button>
              </Flex>
            </VStack>
          </Flex>
        </Body>
      </BorderedBox>
    </PageContainer>
  );
};

export default observer(InstallProducts);
