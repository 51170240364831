import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export default function useNavigate<StateType>(
  to: string,
  replace = false,
  state?: StateType
) {
  const history = useHistory<StateType>();
  return useCallback(() => {
    if (replace) {
      history.replace(to, state);
      return;
    }

    history.push(to, state);
  }, [history, replace, state, to]);
}
