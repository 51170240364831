import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Tabs } from '@chakra-ui/react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import routes from 'routes';
import useStats from 'monitoring/hooks/useStats';
import LoadingBox from 'shared/components/LoadingBox';
import { CommunicationStatus } from 'key-packet/types';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import Onboarding from 'dashboard/Onboarding';
import DashboardAccessControls from 'dashboard/DashboardAccessControls';
import {
  threatLevelByTab,
  ThreatGroupTab,
} from 'dashboard/Dashboard/ThreatGroupTabList/constants';
import {
  dateRangePresetsMap,
  DateRangePreset,
} from 'event-log/components/DatePicker/constants';
import useCommunicationFilters from './useCommunicationFilters';
import useCommunications from './useCommunications';
import KeyPacketsList from './KeyPacketsList';

const dateRangePresets: DateRangePreset[] = [
  dateRangePresetsMap.today,
  dateRangePresetsMap.last7Days,
  dateRangePresetsMap.last30Days,
  dateRangePresetsMap.last12Months,
];

const INITIAL_DATE_RANGE_PRESET = dateRangePresets[2];

type LocationState = {
  filterExpired?: boolean;
};

/**
 * This feature has been split into a separate container component because
 * KeyPacketList uses useDimensions to calculate the height of a sticky header,
 * but this hook does not work if the ref it accepts is not initialized in the
 * first render, which is the case here because loading states render first.
 */
const KeyPacketsListContainer: React.FC = () => {
  const location = useLocation<LocationState>();
  const currentUser = useCurrentUser();
  const stats = useStats();
  const isTeamFeed = !!useRouteMatch({
    path: routes.keyPacket.list.team,
    strict: true,
  });
  const initialStatusFilters = location.state?.filterExpired
    ? [CommunicationStatus.Expired]
    : [];
  const [filterMeta, filters] = useCommunicationFilters(initialStatusFilters);
  const [selectedDateRangePreset, setSelectedDateRangePreset] = useState<
    DateRangePreset | undefined
  >(INITIAL_DATE_RANGE_PRESET);
  const senderFilter = isTeamFeed
    ? filters.senders
    : [currentUser.data?.email ?? ''];

  const [tabIndex, setTabIndex] = useState(ThreatGroupTab.All);

  const communications = useCommunications({
    ...filters,
    senders: senderFilter,
    dateRange: selectedDateRangePreset?.dateRange,
    threats: threatLevelByTab[tabIndex],
    search: filterMeta.search,
  });

  if (stats.loading) {
    return <LoadingBox text="Fetching Communications" />;
  }

  const dashboardAccess = new DashboardAccessControls(stats.data);

  if (!dashboardAccess.canViewCommunications) {
    return <Onboarding />;
  }

  return (
    <Tabs size="lg" index={tabIndex} onChange={setTabIndex}>
      <KeyPacketsList
        communications={communications}
        isTeamFeed={isTeamFeed}
        filterMeta={filterMeta}
        dateRangePresets={dateRangePresets}
        selectedDateRangePreset={selectedDateRangePreset}
        onSelectDateRangePreset={setSelectedDateRangePreset}
      />
    </Tabs>
  );
};

export default observer(KeyPacketsListContainer);
