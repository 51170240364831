import React from 'react';
import { Box, BoxProps, Heading, Text } from '@chakra-ui/react';
import { Chapter, ChaptersDict } from '../types';

const ChapterHeading: React.FC = ({ children }) => (
  <Heading fontSize="xl" mt={8} mb={1}>
    {children}
  </Heading>
);

const ChapterNumber: React.FC = ({ children }) => (
  <Box fontSize="0.75em" color="black">
    {children}
  </Box>
);

type ChapterDictEntry = [string, Chapter];

const excludeIntroductionChapter = ([chapterNumber]: ChapterDictEntry) =>
  Number(chapterNumber) > 0;

interface TutorialTableOfContentsProps extends BoxProps {
  chapters: ChaptersDict;
}

const TutorialTableOfContents: React.FC<TutorialTableOfContentsProps> = ({
  chapters,
  ...props
}) => {
  const tocChapters = Object.entries(chapters).filter(
    excludeIntroductionChapter
  );

  return (
    <Box ml={{ base: 0, md: 8 }} mt={8} {...props}>
      {tocChapters.map(([chapterNumber, chapter]: ChapterDictEntry) => (
        <React.Fragment key={chapterNumber}>
          <ChapterHeading>
            <ChapterNumber>Chapter {chapterNumber}.</ChapterNumber>
            {chapter.name}
          </ChapterHeading>
          {chapter.tocContent && <Text>{chapter.tocContent}</Text>}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default TutorialTableOfContents;
