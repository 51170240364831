import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, ButtonProps, Flex, Text } from '@chakra-ui/react';

export interface FooterNavLinkProps extends ButtonProps {
  direction?: 'back' | 'next';
  to?: string;
  description?: string;
}

const FooterNavLink: React.FC<FooterNavLinkProps> = ({
  direction = 'next',
  description,
  to,
  children,
  type = 'button',
  onClick,
}) => {
  return (
    <Flex
      direction="column"
      alignItems={{
        base: 'center',
        md: direction === 'back' ? 'flex-start' : 'flex-end',
      }}
    >
      {description && (
        <Text color="gray.500" fontSize="sm" mx={4} mb={2}>
          {description}
        </Text>
      )}

      {to ? (
        <Button as={RouterLink} to={to} variant="outline" size="md">
          {children}
        </Button>
      ) : (
        <Button type={type} variant="outline" size="md" onClick={onClick}>
          {children}
        </Button>
      )}
    </Flex>
  );
};

export default FooterNavLink;
