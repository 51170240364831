import { useEffect, useState } from 'react';

/**
 * Controlled state for a JSON textarea that is updated with property values
 * passed from form fields.
 */
export default function useControlledJSONTextarea<T>(
  textareaRef: React.RefObject<HTMLTextAreaElement>,
  fields: T,
  initialBody: string
) {
  const [bodyValue, setBodyValue] = useState(initialBody);

  useEffect(() => {
    if (textareaRef?.current && textareaRef.current.value) {
      try {
        const parsed: T = JSON.parse(textareaRef.current.value);
        const fieldKeys: [keyof T] = Object.keys(fields) as [keyof T];
        fieldKeys.forEach((key: keyof T) => {
          if (key in parsed) {
            parsed[key] = fields[key];
          }
        });
        setBodyValue(JSON.stringify(parsed, null, 2));
      } catch (err) {
        // Do nothing
      }
    }
  }, [fields, textareaRef, setBodyValue]);

  return {
    bodyValue,
  };
}
