import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'shared/components/Table';
import { UserStatus, UserSummary } from 'auth/user/types';
import NoResultsBox from 'shared/components/NoResultsBox';
import { DateCellContent } from 'event-log/components/table-cells';
import ContactNameCellContent from './ContactNameCellContent';

export interface FileSummary {
  name: string | React.ReactElement;
  recipients: string | React.ReactElement;
  created: string;
  expires: string;
  status: string;
}

export interface TableData {
  columns: string[];
  rows: Record<string, string | React.ReactNode>[];
}

type ContactsTableProps = {
  data?: Array<UserSummary>;
  enableSticky?: boolean;
  stickyTop?: string;
  onRowClick?: (id: number) => void;
};

const ContactsTable: React.FC<ContactsTableProps> = ({
  data,
  enableSticky = true,
  stickyTop,
  onRowClick,
}) => {
  if (!data || !data.length) {
    return (
      <NoResultsBox heading="No contacts matched these filters">
        Expand your filter criteria to find more contacts
      </NoResultsBox>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Role
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Name
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Last Accessed
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((contact) => (
          <TableRow
            key={contact.id}
            onClick={onRowClick ? () => onRowClick(contact.id) : undefined}
          >
            <TableCell minWidth="75px">{contact.role}</TableCell>
            <TableCell minWidth="225px">
              <ContactNameCellContent contact={contact} />
            </TableCell>
            <TableCell minWidth="175px">
              {contact.status === UserStatus.Invited ? (
                'Never'
              ) : (
                <DateCellContent date={contact.lastLogin} />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ContactsTable;
