import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  Button,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
} from '@chakra-ui/react';
import { IoIosAdd, IoMdArrowDropdownCircle } from 'react-icons/io';
import { observer } from 'mobx-react';
import routes from 'routes';
import { useApplications } from 'application/ApplicationsController';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import appState from './ApplicationState';

const ApplicationsHeader: React.FC = () => {
  const user = useCurrentUser();
  const applications = useApplications();
  const history = useHistory();
  const hasApplications = applications.data && applications.data.length > 0;

  return (
    <Flex alignItems="center">
      <Heading fontSize="2xl">Applications</Heading>
      <Switch>
        <Route path={routes.application.root} exact>
          {hasApplications && user.access.canCreateApplication && (
            <Button
              variant="outline"
              size="xs"
              type="button"
              ml={3}
              leftIcon={<Icon as={IoIosAdd} boxSize={6} />}
              onClick={appState.openCreateApplicationModal}
            >
              Add New
            </Button>
          )}
        </Route>
        <Route path={routes.application.detail()}>
          <Menu autoSelect={false}>
            <MenuButton mx={1} _expanded={{ color: 'primary.500' }}>
              <Icon as={IoMdArrowDropdownCircle} boxSize={4} />
            </MenuButton>
            <MenuList>
              {user.access.canCreateApplication && (
                <>
                  <MenuItem onClick={appState.openCreateApplicationModal}>
                    Add an application
                  </MenuItem>
                  <MenuDivider />
                </>
              )}
              <MenuItem
                onClick={() => {
                  history.push(routes.application.root);
                }}
              >
                All applications
              </MenuItem>
              {applications.data?.map((app) => (
                <MenuItem
                  key={app.id}
                  onClick={() => {
                    history.push(routes.application.detail(app.id));
                  }}
                >
                  {app.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Route>
      </Switch>
    </Flex>
  );
};

export default observer(ApplicationsHeader);
