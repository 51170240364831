/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { unzipContent } from './shared/utils/unzip';

const messagePrefix = 'https://xqmsg.net/applink?to=';

function continueMessageUpload(
  newFileData: any[],
  encryptedPayloads: any[],
  encryptedFiles: any[]
) {
  for (const file of newFileData) {
    const datatype = file[0];

    if (datatype === 'file') {
      const filename = file[1];
      const contentData = file[2];

      encryptedFiles.push({
        type: 'file',
        n: filename,
        data: contentData,
      });
    } else {
      const filename = file[1];
      let contentString = file[2];
      const headers = file[3];

      contentString = contentString
        .replace(/=3D/g, '=')
        .replace(/(=\r\n)|(=\n)/g, '')
        .replace(/(&amp;)/g, '&')
        .replace(/(")|(>)|(<)/g, ' ');

      const linkStart = contentString.indexOf(messagePrefix);
      if (linkStart > -1) {
        const linkEnd = contentString.indexOf(' ', linkStart);

        if (linkEnd > -1) {
          const payload = contentString
            .substring(linkStart, linkEnd)
            .replace('\n', '')
            .split(' ')[0];

          if (payload.length > 0) {
            encryptedPayloads.push({
              type: 'text',
              n: filename,
              data: payload,
              headers,
            });
          }
        }
      }
    }
  }

  return [encryptedPayloads, encryptedFiles];
}

function processMbox(
  content: string | ArrayBuffer,
  named: string,
  encryptedPayloads: any[],
  encryptedFiles: any[]
) {
  const text = new TextDecoder('utf-8').decode(
    new Uint8Array(content as ArrayBuffer)
  );

  let specs = { Content: '', Subject: 'message', Headers: '' };
  const allSpecs: any[] = [];
  let filename = '';

  function pushSpecs() {
    if (specs.Content.indexOf(messagePrefix) > -1) {
      filename = specs.Subject.replace(/\s+/g, '_').replace(/\./g, '_');
      if (filename.length > 100) filename = filename.substring(0, 100);
      allSpecs.push(['text', filename + '.eml', specs.Content, specs.Headers]);
    } else {
      console.info('Ignoring email: ' + specs.Subject);
    }
  }

  const blockMarker = 'From ';

  function findNextBlock(position: number) {
    let m = text.indexOf(blockMarker, position);
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (m <= 0 || text[m - 1] === '\n') return m;
      m = text.indexOf(blockMarker, m + 1);
    }
  }

  let blockStart = findNextBlock(0);
  let blockEnd = blockStart > -1 ? findNextBlock(blockStart + 1) : text.length;
  if (blockEnd === -1) blockEnd = text.length;

  while (blockStart !== blockEnd) {
    const block = text.substring(blockStart, blockEnd);
    const lines = block.match(/[^\r\n]+/g);
    specs.Content = block;
    if (lines)
      for (const line of lines) {
        if (line.startsWith('Date:')) specs.Headers += line + '\n';
        else if (line.startsWith('Subject:')) {
          specs.Subject = line.substring(9);
          specs.Headers += line + '\n';
        } else if (line.startsWith('From:')) specs.Headers += line + '\n';
        else if (line.startsWith('To:')) specs.Headers += line + '\n';
      }

    pushSpecs();
    specs = { Content: '', Subject: 'message', Headers: '' };
    blockStart = blockEnd;
    blockEnd =
      blockStart > -1 && blockStart < text.length
        ? findNextBlock(blockStart + 1)
        : text.length;
    if (blockEnd === -1) blockEnd = text.length;
  }

  continueMessageUpload(allSpecs, encryptedPayloads, encryptedFiles);
}

export function useImportMessages(
  onSuccess: (messages: string[][], files: string[][]) => void
) {
  const action = useCallback(
    async (file: File) => {
      return new Promise<string[][]>((resolve, reject) => {
        const reader = new FileReader();
        const named = file.name.toLowerCase();

        // .eml, .olm, mbox or .zip file(
        if (
          !named.endsWith('.eml') &&
          !named.endsWith('.olm') &&
          !named.endsWith('.mbox') &&
          !named.endsWith('.zip')
        ) {
          throw new Error(
            'This file is does not have a valid extension as it is not .eml, .olm, mbox or .zip '
          );
        }

        reader.onload = (completionEvent) => {
          if (!completionEvent.target?.result) {
            // should never happen, just here for typesafety
            throw new Error(
              'This file is does not have a valid extension as it is not .eml, .olm, mbox or .zip '
            );
          } else {
            let rowCount = 0;

            let encryptedPayloads: any[] = [];
            let encryptedFiles: any[] = [];
            const data = completionEvent.target.result;
            if (named.endsWith('.eml') || named.endsWith('txt')) {
              const contentString = new TextDecoder('utf-8').decode(
                new Uint8Array(data as ArrayBuffer)
              );

              const lines = contentString.match(/[^\r\n]+/g);
              let headers = '';
              if (lines) {
                for (const line of lines) {
                  if (line.startsWith('Date:')) headers += line + '\n';
                  if (line.startsWith('Subject:')) headers += line + '\n';
                  if (line.startsWith('From:')) headers += line + '\n';
                  if (line.startsWith('To:')) headers += line + '\n';
                }
              }

              [encryptedPayloads, encryptedFiles] = continueMessageUpload(
                [['text', named, contentString, headers]],
                encryptedPayloads,
                encryptedFiles
              );

              rowCount = encryptedPayloads.length;

              if (rowCount === 0) {
                reject(
                  new Error(
                    'There are no encrypted messages in this file, please try a different file.'
                  )
                );
              }

              console.info(encryptedPayloads);

              if (encryptedPayloads !== undefined)
                onSuccess(encryptedPayloads, encryptedFiles);

              resolve(encryptedPayloads);
            } else if (named.endsWith('.xqf')) {
              [encryptedPayloads, encryptedFiles] = continueMessageUpload(
                [['file', named, data]],
                encryptedPayloads,
                encryptedFiles
              );
            } else if (named.endsWith('mbox')) {
              processMbox(data, named, encryptedPayloads, encryptedFiles);

              rowCount = encryptedPayloads.length;

              if (rowCount === 0) {
                reject(
                  new Error(
                    'There are no encrypted messages in this file, please try a different file.'
                  )
                );
              }

              if (encryptedPayloads !== undefined)
                onSuccess(encryptedPayloads, encryptedFiles);

              resolve(encryptedPayloads);
            } else if (named.endsWith('.olm')) {
              unzipContent(data, ['.xml']).then((x) => {
                [encryptedPayloads, encryptedFiles] = continueMessageUpload(
                  x,
                  encryptedPayloads,
                  encryptedFiles
                );

                rowCount = encryptedPayloads.length;

                if (rowCount === 0) {
                  reject(
                    new Error(
                      'There are no encrypted messages in this file, please try a different file.'
                    )
                  );
                }

                if (
                  encryptedPayloads !== undefined &&
                  encryptedFiles !== undefined
                )
                  onSuccess(encryptedPayloads, encryptedFiles);

                resolve(encryptedPayloads);
              });
            } else if (named.endsWith('.zip')) {
              unzipContent(data, ['.eml', '.txt', '.xqf']).then((x) => {
                [encryptedPayloads, encryptedFiles] = continueMessageUpload(
                  x,
                  encryptedPayloads,
                  encryptedFiles
                );

                rowCount = encryptedPayloads.length;

                if (rowCount === 0) {
                  reject(
                    new Error(
                      'There are no encrypted messages in this file, please try a different file.'
                    )
                  );
                }

                if (
                  encryptedPayloads !== undefined &&
                  encryptedFiles !== undefined
                )
                  onSuccess(encryptedPayloads, encryptedFiles);

                resolve(encryptedPayloads);
              });
            }
          }
        };

        reader.readAsArrayBuffer(file);
      });
    },
    [onSuccess]
  );
  return useAsyncAction(action);
}
