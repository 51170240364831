import { ChangeEvent, useCallback, useEffect, useState } from 'react';

export function formatJSON(data?: string): string {
  if (data) {
    const json = JSON.parse(data);
    return JSON.stringify(json, null, 2);
  }
  return '';
}

function formatBody(body: string, contentType: string) {
  if (contentType.indexOf('application/json') >= 0) {
    return formatJSON(body);
  }

  return body;
}

export default function useBodyEditor(
  initialBody = '',
  onFormatted: ((body: string) => void) | undefined,
  contentType = 'application/json'
) {
  const [body, setBody] = useState('');
  const [error, setError] = useState<string | undefined>();

  // Set the initial body and update it when changed.
  useEffect(() => {
    setBody(formatBody(initialBody, contentType));
    setError(undefined);
  }, [initialBody, contentType]);

  // Format the internal body state on blur.
  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const newBody = e.target.value;
      if (newBody.length > 0) {
        try {
          const formatted = formatBody(newBody, contentType);
          setBody(formatted);
          if (onFormatted) {
            onFormatted(formatted);
          }
        } catch (err) {
          setError(err.message);
        }
      }
    },
    [contentType, setBody, onFormatted]
  );

  // Maintain the state of the form but reset errors if modified.
  // Emit the formatted body on every keystroke without rewriting internal state.
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const newBody = e.target.value;
      setBody(newBody);
      setError(undefined);
      try {
        if (onFormatted) {
          const formatted = formatBody(newBody, contentType);
          onFormatted(formatted);
        }
      } catch (err) {
        setError(err.message);
      }
    },
    [contentType, setBody, setError, onFormatted]
  );

  return {
    handleBlur,
    handleChange,
    body,
    setBody,
    error,
  };
}
