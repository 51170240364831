import { CreateKeyPacketRequest } from 'backend/api-types/subscription';
import {
  quantumService,
  subscriptionService,
  validationService,
} from 'backend/services';
import { CommunicationMeta, CommunicationType } from 'key-packet/types';
import { toHex } from 'shared/utils/formatters';
import routes from 'routes';

export const XQ_PUBLIC_RECIPIENT = 'xq.public';
export const DEFAULT_EXPIRY = 72; // 72 Hours
export const XQ_MESSAGE_START_TAG = '[XQ MSG START]';
export const XQ_MESSAGE_END_TAG = '[XQ MSG END]';

export enum EncryptionAlgorithm {
  AES = 'AES',
  XOR = 'XOR',
  OTP = 'OTP',
}

function shuffle(s: string) {
  const a = s.split('');
  const n = a.length;
  for (let i = n - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }
  return a.join('');
}

function extendKey(key: string, extendTo: number) {
  if (key.length > extendTo) {
    return shuffle(key.substring(0, extendTo));
  }
  let g = key;
  while (g.length < extendTo) {
    g += shuffle(key);
  }
  return g;
}

export async function quantumKeyForFile(contentSize: number) {
  const response = await quantumService.get<string>('/', {
    params: {
      ks: 256,
    },
  });

  return extendKey(
    response.data,
    contentSize > 4096 ? 4096 : Math.max(2048, contentSize)
  );
}

export function algorithmPrefix(algorithm: EncryptionAlgorithm): string {
  switch (algorithm) {
    case EncryptionAlgorithm.AES:
      return '.A';
    case EncryptionAlgorithm.XOR:
      return '.X';
    case EncryptionAlgorithm.OTP:
      return '.B';
    default:
      throw new Error('Unsupported encryption algorithm');
  }
}

export async function storeEncryptionKey(
  key: string,
  algorithm: EncryptionAlgorithm,
  type: CommunicationType,
  expireInHours: number = DEFAULT_EXPIRY,
  recipients?: string[],
  meta?: CommunicationMeta
) {
  if (type === CommunicationType.File && !meta?.title) {
    // We will require meta information to be sent when encrypting files
    throw new Error('Missing file meta');
  }

  let metaToSend = meta;
  if (!meta && type === CommunicationType.Email) {
    metaToSend = {
      // Default subject to replicate how GMail handles missing subjects
      subject: '(no subject)',
    };
  }

  // Create the subscriber
  const subscriber = await subscriptionService.get<string>('/exchange', {
    params: {
      request: 'dashboard',
    },
  });

  // Create the key packet
  const createPacketBody: CreateKeyPacketRequest = {
    key: `${algorithmPrefix(algorithm)}${key}`,
    recipients: recipients?.join(',') || XQ_PUBLIC_RECIPIENT,
    type,
    expires: expireInHours,
    meta: metaToSend,
  };

  const packet = await subscriptionService.post<string>(
    '/packet',
    createPacketBody,
    {
      headers: {
        // Use the bearer token for the subscriber
        Authorization: `Bearer ${subscriber.data}`,
      },
    }
  );

  // Now store the key packet
  const locator = await validationService.post<string>('/packet', packet.data, {
    headers: {
      'Content-Type': 'text/plain;charset=UTF-8',

      // Use the bearer token for the subscriber
      Authorization: `Bearer ${subscriber.data}`,
    },
  });

  return locator.data;
}

export function buildDecryptionLink(
  encrypted: string,
  locator: string,
  recipients?: string[]
): string {
  const to = btoa(recipients?.join(',') || XQ_PUBLIC_RECIPIENT);

  // This is the format for XQ encrypted messages that is expected throughout the platform. The locator token
  // is always exactly 43 characters long, so that is how it get parsed out from the message content.
  //
  // Note: btoa encodes it by base64 which is url safe
  const messageInfo = btoa(`
${XQ_MESSAGE_START_TAG}
${locator}${encrypted}
${XQ_MESSAGE_END_TAG}
`);

  let orgQuery = '';
  if (process.env.REACT_APP_DECRYPTION_LINK_ORGANIZATION) {
    orgQuery = `&o=${toHex(
      process.env.REACT_APP_DECRYPTION_LINK_ORGANIZATION
    )}`;
  }

  let baseUrl = `${window.location.origin}${routes.encryption.decryptMessage}`;
  if (process.env.REACT_APP_DECRYPTION_LINK_BASE_URL) {
    baseUrl = process.env.REACT_APP_DECRYPTION_LINK_BASE_URL;
  }

  return `${baseUrl}?to=${to}&b=${messageInfo}${orgQuery}`;
}
