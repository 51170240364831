import React from 'react';
import { useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
import {
  Checkbox,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { StackedInputField } from 'shared/components/form';
import { setAllCheckboxes } from '../helpers';
import { eventTypes } from './useWebhookForm';

interface WebhookFormFieldsProps {
  initialFocusRef?: React.RefObject<HTMLInputElement>;
}

const WebhookFormFields: React.FC<WebhookFormFieldsProps> = ({
  initialFocusRef,
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    errors,
    formState,
  } = useFormContext();
  const { isSubmitted } = formState;
  const watchedEvents = watch('events');
  const isAllChecked = Object.values(watchedEvents).every(Boolean);

  const handleAllEventsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedEvents = setAllCheckboxes(eventTypes, e.target.checked);
    setValue('events', updatedEvents, { shouldValidate: isSubmitted });
  };

  const handleEventChange = (eventType: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { events } = getValues();
    const updatedEvents = cloneDeep(events);
    updatedEvents[eventType] = e.target.checked;
    setValue('events', updatedEvents, { shouldValidate: isSubmitted });
  };

  return (
    <>
      <ModalBody>
        <StackedInputField
          id="url"
          name="url"
          type="text"
          label="Callback URL"
          initialFocusRef={initialFocusRef}
          helperText="This webhook will be called when any of the selected events occur."
          // rightElement={
          //   <InputRightElement p={0} width="auto">
          //     <Button
          //       type="button"
          //       colorScheme="darkgray"
          //       borderTopLeftRadius={0}
          //       borderBottomLeftRadius={0}
          //       borderTopRightRadius="md"
          //       borderBottomRightRadius="md"
          //       height="100%"
          //     >
          //       Send Test
          //     </Button>
          //   </InputRightElement>
          // }
          isRequired
        />
      </ModalBody>

      <ModalBody
        py={8}
        borderTop="1px"
        borderBottom="1px"
        borderColor="gray.50"
      >
        <Checkbox isChecked={isAllChecked} onChange={handleAllEventsChange}>
          Subscribe to all events
        </Checkbox>

        <FormControl isInvalid={!!errors.events}>
          <FormLabel mt={6} mb={6}>
            Select events that will trigger your callback URL
          </FormLabel>

          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            {eventTypes.map((eventType) => (
              <div key={eventType}>
                <Checkbox
                  fontFamily="monospace"
                  name={`events.${eventType}`}
                  isChecked={watchedEvents[eventType]}
                  onChange={handleEventChange(eventType)}
                  ref={register}
                >
                  <Text as="span" fontSize="sm">
                    {eventType}
                  </Text>
                </Checkbox>
              </div>
            ))}
          </Grid>
          <FormErrorMessage mt={4}>{errors.events?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default WebhookFormFields;
