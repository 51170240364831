import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from 'routes';
import EncryptTutorialRoutes from './encrypt/EncryptTutorialRoutes';
import DecryptTutorialRoutes from './decrypt/DecryptTutorialRoutes';

const { tutorials } = routes;

const TutorialRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={tutorials.encrypt.root}>
        <EncryptTutorialRoutes />
      </Route>
      <Route path={tutorials.decrypt.root}>
        <DecryptTutorialRoutes />
      </Route>
    </Switch>
  );
};

export default TutorialRoutes;
