import React from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Select,
} from '@chakra-ui/react';
import { SelectFieldProps } from '../types';

export interface StackedSelectProps extends SelectFieldProps {
  label?: string;
  helperText?: React.ReactNode;
}

const StackedSelect = React.forwardRef<HTMLSelectElement, StackedSelectProps>(
  (
    { id, label, isInvalid, helperText, errorText, isRequired, ...props },
    _ref
  ) => {
    return (
      <FormControl id={id} isInvalid={isInvalid} isRequired={isRequired}>
        {label && <FormLabel>{label}</FormLabel>}
        <InputGroup>
          <Select ref={_ref} isRequired={isRequired} {...props} />
        </InputGroup>
        {isInvalid ? (
          <FormErrorMessage>{errorText}</FormErrorMessage>
        ) : (
          helperText && <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default StackedSelect;
