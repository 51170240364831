import { KeyPacketSummary } from 'backend/api-types/dashboard';
import { EventThreatLevel } from 'event-log/list/useEventLog';

export enum CommunicationType {
  Unknown = 0,
  File,
  Email,
}

export enum CommunicationStatus {
  Available = 0,
  Revoked = 1,
  Expired = 2,
}

export const COMMUNICATION_STATUSES = new Map<CommunicationStatus, string>();
COMMUNICATION_STATUSES.set(CommunicationStatus.Available, 'Available');
COMMUNICATION_STATUSES.set(CommunicationStatus.Revoked, 'Revoked');
COMMUNICATION_STATUSES.set(CommunicationStatus.Expired, 'Expired');

export type CommunicationMeta = {
  /** Email subject */
  subject?: string;

  /** File Name */
  title?: string;

  /** File MIME Type */
  type?: string;
};

export type CommunicationSummary = Omit<KeyPacketSummary, 'meta' | 'type'> & {
  type: CommunicationType;
  meta: CommunicationMeta;
  threat: number;
  verified: boolean;
};

export function convertKeyPacketMeta(initial?: string): CommunicationMeta {
  if (!initial) {
    return {};
  }

  let meta: unknown;
  try {
    meta = JSON.parse(initial);
  } catch (err) {
    return {};
  }

  if (typeof meta === 'object') {
    return {
      ...meta,
    };
  }

  return {};
}

const hasUnverifiedRecipients = (recipients: KeyPacketSummary['recipients']) =>
  recipients.reduce(
    (hasUnverified: boolean, curr) =>
      hasUnverified || curr.user === 'External User',
    false
  );

function convertStatsToThreat(summary: KeyPacketSummary) {
  if (summary?.alerts && summary.alerts > 0) {
    return EventThreatLevel.Alert;
  }
  if (summary?.warnings && summary.warnings > 0) {
    return EventThreatLevel.Warn;
  }
  return EventThreatLevel.Info;
}

export function convertKeyPacketToCommunication(
  k: KeyPacketSummary
): CommunicationSummary {
  return {
    ...k,
    meta: convertKeyPacketMeta(k.meta),
    verified: !hasUnverifiedRecipients(k.recipients),
    threat: convertStatsToThreat(k),
  };
}
