import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import useHLJSTheme from 'theme/useHLJSTheme';

interface CodeProps {
  language: 'bash' | 'php' | 'javascript' | 'csharp';
  title: string;
}

export const Code: React.FC<CodeProps> = ({ language, children }) => {
  const hljsTheme = useHLJSTheme();
  return (
    <SyntaxHighlighter language={language} style={hljsTheme}>
      {children}
    </SyntaxHighlighter>
  );
};

interface CodeViewProps {
  children: React.ReactElement<CodeProps>[] | React.ReactElement<CodeProps>;
}

function isElementArray<T>(array: T[] | T): array is T[] {
  return Array.isArray(array);
}

export const CodeView: React.FC<CodeViewProps> = ({ children }) => {
  let codes: React.ReactElement<CodeProps>[];
  if (isElementArray(children)) {
    codes = children;
  } else {
    codes = [children];
  }
  return (
    <Tabs mb={12}>
      <TabList mb={4}>
        {codes.map((c) => (
          <Tab key={c.props.language}>{c.props.title}</Tab>
        ))}
      </TabList>

      <TabPanels>
        {codes.map((c) => (
          <TabPanel key={c.props.language} p={0}>
            {c}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default CodeView;
