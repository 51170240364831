import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import usePlanForm from 'settings/payment/usePlanForm';
import { FormProvider } from 'react-hook-form';
import SpinnerButton from 'shared/components/SpinnerButton';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { PlanType } from 'settings/account/plan/constants';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { observer } from 'mobx-react';

interface PlanFormProps {
  plan: PlanType;
}

const PlanForm: React.FC<PlanFormProps> = ({ plan, children }) => {
  const business = useCurrentBusiness();
  const { form, onSubmit, error } = usePlanForm(plan);
  const isProPlan = plan === PlanType.Pro;
  const isFreePlan = plan === PlanType.Free;
  const isActivePlan = plan === business?.data?.subscription?.plan;

  const goToContactUs = () => {
    window.open('https://xqmsg.co/contact-us', '_blank');
  };

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        noValidate
        onSubmit={onSubmit}
        border="solid 1px"
        borderColor="gray.100"
        borderRadius="lg"
        direction="column"
        shadow="lg"
        overflow="hidden"
        justifyContent="space-between"
      >
        <Box>{children}</Box>
        <Box px={8}>
          <FormErrorAlert error={error} />
        </Box>

        <Box textAlign="center" mt={4} p={8} as="footer" bg="gray.50">
          {isProPlan ? (
            <SpinnerButton
              type="submit"
              isLoading={form.formState.isSubmitting}
              disabled={plan === PlanType.Free}
              colorScheme="green"
            >
              Select Plan
            </SpinnerButton>
          ) : (
            <Button
              type="button"
              colorScheme="green"
              disabled={isActivePlan && isFreePlan}
              onClick={goToContactUs}
            >
              Contact Us
            </Button>
          )}
        </Box>
      </Flex>
    </FormProvider>
  );
};

export default observer(PlanForm);
