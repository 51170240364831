import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { dashboardService } from 'backend/services';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { CreateBusinessRequest } from 'backend/api-types/dashboard';
import authController from 'auth/AuthenticationController';
import { useGlobalStore } from 'store/GlobalStoreContext';
import { useCurrentBusiness } from '../CurrentBusinessController';
import { useListBusinesses } from '../ListBusinessesController';

export type CreateTeamFormValues = {
  name: string;
  convertFromExisting: boolean;
};

const createTeamFormSchema = yup.object().shape({
  name: yup.string().trim().required('Required'),
});

export function convertNameToSlug(name: string): string {
  return name.toLowerCase().trim().replace(/\W/g, ' ').replace(/\s+/g, '-');
}

export default function useCreateTeamForm(
  onSuccess?: () => void
): FormHandler<CreateTeamFormValues> {
  const { cache } = useGlobalStore();
  const user = useCurrentUser();
  const business = useCurrentBusiness();
  const listBusinesses = useListBusinesses();

  const defaultValues = useMemo(() => {
    return {
      name: '',
      convertFromExisting: business.data?.isPersonal,
    };
  }, [business.data]);

  const createTeam = useCallback(
    async (values: CreateTeamFormValues) => {
      if (!user.data) {
        throw new Error('You must be signed in to create a team');
      }

      // Declaring this in a separate line so that Typescript enforces that we are sending the proper request body
      const requestBody: CreateBusinessRequest = {
        name: values.name,
        workspace: convertNameToSlug(values.name),
        email: user.data.email,
        aemail: user.data.email,
        convertFromExisting: values.convertFromExisting,
      };

      const res = await dashboardService.post<string>('/business', requestBody);
      authController.updateAccessToken(res.data);

      // Refetch these in a local loading state and don't clear them from the cache.
      // This prevents the global loading screen from flashing when the cache gets reset.
      await business.execute();
      await user.execute();
      await listBusinesses.execute();

      // Reset all other cache items so that there is no stale data lying around.
      cache.removeAllExcept(business.key, user.key, listBusinesses.key);

      if (onSuccess) {
        onSuccess();
      }
    },
    [user, business, listBusinesses, cache, onSuccess]
  );

  return useFormHandler<CreateTeamFormValues>(
    createTeam,
    defaultValues,
    createTeamFormSchema
  );
}
