import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';
import EncryptionHome from './EncryptionHome';
import EncryptMessageScene from './encrypt/EncryptMessageScene';
import EncryptFileScene from './encrypt/EncryptFileScene';
import DecryptMessageScene from './decrypt/DecryptMessageScene';
import DecryptFileScene from './decrypt/DecryptFileScene';

const EncryptionRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.root} exact>
        <EncryptionHome />
      </Route>
      <Route path={routes.encryptMessage} exact>
        <EncryptMessageScene />
      </Route>
      <Route path={routes.encryptFile} exact>
        <EncryptFileScene />
      </Route>
      <Route path={routes.decryptMessage} exact>
        <DecryptMessageScene />
      </Route>
      <Route path={routes.decryptFile} exact>
        <DecryptFileScene />
      </Route>
    </Switch>
  );
};

export default EncryptionRoutes;
