import React, { useState } from 'react';
import { Box, BoxProps, Button, Collapse, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useInvoices } from '../invoices/InvoicesController';
import { InvoiceLineItem } from '../invoices';
import { Section, SectionHeading } from '../styled';

const DEFAULT_NUM_INVOICES_SHOWN = 3;

const InvoicesSection: React.FC<BoxProps> = (props) => {
  const [showAllInvoices, setShowAllInvoices] = useState(false);
  const invoices = useInvoices();
  const hasInvoices = !!(invoices.data && invoices.data.length);
  const hasManyInvoices =
    !!invoices.data && invoices.data.length > DEFAULT_NUM_INVOICES_SHOWN;

  const initialLineItems = invoices.data
    ?.slice(0, DEFAULT_NUM_INVOICES_SHOWN)
    .map((invoice) => <InvoiceLineItem key={invoice.id} invoice={invoice} />);

  const expandedLineItems = invoices.data
    ?.slice(DEFAULT_NUM_INVOICES_SHOWN)
    .map((invoice) => <InvoiceLineItem key={invoice.id} invoice={invoice} />);

  return (
    <Section {...props}>
      <SectionHeading>My Invoices</SectionHeading>

      {hasInvoices ? (
        <>
          {initialLineItems}

          {hasManyInvoices && (
            <Box minHeight="24px">
              {!showAllInvoices && (
                <Button
                  variant="link"
                  type="button"
                  onClick={() => setShowAllInvoices(true)}
                  mr="auto"
                  minWidth="auto"
                  px={0}
                >
                  View All
                </Button>
              )}

              <Collapse in={showAllInvoices}>
                <Box>{expandedLineItems}</Box>
              </Collapse>
            </Box>
          )}
        </>
      ) : (
        <Text>No invoices have been created yet.</Text>
      )}
    </Section>
  );
};

export default observer(InvoicesSection);
