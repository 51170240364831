import React from 'react';
import { Box, FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { CardElement } from '@stripe/react-stripe-js';
import { StackedInputField } from 'shared/components/form';

const PaymentFormContent: React.FC = () => {
  return (
    <VStack spacing={4} my={8}>
      <StackedInputField name="name" required label="Name on Card" />
      <FormControl>
        <FormLabel>Credit Card</FormLabel>
        <Box
          border="solid 1px"
          borderColor="gray.200"
          borderRadius="md"
          p={3}
          py="15px"
          width="100%"
        >
          <CardElement />
        </Box>
      </FormControl>
    </VStack>
  );
};

export default PaymentFormContent;
