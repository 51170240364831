import React from 'react';
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  IconButton,
  Heading,
  Spinner,
} from '@chakra-ui/react';
import { ChakraProps } from '@chakra-ui/system';
import { HiOutlineRefresh } from 'react-icons/hi';

interface TableCapWrapperProps extends BoxProps {
  isSticky?: boolean;
}

/**
 * Wrapper container for TableCap that applies a blocker behind it to prevent the display
 * of content when it scrolls past a sticky position that has whitespace above it.
 *
 * A wrapper is used instead of applying the blocker to TableCap because the appropriate
 * z-index order could not be achieved that way.
 */
export const TableCapWrapper = React.forwardRef<
  HTMLDivElement,
  TableCapWrapperProps
>(({ isSticky, ...props }, _ref) => {
  const styledProps: TableCapWrapperProps = {
    zIndex: 1,
  };

  if (isSticky) {
    styledProps.position = 'sticky';
    styledProps.top = 0;
    styledProps._after = {
      display: 'block',
      content: '""',
      bg: 'backdrop',
      position: 'absolute',
      left: '-1px', // Compensate for borders that may be part of the sticky content
      right: '-1px',
      top: '-100%',
      height: '100%',
      zIndex: -1,
      minHeight: '100px',
    };
  }

  return <Box {...styledProps} {...props} ref={_ref} />;
});

export interface TableCapProps extends FlexProps {
  heading?: string;
  nav?: React.ReactNode;
  isLoading?: boolean;
  /**
   * Tables that use a skeleton loader do not need to render the same styles to
   * turn the table cap into a loading indicator.
   */
  isTableWithSkeleton?: boolean;
}

/**
 * A section of bordered content rendered above the table that can display a heading,
 * results count or any other info related to the table.
 *
 * When isLoading is enabled, it acts as a loading state for the table by displaying
 * a spinner next to its heading.
 */
export const TableCap: React.FC<TableCapProps> = ({
  heading,
  nav,
  isLoading,
  isTableWithSkeleton = false,
  children,
  ...props
}) => {
  let skeletonProps: BoxProps = {};
  if (isTableWithSkeleton) {
    skeletonProps = {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: 0,
    };
  }

  const showLoadingIndicator = isLoading && !isTableWithSkeleton;

  return (
    <Box
      bg="white"
      border="1px"
      borderBottom={isLoading ? '1px' : 0}
      borderColor="gray.100"
      borderTopLeftRadius="md"
      borderTopRightRadius="md"
      borderBottomLeftRadius={showLoadingIndicator ? 'md' : 0}
      borderBottomRightRadius={showLoadingIndicator ? 'md' : 0}
      width="100%"
      mx="auto"
      position="relative"
      {...skeletonProps}
      {...props}
    >
      {heading && (
        <Flex
          alignItems="center"
          width="100%"
          pt={4}
          px={6}
          pb={children ? 0 : 6}
        >
          <Heading fontSize="md">{heading}</Heading>
        </Flex>
      )}
      {/* this component will be phased out and replaced with Skeleton table content */}
      {showLoadingIndicator && (
        <Spinner
          ml={4}
          size="sm"
          color="blue.500"
          position="absolute"
          right={1}
          top={1}
        />
      )}

      {nav && nav}
      <Flex direction="column">{children}</Flex>
    </Box>
  );
};

export const TableCapContent: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box px={6} py={4} {...props}>
      {children}
    </Box>
  );
};

interface TableWrapperProps extends BoxProps {
  hideLastRowBorderBottom?: boolean;
}

/**
 * This wrapper applies outer borders to a table so adjacent components, such as TableCap or
 * infinite scroll loading spinners, can be rendered within the borders to make them appear
 * to be part of the table.  It also allows for overflow-x scrolling on the table.
 *
 * The last bottom row of the table is disabled by default because it is drawn by this wrapper.
 */
export const TableWrapper: React.FC<TableWrapperProps> = ({
  hideLastRowBorderBottom = true,
  ...props
}) => {
  const sx: Record<string, ChakraProps> = {};
  if (hideLastRowBorderBottom) {
    sx['tbody tr:last-child td'] = {
      borderBottom: 0,
    };
  }

  return (
    <Box
      border="1px"
      borderColor="gray.100"
      borderTop={0}
      bg="white"
      borderRadius="md"
      borderTopLeftRadius={0}
      borderTopRightRadius={0}
      mb={8} // Room for the user to scroll past the table so they see the infinite scroll loader.
      sx={sx}
      {...props}
    />
  );
};

export const TableOverflow: React.FC<BoxProps> = (props) => {
  return <Box css={{ WebkitOverflowScrolling: 'touch' }} {...props} />;
};

interface TableLoadingRowsProps extends FlexProps {
  isLoading?: boolean;
  onLoadMore: () => void;
}

/**
 * A loading spinner rendered after the table to indicate more rows are being loaded.
 */
export const TableLoadingRows: React.FC<TableLoadingRowsProps> = ({
  isLoading,
  onLoadMore,
  ...props
}) => {
  return (
    <Flex
      width="full"
      direction="column"
      justifyContent="center"
      alignItems="center"
      height={20}
      borderTop="solid 1px"
      borderTopColor="gray.100"
      {...props}
    >
      {isLoading ? (
        <Spinner size="lg" color="blue.500" />
      ) : (
        <IconButton
          aria-label="Fetch more rows"
          icon={<HiOutlineRefresh />}
          fontSize="3xl"
          type="button"
          variant="link"
          onClick={onLoadMore}
        />
      )}
    </Flex>
  );
};

/**
 * A message to display after the table when there are no more rows to load.
 */
export const TableResultsEnd: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box my={8} textAlign="center" fontWeight="bold" {...props}>
      {children ?? 'End of results'}
    </Box>
  );
};
