import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

const TutorialAPIKeyFields: React.FC = () => {
  const { register } = useFormContext();
  return (
    <FormControl id="apiKey" mt={4}>
      <FormLabel color="danger.500">
        Enter the application API key to receive these operations
      </FormLabel>

      <Input name="apiKey" ref={register} required />
    </FormControl>
  );
};

export default TutorialAPIKeyFields;
