import { AxiosInstance } from 'axios';
import bind from 'bind-decorator';
import {
  CreateAPIKeyRequest,
  CreateAPIKeyResponse,
  GetAPIKeysParams,
  GetAPIKeysResponse,
} from 'backend/api-types/dashboard';
import { action, computed, ObservableMap } from 'mobx';
import useAsyncAction, { AsyncAction } from 'shared/hooks/useAsyncAction';
import { dashboardService } from '../backend/services';
import AsyncData from '../cache/AsyncData';
import useAsyncData from '../cache/useAsyncData';

export type APIKeySummary = {
  id: number;
  appId: number;
  name: string;
};

export type APIKeyDetails = APIKeySummary & {
  key: string;
};

export enum APIKeyPlatform {
  Generic = 0,
  iOS,
  Chrome,
  gSuite,
  Website,
  Android,
  Outlook,
  iMessage,
  Slack,

  // Skipping 9 for some reason
  PHP = 10,
}

export enum APIKeyScope {
  XQPlatform = 1,
  Dashboard,
}
export default class APIKeysController extends AsyncData<
  GetAPIKeysResponse,
  GetAPIKeysParams,
  Array<APIKeySummary>
> {
  protected _axios: AxiosInstance = dashboardService;

  constructor() {
    super(`api-keys`, '/apikey');
  }

  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: GetAPIKeysResponse): Array<APIKeySummary> {
    return (
      data.keys.map((key) => ({
        appId: key.app ?? 0,
        id: key.id ?? 0,
        name: key.name ?? 'Untitled',
      })) ?? []
    );
  }

  @bind
  @action
  public async addKey(appId: number, name: string): Promise<APIKeyDetails> {
    // Declaring this in a separate line so that Typescript enforces that we are sending the proper request body
    const requestBody: CreateAPIKeyRequest = {
      name,
      app: appId,

      // Indicates that the key will have access to the XQ platform but not the dashboard. As in
      // this key will not be able to create other keys or perform other administrative tasks.
      api: APIKeyScope.XQPlatform,

      // Always a generic platform for now
      platform: APIKeyPlatform.Generic,
    };

    const res = await dashboardService.post<CreateAPIKeyResponse>(
      '/apikey',
      requestBody
    );

    const summary: APIKeySummary = {
      name,
      id: res.data.id,
      appId,
    };

    // Update the list of keys
    if (this._data) {
      this._data = [...this._data, summary];
    }

    return {
      ...summary,
      key: res.data.key,
    };
  }

  @bind
  @action
  public async deleteKey(id: number): Promise<void> {
    await dashboardService.delete(`/apikey/${id}`);

    // Update the list of keys
    if (this._data) {
      this._data = this._data.filter((key) => key.id !== id);
    }
  }

  @bind
  public getById(id: number): APIKeySummary | undefined {
    return this._lookup.get(id);
  }

  @bind
  public getByAppId(appId: number): APIKeySummary[] | undefined {
    if (!this._data) {
      return;
    }

    return this._data.filter((key) => key.appId === appId);
  }

  // This is a lookup map on the raw for performance reasons. It is cached by mobx and re-built any time the _data changes
  @computed
  protected get _lookup(): ObservableMap<number, APIKeySummary> {
    if (!this._data) {
      return new ObservableMap<number, APIKeySummary>();
    }

    const result = new Map();
    this._data.forEach((key) => {
      result.set(key.id, key);
    });

    return new ObservableMap(result);
  }
}

export function useAPIKeys(): APIKeysController {
  return useAsyncData<APIKeysController>(new APIKeysController());
}

export function useDeleteAPIKey(
  onSuccess?: (data: void) => void,
  onError?: (err: Error) => void
): AsyncAction<void, [number]> {
  const keys = useAPIKeys();
  return useAsyncAction<void, [number]>(keys.deleteKey, onSuccess, onError);
}
