import React from 'react';
import { Box } from '@chakra-ui/react';
import { PageContainer } from 'shared/components/containers';
import routes from 'routes';
import EncryptMessageContext, {
  EncryptMessageContextProvider,
} from './EncryptMessageContext';
import EncryptWizard from './EncryptWizard';
import Message from './chapters/Message';
import { RecipientsHOC } from './chapters/Recipients';
import { ExpirationHOC } from './chapters/Expiration';
import Send from './chapters/Send';

const chapters: React.ComponentType[] = [
  Message,
  RecipientsHOC({
    context: EncryptMessageContext,
    heading: 'Who will be able to view this encrypted message?',
    helperText:
      'Restrict who can decrypt this message by providing their email address.',
    optional: true,
  }),
  ExpirationHOC({
    context: EncryptMessageContext,
    heading: 'Set your message expiration',
    helperText:
      'Once the time has elapsed, the message will no longer be available for reading.',
    communicationName: 'Message',
  }),
  Send,
];

const EncryptMessageScene: React.FC = () => {
  return (
    <PageContainer display="flex" flexDirection="column" flex={1}>
      <EncryptMessageContextProvider
        finalRoute={routes.home}
        numChapters={chapters.length}
      >
        <Box>
          <EncryptWizard
            chapters={chapters}
            heading="Locally encrypt your message"
            helperText="Your message is encrypted locally. It never leaves your device."
            context={EncryptMessageContext}
          />
        </Box>
      </EncryptMessageContextProvider>
    </PageContainer>
  );
};

export default EncryptMessageScene;
