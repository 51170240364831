import {
  Collapse,
  Heading,
  Icon,
  ModalBody,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { UserSummary } from 'auth/user/types';
import React from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { PseudoAccordionButton } from 'shared/components/accordion';
import DeleteOrDisableContactForm from './DeleteOrDisableContactForm';

type DeleteOrDisableContactSectionProps = {
  contact: UserSummary;
  disable: boolean;
};

const DeleteOrDisableContactSection: React.FC<DeleteOrDisableContactSectionProps> = ({
  contact,
  disable,
}) => {
  const deleteDisclosure = useDisclosure();
  return (
    <>
      <PseudoAccordionButton
        isExpanded={deleteDisclosure.isOpen}
        onClick={deleteDisclosure.onToggle}
      >
        {disable ? 'Disable' : 'Delete'} Contact
      </PseudoAccordionButton>
      <Collapse in={deleteDisclosure.isOpen}>
        <ModalBody pt={6}>
          <Heading
            display="flex"
            alignItems="center"
            fontSize="inherit"
            textTransform="uppercase"
          >
            <Icon
              as={HiOutlineExclamationCircle}
              flex="none"
              mr={1}
              boxSize="1.0675rem"
              color="danger.500"
            />
            Danger Zone
          </Heading>
          <Text color="gray.700" mt={1} mb={4}>
            {disable
              ? ' Once disabled, XQ reporting will cease.'
              : ' Once deleted, you cannot retrieve the contact or its data.'}
          </Text>
          <DeleteOrDisableContactForm
            contact={contact}
            disable={disable}
            isExpanded={deleteDisclosure.isOpen}
          />
        </ModalBody>
      </Collapse>
    </>
  );
};

export default DeleteOrDisableContactSection;
