import React from 'react';
import {
  Text,
  Box,
  Heading,
  Wrap,
  WrapItem,
  Grid,
  TextProps,
  Divider,
  Button,
  Icon,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import format from 'date-fns/format';
import { HiReply } from 'react-icons/hi';
import { PageContainer } from 'shared/components/containers';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import useNavigate from 'shared/hooks/useNavigate';
import LoadingBox from 'shared/components/LoadingBox';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { productsConfig } from 'welcome/InstallProducts/productsConfig';
import ProductLink from 'encryption/components/ProductLink';
import EncryptionTabs from 'encryption/components/EncryptionTabs';
import { ReplyToMessageLocationState } from 'encryption/encrypt/EncryptMessageContext';
import routes from 'routes';
import useDecryptMessage from './useDecryptMessage';

const MAXIMUM_CONTENT_WIDTH = '3xl';

const GridLabel: React.FC<TextProps> = (props) => {
  return (
    <Text
      as="span"
      fontWeight="bold"
      textAlign={{ base: 'left', sm: 'right' }}
      mt={{ base: 2, sm: 0 }}
      {...props}
    />
  );
};

const DecryptMessageScene: React.FC = () => {
  const currentUser = useCurrentUser();

  const decrypted = useDecryptMessage();
  const message = decrypted.data?.message;
  const communication = decrypted.data?.communication;

  const canReply =
    currentUser.data?.email !== communication?.user &&
    communication?.user !== 'External User';
  const goToReply = useNavigate<ReplyToMessageLocationState>(
    routes.encryption.encryptMessage,
    false,
    {
      replyTo: communication?.user ?? '',
      subject: `RE: ${communication?.meta.subject}`,
    }
  );

  if (decrypted.loading) {
    return <LoadingBox />;
  }

  return (
    <PageContainer display="flex" flexDirection="column" flex={1}>
      <EncryptionTabs />

      {decrypted.error && (
        <FormErrorAlert
          error={decrypted.error}
          maxWidth={MAXIMUM_CONTENT_WIDTH}
        />
      )}
      {!!(message && communication) && (
        <>
          <Heading size="md" mb={6}>
            Here is the decrypted message that was sent to you
          </Heading>
          <Box
            border="solid 1px"
            borderColor="gray.100"
            borderRadius="lg"
            p={6}
            bg="white"
            maxWidth={MAXIMUM_CONTENT_WIDTH}
          >
            <Grid
              templateColumns={{ base: '1fr', sm: 'auto 1fr' }}
              columnGap={4}
            >
              <GridLabel>Sent:</GridLabel>
              <Text>
                {format(communication.updated, 'LLL d, YYY h:mm:ss a')}
              </Text>

              <GridLabel>From:</GridLabel>
              <Text>{communication.user}</Text>

              {!!communication.meta.subject && (
                <>
                  <GridLabel>Subject:</GridLabel>
                  <Text>{communication.meta.subject}</Text>
                </>
              )}
            </Grid>
            <Divider borderColor="gray.100" borderBottomWidth="1px" my={3} />
            <Text
              whiteSpace="pre-line"
              minHeight={{ base: 'auto', md: '200px' }}
            >
              {message}
            </Text>
            {canReply && (
              <Box mt={6} pt={6} borderColor="gray.100" borderTopWidth="1px">
                <Button type="button" onClick={goToReply}>
                  <Icon as={HiReply} mr={2} />
                  Reply
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
      <Box mt={8}>
        <Text fontWeight="bold" color="gray.700">
          Decrypt your content faster using our products:
        </Text>

        <Wrap mt={4} spacing={{ base: 6, sm: 8, md: 12 }}>
          <WrapItem>
            <ProductLink
              href={productsConfig.googleSuite.link}
              image={productsConfig.googleSuite.image}
              name={productsConfig.googleSuite.name}
            />
          </WrapItem>
          <WrapItem>
            <ProductLink
              href={productsConfig.gmail.link}
              image={productsConfig.gmail.image}
              name={productsConfig.gmail.name}
            />
          </WrapItem>
          <WrapItem>
            <ProductLink
              href={productsConfig.outlook.link}
              image={productsConfig.outlook.image}
              name={productsConfig.outlook.name}
            />
          </WrapItem>
          <WrapItem>
            <ProductLink
              href={productsConfig.slack.link}
              image={productsConfig.slack.image}
              name={productsConfig.slack.name}
            />
          </WrapItem>
        </Wrap>
      </Box>
    </PageContainer>
  );
};

export default observer(DecryptMessageScene);
