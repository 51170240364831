import React from 'react';
import { Link, Box, Flex, Text } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import routes from 'routes';
import { useAPIKeys } from 'api-keys/APIKeysController';
import { ApplicationSummary } from '../ApplicationsController';

interface ApplicationSummaryCardProps {
  summary: ApplicationSummary;
}

const ApplicationSummaryCard: React.FC<ApplicationSummaryCardProps> = ({
  summary,
}) => {
  const keys = useAPIKeys();

  return (
    <Link
      to={routes.application.detail(summary.id)}
      as={RouterLink}
      display="flex"
      role="group"
      position="relative"
      flexDirection="column"
      color="gray.900"
      textDecoration="none"
      bg="white"
      border="1px"
      borderColor="gray.100"
      borderRadius={4}
      transition="all 0.2s linear"
      shadow="lg"
      _hover={{
        borderColor: 'primary.300',
        shadow: 'xl',
      }}
      _focus={{
        borderColor: 'primary.600',
      }}
      _active={{
        borderColor: 'primary.300',
      }}
    >
      <Box flex="none" pt={3} pb={4} px={6}>
        <Text
          fontSize="xs"
          textTransform="uppercase"
          fontWeight="bold"
          color="blue.700"
          position="absolute"
          top={4}
          right={6}
          transition="color 0.2s linear"
          _groupHover={{
            color: 'blue.500',
          }}
        >
          <Flex alignItems="center" as="span">
            <InfoOutlineIcon boxSize={3} verticalAlign="middle" mr={2} />
            Details
          </Flex>
        </Text>
        <Text fontSize="sm" color="gray.300" mb={1}>
          Application
        </Text>
        <Text fontWeight="bold">{summary.name}</Text>
        {summary.description && (
          <>
            <Text
              fontSize="xs"
              fontWeight="bold"
              textTransform="uppercase"
              color="gray.500"
              mt={6}
              mb={2}
            >
              Description
            </Text>
            <Text color="gray.600">{summary.description}</Text>
          </>
        )}
      </Box>
      <Flex
        alignItems="center"
        borderTop="1px"
        borderColor="gray.100"
        py={4}
        px={6}
        mt="auto"
      >
        <Text textTransform="uppercase" mr={2}>
          API Keys
        </Text>
        <Text fontWeight="bold" color="blue.500">
          {keys.getByAppId(summary.id)?.length ?? '0'}
        </Text>
      </Flex>
    </Link>
  );
};

export default observer(ApplicationSummaryCard);
