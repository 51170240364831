import React from 'react';
import routes from 'routes';
import { ChaptersDict } from '../types';

const {
  tutorials: { encrypt },
} = routes;

export default {
  0: {
    name: 'What to expect in this tutorial',
    to: encrypt.chapter0,
  },
  1: {
    name: 'Creating the subscriber',
    to: encrypt.chapter1,
    tocContent: (
      <>
        The subscriber is an email address that is authorized to use this
        encryption key.
      </>
    ),
  },
  2: {
    name: 'Creating the encryption key',
    to: encrypt.chapter2,
    tocContent: (
      <>
        Your key is a random string of bits used by an encryption algorithm for
        rewriting data so it appears to be random.
      </>
    ),
  },
  3: {
    name: 'Creating the key packet',
    to: encrypt.chapter3,
    tocContent: (
      <>
        An encrypted packet containing the encryption key that you want to
        protect along with a list of the identities that are allowed to access
        it and how long it is allowed to be used.
      </>
    ),
  },
  4: {
    name: 'Storing the key packet',
    to: encrypt.chapter4,
    tocContent: (
      <>
        The key server stores the key packet and returns you a locator token,
        used by the subscriber or recipients to retrieve the secret key in the
        future.
      </>
    ),
  },
  5: {
    name: 'Encrypting a message',
    to: encrypt.chapter5,
    tocContent: <>Encrypt a message using your encryption key.</>,
  },
} as ChaptersDict;
