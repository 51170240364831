import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import routes from './routes';
import AuthRoutes from './auth/AuthRoutes';
import DashboardRoutes from './dashboard/DashboardRoutes';
import AllReferencesProvider from './docs/references/ReferencesContext';

const App: React.FC = () => {
  return (
    <>
      {/* Default meta tags for the application */}
      <Helmet>
        <title>XQ Message Developer Dashboard</title>
        <meta
          name="description"
          content="The easiest way to manage encryption keys throughout your applications"
        />
      </Helmet>

      <Switch>
        <Route
          path={[
            routes.auth.login,
            routes.auth.code,
            routes.auth.forgotPassword,
            routes.auth.signup,
            routes.auth.verify,
            routes.auth.accountVerified,
          ]}
          component={AuthRoutes}
          exact
        />

        {/* Default route is the main application page */}
        <Route>
          <AllReferencesProvider>
            <DashboardRoutes />
          </AllReferencesProvider>
        </Route>
      </Switch>
    </>
  );
};

export default App;
