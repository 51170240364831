import React, { useRef } from 'react';
import { Box, Text, useDimensions, useMediaQuery } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { Waypoint } from 'react-waypoint';
import {
  TableCapWrapper,
  TableCap,
  TableLoadingRows,
  TableWrapper,
  TableOverflow,
  TableCapContent,
} from 'shared/components/Table';
import BackToTopFAB from 'shared/components/BackToTopFAB';
import { FilterMenuStack, FilterMenu } from 'shared/components/FilterMenu';
import useCommunicationFilters from 'key-packet/list/useCommunicationFilters';
import useCommunications from 'key-packet/list/useCommunications';
import {
  MINIMUM_STICKY_VIEWPORT_HEIGHT,
  TableNav,
} from 'shared/components/Table/Table';
import { TableNavItems } from './ContactDetailsScene';
import KeyPacketTable from './KeyPacketTable';

interface KeyPacketProps {
  contactId: number;
  contactEmail: string;
}

const KeyPacketsList: React.FC<KeyPacketProps> = ({
  contactId,
  contactEmail,
}) => {
  let tableCapHeight;
  const userFilter = [contactEmail];
  const [filterMeta] = useCommunicationFilters();
  const communications = useCommunications({
    senders: userFilter,
  });
  const tableCapRef = useRef<HTMLDivElement>(null);
  const tableCapDimensions = useDimensions(tableCapRef, true);
  const navItems = TableNavItems(contactId);

  // Any smaller than this and the table will need horizontal scrolling,
  // which breaks the sticky table header cells.
  const [enableSticky] = useMediaQuery(
    `(min-width: 1280px) and (min-height: ${MINIMUM_STICKY_VIEWPORT_HEIGHT}px)`
  );

  if (enableSticky && tableCapDimensions?.contentBox.height) {
    tableCapHeight = `${
      Math.round(tableCapDimensions?.contentBox.height) + 1
    }px`;
  }

  const onNoMoreRows = ({ event }: Waypoint.CallbackArgs) => {
    // Check for an event to prevent waypoint from firing when it is visible when mounted.
    if (!communications.loading && event) {
      communications.loadMore();
    }
  };

  return (
    <Box width="100%" maxWidth="6xl" mx="auto">
      <TableCapWrapper isSticky={enableSticky} ref={tableCapRef}>
        <TableCap
          isLoading={communications.isInitialLoading}
          nav={<TableNav navItems={navItems} />}
        >
          <TableCapContent>
            {/* minHeight prevents incorrect component height calculation at load */}
            <FilterMenuStack>
              <Text fontWeight="bold">Filter By:</Text>
              <FilterMenu
                name="Type"
                options={filterMeta.types.filters}
                activeFilterCount={filterMeta.types.activeCount}
                onFilterClick={filterMeta.types.update}
              />
            </FilterMenuStack>
          </TableCapContent>
        </TableCap>
      </TableCapWrapper>
      {!!communications.data && (
        <TableWrapper>
          <TableOverflow overflowX={enableSticky ? 'visible' : 'auto'}>
            <KeyPacketTable
              data={communications.data}
              enableSticky={enableSticky}
              stickyTop={tableCapHeight}
              canViewOwner
            />
          </TableOverflow>
          {communications.hasData && (
            <Waypoint onEnter={onNoMoreRows} bottomOffset="-100px">
              <div>
                <TableLoadingRows
                  isLoading={communications.loading}
                  onLoadMore={communications.loadMore}
                />
              </div>
            </Waypoint>
          )}
        </TableWrapper>
      )}

      <BackToTopFAB />
    </Box>
  );
};

export default observer(KeyPacketsList);
