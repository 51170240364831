import React from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, OnChangeProps, Range } from 'react-date-range';

type DatePickerProps = {
  onChange: (range: OnChangeProps) => void;
  range: Range;
  key: 'selection';
};

const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  range,
  ...props
}) => {
  return (
    <DateRange
      editableDateInputs
      onChange={onChange}
      ranges={[range]}
      {...props}
    />
  );
};

export default DatePicker;
