import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { Box, Heading, Button, Flex, Text } from '@chakra-ui/react';

const JoyrideTooltip: React.FC<TooltipRenderProps> = ({
  continuous,
  index,
  step,
  size,
  backProps,
  skipProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => {
  const stepCount = index + 1;
  return (
    <Box bg="white" borderRadius="md" p={5} maxWidth="sm" {...tooltipProps}>
      {step.title && (
        <Heading size="md" mb={2}>
          {step.title}
        </Heading>
      )}
      <Box mb={4}>{step.content}</Box>
      <Flex justify="space-between" alignItems="center">
        {continuous && (
          <>
            <Button size="sm" variant="link" colorScheme="gray" {...skipProps}>
              Skip Tour
            </Button>
            <Flex alignItems="center" ml="auto">
              {index > 0 && (
                <Button size="sm" variant="outline" {...backProps}>
                  Back
                </Button>
              )}
              <Text mx={3}>
                {stepCount} of {size}
              </Text>
              <Button size="sm" {...primaryProps}>
                {stepCount < size ? 'Next' : 'Done'}
              </Button>
            </Flex>
          </>
        )}
        {!continuous && (
          <Button size="sm" {...closeProps}>
            Got It
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default JoyrideTooltip;
