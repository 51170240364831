import { format, startOfDay } from 'date-fns';

export function isDateRangeMultipleDays(
  fromDate: Date,
  toDate: Date | undefined
) {
  if (!toDate) {
    return false;
  }

  return startOfDay(fromDate).getTime() !== startOfDay(toDate).getTime();
}

export function describeDateRange(
  fromDate: Date,
  toDate: Date | undefined,
  pattern = 'MMM do, yyyy'
) {
  if (toDate && isDateRangeMultipleDays(fromDate, toDate)) {
    return `${format(fromDate, pattern)} - ${format(toDate, pattern)}`;
  }

  return format(fromDate, pattern);
}
