import React from 'react';
import JoyRide, { Step } from 'react-joyride';
import { firstTourStepProps, defaultJoyrideProps } from './constants';
import useTour from './useTour';

const steps: Step[] = [
  {
    target: '[data-tour-id="sidebar"]',
    title: 'Welcome to XQ Message',
    content:
      'Access all of your XQ features and reporting directly from this sidebar navigation.',
    spotlightPadding: 0,
    offset: 0,
    placement: 'right',
    ...firstTourStepProps,
  },
  {
    target: '[data-tour-id="switch-team"]',
    title: 'Teams',
    content:
      'Create teams so you can monitor all of the encryption activities within groups of contacts.',
  },
];

const WelcomeTour = () => {
  const tour = useTour('welcome');

  if (tour.isFinished) {
    return null;
  }

  return (
    <JoyRide
      steps={steps}
      run={tour.run}
      callback={tour.handleCallback}
      {...defaultJoyrideProps}
    />
  );
};

export default WelcomeTour;
