import React from 'react';
import { CenteredLayout } from 'shared/components/CenteredLayout';
import EmailLoginResult from './EmailLoginResult/EmailLoginResult';

const EmailLoginResultScene: React.FC = () => {
  return (
    <CenteredLayout showLogo>
      <EmailLoginResult />
    </CenteredLayout>
  );
};

export default EmailLoginResultScene;
