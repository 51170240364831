import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './routes';
import CreateTeamScene from './create/CreateTeamScene';
import InviteTeamMembersScene from './invite/InviteTeamMembersScene';

const TeamRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.create} exact>
        <CreateTeamScene />
      </Route>

      <Route path={routes.invite} exact>
        <InviteTeamMembersScene />
      </Route>

      <Redirect to={routes.create} />
    </Switch>
  );
};

export default TeamRoutes;
