import React from 'react';
import { observer } from 'mobx-react';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import PlanInformationSection from './billing/PlanInformationSection';
import PaymentInformationSection from './billing/PaymentInformationSection';
import InvoicesSection from './billing/InvoicesSection';

const Billing: React.FC = () => {
  const user = useCurrentUser();
  const business = useCurrentBusiness();

  if (!business.data) {
    return null;
  }

  return (
    <>
      <PlanInformationSection />
      <PaymentInformationSection />
      {user.access.canViewInvoices && <InvoicesSection borderBottom={0} />}
    </>
  );
};

export default observer(Billing);
