import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import customChakraTheme from './theme/customChakraTheme';
import App from './App';
import { GlobalStoreContextProvider } from './store/GlobalStoreContext';
import { MagicLinkQueryStoreContextProvider } from './auth/MagicLinkQueryStoreContext';

ReactDOM.render(
  <React.StrictMode>
    <GlobalStoreContextProvider>
      <ChakraProvider theme={customChakraTheme} resetCSS>
        <BrowserRouter>
          <HelmetProvider>
            <MagicLinkQueryStoreContextProvider>
              <App />
            </MagicLinkQueryStoreContextProvider>
          </HelmetProvider>
        </BrowserRouter>
      </ChakraProvider>
    </GlobalStoreContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
