import React, { createContext, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import {
  defaultEncryptWizardValues,
  EncryptWizardLocationState,
  EncryptWizardValues,
} from './EncryptMessageContext';

const LOCAL_STORAGE_KEY = 'encrypt-file-state';
const LOCAL_STORAGE_TTL = 60 * 60 * 1000; // 1 Hour

export type EncryptFileValues = {
  /** The recipients of the file */
  recipients?: string[];

  /** Number of hours till the file expires */
  expireInHours?: number;

  /** The date that the locally storage values will expire (in milliseconds) */
  localStorageExpiration?: number;
};

export type EncryptFileContextValues = EncryptWizardValues & EncryptFileValues;

const EncryptFileContext = createContext<EncryptFileContextValues>(
  defaultEncryptWizardValues
);

type EncryptFileContextProviderProps = {
  finalRoute: string;
  numChapters: number;
};

export const EncryptFileContextProvider: React.FC<EncryptFileContextProviderProps> = ({
  children,
  finalRoute,
  numChapters,
}) => {
  const history = useHistory();
  const [state, setState] = useLocalStorageState<EncryptFileValues>(
    LOCAL_STORAGE_KEY
  );
  const { state: locationState, pathname } = useLocation<
    EncryptWizardLocationState | undefined
  >();

  const goNext = useCallback(
    (newState: EncryptFileValues) => {
      if (locationState?.chapter && locationState.chapter >= numChapters) {
        // There are no other chapters to go to. Use the exit route instead.
        setState.reset();
        history.push(finalRoute);
        return;
      }

      const merged = {
        ...state,
        ...newState,
        localStorageExpiration: Date.now() + LOCAL_STORAGE_TTL,
      };
      setState(merged);

      history.push(pathname, {
        chapter: (locationState?.chapter ?? 1) + 1,
      });
    },
    [
      finalRoute,
      history,
      locationState?.chapter,
      numChapters,
      pathname,
      setState,
      state,
    ]
  );

  // Reset the store if there is are stagnant variables.
  useEffect(() => {
    if (
      state?.localStorageExpiration &&
      Date.now() > state?.localStorageExpiration
    ) {
      setState.reset();
    }
  }, [state?.localStorageExpiration, setState]);

  // Redirect to the first step if the user has somehow made it to a later step
  // without setting an expiry. This handles the user hitting "back" in the browser
  // after the state has been cleared.
  useEffect(() => {
    if (
      !state?.localStorageExpiration &&
      locationState?.chapter &&
      locationState?.chapter !== 1
    ) {
      history.replace(pathname, {
        chapter: 1,
      });
    }
  }, [
    locationState?.chapter,
    history,
    pathname,
    state?.localStorageExpiration,
  ]);

  return (
    <EncryptFileContext.Provider
      value={{
        ...state,
        chapter: locationState?.chapter ?? 1,
        goPrev: history.goBack,
        goNext,
        reset: setState.reset,
      }}
    >
      {children}
    </EncryptFileContext.Provider>
  );
};

export default EncryptFileContext;
