import React, { useContext, useMemo } from 'react';
import * as yup from 'yup';
import { Heading, Text } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useFormHandler } from 'shared/hooks/useFormHandler';
import { MultiEmailField } from 'shared/components/MultiEmail';
import { EncryptWizardValues } from '../EncryptMessageContext';
import { StepHeader, StepContent, StepNavigation } from '../components';

type RecipientsProps = {
  context: React.Context<
    EncryptWizardValues & {
      recipients?: string[];
    }
  >;
  heading: string;
  helperText: string;
  optional: boolean;
};

type FormValues = {
  recipients: string[];
};

const Recipients: React.FC<RecipientsProps> = ({
  context,
  heading,
  helperText,
  optional,
}) => {
  const { goPrev, goNext, recipients } = useContext(context);

  const schema = useMemo(() => {
    return yup.object().shape({
      recipients: optional
        ? yup.array().of(yup.string())
        : yup.array().of(yup.string()).required('Required'),
    });
  }, [optional]);

  const { onSubmit, form } = useFormHandler<FormValues>(
    goNext,
    { recipients },
    schema,
    true
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} noValidate>
        <StepHeader>
          <Heading size="sm" mb={2}>
            {heading}
            {optional && <Text as="em"> (optional)</Text>}
          </Heading>
          <Text>{helperText}</Text>
        </StepHeader>
        <StepContent>
          <MultiEmailField name="recipients" />
          <StepNavigation onPrevClick={goPrev} />
        </StepContent>
      </form>
    </FormProvider>
  );
};

export function RecipientsHOC(props: RecipientsProps) {
  return () => <Recipients {...props} />;
}

export default Recipients;
