import { useCallback } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { TryItResult } from 'shared/components/TryIt';

type ResultKey = number | string;
type ChapterResults = Record<ResultKey, TryItResult | undefined>;

export default function useTutorialChapters(resultsKey: string) {
  const [
    chapterResults,
    setChapterResults,
  ] = useLocalStorageState<ChapterResults>(resultsKey, {});

  const storeResult = useCallback(
    (step: number | string) => (results: TryItResult | undefined) => {
      setChapterResults((prevState) => {
        return {
          ...prevState,
          [step]: results,
        };
      });
    },
    [setChapterResults]
  );

  return {
    chapterResults,
    setChapterResults,
    storeResult,
  };
}

export type UseTutorialChaptersReturn = ReturnType<typeof useTutorialChapters>;
