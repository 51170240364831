import { useContext } from 'react';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormHandler, FormHandler } from 'shared/hooks/useFormHandler';
import { AuthenticationController } from 'auth/AuthenticationController';
import routes from 'auth/routes';
import { PostLoginReturnState } from 'auth/ProtectedRoute';
import MagicLinkQueryStoreContext from 'auth/MagicLinkQueryStoreContext';

export type EmailLoginFormValues = {
  email: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Enter your email address')
    .email('Invalid email address'),
});

const defaultValues: EmailLoginFormValues = {
  email: '',
};

export default function useEmailLoginForm(): FormHandler<EmailLoginFormValues> {
  const history = useHistory();
  const { state } = useLocation<PostLoginReturnState | undefined>();
  const magicLinkState = useContext(MagicLinkQueryStoreContext);

  return useFormHandler<EmailLoginFormValues>(
    async ({ email }) => {
      await AuthenticationController.requestLoginLink(email);

      // If we are tracking which location to return to after authentication, we need to elevate
      // that information to local storage so that it is accessible when the user
      // comes back after verifying their email address.
      if (state?.returnTo) {
        magicLinkState.update(state.returnTo);
      }

      history.push(routes.code, { email });
    },
    defaultValues,
    schema
  );
}
