import { AxiosInstance } from 'axios';
import bind from 'bind-decorator';
import { action } from 'mobx';
import {
  CreateContactRequest,
  CreateContactResponse,
  ListContactsParams,
  ListContactsResponse,
} from 'backend/api-types/dashboard';
import authController from 'auth/AuthenticationController';
import {
  convertContactToUser,
  UserNotificationStatus,
  UserRole,
  userRoleToNumberMap,
  UserStatus,
  UserSummary,
} from 'auth/user/types';
import { dashboardService } from '../backend/services';
import AsyncData from '../cache/AsyncData';
import useAsyncData from '../cache/useAsyncData';
import { CreateContactFormValues } from './create/CreateContactForm/useCreateContactForm';

export default class ContactsController extends AsyncData<
  ListContactsResponse,
  ListContactsParams,
  Array<UserSummary>
> {
  protected _axios: AxiosInstance = dashboardService;

  constructor() {
    super('contacts', '/contact');
  }

  @bind
  // eslint-disable-next-line class-methods-use-this
  protected _mapData(data: ListContactsResponse): Array<UserSummary> {
    return data.contacts.map(convertContactToUser);
  }

  /**
   * @param values Form field values.
   * @param overflow Account has reached its plan seat limit so force this contact to be created as an overflow seat.
   */
  @action
  public async addContact(
    values: CreateContactFormValues,
    overflow = false
  ): Promise<UserSummary> {
    const body: CreateContactRequest = {
      ...values,
      role: userRoleToNumberMap[values.role] ?? 2,
      overflow,
    };
    const res = await dashboardService.post<CreateContactResponse>(
      '/contact',
      body
    );

    const summary = {
      id: res.data.id ?? 0,
      ...values,
      lastLogin: 0,
      moreInfo: '',
      status: UserStatus.Invited,
    };

    // Update the list of contacts
    if (this._data) {
      this._data = [...this._data, summary];
    }
    return summary;
  }

  @action
  public async deleteContact(id: number, permanently: boolean): Promise<void> {
    await dashboardService.delete(`/contact/${id}?delete=${permanently}`);

    // Update the list of contacts
    if (this._data && permanently) {
      this._data = this._data.filter((contact) => contact.id !== id);
    } else if (this._data) {
      const index = this._data.findIndex((item: UserSummary) => item.id === id);
      this._data[index].status = UserStatus.Disabled;
      this._data = [...this._data];
    }
  }

  @bind
  public async invite(email: string) {
    // Declaring this in a separate line so that Typescript enforces that we are sending the proper request body
    const requestBody: CreateContactRequest = {
      email,
      role: 2, // Newly invited users are all given the 'User' role
      notifications: UserNotificationStatus.None,
    };

    const res = await dashboardService.post<CreateContactResponse>(
      '/contact',
      requestBody
    );

    // Update the cached data
    if (this._data) {
      this._data.push({
        id: res.data.id,
        email,
        lastLogin: 0,
        moreInfo: '',
        role: UserRole.User,
        notificationsStatus: UserNotificationStatus.None,
        status: UserStatus.Invited,
      });
    }
  }
}

type UseContactsOptions = {
  /** Skip the initial fetching of data. */
  skip?: boolean;
};

export function useContacts(
  options: UseContactsOptions = {}
): ContactsController {
  const { skip = false } = options;
  return useAsyncData<ContactsController>(
    new ContactsController(),
    skip || !authController.isLoggedIn
  );
}
