import React from 'react';
import {
  Menu,
  MenuProps,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Icon,
  Text,
  Flex,
} from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';
import { HiPlusCircle } from 'react-icons/hi';
import { Link as RouterLink } from 'react-router-dom';
import { BusinessOverview } from 'backend/api-types/dashboard';
import routes from 'routes';
import useSwitchTeam from './useSwitchTeam';

interface SwitchTeamMenuProps extends Omit<MenuProps, 'children'> {
  currentTeam?: string;
  teams: BusinessOverview[] | null;
}

const SwitchTeamMenu: React.FC<SwitchTeamMenuProps> = ({
  currentTeam,
  teams,
  ...props
}) => {
  const [switchTeam, isSwitchPending] = useSwitchTeam();
  const handleSwitchTeam = (id: number, name: string) => () =>
    switchTeam(id, name);

  const canShowMenu = teams && teams.length > 1;

  return (
    <Menu autoSelect={false} {...props}>
      <MenuButton
        display="flex"
        alignItems="center"
        data-tour-id="switch-team"
        opacity={isSwitchPending ? 0.5 : 1}
        disabled={isSwitchPending}
      >
        <Flex alignItems="center">
          <Text isTruncated maxWidth={{ md: '165px', xl: 'none' }}>
            {currentTeam}
          </Text>
          <TriangleDownIcon boxSize={2} mx={1} />
        </Flex>
      </MenuButton>
      <MenuList maxWidth="xs">
        <MenuItem
          as={RouterLink}
          to={routes.team.create}
          fontWeight="semibold"
          color="blue.500"
        >
          <Icon as={HiPlusCircle} color="blue.500" boxSize={5} mr={1} />
          Create a new team
        </MenuItem>
        {canShowMenu && teams && (
          <MenuGroup title="My Teams">
            {teams.map((team) => (
              <MenuItem
                key={team.id}
                onClick={handleSwitchTeam(team.id, team.name)}
              >
                <Text as="span" isTruncated>
                  {team.name}
                </Text>
              </MenuItem>
            ))}
          </MenuGroup>
        )}
      </MenuList>
    </Menu>
  );
};

export default SwitchTeamMenu;
