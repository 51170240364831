
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.1,"time":66000,"words":220}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "https://xqmessage.com/api/client/img/crossplatform_api.svg",
        "alt": null
      }}></img></p>
    <h1 {...{
      "id": "who-is-xq--people-who-care-about-data"
    }}>{`Who Is XQ?  People Who Care About Data`}</h1>
    <h3 {...{
      "id": "xq-brings-together-software-developers-and-security-experts-from-silicon-valley-and-washington-dc"
    }}>{`XQ brings together software developers and security experts from Silicon Valley and Washington DC.`}</h3>
    <p>{`What makes XQ special is our strong technical and product development expertise combined with a deep knowledge of state-sponsored cyberattacks.`}</p>
    <p>{`Our team includes inventors, graphic designers, coding types, security nerds as well as people who have protected US Presidents (which is a very difficult job to get even though it doesn’t seem that difficult right now).`}</p>
    <h3 {...{
      "id": "early-on-the-xq-team-realized-that-data-exfiltration-attacks-from-email-servers-and-cloud-apps-was-almost-a-daily-occurance"
    }}>{`Early on the XQ team realized that data exfiltration attacks from email servers and cloud apps was almost a daily occurance.`}</h3>
    <p>{`The confusing part is that enterprises now spend $100 Billion on security but it doesn’t seem to slow down the attackers. So the XQ team set about designing a data protection solution from a clean sheet of paper (at a bar in San Francisco).  What we realized and led to the product concept that became XQ is that to truly protect data you must embed encryption, routing and management functions into the application stack before it reaches the cloud. Protecting data once you’re lost possesion is impossible so locking it down the instant it’s been created is the key to success.`}</p>
    <p><a parentName="p" {...{
        "href": "https://xqmsg.co/about"
      }}><strong parentName="a">{`Learn More About XQ`}</strong></a></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "xq-brings-together-software-developers-and-security-experts-from-silicon-valley-and-washington-dc",
    level: 3,
    title: "XQ brings together software developers and security experts from Silicon Valley and Washington DC.",
    children: [
        
      ]
  },
{
    id: "early-on-the-xq-team-realized-that-data-exfiltration-attacks-from-email-servers-and-cloud-apps-was-almost-a-daily-occurance",
    level: 3,
    title: "Early on the XQ team realized that data exfiltration attacks from email servers and cloud apps was almost a daily occurance.",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "About XQ",
  "description": "About XS",
  "route": "/about",
  "disableTableOfContents": true
}

