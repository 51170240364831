import React from 'react';
import { Stack, StackProps } from '@chakra-ui/react';

export const FilterMenuStack: React.FC<StackProps> = (props) => {
  return (
    <Stack
      direction={['column', 'row']}
      alignItems={['flex-start', 'flex-end']}
      spacing={[2, 4]}
      {...props}
    />
  );
};

export const FiltersContainer: React.FC<StackProps> = (props) => {
  return (
    <Stack
      direction={['column', 'row']}
      justify={['flex-start', 'space-between']}
      alignItems={['flex-start', 'flex-end']}
      spacing={[2, 4]}
      my={4}
      pl={[0, 4]}
      pr={[0, 4]}
      {...props}
    />
  );
};
