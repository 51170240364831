import React from 'react';
import { Text } from '@chakra-ui/react';
import {
  CenteredLayoutHeading,
  CenteredLayoutContent,
  CenteredLayoutContentInner,
  CenteredLayout,
} from 'shared/components/CenteredLayout';

/**
 * NOTE:
 * ----------------------------------------------------------------------------------
 * This component should only be seen when a user who has been invited to XQ verifies
 * there account, but they do not have access to the dashboard. This is the case for
 * "Vendor" and "Customer" user roles.
 * ----------------------------------------------------------------------------------
 */
const AccountVerifiedScene: React.FC = () => {
  return (
    <CenteredLayout showLogo>
      <CenteredLayoutContent>
        <CenteredLayoutHeading>
          Thank you for verifying your account!
        </CenteredLayoutHeading>

        <CenteredLayoutContentInner maxWidth="2xl" textAlign="center">
          <Text>You may now close this window</Text>
        </CenteredLayoutContentInner>
      </CenteredLayoutContent>
    </CenteredLayout>
  );
};

export default AccountVerifiedScene;
