import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  useDimensions,
  useMediaQuery,
} from '@chakra-ui/react';
import { FilterMenu, FilterMenuStack } from 'shared/components/FilterMenu';
import {
  TableCap,
  TableCapContent,
  TableCapWrapper,
  TableWrapper,
} from 'shared/components/Table';
import BackToTopFAB from 'shared/components/BackToTopFAB';
import { MINIMUM_STICKY_VIEWPORT_HEIGHT } from 'shared/components/Table/Table';
import ContactsTable from './ContactsTable';
import routes from '../routes';
import useContactsTable from './useContactsTable';

const ListContactsScene: React.FC = () => {
  let tableCapHeight: string | undefined;
  const history = useHistory();
  const { data, loading, finished, filters } = useContactsTable();
  const tableCapRef = useRef<HTMLDivElement>(null);
  const tableCapDimensions = useDimensions(tableCapRef, true);

  // Any smaller than this and the table will need horizontal scrolling,
  // which breaks the sticky table header cells.
  const [enableSticky] = useMediaQuery(
    `(min-width: 1280px) and (min-height: ${MINIMUM_STICKY_VIEWPORT_HEIGHT}px)`
  );

  // get the height of the table cap for sticky anchor point.
  if (tableCapDimensions?.contentBox.height) {
    tableCapHeight = `${
      Math.round(tableCapDimensions?.contentBox.height) + 1
    }px`;
  }

  const handleRowClick = (id: number) => {
    history.push(routes.detail.activity(id));
  };

  return (
    <>
      <Box maxWidth="6xl" width="100%" mx="auto" my={8} zIndex={2}>
        <Heading as="h1" size="lg" mb={1}>
          Team
        </Heading>
        <Heading as="p" size="md" fontWeight="normal">
          These people are part of your team. You can track and verify
          communications sent among team members.
        </Heading>
      </Box>
      <Box maxWidth="6xl" width="100%" mx="auto">
        <TableCapWrapper isSticky={enableSticky} ref={tableCapRef}>
          <TableCap isLoading={loading}>
            <TableCapContent>
              <FilterMenuStack>
                <Text fontWeight="bold">Filter By:</Text>
                <FilterMenu
                  name="Role"
                  options={filters.roles.filters}
                  activeFilterCount={filters.roles.activeCount}
                  onFilterClick={filters.roles.update}
                />
              </FilterMenuStack>
            </TableCapContent>
          </TableCap>
        </TableCapWrapper>

        {finished && tableCapHeight && (
          <TableWrapper overflowX={enableSticky ? 'visible' : 'auto'}>
            <ContactsTable
              onRowClick={handleRowClick}
              data={data}
              enableSticky={enableSticky}
              stickyTop={tableCapHeight}
            />
          </TableWrapper>
        )}

        <BackToTopFAB />
      </Box>
    </>
  );
};

export default observer(ListContactsScene);
