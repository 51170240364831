import { useToast } from '@chakra-ui/react';
import { dashboardService } from 'backend/services';
import { SwitchCurrentBusinessResponse } from 'backend/api-types/dashboard';
import { useGlobalStore } from 'store/GlobalStoreContext';
import authController from 'auth/AuthenticationController';
import { formatErrorResponse } from 'shared/utils/errors';
import useAsyncAction from 'shared/hooks/useAsyncAction';

export default function useSwitchTeam() {
  const { cache } = useGlobalStore();
  const toast = useToast();

  const switchTeam = async (id: number, name: string) => {
    try {
      const res = await dashboardService.get<SwitchCurrentBusinessResponse>(
        `/business/${id}/auth`
      );
      authController.updateAccessToken(res.data);

      // Reset the cache to clear all the old data and make room for the new
      cache.reset();

      toast({
        title: `Switched to ${name}`,
        description: 'You are now viewing data for a new team',
        status: 'success',
        position: 'bottom-right',
      });
    } catch (err) {
      toast({
        title: 'Error switching team',
        description: formatErrorResponse(err).message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  return useAsyncAction(switchTeam);
}
