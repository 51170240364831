import { createLocalStorageStateHook } from 'use-local-storage-state';

const useTutorialAPIKeyState = createLocalStorageStateHook(
  'tutorial.apiKey',
  ''
);

export default function useTutorialAPIKey() {
  const [apiKey, setAPIKey] = useTutorialAPIKeyState();
  return {
    apiKey,
    setAPIKey,
  };
}

export type UseTutorialAPIKeyReturn = ReturnType<typeof useTutorialAPIKey>;
