import React from 'react';
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import APIKeyForm from 'api-keys/create/APIKeyForm';

interface CreateFirstAPIKeyProps {
  appId: number;
  name?: string;
  onSuccess: (apiKey: string) => void;
}

const CreateFirstAPIKey: React.FC<CreateFirstAPIKeyProps> = ({
  appId,
  name,
  onSuccess,
}) => {
  return (
    <Flex flex={{ base: 'none', lg: 1 }}>
      <Grid
        templateColumns={{ base: '1fr', lg: 'minmax(300px, 500px) auto' }}
        flex={1}
      >
        <Flex
          px={8}
          py={{ base: 4, lg: 16 }}
          bg="primary.900"
          color="white"
          fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}
        >
          <Box maxWidth={{ base: 'auto', lg: '370px' }} my="auto">
            {name && (
              <Text color="primary.500" mb={2}>
                {name},
              </Text>
            )}
            <Text>
              Welcome to your application dashboard. Manage keys, webhooks and
              application details all in one place.
              <br />
              <br />
              Add your first key to start.
            </Text>
          </Box>
        </Flex>
        <Flex bg="white" p={8}>
          <Box m="auto" width="100%" maxWidth="lg">
            <Heading
              fontSize={{ base: 'lg', lg: '2xl' }}
              fontWeight="normal"
              mt={12}
              mb={4}
            >
              Add your first API key
            </Heading>
            <APIKeyForm appId={appId} onSuccess={onSuccess} />
          </Box>
        </Flex>
      </Grid>
    </Flex>
  );
};

export default CreateFirstAPIKey;
