import bind from 'bind-decorator';
import { action, computed, observable } from 'mobx';
import jwtDecode from 'jwt-decode';
import {
  dashboardService,
  setAuthToken,
  removeAuthToken,
} from 'backend/services';
import {
  CreateLogoutResponse,
  LoginRequest,
  SendMagicLink,
  SignUpRequestBody,
} from 'backend/api-types/dashboard';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { globalCache } from 'store/GlobalStoreContext';

const ACCESS_TOKEN_KEY = 'accessToken';
const ACCESS_TOKEN_VERIFICATION_INTERVAL = 60000; // 60 Seconds

export type TokenData = {
  // Workspace name
  aud: string;

  // Expiry date in seconds epoch
  exp: number;

  // Issuer: "dashboard"
  iss: string;

  // Users email address
  sub: string;

  // Signature
  jti: string;
};

export class AuthenticationController {
  public static async requestLoginLink(email: string): Promise<void> {
    const body: SendMagicLink = {
      email,
    };

    await dashboardService.post('/login/link', body);
  }

  public static async signUp(email: string, optIn: boolean): Promise<void> {
    const body: SignUpRequestBody = {
      email,
      optIn,
    };
    await dashboardService.post<string>('/signup', body);
  }

  @observable
  private _isLoggedIn = false;

  @observable
  private _accessToken?: string;

  @observable
  private _loading = false;

  @observable
  private _isLoggingOut = false;

  constructor() {
    this._accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) ?? undefined;

    if (this._accessToken) {
      this._loading = true;
      setAuthToken(this._accessToken);
      setImmediate(this._checkAuthentication);
    }
  }

  @computed
  public get isLoggedIn() {
    return this._isLoggedIn;
  }

  @computed
  public get accessToken() {
    return this._accessToken;
  }

  @computed
  public get data(): TokenData | null {
    if (!this._accessToken) {
      return null;
    }

    try {
      return jwtDecode(this._accessToken) as TokenData;
    } catch (err) {
      // This token is not a properly formatted JWT token. This should never actually happen but here as a sanity check
      console.error('Invalid JWT Token: ', err);
      return null;
    }
  }

  @computed
  public get loading() {
    return this._loading;
  }

  @computed
  public get isLoggingOut() {
    return this._isLoggingOut;
  }

  /**
   * Validate the existing access token. It is important that this method never throw
   * an error.
   */
  private static async _validateAccessToken(): Promise<boolean> {
    try {
      await dashboardService.get('/session');
      return true;
    } catch {
      return false;
    }
  }

  @bind
  private async _checkAuthentication(): Promise<void> {
    if (!this._accessToken) {
      this._isLoggedIn = false;
      this._loading = false;
      return;
    }

    const valid = await AuthenticationController._validateAccessToken();
    if (!valid) {
      this._isLoggedIn = false;
      this._accessToken = undefined;
      this._loading = false;

      localStorage.removeItem(ACCESS_TOKEN_KEY);
      removeAuthToken();

      // Reset the cache once the user logs out to clear away any stored data.
      //
      // NOTE: This needs to be done as the last step to avoid causing
      // any networks requests to re-issue while the user is being logged out.
      globalCache.reset();
      return;
    }

    if (!this._isLoggedIn) {
      this._isLoggedIn = true;
    }

    if (this._loading) {
      this._loading = false;
    }

    // Schedule the next check of the whether or not the user is still logged in
    let timeTillNextCheck = ACCESS_TOKEN_VERIFICATION_INTERVAL;
    if (
      this.data?.exp &&
      Date.now() + ACCESS_TOKEN_VERIFICATION_INTERVAL > this.data.exp * 1000
    ) {
      // The token is set to expire before the next check takes place. Lets schedule for 1 millisecond
      // past the expiry instead of waiting the entire interval.
      //
      // Safety applies a minimum wait time of 0 milliseconds in the bizarre event of the wait
      // time becoming negative.
      timeTillNextCheck = Math.max(this.data.exp * 1000 - Date.now() + 1, 0);
    }
    setTimeout(this._checkAuthentication, timeTillNextCheck);
  }

  @bind
  public async login(body: LoginRequest): Promise<void> {
    const res = await dashboardService.post<string>('/login', body);
    this._loading = false;
    this._isLoggedIn = true;
    this.updateAccessToken(res.data);

    // Start checking whether or not you still have access
    setImmediate(this._checkAuthentication);
  }

  @bind
  public async logout(): Promise<void> {
    this._isLoggingOut = true;

    try {
      await dashboardService.post<CreateLogoutResponse>('/logout');
    } catch (err) {
      // This should only ever fail due to network issues.
      // Let's ignore the error and continue with the logout.
      console.warn('Failed to logout: ', err);
    }

    removeAuthToken();
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    this._accessToken = undefined;
    this._isLoggedIn = false;
    this._isLoggingOut = false;

    // Reset the cache once the user logs out to clear away any stored data.
    //
    // NOTE: This needs to be done as the last step to avoid causing
    // any networks requests to re-issue while the user is being logged out.
    globalCache.reset();
  }

  @bind
  @action
  public updateAccessToken(token: string) {
    this._accessToken = token;
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    setAuthToken(token);
  }

  @bind
  public async loginWithToken(idToken: string): Promise<void> {
    return this.login({
      method: 1,
      pwd: idToken,
    });
  }

  @bind
  public async verifyAccount(token: string): Promise<boolean> {
    const res = await dashboardService.get<string>('/login/verify', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200 || !res.data) {
      // The request was successful but we did not get an access token back. This
      // means that the user is properly verified but does not have access to the
      // dashboard application; they must be a "Vendor" or "Customer"
      return false;
    }

    // If we get an access token back, we can log the user in.
    this.updateAccessToken(res.data);
    this._isLoggedIn = true;

    // Start checking whether or not you still have access
    setImmediate(this._checkAuthentication);
    return true;
  }
}

const authController = new AuthenticationController();
export default authController;

export function useLogout() {
  return useAsyncAction<void>(authController.logout);
}
