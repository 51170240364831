import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'auth/routes';
import {
  CenteredLayoutHeading,
  CenteredLayoutContent,
  CenteredLayoutContentInner,
} from 'shared/components/CenteredLayout';

const VerifyError: React.FC = () => {
  return (
    <CenteredLayoutContent>
      <CenteredLayoutHeading>Oops, something went wrong</CenteredLayoutHeading>

      <CenteredLayoutContentInner textAlign="center">
        <Text mb={8}>
          This new account sign up has expired or it is no longer valid.
        </Text>
        <Button as={RouterLink} to={routes.signup}>
          Try again
        </Button>
      </CenteredLayoutContentInner>
    </CenteredLayoutContent>
  );
};

export default VerifyError;
