import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { Switch, Link as RouteLink, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Link,
  useMediaQuery,
} from '@chakra-ui/react';
import LoadingBox from 'shared/components/LoadingBox';
import AccessDenied from 'shared/components/AccessDenied';
import RequestErrorAlert from 'shared/components/RequestErrorAlert';
import routes from 'routes';
import ProtectedRoute from 'auth/ProtectedRoute';
import { MINIMUM_STICKY_VIEWPORT_HEIGHT } from 'shared/components/Table/Table';
import ModalState from 'shared/states/ModalState';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import UpdateContactModal from 'contacts/update/UpdateContactModal';
import ContactData from './ContactData';
import ActivityFeed from './ActivityFeed';
import { useContact } from '../ContactDetailsController';
import KeyPacketsList from './KeyPacketsList';

export const TableNavItems = (contactId: number) => [
  {
    label: 'Activity',
    route: routes.contacts.detail.activity(contactId),
  },
  {
    label: 'Communications',
    route: routes.contacts.detail.communications(contactId),
  },
];

const ContactDetailsScene: React.FC = () => {
  const currentUser = useCurrentUser();
  const { current: editContactModalState } = useRef(new ModalState());
  const { contactId } = useParams<{ contactId: string }>();
  const user = useContact(Number(contactId));
  const [enableSticky] = useMediaQuery(
    `(min-width: 1280px) and (min-height: ${MINIMUM_STICKY_VIEWPORT_HEIGHT}px)`
  );

  if (!currentUser.access.canViewContactDetails(Number(contactId))) {
    return <AccessDenied />;
  }

  if (user.loading) {
    return <LoadingBox />;
  }

  if (!user.data || user.error) {
    const contactNotFound = user.error?.response?.status === 404;
    return (
      <RequestErrorAlert canTryAgain={!contactNotFound}>
        {contactNotFound ? (
          <>
            This contact does not exist or does not belong to your team.
            <Link as={RouteLink} to={routes.contacts.root} ml={2}>
              My Contacts
            </Link>
          </>
        ) : (
          'Could not load the contact information.'
        )}
      </RequestErrorAlert>
    );
  }

  return (
    <>
      <Box maxWidth="6xl" width="100%" mx="auto">
        <SimpleGrid
          spacing={8}
          columns={{ xl: 2 }}
          maxWidth="100%"
          gridTemplateColumns={{ xl: '350px auto' }}
        >
          <Flex flexDirection="column" justifyContent="space-between">
            <Box
              position="sticky"
              top={0}
              p={6}
              border="solid 1px"
              borderColor="gray.100"
              borderRadius="md"
              maxWidth="100%"
            >
              <Flex justifyContent="space-between" mb={1}>
                {currentUser.access.canViewContacts &&
                  currentUser.access.canEditContact(
                    Number(contactId),
                    user.data.role
                  ) && (
                    <Button
                      variant="link"
                      colorScheme="primary"
                      size="xs"
                      onClick={editContactModalState.open}
                    >
                      Edit Info
                    </Button>
                  )}
              </Flex>
              <ContactData user={user} />
            </Box>
          </Flex>
          {user.data && (
            <UpdateContactModal
              id={user.data.id}
              isOpen={editContactModalState.isOpen}
              onClose={editContactModalState.close}
            />
          )}
          <Box maxWidth="100%" overflowX={enableSticky ? 'visible' : 'hidden'}>
            <Switch>
              <ProtectedRoute path={routes.contacts.detail.activity()} exact>
                <ActivityFeed contactId={Number(contactId)} />
              </ProtectedRoute>
              <ProtectedRoute
                path={routes.contacts.detail.communications()}
                exact
              >
                <KeyPacketsList
                  contactId={Number(contactId)}
                  contactEmail={user.data.email}
                />
              </ProtectedRoute>
            </Switch>
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
};

export default observer(ContactDetailsScene);
