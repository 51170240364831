import React from 'react';
import { observer } from 'mobx-react';
import { useParams, useHistory, Link as RouteLink } from 'react-router-dom';
import { Box, Button, Flex, Link, SimpleGrid } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useApplications } from 'application/ApplicationsController';
import { useAPIKeys } from 'api-keys/APIKeysController';
import { useEventDetails } from 'event-log/EventController';
import LevelIndicatorIcon from 'event-log/components/LevelIndicatorIcon';
import { EventThreatLevel } from 'event-log/list/useEventLog';
import LoadingBox from 'shared/components/LoadingBox';
import RequestErrorAlert from 'shared/components/RequestErrorAlert';
import { useEventTypes } from 'event-log/EventTypesController';
import routes from 'routes';
import RelatedEventsTable from './RelatedEventsTable';

const EventDetails: React.FC = () => {
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();
  const applications = useApplications();
  const apiKeys = useAPIKeys();
  const eventController = useEventDetails(Number(eventId));
  const eventTypes = useEventTypes();
  const threatLevel = eventController.data?.threat || 0;

  if (eventController.loading) {
    return <LoadingBox />;
  }

  if (!eventController.data) {
    const eventNotFound = eventController.error?.response?.status === 404;
    return (
      <RequestErrorAlert canTryAgain={!eventNotFound}>
        {eventNotFound ? (
          <>
            This event does not exist or does not belong to your team. Go back
            to{' '}
            <Link as={RouteLink} to={routes.eventLog.viewLog}>
              event log
            </Link>
          </>
        ) : (
          'Failed to load the details for this event'
        )}
      </RequestErrorAlert>
    );
  }

  const relationKey = eventController.data.relatedTo;
  const userName = eventController.data.user;
  const apiKeyName =
    apiKeys.getById(eventController.data?.key ?? 0)?.name ?? '';
  const appName =
    applications.getById(eventController.data?.app ?? 0)?.name ?? '';
  return (
    <Box flex="none" width="100%" maxWidth="6xl" mx="auto">
      <Flex display="flex" justifyContent="space-between">
        <Button
          colorScheme="gray"
          leftIcon={<ChevronLeftIcon boxSize={4} />}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Flex>

      <Box bg="white" p={4} mt={4} mb={8} position="relative" zIndex={2}>
        <SimpleGrid columns={[1, 1, 2]}>
          <SimpleGrid
            columns={2}
            gridTemplateColumns="100px auto"
            gridRowGap={4}
            gridColumnGap={4}
          >
            <Box fontWeight="bold">Log #: </Box>
            <Box>{eventController.data?.id} </Box>
            <Box fontWeight="bold">Threat Level: </Box>
            <Flex flexShrink={2} align="center">
              <Box mr={2}>
                <LevelIndicatorIcon threat={threatLevel} mx="auto" />
              </Box>
              {EventThreatLevel[threatLevel]}
            </Flex>
            {!!userName && (
              <>
                <Box fontWeight="bold">Contact: </Box>
                <Box>{userName}</Box>
              </>
            )}
            {!!appName && (
              <>
                <Box fontWeight="bold">Application: </Box>
                <Box>{appName}</Box>
              </>
            )}
            {!!apiKeyName && (
              <>
                <Box fontWeight="bold">API Key: </Box>
                <Box>{apiKeyName}</Box>
              </>
            )}
            <Box fontWeight="bold">Event Type: </Box>
            <Box>{eventTypes.getTitle(eventController.data.action)}</Box>
          </SimpleGrid>
          <SimpleGrid
            columns={2}
            gridTemplateColumns="100px auto"
            gridRowGap={4}
            gridTemplateRows="24px"
            pt={[4, 4, 0]}
          >
            <Box fontWeight="bold">Location: </Box>
            <Box>{eventController.data?.location} </Box>
            <Box fontWeight="bold">IP Address: </Box>
            <Box>{eventController.data?.ip} </Box>
          </SimpleGrid>
        </SimpleGrid>
      </Box>

      {!!relationKey && <RelatedEventsTable relationKey={relationKey} />}
    </Box>
  );
};

export default observer(EventDetails);
