import React, { useState } from 'react';
import {
  Button,
  ButtonProps,
  ButtonGroup,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import routes from 'routes';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'shared/components/Table';
import { CommunicationStatus, CommunicationSummary } from 'key-packet/types';
import NoResultsBox from 'shared/components/NoResultsBox';
import useNavigateLazy from 'shared/hooks/useNavigateLazy';
import LockedEvent from 'monitoring/components/LockedEvent';
import ThreatIcon from 'activity/components/ThreatIcon';
import KeyPacketStatus from './KeyPacketStatus';
import KeyPacketRetrieved from './KeyPacketRetrieved';
import KeyPacketRecipients from './KeyPacketRecipients';
import KeyPacketDescription from './KeyPacketDescription';
import KeyPacketExpiryDate from './KeyPacketExpiryDate';
import KeyPacketUpdatedDate from './KeyPacketUpdatedDate';
import RecipientsVerificationBadge from './RecipientsVerificationBadge';

const LOCKED_EVENT_ID = 0;

interface TableHeadButtonProps extends ButtonProps {
  isActive?: boolean;
}

const TableHeadButton: React.FC<TableHeadButtonProps> = ({
  isActive = false,
  children,
  ...props
}) => {
  return (
    <Button
      type="button"
      variant="link"
      color="inherit"
      py={0}
      px={0}
      borderRadius={0}
      minWidth="auto"
      opacity={isActive ? 1 : 0.5}
      textTransform="inherit"
      {...props}
    >
      {children}
    </Button>
  );
};

interface KeyPacketTableProps {
  enableSticky?: boolean;
  stickyTop: string | undefined;
  data: CommunicationSummary[] | null;
  canViewSender?: boolean;
  /** Skeleton table rows will be displayed while the initial data is loading. */
  isInitialLoading?: boolean;
}

const KeyPacketTable: React.FC<KeyPacketTableProps> = ({
  data,
  enableSticky = true,
  stickyTop,
  canViewSender = false,
  isInitialLoading = false,
}) => {
  const [showSender, setShowSender] = useState(canViewSender);
  const [showUpdated, setShowUpdated] = useState(false);
  const navigateTo = useNavigateLazy();

  if (!data || !data.length) {
    return (
      <NoResultsBox heading="No communications matched these filters">
        Expand your filter criteria to find more communications
      </NoResultsBox>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Level
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Read
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Resource
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            {canViewSender ? (
              <ButtonGroup spacing={2}>
                <TableHeadButton
                  isActive={showSender}
                  onClick={() => setShowSender(true)}
                >
                  Sender
                </TableHeadButton>
                <Text as="span">/</Text>
                <TableHeadButton
                  isActive={!showSender}
                  onClick={() => setShowSender(false)}
                >
                  Recipients
                </TableHeadButton>
              </ButtonGroup>
            ) : (
              <>Recipients</>
            )}
          </TableCell>
          <TableCell
            isSticky={enableSticky}
            stickyTop={stickyTop}
            textAlign="center"
          >
            Verified
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            <ButtonGroup spacing={2}>
              <TableHeadButton
                isActive={!showUpdated}
                onClick={() => setShowUpdated(false)}
              >
                Expires
              </TableHeadButton>
              <Text as="span">/</Text>
              <TableHeadButton
                isActive={showUpdated}
                onClick={() => setShowUpdated(true)}
              >
                Updated
              </TableHeadButton>
            </ButtonGroup>
          </TableCell>
          <TableCell
            isSticky={enableSticky}
            stickyTop={stickyTop}
            textAlign="right"
            pl={2}
          >
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((resource, idx) => {
          return resource.id === LOCKED_EVENT_ID ? (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={resource.id + idx}>
              <TableCell colSpan={7} pl={0}>
                <Skeleton isLoaded={!isInitialLoading}>
                  <LockedEvent />
                </Skeleton>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow
              key={resource.id}
              onClick={() => navigateTo(routes.keyPacket.detail(resource.id))}
              data-tour-id={idx === 0 ? 'first-communication-table-row' : ''}
            >
              <TableCell textAlign="center">
                <Skeleton isLoaded={!isInitialLoading}>
                  <ThreatIcon
                    threatLevel={resource.threat}
                    boxSize={6}
                    showTooltip
                  />
                </Skeleton>
              </TableCell>
              <TableCell textAlign="center">
                <Skeleton isLoaded={!isInitialLoading}>
                  <KeyPacketRetrieved
                    type={resource.type}
                    retrieved={resource.accesses}
                  />
                </Skeleton>
              </TableCell>
              <TableCell
                width="100%"
                minWidth="200px"
                maxWidth="100%"
                isTruncated
              >
                <Skeleton isLoaded={!isInitialLoading}>
                  <KeyPacketDescription
                    type={resource.type}
                    meta={resource.meta}
                  />
                </Skeleton>
              </TableCell>
              <TableCell>
                <Skeleton isLoaded={!isInitialLoading}>
                  {showSender ? (
                    <Text>{resource.user}</Text>
                  ) : (
                    <>
                      {isInitialLoading ? (
                        <Text>Loading</Text>
                      ) : (
                        <KeyPacketRecipients recipients={resource.recipients} />
                      )}
                    </>
                  )}
                </Skeleton>
              </TableCell>
              <TableCell>
                <Skeleton
                  isLoaded={!isInitialLoading}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <RecipientsVerificationBadge isVerified={resource.verified} />
                </Skeleton>
              </TableCell>
              <TableCell whiteSpace="nowrap">
                <Skeleton isLoaded={!isInitialLoading}>
                  {showUpdated ? (
                    <KeyPacketUpdatedDate updatedDate={resource.updated} />
                  ) : (
                    <KeyPacketExpiryDate
                      expiryDate={resource.expires}
                      isExpired={
                        resource.status === CommunicationStatus.Expired
                      }
                    />
                  )}
                </Skeleton>
              </TableCell>
              <TableCell>
                <Skeleton
                  isLoaded={!isInitialLoading}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <KeyPacketStatus status={resource.status} showTooltip />
                </Skeleton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default KeyPacketTable;
