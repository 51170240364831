import React from 'react';
import { ButtonGroup, ButtonGroupProps, Button } from '@chakra-ui/react';
import cx from 'classnames';

interface TeamFilterNavProps extends Omit<ButtonGroupProps, 'onSelect'> {
  isTeamView: boolean;
  onSelect: (isTeam: boolean) => void;
}

const TeamFilterNav: React.FC<TeamFilterNavProps> = ({
  isTeamView,
  onSelect,
  ...props
}) => {
  return (
    <ButtonGroup spacing={4} data-tour-id="activity-team-filter" {...props}>
      <Button
        className={cx({ active: !isTeamView })}
        variant="activeSolid"
        size="sm"
        type="button"
        onClick={() => onSelect(false)}
      >
        My Activity
      </Button>
      <Button
        className={cx({ active: isTeamView })}
        variant="activeSolid"
        size="sm"
        type="button"
        onClick={() => onSelect(true)}
      >
        Team Activity
      </Button>
    </ButtonGroup>
  );
};

export default TeamFilterNav;
