import React from 'react';
import JoyRide, { Step } from 'react-joyride';
import { firstTourStepProps, defaultJoyrideProps } from './constants';
import useTour from './useTour';

const steps: Step[] = [
  {
    target: '[data-tour-id="activity-date-filter"]',
    title: 'Filter by date range',
    content:
      'Date filters affect all of the communications and activities data on this screen.',
    spotlightPadding: 10,
    styles: {
      spotlight: {
        borderRadius: 100,
      },
    },
    ...firstTourStepProps,
  },
  {
    target: '[data-tour-id="activity-team-filter"]',
    title: 'Switch to your team activity',
    content:
      'View your own communications and activities or switch to see everything your team is doing.',
    spotlightPadding: 10,
    styles: {
      spotlight: {
        borderRadius: 100,
      },
    },
  },
  {
    target: '[data-tour-id="activity-communications"]',
    title: 'Communications',
    content:
      'Keep tabs on every communication you have created and track which ones have expired.',
    spotlightPadding: 0,
    offset: 0,
    styles: {
      spotlight: {
        borderRadius: '8px',
      },
    },
  },
  {
    target: '[data-tour-id="activity-activities-stats"]',
    title: 'Activities',
    content:
      'View the status of your activities so you can investigate any that need attention.',
    spotlightPadding: 0,
    offset: 0,
    styles: {
      spotlight: {
        borderRadius: '8px',
      },
    },
  },
];

const ActivityTour = () => {
  const tour = useTour('activity');

  if (tour.isFinished) {
    return null;
  }

  return (
    <JoyRide
      steps={steps}
      run={tour.run}
      callback={tour.handleCallback}
      disableScrollParentFix
      {...defaultJoyrideProps}
    />
  );
};

export default React.memo(ActivityTour);
