import {
  Avatar,
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useApplications } from 'application/ApplicationsController';
import authController, { useLogout } from 'auth/AuthenticationController';
import { useCurrentUser } from 'auth/user/CurrentUserController';
import { useContacts } from 'contacts/ContactsController';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { HiOutlineDocument, HiOutlineMail } from 'react-icons/hi';
import {
  IoAppsOutline,
  IoChatbubbleEllipsesOutline,
  IoDocumentsOutline,
  IoExitOutline,
  IoInformationCircleOutline,
  IoKeyOutline,
  IoLockOpenOutline,
  IoLogInOutline,
  IoPersonCircleOutline,
  IoSpeedometerOutline,
  IoStatsChartOutline,
} from 'react-icons/io5';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { PlanType } from 'settings/account/plan/constants';
import { useCurrentBusiness } from 'team/CurrentBusinessController';
import dashes from './dashes.svg';
import LinkGroup, {
  GroupedSubrouteDefinition,
  LinkOptions,
  SubRouteDefinition,
} from './LinkGroup';
import SwitchTeamModal from './SwitchTeamModal';

const external: LinkOptions = { external: true, target: '_blank' };

const Divider: React.FC = () => {
  return <chakra.hr my={4} borderColor="gray.100" />;
};

type RouteConfig = {
  documentation: {
    subRoutes: SubRouteDefinition[];
    grouped?: GroupedSubrouteDefinition[];
  };
  about: {
    subRoutes: SubRouteDefinition[];
    grouped?: GroupedSubrouteDefinition[];
  };
};

interface NavigationProps {
  onClose: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ onClose }) => {
  /**
   * HACK:
   * There is a bug in Chakra where the "Collapse" component doesn't render properly
   * when it is "true" from the get go. That means the all child routes are inaccessible
   * and hidden when a user refreshes there screen. To get around this, there is blip where
   * no subRoutes are defined, and they get defined after the first render.
   *
   * Once this is fixed in Chakra, we can remove this hack.
   */
  const [routeConfig, setRouteConfig] = useState<RouteConfig>({
    documentation: {
      subRoutes: [],
      grouped: [],
    },
    about: {
      subRoutes: [],
      grouped: [],
    },
  });
  const user = useCurrentUser();
  const business = useCurrentBusiness();
  const contacts = useContacts();
  const apps = useApplications();
  const [logout] = useLogout();
  const switchTeamModal = useDisclosure();
  const currentTeam = business.data?.name;

  const applicationRoutes: Array<SubRouteDefinition> = useMemo(() => {
    return (
      apps.data?.map((a) => [routes.application.detail(a.id), a.name]) ?? []
    );
  }, [apps.data]);

  const canUpgrade =
    user.access.canModifyPlan &&
    business.data?.subscription.plan === PlanType.Free &&
    contacts.data &&
    contacts.data.length > 1;

  const showSwitchTeams = true; // !business.data?.isPersonal;

  useEffect(() => {
    setRouteConfig({
      documentation: {
        subRoutes: [
          [routes.docs.intro, 'Introduction'],
          [routes.docs.quickStart, 'Quick Start Guide'],
          /* [routes.docs.auth, 'Authorization and Access'],
          [routes.docs.applications, 'Application Management'],
          [routes.docs.subscriber, 'Subscriber Management'],
          [routes.docs.keys, 'Key Management'],
          [routes.docs.webHooks, 'Web Hooks'],
          [routes.docs.sampleApplications, 'Sample Applications'], */
        ],
        grouped: [
          user.access.canAccessDeveloperFeatures
            ? {
                heading: 'Tutorials',
                routes: [
                  [routes.tutorials.encrypt.chapter0, 'Encrypt a message'],
                  [routes.tutorials.decrypt.chapter0, 'Decrypt a message'],
                ],
              }
            : null,
          {
            heading: 'API Reference',
            routes: [
              /* [routes.docs.apiReference, 'Introduction'], */
              [routes.docs.quantum, 'Quantum'],
              [routes.docs.subscription, 'Subscription'],
              [routes.docs.validation, 'Validation'],
            ],
          },
          {
            heading: 'Client SDKs',
            routes: [
              /* [routes.docs.clientSDKs, 'Introduction'], */
              ['https://github.com/xqmsg/jssdk-core', 'Javascript', external],
              ['https://github.com/xqmsg/javasdk-core', 'Java', external],
              ['https://github.com/xqmsg/csdk-core', 'C', external],
            ],
          },
        ].filter((ex) => ex) as GroupedSubrouteDefinition[],
      },
      about: {
        subRoutes: [
          ['https://xqmsg.co/contact-us', 'Contact Us', external],
          ['https://xqmsg.co/terms', 'Terms of Service', external],
          ['https://xqmsg.co/privacy-policy', 'Privacy Policy', external],
        ],
      },
    });
  }, [user.access.canAccessDeveloperFeatures]);

  // Check to see if the clicked element inside the navigation is a link so the
  // mobile drawer can be automatically closed.
  const handleWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const el = e.target as HTMLElement;
    const link = el.closest('a');
    if (link) {
      onClose();
    }
  };

  return (
    <Flex
      flex={1}
      flexDirection="column"
      pt={4}
      overflowY="auto"
      css={{
        WebkitOverflowScrolling: 'touch',
      }}
      onClick={handleWrapperClick}
    >
      <Box flex="none">
        <Flex
          pl="56px"
          pr={4}
          justifyContent="flex-end"
          display={{ base: 'flex', md: 'none' }}
        >
          {canUpgrade && (
            <Button
              as={Link}
              size="xs"
              variant="outline"
              colorScheme="green"
              to={routes.settings.plans}
            >
              Upgrade
            </Button>
          )}
        </Flex>
        <Flex
          display={{ base: 'flex', md: 'none' }}
          flexWrap="wrap"
          alignItems="center"
          pt={4}
          pb={3}
          pl="24px"
          pr={4}
        >
          {showSwitchTeams ? (
            <>
              <Text
                fontWeight="semibold"
                maxWidth={{ base: '150px', sm: '250px' }}
                mr={2}
                isTruncated
              >
                {currentTeam}
              </Text>
              <Button
                type="button"
                flex="none"
                variant="outline"
                size="xs"
                ml="auto"
                onClick={switchTeamModal.onOpen}
              >
                Switch Team
              </Button>
            </>
          ) : (
            <Button
              as={Link}
              size="xs"
              variant="outline"
              ml="auto"
              to={routes.team.create}
            >
              Create Team
            </Button>
          )}
        </Flex>
        <SwitchTeamModal {...switchTeamModal} onCloseNavigation={onClose} />

        <Heading as="h3" fontSize="lg" pl={6} mt={8} mb={4}>
          Monitor
        </Heading>

        <LinkGroup
          isProtected
          to={routes.home}
          title="Activity"
          Icon={IoSpeedometerOutline}
          exact
        />
        <LinkGroup
          isProtected
          to={routes.keyPacket.list.user}
          isActive={(_, location) =>
            location.pathname.startsWith(routes.keyPacket.root)
          }
          title="Communications"
          Icon={IoChatbubbleEllipsesOutline}
        />
        {user.access.canViewContacts && (
          <LinkGroup
            isProtected
            to={routes.contacts.root}
            isActive={(_, location) =>
              location.pathname.startsWith(routes.contacts.root)
            }
            title="Team"
            Icon={IoPersonCircleOutline}
          />
        )}

        <Divider />

        <Heading as="h3" fontSize="lg" pl={6} mt={8} mb={4}>
          Secure Messaging
        </Heading>

        <LinkGroup
          isProtected
          to={routes.encryption.encryptMessage}
          title="Encrypt Message"
          Icon={HiOutlineMail}
        />
        <LinkGroup
          isProtected
          to={routes.encryption.encryptFile}
          title="Encrypt File"
          Icon={HiOutlineDocument}
        />
        <LinkGroup
          isProtected
          to={routes.encryption.decryptFile}
          title="Decrypt File"
          Icon={IoKeyOutline}
        />

        <Divider />

        <Heading as="h3" fontSize="lg" pl={6} mt={8} mb={4}>
          Audit Tools
        </Heading>

        <LinkGroup
          isProtected
          to={routes.export.root}
          title="Export"
          Icon={IoExitOutline}
          exact
        />
        <LinkGroup
          isProtected
          to={routes.unlock.root}
          title="Unlock"
          Icon={IoLockOpenOutline}
          exact
        />

        {authController.isLoggedIn && <Divider />}

        {user.access.canAccessDeveloperFeatures && (
          <>
            <Heading as="h3" fontSize="lg" pl={6} mt={8} mb={4}>
              Developers
            </Heading>

            <LinkGroup
              isProtected
              to={routes.application.root}
              Icon={IoAppsOutline}
              title="Applications"
              subRoutes={applicationRoutes}
            />
            <LinkGroup
              isProtected
              to={routes.eventLog.viewLog}
              title="Event Log"
              Icon={IoStatsChartOutline}
            />
          </>
        )}

        <LinkGroup
          to={routes.docs.root}
          Icon={IoDocumentsOutline}
          title="Documentation"
          subRoutes={routeConfig.documentation.subRoutes}
          groupedSubRoutes={routeConfig.documentation.grouped}
        />
        {authController.isLoggedIn && <Divider />}
        <LinkGroup
          to={routes.docs.about}
          title="About XQ"
          Icon={IoInformationCircleOutline}
          subRoutes={routeConfig.about.subRoutes}
        />
      </Box>

      <Box marginTop="auto">
        {authController.isLoggedIn ? (
          <Flex
            onClick={() => logout()}
            role="button"
            color="gray.400"
            fontWeight="normal"
            alignItems="center"
            pl={6}
            pt={2}
            pb={4}
            display={{ base: 'flex', lg: 'none' }}
          >
            <Avatar
              bg="blue.500"
              color="white"
              size="xs"
              mr="10px"
              name={user.fullName}
              src={user.avatar}
            />
            Logout
          </Flex>
        ) : (
          <LinkGroup
            to={routes.auth.login}
            Icon={IoLogInOutline}
            title="Login"
          />
        )}

        <Image src={dashes} width="100%" />
      </Box>
    </Flex>
  );
};

export default observer(Navigation);
