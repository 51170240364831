import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import CreateApplicationForm from 'application/create/CreateApplicationForm';

const CreateFirstApplication: React.FC = () => {
  return (
    <>
      <Box borderBottom="1px" borderBottomColor="gray.50" p={6}>
        <Heading textTransform="uppercase" size="xs" my={0} color="primary.900">
          Create your first application
        </Heading>
      </Box>
      <Box width="100%" pt={8} pb={16} px={6}>
        <CreateApplicationForm />
      </Box>
    </>
  );
};

export default CreateFirstApplication;
