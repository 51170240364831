import React from 'react';
import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import {
  HiOutlineMail,
  HiOutlineDocument,
  HiOutlineChat,
} from 'react-icons/hi';
import { KeyPacketSummary } from 'backend/api-types/dashboard';

type KeyPacketTypeConfig = {
  icon: React.ElementType;
  title: string;
  color: string;
};

export const keyPacketTypeConfig: Record<
  KeyPacketSummary['type'],
  KeyPacketTypeConfig
> = {
  0: {
    icon: HiOutlineChat,
    title: 'Message',
    color: 'inherit',
  },
  1: {
    icon: HiOutlineDocument,
    title: 'File',
    color: 'inherit',
  },
  2: {
    icon: HiOutlineMail,
    title: 'Email',
    color: 'inherit',
  },
};

interface KeyPacketRetrievedProps {
  type: KeyPacketSummary['type'];
  retrieved: number;
}

const KeyPacketRetrieved: React.FC<KeyPacketRetrievedProps> = ({
  type,
  retrieved,
}) => {
  const config = keyPacketTypeConfig[type];
  const renderedCount = retrieved.toLocaleString();

  return (
    <Tooltip
      hasArrow
      bg="gray.500"
      label={`Resource retrieved ${renderedCount} times`}
      placement="top"
    >
      <Flex direction="column" alignItems="center" color={config.color}>
        <Icon
          as={config.icon}
          color="inherit"
          boxSize={6}
          title={config.title}
        />
        <Text
          fontSize="sm"
          fontWeight="semibold"
          sx={{
            fontVariantNumeric: 'tabular-nums',
          }}
        >
          {renderedCount}
        </Text>
      </Flex>
    </Tooltip>
  );
};

export default KeyPacketRetrieved;
