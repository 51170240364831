import React, { useCallback } from 'react';
import { Skeleton } from '@chakra-ui/react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'shared/components/Table';
import NoResultsBox from 'shared/components/NoResultsBox';
import {
  DateCellContent,
  LocationCellContent,
} from 'event-log/components/table-cells';
import { EventLogItem } from 'event-log/types';
import LockedEvent from 'monitoring/components/LockedEvent';
import ActivityDescription from 'activity/components/ActivityDescription';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

const LOCKED_EVENT_ID = 0;

interface ActivityFeedTableProps {
  enableSticky?: boolean;
  stickyTop?: string;
  data: EventLogItem[] | null;
  /** Skeleton table rows will be displayed while the initial data is loading. */
  isInitialLoading?: boolean;
  hideUser?: boolean;
  noResultsHeading?: string;
  noResultsContent?: React.ReactNode;
}

const ActivityFeedTable: React.FC<ActivityFeedTableProps> = ({
  data,
  isInitialLoading,
  enableSticky = true,
  stickyTop = 0,
  hideUser,
  noResultsHeading = 'No activities found matching these filters',
  noResultsContent = 'Expand your date range or filters to find more activities',
}) => {
  const history = useHistory();

  const goToEventDetails = useCallback(
    (id: number) => {
      history.push(routes.eventLog.details(id));
    },
    [history]
  );

  if (!data || !data.length) {
    return (
      <NoResultsBox heading={noResultsHeading}>{noResultsContent}</NoResultsBox>
    );
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Activity
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Date
          </TableCell>
          <TableCell isSticky={enableSticky} stickyTop={stickyTop}>
            Location + IP Address
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((event, i) => {
          return event.id === LOCKED_EVENT_ID ? (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={event.id + i}>
              <TableCell colSpan={3} pl={0}>
                <Skeleton isLoaded={!isInitialLoading}>
                  <LockedEvent />
                </Skeleton>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow
              key={event.id}
              onClick={() => {
                goToEventDetails(event.id);
              }}
            >
              <TableCell width="60%" wordBreak="break-word" minWidth="200px">
                <Skeleton isLoaded={!isInitialLoading}>
                  <ActivityDescription event={event} hideUser={hideUser} />
                </Skeleton>
              </TableCell>
              <TableCell minWidth="140px">
                <Skeleton isLoaded={!isInitialLoading}>
                  <DateCellContent date={event.created} />
                </Skeleton>
              </TableCell>
              <TableCell>
                <Skeleton isLoaded={!isInitialLoading}>
                  <LocationCellContent
                    location={event.location}
                    ip={event.ip}
                  />
                </Skeleton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ActivityFeedTable;
